import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {BondClaimData} from "../../../models/classes/BondClaimData";
import {defaultPrepLogoUrl} from "../../../common/constants";
import {BaseClass} from "../../../base-class";
import {PersistenceService} from "../../../services/persistence.service";
import {ModalType} from "../../../models/enums/ModalType";
import {BondClaimModalPayload} from "../../../models/classes/BondClaimModalPayload";
import {ModalService} from "../../../services/modal.service";
import {StateChangeService} from "../../../services/state-change.service";
import {AppConfigService} from "../../../services/app-config.service";
import {CalculationService} from "../../../services/calculation-service/calculation.service";
import {PriceOracleService} from "../../../services/price-oracle.service";
import BigNumber from "bignumber.js";
import {Subscription} from "rxjs";

@Component({
  selector: "app-my-bond-row",
  templateUrl: "./my-bond-row.component.html"
})
export class MyBondRowComponent extends BaseClass implements OnInit, OnDestroy {

  @Input() bond!: BondClaimData;

  // template data variables
  principalImg?: string;
  principalImgWidth?: string;
  principalTag?: string;
  payoutTag?: string;
  payoutAmount?: BigNumber;
  pendingPayoutFor?: BigNumber;
  vestingLeftInSeconds?: BigNumber;

  userDataSub?: Subscription;

  constructor(private modalService: ModalService,
              public override persistenceService: PersistenceService,
              private stateChangeService: StateChangeService,
              private appConfigService: AppConfigService,
              private calculationService: CalculationService,
              private priceOracleService: PriceOracleService) {
    super(persistenceService);
  }

  ngOnInit(): void {
    this.initData();

    this.subscribeToUserDataReloadUpdate();
  }

  ngOnDestroy(): void {
    // un-subscribe from user data reload subscription
    this.userDataSub?.unsubscribe();
  }

  subscribeToUserDataReloadUpdate(): void {
    // reload template variables after user data reload
    this.stateChangeService.userDataFinishedLoading$.subscribe(() => {
      if (!this.modalService.activeModal && !document.hidden) {
        this.initData();
      }
    });
  }

  initData(): void {
    this.principalImg = this.bond.bond.principalToken.img;
    this.principalImgWidth = this.bond?.bondPrincipalIsLp() ? "58" : "32";
    this.principalTag = this.bond?.bond?.principalToken?.tag;
    this.payoutTag = this.bond?.bond?.payoutToken?.tag;
    this.payoutAmount = this.bond?.bondInfo?.payout;
    this.pendingPayoutFor = this.bond?.pendingPayoutFor;
    this.vestingLeftInSeconds = this.bond?.vestingLeftInSeconds();
  }

  onClaimClick(bond: BondClaimData) {
    const res = this.calculationService.calculateClaimBondDiscountUSD(bond);
    this.modalService.setActiveModal(ModalType.CLAIM, new BondClaimModalPayload(
      bond.pendingPayoutFor,
      bond.bondInfo.payout,
      res.bondDiscount,
      bond.vestingLeftInSeconds(),
      res.bondPriceUSD,
      this.priceOracleService.getIrc2TokenPrice(bond.bond.payoutToken),
      bond.bond
    ));
  }

  handlePrincipalImgError($event: any) {
    $event.target.src = defaultPrepLogoUrl;
  }

}
