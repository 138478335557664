import Axios, {AxiosInstance, AxiosRequestConfig} from "axios";
import {RestApiUrls} from "./rest-api-urls";
import {isValidDid} from "../validation/validation";
import {Magic} from "magic-sdk";
import {Consts} from "./consts";
import {BridgeError} from "../models/errors/bridgeError";
import {extractDetailMessageFromError, log} from "./Utils";

async function signRequest(config: AxiosRequestConfig, magic: Magic) {

    let bridgeToken: string | null;
    if (process.env.TEST == "true") bridgeToken = "test";
    else bridgeToken = localStorage.getItem("bridgeToken");
    if (!bridgeToken) {
        throw new BridgeError("Network error, are you logged in?",
            Error("DID token does not exists, user not logged in?"))
    }

    // if bridge token has expired, create new one
    if (process.env.TEST != "true" && !isValidDid(bridgeToken))
        bridgeToken = await refreshDidToken(magic);

    // if token is null there is no login session
    if (bridgeToken == null) {
        throw new BridgeError("Network error, are you logged in?",
            Error("bridgeToken === null -> User is logged out!"))
    }

    config["headers"]["Authorization"] = `Token ${bridgeToken}`;

    return config;
}

async function refreshDidToken(magic: Magic): Promise<string> {
    log("Refreshing did token..");
    const bridgeToken = await magic.user.generateIdToken({lifespan: Consts.DID_LIFESPAN});
    localStorage.setItem("bridgeToken", bridgeToken);

    return bridgeToken;
}

export class RequestsWrapper {

    public axios: AxiosInstance;
    public magic: Magic;

    constructor(magic: Magic) {
        this.magic = magic;

        this.axios = Axios.create({
            baseURL: RestApiUrls.BASE_REST_API_URL,
            headers: {
                common: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            }
        });

        // Response interceptor for API calls
        this.axios.interceptors.response.use((response) => {
            return response
        }, async (error) => {
            const originalRequest = error.config;

            if (error?.response?.status === 403 && String(error.response.data?.message).includes("expired") && !originalRequest._retry) {
                originalRequest._retry = true;
                const bridgeToken = await refreshDidToken(this.magic);
                Axios.defaults.headers.common['Authorization'] = `Token ${bridgeToken}`;
                return this.axios(originalRequest);
            }

            let message: string

            if (error.response) {
                // (5xx, 4xx) code
                if (error.response?.data['message']) {
                    if (extractDetailMessageFromError(error)) {
                        message = "PrimeTrust: " + extractDetailMessageFromError(error);
                    } else {
                        message = error.response.data['message']
                    }
                } else {
                    message = "Server responded with an error. Please try again later."
                }
            } else if (error.request) {
                // no response
                message = "Please check your internet connection and try again later."
            } else {
                message = "Unknown error occurred. Please try again later."
            }

            return Promise.reject(new BridgeError(message, error));
        });

        // Add a request interceptor
        this.axios.interceptors.request.use(async config => {
            config = await signRequest(config, this.magic);

            return config;
        });
    }

}


