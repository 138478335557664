import {Injectable} from '@angular/core';
import {NavItem} from '../models/enums/NavItem';
import {NavigationEnd, Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  /**
   * This service is used to handle and share the header component state
   */

  // currently active menu in header
  private activeMenu: NavItem = NavItem.MARKETPLACE;

  constructor(private router: Router) {
    router.events.subscribe( (event) => ( event instanceof NavigationEnd ) && this.deriveNavItem() );
  }

  deriveNavItem() {
    console.log("deriveNavItem for url = " + this.router.url);
    if (this.router.url === "/my-bonds") {
      this.activeMenu = NavItem.MY_BONDS
    } else if (this.router.url === "/dashboard") {
      this.activeMenu = NavItem.DASHBOARD
    } else {
      this.activeMenu = NavItem.MARKETPLACE
    }
  }

  // handleActiveMenuChange(activeMenu: NavItem) {
  //   this.activeMenu = activeMenu;
  // }

  isMarketplaceActive(): boolean {
    return this.activeMenu === NavItem.MARKETPLACE;
  }

  isMenuActive(menu: NavItem): boolean {
    return this.activeMenu === menu;
  }
}
