import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {Wallet} from '../../models/classes/Wallet';
import log from 'loglevel';
import {NotificationService} from '../../services/notification.service';
import {ModalService} from '../../services/modal.service';
import {LoginService} from '../../services/login.service';
import {LedgerService} from '../../services/ledger.service';
import {BaseClass} from '../../base-class';
import {PersistenceService} from '../../services/persistence.service';
import {ModalType} from '../../models/enums/ModalType';
import BigNumber from 'bignumber.js';

@Component({
  selector: 'app-ledger-modal',
  templateUrl: './ledger-modal.component.html'
})
export class LedgerModalComponent extends BaseClass {

  @ViewChild('ledgerModal', { read: ElementRef }) LedgerAddressListModal!: ElementRef;

  @Output() showLoadingModal = new EventEmitter<void>();

  // window on which user is on (e.g. 1st = [0, 1, 2, 3, 4])
  activeLedgerAddressWindow = 0;
  activeLedgerAddressPageList = [0, 1, 2, 3, 4];
  // page that the user has selected
  selectedLedgerAddressPage = 0;
  // default window and page size
  ledgerAddressPageSize = 5;

  ledgerWallets: Wallet[] = [];

  constructor(private notificationService: NotificationService,
              private modalService: ModalService,
              private loginService: LoginService,
              private ledgerService: LedgerService,
              public override persistenceService: PersistenceService) {
    super(persistenceService);
  }

  onSignInLedgerClick(): void {
    // set default pagination values
    this.activeLedgerAddressWindow = 0;
    this.selectedLedgerAddressPage = 0;
    this.activeLedgerAddressPageList = [0, 1, 2, 3, 4];

    this.fetchLedgerWallets();
  }

  onSelectLedgerAddressClick(wallet: Wallet): void {
    this.modalService.hideActiveModal();
    this.loginService.walletLogin(wallet);
  }

  onLedgerAddressPageClick(page: number): void {
    this.selectedLedgerAddressPage = page;
    this.fetchLedgerWallets();
  }

  onLedgerPageNextClick(): void {
    this.activeLedgerAddressWindow += 1;
    this.activeLedgerAddressPageList = [];

    const start = this.activeLedgerAddressWindow * this.ledgerAddressPageSize;
    const end = this.activeLedgerAddressWindow * this.ledgerAddressPageSize + this.ledgerAddressPageSize - 1;

    for (let i = start; i <= end; i++) {
      this.activeLedgerAddressPageList.push(i);
    }

    log.debug("******** onLedgerPageNextClick ********");
    log.debug(`activeLedgerAddressWindow = ${this.activeLedgerAddressWindow}`);
    log.debug(`activeLedgerAddressPageList = ${this.activeLedgerAddressPageList}`);
    log.debug(`selectedLedgerAddressPage = ${this.activeLedgerAddressPageList[0]}`);

    this.selectedLedgerAddressPage = this.activeLedgerAddressPageList[0];

    this.fetchLedgerWallets();
  }

  onLedgerPageBackClick(): void {
    if (this.activeLedgerAddressWindow === 0 && this.selectedLedgerAddressPage === 0) {
      return;
    }

    this.activeLedgerAddressWindow -= 1;
    this.activeLedgerAddressPageList = [];

    const start = this.activeLedgerAddressWindow * this.ledgerAddressPageSize;
    const end = this.activeLedgerAddressWindow * this.ledgerAddressPageSize + this.ledgerAddressPageSize - 1;

    for (let i = start; i <= end; i++) {
      this.activeLedgerAddressPageList.push(i);
    }

    this.selectedLedgerAddressPage = this.activeLedgerAddressPageList[0];

    this.fetchLedgerWallets();
  }

  ledgerIcxBalance(wallet: Wallet): BigNumber {
    return wallet.balances.get("ICX") ?? new BigNumber("0");
  }

  fetchLedgerWallets(): void {
    this.modalService.hideActiveModal();

    // emit show loading modal to modal component
    this.showLoadingModal.emit();

    this.ledgerService.getLedgerWallets(this.selectedLedgerAddressPage).then(wallets => {
      this.ledgerWallets = wallets;

      this.modalService.hideActiveModal();
      this.modalService.setActiveModal(ModalType.LEDGER);
    }).catch(e => {
      this.modalService.hideActiveModal();
      log.error(e);
      this.notificationService.showAlertNotification("Couldn’t detect Ledger.\n" +
        "Make sure it’s connected and try again.");
    });
  }

  isModalActive(type: ModalType): boolean {
    return this.modalService.activeModal?.type === type;
  }

  hideActiveModal(): void {
    this.modalService.hideActiveModal();
  }

}
