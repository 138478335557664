import {LitElement, html, customElement, property} from 'lit-element';
import { environment } from '../../environment/environment';
import { User } from '../../lib/models/User/User';

//css styles
// @ts-ignore
import mainCSS from '../../assets/css/main.css';
// @ts-ignore
import normalizeCSS from '../../assets/css/normalize.css';

@customElement('withdraw-elem')
class WithdrawElement extends LitElement {
  
  constructor() {
    super();
    this.iframeUrl = environment.STABLY_IFRAME_URL;
  }

  static styles = [
    mainCSS,
    normalizeCSS
  ];

  @property() user: User | undefined;
  @property() iframeUrl: string;

  private backToHomeViewEvent(){
    // re-render the iframe
    (this.shadowRoot!.querySelector('.stably-iframe') as HTMLIFrameElement).src += '';
    let event = new CustomEvent('backToHomeView', {});
    this.dispatchEvent(event);
  }

  protected updated(_changedProperties: Map<string, any>) {
    super.updated(_changedProperties);

    if(_changedProperties.has('user')){
      this.iframeUrl = `${environment.STABLY_IFRAME_URL}?walletAddr=${this.user?.iconWalletAddress}&user_email=${this.user?.email}&trade_method=sell`;
    }
  }

  protected render(): unknown {
    return html`
    <div class="iframe-container">

      <a class="iframe-close-icon" @click=${this.backToHomeViewEvent}>
        x
      </a>
      <div>
        <iframe id='withdraw-iframe' class='stably-iframe' src=${this.iframeUrl}></iframe>
      </div>

    </div>
    `
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'withdraw-elem': WithdrawElement;
  }
}