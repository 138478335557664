import {ActionPayloadI} from '../interfaces/ActionPayloadI';
import {BondConfig} from './BondConfigInfo';
import BigNumber from 'bignumber.js';
import {Utils} from '../../common/utils';

export class TopUpPayloadSuccess implements ActionPayloadI {
  amount: BigNumber;
  bond: BondConfig;
  txHash: string;


  constructor(amount: BigNumber, bond: BondConfig, txHash: string) {
    this.amount = amount;
    this.bond = bond;
    this.txHash = txHash;
  }

  successMessage(): string {
    return `Successfully transferred ${Utils.formatNumberToUSLocaleString(this.amount.dp(2))} ${this.bond.payoutToken.tag}.}`
  }

  errorMessage(): string {
    return `Failed to transfer ${this.bond.payoutToken.tag}. Please try again in a moment.`
  }
}
