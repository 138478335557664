import BigNumber from "bignumber.js";

export class BondInfo {
  // payout token remaining to be paid
  payout: BigNumber;
  // Blocks left to vest
  vesting: BigNumber;
  // Last interaction
  lastBlock: BigNumber;
  // Price paid (principal tokens per payout token) in ten-millionths - 4000000 = 0.4
  truePricePaid: BigNumber;

  constructor(payout: BigNumber, vesting: BigNumber, lastBlock: BigNumber, truePricePaid: BigNumber) {
    this.payout = payout;
    this.vesting = vesting;
    this.lastBlock = lastBlock;
    this.truePricePaid = truePricePaid;
  }
}
