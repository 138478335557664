// @ts-ignore
import BridgeService from "../../../../build/bridge.bundle";
import BigNumber from 'bignumber.js';
import {Irc2TokenTag} from '../types/Types';


export class Wallet {
  address?: string;
  email?: string;
  type?: WalletType;
  name: string;
  bridge?: BridgeService;
  path?: string;
  balances: Map<Irc2TokenTag, BigNumber>;

  constructor(address: string, type: WalletType, bridge?: BridgeService, email?: string, path?: string) {
    this.address = address;
    this.type = type;
    this.name = getWalletName(type);
    this.bridge = bridge;
    this.email = email
    this.path = path;
    this.balances = new Map<Irc2TokenTag, BigNumber>();
  }
}

export enum WalletType {
  ICON,
  BRIDGE,
  LEDGER
}

export function getWalletName(type: WalletType): string {
  switch (type) {
    case WalletType.ICON:
      return "ICON wallet"
    case WalletType.LEDGER:
      return "Ledger wallet"
    case WalletType.BRIDGE:
      return "Bridge wallet"
  }
}
