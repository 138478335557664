import {hideSpan, showSpanAsBlock} from "./view-transitions";

export function enterPressed(e:KeyboardEvent):boolean {
    return e.code == "Enter";
}

// show span error and color border to red
export function showSpanError(message: string, span: HTMLElement, inputField: HTMLElement) {
    span.innerHTML = message;
    showSpanAsBlock(span);
    inputField.classList.add("border-red");
}

export function hideSpanError(span: HTMLElement, inputField: HTMLElement) {
    hideSpan(span);
    inputField.classList.remove("border-red");
}

export function formatTxAmount(transactionAmount : any) {
    if(!isNaN(transactionAmount)) {
        if(parseInt(transactionAmount) == transactionAmount) 
            return +transactionAmount;

        return transactionAmount > 1 ? 
            +parseFloat(transactionAmount).toFixed(2) :
            +parseFloat(transactionAmount).toFixed(4)
    }
    return transactionAmount;
}