import {Irc2TokenTransaction} from "./Irc2TokenTransaction";
export class BridgeTransaction {
    dateString: string;
    date: Date;
    amount: number;
    iconTransaction: Irc2TokenTransaction | undefined;
    type: TransactionType;


    constructor(dateString: string, date: Date, amount: number, iconTransaction: Irc2TokenTransaction | undefined,
                type: TransactionType) {
        this.dateString = dateString;
        this.date = date;
        this.amount = amount;
        this.iconTransaction = iconTransaction;
        this.type = type;
    }
}

export enum TransactionType {
    SENT = "Sent",
    RECEIVED = "Received"
}
