const IconService = require("icon-sdk-js");

export function isEoaAddress(address: string): boolean {
    if (!address) return false;
    return IconService.IconValidator.isEoaAddress(address);
}

// test if string is a digit without decimal points
export function isWholeDigit(value: string): boolean {
    if (!value) return false;
    return /^\d+$/.test(value);
}

export function amountContainsMaxOneDotFollowedBy2decimals(amount: number): boolean {
    // Amount should consist of only one dot or none (e.g. 1 or 1.2)
    return /^-?\d*\.?(?:\d{1,2})?$/.test(amount.toString())
}

export function containsOnlyDigits(value: string | undefined): boolean {
    if (!value) return false;
    return /^\d+$/.test(value);
}

export function isValidDid(didToken: string): boolean {
    if (!didToken) return false;
    const claim = JSON.parse(JSON.parse(atob(didToken))[1]);
    return Math.round(new Date().getTime() / 1000) <= claim.ext;
}




