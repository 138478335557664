import { LitElement, html, customElement, property } from 'lit-element';
import { environment } from '../../environment/environment';
import { User } from '../../lib/models/User/User';
import {hideModalView} from "../logic/view-transitions";

// @ts-ignore
import bankIcon from '../../assets/img/icon/bank.svg';
// @ts-ignore
import addCryptoIcon from '../../assets/img/icon/add-crypto.svg';


//css styles
// @ts-ignore
import mainCSS from '../../assets/css/main.css';
// @ts-ignore
import normalizeCSS from '../../assets/css/normalize.css';

@customElement('deposit-elem')
class DepositElement extends LitElement {

  constructor() {
    super();
    this.iframeUrl = environment.STABLY_IFRAME_URL;
  }

  static styles = [
    mainCSS,
    normalizeCSS
  ];

  @property() user: User | undefined;
  @property() iframeUrl: string;

  @property() landingView: HTMLElement | null | undefined;
  @property() stablyIframeView: HTMLElement | null | undefined;

  @property() activeView: HTMLElement | null | undefined;

  private backToHomeViewEvent() {
    this.showModalView(this.landingView);

    let event = new CustomEvent('backToHomeView', {});
    this.dispatchEvent(event);
  }

  private showBlockchainAddress() {
    this.showModalView(this.landingView);

    const event = new CustomEvent('redirectToAddressView', {});
    this.dispatchEvent(event);
  }

  protected firstUpdated(_changedProperties: Map<string | number | symbol, unknown>): void {
    this.landingView = this.shadowRoot!.getElementById('deposit-methods');
    this.stablyIframeView = this.shadowRoot!.getElementById('iframe-view');

    this.showModalView(this.landingView);
  }

  protected updated(_changedProperties: Map<string, any>) {
    super.updated(_changedProperties);

    if (_changedProperties.has('user')) {
      if (this.user?.iconWalletAddress) {
        this.iframeUrl = `${environment.STABLY_IFRAME_URL}?walletAddr=${this.user?.iconWalletAddress}&user_email=${this.user?.email}&trade_method=buy`;
      } else {
        this.iframeUrl = environment.STABLY_IFRAME_URL;
      }
    }
  }

  private showModalView(modalView: HTMLElement | null | undefined) {
    hideModalView(this.activeView);
    modalView!.style.display = "block";
    this.setActiveModalView(modalView);
  }

  private setActiveModalView(newActiveModal: HTMLElement | null | undefined) {
    this.activeView = newActiveModal;
  }

  private handleIframeClose() {
    // re-render the iframe
    (this.shadowRoot!.querySelector('.stably-iframe') as HTMLIFrameElement).src += '';
    this.showModalView(this.landingView);
  }

  protected render(): unknown {
    return html`

    <div class="widget" id="deposit-methods">
        <!-- Navigation -->
        <div class="grid">
          <div class="grid-cell">
            <a @click=${this.backToHomeViewEvent} class="back">Back</a>
          </div>
          <div class="grid-cell">
            <p class="label-upper text-center">Deposit</p>
          </div>
          <div class="grid-cell">

          </div>
        </div>
        <div class="margin-top-35">
          <table class="payments-list" >
            <tbody>
              <tr class="card" @click=${() => this.showModalView(this.stablyIframeView)}>
                <td><img src="${bankIcon}"></td>
                <td style="text-align: left; padding-left: 15px;">Buy crypto<br><span class="label-small">Use Stably to purchase USDS, a cryptocurrency equivalent to 1 USD.</span><br><span class="label-small" style="font-style: italic;">Requires a verified identity.</span></td>
              </tr>
              <tr>
                <td colspan="3" class="title" style="padding-bottom: 25px;"></td>
              </tr>
              <tr class="card" style="padding-top: 35px; margin-top: 25px;" @click=${this.showBlockchainAddress}>
                <td><img src="${addCryptoIcon}"></td>
                <td style="text-align:left; padding-left:15px;">Add existing crypto<br><span class="label-small">Copy your blockchain address and send compatible assets to it.</span></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      
      <div class="iframe-container" id="iframe-view" hidden>
        <a class="iframe-close-icon" @click=${this.handleIframeClose}>
          x
        </a>
        <div>
          <iframe id='deposit-iframe' class='stably-iframe' src=${this.iframeUrl}></iframe>
        </div>
      </div>
    `
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'deposit-elem': DepositElement;
  }
}