<div class="heading-section wf-section">
  <div class="container w-container">
    <h1 class="heading-2">My bonds <img class="refresh-button" src="assets/images/refresh.svg" alt="refresh" (click)="onRefreshClick()"></h1>
  </div>
</div>

<div class="pairs-section wf-section">
  <div class="container w-container">
    <div class="table table-header">
      <div class="table-header-item table-2-header-item-1">
        <div class="table-header-item-text">Bonds</div>
      </div>
      <div class="table-header-item table-2-header-item-2">
        <div class="table-header-item-text mobile-right roi">
          Claimable
          <img src="assets/images/tooltip-icon.svg" class="tooltip-icon" alt="">
          <span class="roi-tooltip">
              The amount of payout tokens ready to be claimed.
            </span>
        </div>
      </div>
      <div class="table-header-item hide-mobile table-2-header-item-3">
        <div class="table-header-item-text roi">
          Pending
          <img src="assets/images/tooltip-icon.svg" class="tooltip-icon" alt="">
          <span class="roi-tooltip">
              The amount of pending payout tokens to be vested.
            </span>
        </div>
      </div>
      <div class="table-header-item hide-mobile table-2-header-item-4">
        <div class="table-header-item-text roi">
          BOND VESTING TERM
          <img src="assets/images/tooltip-icon.svg" class="tooltip-icon" alt="">
          <span class="roi-tooltip">
            Shows the remaining days left until the bond is fully vested. A bond vests linearly to the bonder over
            a length of time, called the bond vesting term. This means the bonder can claim a portion of the reward
            tokens each day, with all rewards being claimable at the end of the term.
          </span>
        </div>
      </div>
      <div class="table-header-item hide-mobile table-2-header-item-5"></div>
    </div>

    <ng-container *ngFor="let bond of userBondClaimData;">
      <app-my-bond-row style="display: contents"
                       [bond]="bond"
      >

      </app-my-bond-row>
    </ng-container>

  </div>
</div>
