import {Irc2TokenTag} from '../types/Types';
import {OracleType} from '../enums/OracleType';

export class Irc2Token {
  tag: Irc2TokenTag;
  name: string;
  address: string;
  img: string;
  decimals: number;
  isStableCoin: boolean;
  oracle: OracleType;


  constructor(tag: Irc2TokenTag, name: string, address: string, decimals: number, isStableCoin: boolean, img: string, oracle: OracleType) {
    this.tag = tag;
    this.name = name;
    this.address = address;
    this.decimals = decimals;
    this.isStableCoin = isStableCoin;
    this.img = img;
    this.oracle = oracle;
  }
}
