import {Component, OnDestroy, OnInit} from '@angular/core';
import {DataLoaderService} from "./services/data-loader.service";
import {IconexApiService} from "./services/iconex-api.service";
import {ReloaderService} from './services/reloader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {

  private attachedListener: boolean;

  constructor(private dataLoaderService: DataLoaderService,
              private iconexApiService: IconexApiService,
              private reloaderService: ReloaderService) {
    // register Iconex handler
    window.addEventListener("ICONEX_RELAY_RESPONSE", (e: any) => this.iconexApiService.iconexEventHandler(e));
    this.attachedListener = true;
  }

  ngOnInit(): void {
    if (!this.attachedListener){
      window.addEventListener("ICONEX_RELAY_RESPONSE", (e: any) => this.iconexApiService.iconexEventHandler(e));
    }

    this.dataLoaderService.loadCoreData();
  }

  ngOnDestroy(): void {
    if (this.attachedListener){
      window.removeEventListener("ICONEX_RELAY_RESPONSE", (e: any) => this.iconexApiService.iconexEventHandler(e));
      this.attachedListener = true;
    }
  }
}
