<!-- SIGN IN MODAL-->
<div class="modal modal-wallet" [ngStyle]="displayAsBlockOrHide(isModalActive(ModalType.SIGN_IN))">
  <div class="sidebar-container">
    <div class="sidebar-heading">
      <div class="sidebar-heading-pair">
        <h2 class="text-white sidebar-heading-text">Sign in</h2>
      </div>
      <a data-w-id="08f152b5-b5b1-45c2-5bad-c7f867ad2717" href="javascript:void(0);" (click)="hideActiveModal()" class="sidebar-close w-inline-block"><img src="assets/images/close-arrow.svg" loading="lazy" alt=""></a>
    </div>

    <div class="sidebar-content-wrapper sidebar-table"></div>
    <div class="sidebar-content-text">
      <div class="row w-row">
        <div class="column sign-in-column w-col w-col-4 w-col-small-4 w-col-tiny-tiny-stack">
          <a href="javascript:void(0);" (click)="onLoginClick(WalletType.ICON)" class="icx-wallet-option w-inline-block"><img src="assets/images/icx-icon.svg" loading="lazy" width="10" alt="" class="image-4">
            <div class="icx-wallet-name">ICON</div>
          </a>
        </div>
        <div class="column sign-in-column w-col w-col-4 w-col-small-4 w-col-tiny-tiny-stack">
          <a href="javascript:void(0);" (click)="onLoginClick(WalletType.BRIDGE)" class="icx-wallet-option w-inline-block"><img src="assets/images/uasb-white.png" loading="lazy" width="10" alt="" class="image-4">
            <div class="icx-wallet-name">Bridge</div>
          </a>
        </div>
        <div class="column sign-in-column w-col w-col-4 w-col-small-4 w-col-tiny-tiny-stack">
          <a href="javascript:void(0);" (click)="onLoginClick(WalletType.LEDGER)" class="icx-wallet-option w-inline-block"><img src="assets/images/ledger-1.png" loading="lazy" width="10" alt="" class="image-4">
            <div class="icx-wallet-name">Ledger</div>
          </a>
        </div>
      </div>
    </div>
    <div class="sidebar-content-text">
      <div class="sign-in-disclaimer">Early contributors are not liable for any lost or stolen funds<br>
        <a href="https://www.karmafinance.org/disclaimer" target="_blank" class="disclaimer-popup-link">View disclaimer</a>
      </div>
    </div>
  </div>
</div>


<!-- WALLET MODAL -->
<div class="modal modal-view-wallet" [ngStyle]="displayAsBlockOrHide(isModalActive(ModalType.WALLET))">
  <div class="sidebar-container">
    <div class="sidebar-heading">
      <div class="sidebar-heading-pair">
        <h2 class="text-white sidebar-heading-text">Wallet</h2>
      </div>
      <a data-w-id="bc244b2b-7012-02d5-9396-7b638950f0ea" href="javascript:void(0);" (click)="hideActiveModal()"
         class="sidebar-close w-inline-block"><img src="assets/images/close-arrow.svg" loading="lazy" alt=""></a>
    </div>
    <h4 class="text-white wallet-balance">Wallet info</h4>
    <div class="sidebar-content-wrapper sidebar-table">
      <div class="sidebar-table-row">
        <div class="sidebar-table-column">
          <div class="sidebar-table-text-left">Wallet</div>
        </div>
        <div class="sidebar-table-column">
          <div class="sidebar-table-text-right">{{persistenceService.activeWallet?.name}}</div>
        </div>
      </div>
      <div class="sidebar-table-row">
        <div class="sidebar-table-column">
          <div class="sidebar-table-text-left">Address</div>
        </div>
        <div class="sidebar-table-column">
          <div class="sidebar-table-text-right">{{formatIconAddressToShort(persistenceService.activeWallet?.address)}}</div>
        </div>
      </div>
    </div>
    <app-token-balances style="display: contents">

    </app-token-balances>
  </div>
  <div class="sidebar-content-utility-buttons utility-buttons-wallet">
    <div class="row w-row">
      <div class="column w-col w-col-6">
        <a href="javascript:void(0);" (click)="onCopyIconAddressClick()" class="button button-utility copy-address">
          {{copyString}}
        </a>
      </div>
      <div class="column w-col w-col-6">
        <a href="javascript:void(0);" (click)="onSignOutClick()" class="button button-utility">Sign out</a>
      </div>
    </div>
  </div>
</div>

<app-ledger-modal (showLoadingModal)="showLoadingModal()"
> </app-ledger-modal>

<!-- BOND MODAL -->
<app-bond-modal style="display: contents"
                *ngIf="isBondModalActive()"
                [payload]="activeModalPayload()"
                (hideModalEvent)="hideActiveModal()"
                (showSignInModalEvent)="showSignInModal()"
                >

</app-bond-modal>

<!-- SUCCESS BOND MODAL -->
<div class="modal modal-success" [ngStyle]="displayAsBlockOrHide(isModalActive(ModalType.BOND_SUCCESS))">
  <div class="sidebar-container">
    <ng-lottie *ngIf="showConfetti" [styles]="styles" [options]="options" class="confetti" (animationCreated)="animationCreated($event)"></ng-lottie>
    <div class="sidebar-heading">
      <div class="sidebar-heading-pair">
        <h2 class="text-white sidebar-heading-text">Bond Discount Secured!</h2>
      </div>
      <a data-w-id="c34ebc8b-794c-db6e-ce6e-5b63278fcb43" href="javascript:void(0);" (click)="hideActiveModal()"
         class="sidebar-close w-inline-block"><img src="assets/images/close-arrow.svg" loading="lazy" alt=""></a>
    </div>
    <div class="sidebar-content-wrapper sidebar-table">
      <div class="sidebar-content-text">
        <div class="success-message">Visit Karma Bond anytime to claim your vested portion. <br>The bond will fully vest after {{secondsToHumanizedDateString(successBondVestingTerm())}}</div>
      </div>
      <div class="sidebar-table-row sidebar-table-row-success">
        <div class="sidebar-table-column">
          <div class="sidebar-table-text-left">Your Incoming bond</div>
        </div>
        <div class="sidebar-table-column">
          <div class="sidebar-table-text-right">{{successBondPayoutAmount() | roundDown2Dec | toZeroIfDash}} {{payoutTokenName()}}</div>
        </div>
      </div>
      <div class="sidebar-table-row">
        <div class="sidebar-table-column">
          <div class="sidebar-table-text-left roi">
            Your Incoming bKarma
            <img src="assets/images/tooltip-icon.svg" class="tooltip-icon">
            <span class="roi-tooltip">
              <div class="roi-tooltip-close" onclick="document.getElementByClassName('roi-tooltip').style.display = 'none';">&times;</div>
              The amount of bKARMA you will receive. For each 1 USD equivalent of fees collected by the Karma Bond protocol, you will be rewarded with 1 bKARMA.
            </span>
          </div>
        </div>
        <div class="sidebar-table-column">
          <div class="sidebar-table-text-right">{{successBondbKarmaAmount()}} bKarma</div>
        </div>
      </div>
      <div class="sidebar-table-row">
        <div class="sidebar-table-column">
          <div class="sidebar-table-text-left roi">
            Vesting term
            <img src="assets/images/tooltip-icon.svg" class="tooltip-icon">
            <span class="roi-tooltip">
              <div class="roi-tooltip-close" onclick="document.getElementByClassName('roi-tooltip').style.display = 'none';">&times;</div>
              The duration of the bond whereby the bond
              can be claimed in its entirety. With linear
              vesting, you may decide to claim a portion of
              the payout before this time. However, claiming
              multiple times will result in more total gas fees.
            </span>
          </div>
        </div>
        <div class="sidebar-table-column">
          <div class="sidebar-table-text-right">{{secondsToHumanizedDateString(successBondVestingTerm())}}</div>
        </div>
      </div>
    </div>
    <div class="sidebar-content-utility-buttons">
      <div class="row w-row">
        <div class="column w-col w-col-6">
          <a target="_blank" href="https://twitter.com/intent/tweet?text=I%20just%20invested%20on%20Karma%20Finance!%20@karmadaofinance" class="button button-modal button-tweet button-block w-inline-block">
            <img src="assets/images/tweet.svg" loading="lazy" alt="" class="tweet-button-image">
            <div>Share on Twitter</div>
          </a>
        </div>
        <div class="column w-col w-col-6">
          <a href="{{bondSuccessTrackerLink()}}" target="_blank" class="button button-utility">View on ICON Tracker</a>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- MODAL CLAIM -->
<div class="modal modal-claim-rewards" [ngStyle]="displayAsBlockOrHide(isModalActive(ModalType.CLAIM))">
  <div class="sidebar-container">
    <div class="sidebar-heading">
      <div class="sidebar-heading-pair">
        <h2 class="text-white sidebar-heading-text">{{claimPrincipalTokenName()}}</h2>
        <h2 class="text-white sidebar-heading-text sidebar-heading-text-lp-bond">{{claimPrincipalIsLp() ? "LP ":""}} Bond</h2>
      </div>
      <a data-w-id="c4ce6abc-3d6a-8a62-5400-f0f60388324e" href="javascript:void(0);" (click)="hideActiveModal()"
         class="sidebar-close w-inline-block"><img src="assets/images/close-arrow.svg" loading="lazy" alt=""></a>
    </div>
    <div class="sidebar-content-wrapper">
      <div class="sidebar-bond-price">
        <div class="sidebar-price-text">${{claimBondPriceUSD().toFixed(5) | usFormat}}</div>
        <div class="sidebar-price-subtext">Bond Price</div>
      </div>
      <div class="divider"></div>
      <div class="sidebar-bond-price">
        <div class="sidebar-price-text">${{claimBondMarketPriceUSD().toFixed(5) | usFormat}}</div>
        <div class="sidebar-price-subtext">Market price</div>
      </div>
    </div>
    <div class="sidebar-content-cta">
      <a href="javascript:void(0);" (click)="onClaimClick()" class="button-modal button button-gradient button-block">Claim rewards</a>
    </div>
    <div class="sidebar-content-wrapper sidebar-table">
      <div class="sidebar-table-row">
        <div class="sidebar-table-column">
          <div class="sidebar-table-text-left roi">
            Claimable rewards
            <img src="assets/images/tooltip-icon.svg" class="tooltip-icon">
            <span class="roi-tooltip">
              <div class="roi-tooltip-close" onclick="document.getElementByClassName('roi-tooltip').style.display = 'none';">&times;</div>
              The amount of payout token ready to be claimed.
            </span>
          </div>
        </div>
        <div class="sidebar-table-column">
          <div class="sidebar-table-text-right">{{claimBondClaimable() | roundDown2Dec | usFormat | toZeroIfDash}} {{claimPayoutTokenName()}}</div>
        </div>
      </div>
      <div class="sidebar-table-row">
        <div class="sidebar-table-column">
          <div class="sidebar-table-text-left roi">
            Pending rewards
            <img src="assets/images/tooltip-icon.svg" class="tooltip-icon">
            <span class="roi-tooltip">
              <div class="roi-tooltip-close" onclick="document.getElementByClassName('roi-tooltip').style.display = 'none';">&times;</div>
              The amount of payout token still vesting.
            </span>
          </div>
        </div>
        <div class="sidebar-table-column">
          <div class="sidebar-table-text-right">{{claimBondPending() | roundDown2Dec | usFormat | toZeroIfDash}} {{claimPayoutTokenName()}}</div>
        </div>
      </div>
      <div class="sidebar-table-row">
        <div class="sidebar-table-column">
          <div class="sidebar-table-text-left roi">
            DISCOUNT
            <img src="assets/images/tooltip-icon.svg" class="tooltip-icon">
            <span class="roi-tooltip">
              <div class="roi-tooltip-close" onclick="document.getElementByClassName('roi-tooltip').style.display = 'none';">&times;</div>
              A discount or a premium is the price a user will pay for the pay-out asset compared to
              the current market price. If the value is positive, the user pays for the asset at a market
              discount. If the value is negative, the user pays for the asset at a market premium.
              Do not bond when the pay-out token is at a premium.
            </span>
          </div>
        </div>
        <div class="sidebar-table-column">
          <div class="sidebar-table-text-right {{claimBondRoi().isNegative() ? 'negative-discount': ''}}">{{claimBondRoi() | toFixed2DecPercent: true}}</div>
        </div>
      </div>
      <div class="sidebar-table-row">
        <div class="sidebar-table-column">
          <div class="sidebar-table-text-left roi">
            Vesting term
            <img src="assets/images/tooltip-icon.svg" class="tooltip-icon">
            <span class="roi-tooltip">
              <div class="roi-tooltip-close" onclick="document.getElementByClassName('roi-tooltip').style.display = 'none';">&times;</div>
              The duration of the bond whereby the bond
              can be claimed in its entirety. With linear
              vesting, you may decide to claim a portion of
              the payout before this time. However, claiming
              multiple times will result in more total gas fees.
            </span>
          </div>
        </div>
        <div class="sidebar-table-column">
          <div class="sidebar-table-text-right">{{secondsToHumanizedDateString(claimBondVestingTerm())}}</div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal modal-loading" [ngStyle]="displayAsBlockOrHide(isModalActive(ModalType.LOADING))">
  <div class="sidebar-container">
    <div class="sidebar-heading">
      <div class="sidebar-heading-pair">
        <h2 class="text-white sidebar-heading-text">Please wait...</h2>
      </div>
      <a data-w-id="c34ebc8b-794c-db6e-ce6e-5b63278fcb43" href="javascript:void(0);" class="sidebar-close w-inline-block">
        <img src="assets/images/close-arrow.svg" loading="lazy" alt="" (click)="hideActiveModal()">
      </a>
    </div>
    <div class="sidebar-content-wrapper sidebar-table">
      <div class="sidebar-content-text">
        <div class="loading-animation">
          <svg viewBox="0 0 48 48" width="48" height="48"><title>dots anim 7</title><g fill="#ffffff"><g class="nc-loop-dots-anim-7-icon-f"><circle cx="11" cy="11" r="8" data-color="color-2"></circle><circle cx="37" cy="11" fill="#ffffff" r="8"></circle><circle cx="37" cy="37" r="8" data-color="color-2"></circle><circle cx="11" cy="37" fill="#ffffff" r="8"></circle></g><style>.nc-loop-dots-anim-7-icon-f{--animation-duration:1s;transform-origin:50% 50%;animation:nc-loop-dots-anim-7 var(--animation-duration) infinite cubic-bezier(.645,.045,.355,1)}@keyframes nc-loop-dots-anim-7{0%{transform:scale(1)}50%{transform:scale(.7) rotate(90deg)}100%{transform:scale(1) rotate(180deg)}}</style></g></svg>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal modal-claim-rewards-success" [ngStyle]="displayAsBlockOrHide(isModalActive(ModalType.CLAIM_SUCCESS))">
  <div class="sidebar-container">
    <ng-lottie *ngIf="showConfetti" [styles]="styles" [options]="options" class="confetti" (animationCreated)="animationCreated($event)"></ng-lottie>
    <div class="sidebar-heading">
      <div class="sidebar-heading-pair">
        <h2 class="text-white sidebar-heading-text">Request Submitted</h2>
      </div>
      <a data-w-id="9340a9f2-1312-5bb1-67ba-4160cc49646d" href="javascript:void(0);" (click)="hideActiveModal()"
         class="sidebar-close w-inline-block"><img src="assets/images/close-arrow.svg" loading="lazy" alt=""></a>
    </div>
    <div class="sidebar-content-wrapper sidebar-table">
      <div class="sidebar-table-row sidebar-table-row-success">
        <div class="sidebar-table-column">
          <div class="sidebar-table-text-left">Your Claimed Bond</div>
        </div>
        <div class="sidebar-table-column">
          <div class="sidebar-table-text-right">{{claimedSuccessAmount() | roundDown2Dec | usFormat}} {{claimedPayoutTokenName()}}</div>
        </div>
      </div>
    </div>
<!--    <div class="sidebar-content-text">-->
<!--      <div class="staking-message">Visit Omm Finance to start staking.</div>-->
<!--    </div>-->
<!--    <div class="sidebar-content-cta sidebar-content-cta-tweet">-->
<!--      <a href="javascript:void(0);" class="button button-modal button-tweet button-block w-inline-block">-->
<!--        <div>Stake Now</div>-->
<!--      </a>-->
<!--    </div>-->
    <div class="sidebar-content-utility-buttons bottom-20">
      <a href="{{claimedSuccessTrackerLink()}}" target="_blank" class="button button-utility">View on ICON Tracker</a>
    </div>
  </div>
</div>

<!-- MODAL TOGGLE BOND -->
<div class="modal modal-claim-rewards" [ngStyle]="displayAsBlockOrHide(isModalActive(ModalType.TOGGLE_BOND))">
  <div class="sidebar-container">
    <div class="sidebar-heading">
      <div class="sidebar-heading-pair">
        <h2 class="text-white sidebar-heading-text sidebar-heading-text-lp-bond">Toggle Bond</h2>
      </div>
      <a data-w-id="c4ce6abc-3d6a-8a62-5400-f0f60388324e" href="javascript:void(0);" (click)="hideActiveModal()"
         class="sidebar-close w-inline-block"><img src="assets/images/close-arrow.svg" loading="lazy" alt=""></a>
    </div>
    <div class="sidebar-content-wrapper">
      <div class="sidebar-pair">
        <span style="position: relative">
          <img src="assets/images/{{principalTokenImg()}}" loading="lazy" height="32" alt="" class="sidebar-pair-image">
          <span class="dot-{{bondIsEnabled() ? 'green' : 'red'}}"></span>
        </span>

        <div class="sidebar-pair-name">{{principalTokenName()}}</div>
      </div>
    </div>
    <div class="sidebar-content-cta">
      <a href="javascript:void(0);" (click)="onBondToggleClick()" class="button-modal button button-gradient button-block">{{bondIsEnabled() ? "Disable" : "Enable"}} Bond</a>
    </div>
  </div>
</div>

<!-- MODAL TOGGLE SUCCESS -->
<div class="modal modal-claim-rewards-success" [ngStyle]="displayAsBlockOrHide(isModalActive(ModalType.TOGGLE_BOND_SUCCESS))">
  <div class="sidebar-container">
    <div class="sidebar-heading">
      <div class="sidebar-heading-pair">
        <h2 class="text-white sidebar-heading-text">Bond {{bondToggledSuccess()?.bondWasEnabled ? "disabled" : "enabled"}}</h2>
      </div>
      <a data-w-id="9340a9f2-1312-5bb1-67ba-4160cc49646d" href="javascript:void(0);" (click)="hideActiveModal()"
         class="sidebar-close w-inline-block"><img src="assets/images/close-arrow.svg" loading="lazy" alt=""></a>
    </div>
    <div class="sidebar-content-wrapper sidebar-table">
      <div class="sidebar-table-row sidebar-table-row-success">
        <div class="sidebar-table-column">
          <div class="sidebar-table-text-left">Toggled Bond</div>
        </div>
        <div class="sidebar-table-column">
          <div class="sidebar-table-text-right">{{bondToggledSuccess()?.bond?.principalToken?.tag}}</div>
        </div>
      </div>
    </div>

    <div class="sidebar-content-utility-buttons bottom-20">
      <a href="{{toggleBondSuccessTrackerLink()}}" target="_blank" class="button button-utility">View on ICON Tracker</a>
    </div>
  </div>
</div>

<!-- WITHDRAW PAYOUT MODAL -->
<div class="modal modal-bond" [ngStyle]="displayAsBlockOrHide(isModalActive(ModalType.WITHDRAW_PAYOUT))">
  <div class="sidebar-container">
    <div class="sidebar-heading">
      <div class="sidebar-heading-pair">
        <h2 class="text-white sidebar-heading-text">{{principalTokenName()}} {{principalIsLp() ? "LP Bond": "Bond"}} Treasury</h2>
      </div>
      <a data-w-id="d67296ce-56e5-75dc-36b8-e731b8206c68" href="javascript:void(0);" (click)="hideActiveModal()"
         class="sidebar-close w-inline-block"><img src="assets/images/close-arrow.svg" loading="lazy" alt=""></a>
    </div>
    <div class="sidebar-content-wrapper">
      <div class="sidebar-pair">
        <img src="assets/images/{{payoutTokenImg()}}" loading="lazy" height="32" alt="" class="sidebar-pair-image">
        <div class="sidebar-pair-name">{{payoutTokenName()}}</div>
      </div>
    </div>
    <div class="sidebar-content-cta" [ngStyle]="hideElement(!persistenceService.userIsLoggedIn())">
      <div class="form-block w-form">
        <form id="bond-form" name="bond-form" data-name="Bond form" class="form" (submit)="handleWithdrawIrc2Submit($event)">
          <div class="bond-input-wrap">
            <input type="text" class="text-field w-input" maxlength="256" #withdrawIrc2Input (keyup)="onWithdrawIrc2LostFocus($event)" name="name" data-name="Name" placeholder="Amount" id="name">
            <a href="javascript:void(0);" class="max-link" (click)="onMaxWithdrawIrc2Click()">Max</a>
          </div>
          <input type="submit" value="Withdraw" data-wait="Please wait..." class="button button-bond w-button">
        </form>
        <div class="w-form-done">
          <div>Thank you! Your submission has been received!</div>
        </div>
        <div class="w-form-fail">
          <div>Oops! Something went wrong while submitting the form.</div>
        </div>
      </div>
    </div>
    <div class="sidebar-content-cta" [ngStyle]="hideElement(persistenceService.userIsLoggedIn() || true )">
      <a href="javascript:void(0);" (click)="showSignInModal()" class="button-modal button button-gradient button-block">Connect Wallet</a>
    </div>
    <div class="sidebar-content-wrapper sidebar-table">
      <div class="sidebar-table-row">
        <div class="sidebar-table-column">
          <div class="sidebar-table-text-left roi">
            Treasury payout token balance
            <img src="assets/images/tooltip-icon.svg" class="tooltip-icon">
            <span class="roi-tooltip">
              <div class="roi-tooltip-close" onclick="document.getElementByClassName('roi-tooltip').style.display = 'none';">&times;</div>
              Displays the current payout token balance of custom treasury.
            </span>
          </div>
        </div>
        <div class="sidebar-table-column">
          <div class="sidebar-table-text-right">
            {{treasuryPayoutBalance() | roundDown2Dec | usFormat | toZeroIfDash}} {{payoutTokenName()}}
          </div>
        </div>
      </div>
    </div>
    <div class="sidebar-content-wrapper sidebar-table">
      <div class="sidebar-table-row">
        <div class="sidebar-table-column">
          <div class="sidebar-table-text-left roi">
            Destination address
            <img src="assets/images/tooltip-icon.svg" class="tooltip-icon">
            <span class="roi-tooltip">
              <div class="roi-tooltip-close" onclick="document.getElementByClassName('roi-tooltip').style.display = 'none';">&times;</div>
              Destination address to which payout token will be withdrawn to.
            </span>
          </div>
        </div>
        <div class="sidebar-table-column">
          <div class="sidebar-table-text-right">
            {{formatIconAddressToShort(withdrawDestinationAddress())}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- MODAL WITHDRAW IRC2 SUCCESS -->
<div class="modal modal-claim-rewards-success" [ngStyle]="displayAsBlockOrHide(isModalActive(ModalType.WITHDRAW_PAYOUT_SUCCESS))">
  <div class="sidebar-container">
    <div class="sidebar-heading">
      <div class="sidebar-heading-pair">
        <h2 class="text-white sidebar-heading-text">{{payoutTokenName()}} withdrawn</h2>
      </div>
      <a data-w-id="9340a9f2-1312-5bb1-67ba-4160cc49646d" href="javascript:void(0);" (click)="hideActiveModal()"
         class="sidebar-close w-inline-block"><img src="assets/images/close-arrow.svg" loading="lazy" alt=""></a>
    </div>
    <div class="sidebar-content-wrapper sidebar-table">
      <div class="sidebar-table-row sidebar-table-row-success">
        <div class="sidebar-table-column">
          <div class="sidebar-table-text-left">Amount</div>
        </div>
        <div class="sidebar-table-column">
          <div class="sidebar-table-text-right">{{withdrawnAmount() | usFormat}}</div>
        </div>
      </div>
    </div>
    <div class="sidebar-content-wrapper sidebar-table">
      <div class="sidebar-table-row sidebar-table-row-success">
        <div class="sidebar-table-column">
          <div class="sidebar-table-text-left">Destination</div>
        </div>
        <div class="sidebar-table-column">
          <div class="sidebar-table-text-right">{{formatIconAddressToShort(withdrawDestinationAddress())}}</div>
        </div>
      </div>
    </div>

    <div class="sidebar-content-utility-buttons bottom-20">
      <a href="{{withdrawSuccessTrackerLink()}}" target="_blank" class="button button-utility">View on ICON Tracker</a>
    </div>
  </div>
</div>


<!-- WITHDRAW PRINCIPAL MODAL -->
<div class="modal modal-bond" [ngStyle]="displayAsBlockOrHide(isModalActive(ModalType.WITHDRAW_PRINCIPAL))">
  <div class="sidebar-container">
    <div class="sidebar-heading">
      <div class="sidebar-heading-pair">
        <h2 class="text-white sidebar-heading-text">{{principalTokenName()}} {{principalIsLp() ? "LP Bond": "Bond"}} Treasury</h2>
      </div>
      <a data-w-id="d67296ce-56e5-75dc-36b8-e731b8206c68" href="javascript:void(0);" (click)="hideActiveModal()"
         class="sidebar-close w-inline-block"><img src="assets/images/close-arrow.svg" loading="lazy" alt=""></a>
    </div>
    <div class="sidebar-content-wrapper">
      <div class="sidebar-pair">
        <img src="assets/images/{{principalTokenImg()}}" loading="lazy" height="32" alt="" class="sidebar-pair-image">
        <div class="sidebar-pair-name">{{principalTokenName()}}</div>
      </div>
    </div>
    <div class="sidebar-content-cta" [ngStyle]="hideElement(!persistenceService.userIsLoggedIn())">
      <div class="form-block w-form">
        <form id="bond-form" name="bond-form" data-name="Bond form" class="form" (submit)="handleWithdrawPrincipalSubmit($event)">
          <div class="bond-input-wrap">
            <input type="text" class="text-field w-input" maxlength="256" #withdrawPrincInput (keyup)="onWithdrawPrincipalLostFocus($event)" name="name" data-name="Name" placeholder="Amount" id="name">
            <a href="javascript:void(0);" class="max-link" (click)="onMaxWithdrawPrincipalClick()">Max</a>
          </div>
          <input type="submit" value="Withdraw" data-wait="Please wait..." class="button button-bond w-button">
        </form>
        <div class="w-form-done">
          <div>Thank you! Your submission has been received!</div>
        </div>
        <div class="w-form-fail">
          <div>Oops! Something went wrong while submitting the form.</div>
        </div>
      </div>
    </div>
    <div class="sidebar-content-cta" [ngStyle]="hideElement(persistenceService.userIsLoggedIn() || true )">
      <a href="javascript:void(0);" (click)="showSignInModal()" class="button-modal button button-gradient button-block">Connect Wallet</a>
    </div>
    <div class="sidebar-content-wrapper sidebar-table">
      <div class="sidebar-table-row">
        <div class="sidebar-table-column">
          <div class="sidebar-table-text-left roi">
            Treasury principal token balance
            <img src="assets/images/tooltip-icon.svg" class="tooltip-icon">
            <span class="roi-tooltip">
              <div class="roi-tooltip-close" onclick="document.getElementByClassName('roi-tooltip').style.display = 'none';">&times;</div>
              Displays the current principal token balance of custom treasury.
            </span>
          </div>
        </div>
        <div class="sidebar-table-column">
          <div class="sidebar-table-text-right">
            {{treasuryPrincipalBalance() | roundDown2Dec | usFormat | toZeroIfDash}} {{principalTokenName()}}
          </div>
        </div>
      </div>
    </div>
    <div class="sidebar-content-wrapper sidebar-table">
      <div class="sidebar-table-row">
        <div class="sidebar-table-column">
          <div class="sidebar-table-text-left roi">
            Destination address
            <img src="assets/images/tooltip-icon.svg" class="tooltip-icon">
            <span class="roi-tooltip">
              <div class="roi-tooltip-close" onclick="document.getElementByClassName('roi-tooltip').style.display = 'none';">&times;</div>
              Destination address to which payout token will be withdrawn to.
            </span>
          </div>
        </div>
        <div class="sidebar-table-column">
          <div class="sidebar-table-text-right">
            {{formatIconAddressToShort(withdrawDestinationAddress())}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- MODAL WITHDRAW PRINCIPAL SUCCESS -->
<div class="modal modal-claim-rewards-success" [ngStyle]="displayAsBlockOrHide(isModalActive(ModalType.WITHDRAW_PRINCIPAL_SUCCESS))">
  <div class="sidebar-container">
    <div class="sidebar-heading">
      <div class="sidebar-heading-pair">
        <h2 class="text-white sidebar-heading-text">{{principalTokenName()}} withdrawn</h2>
      </div>
      <a data-w-id="9340a9f2-1312-5bb1-67ba-4160cc49646d" href="javascript:void(0);" (click)="hideActiveModal()"
         class="sidebar-close w-inline-block"><img src="assets/images/close-arrow.svg" loading="lazy" alt=""></a>
    </div>
    <div class="sidebar-content-wrapper sidebar-table">
      <div class="sidebar-table-row sidebar-table-row-success">
        <div class="sidebar-table-column">
          <div class="sidebar-table-text-left">Amount</div>
        </div>
        <div class="sidebar-table-column">
          <div class="sidebar-table-text-right">{{withdrawnAmount() | usFormat}}</div>
        </div>
      </div>
    </div>
    <div class="sidebar-content-wrapper sidebar-table">
      <div class="sidebar-table-row sidebar-table-row-success">
        <div class="sidebar-table-column">
          <div class="sidebar-table-text-left">Destination</div>
        </div>
        <div class="sidebar-table-column">
          <div class="sidebar-table-text-right">{{formatIconAddressToShort(withdrawDestinationAddress())}}</div>
        </div>
      </div>
    </div>

    <div class="sidebar-content-utility-buttons bottom-20">
      <a href="{{withdrawSuccessTrackerLink()}}" target="_blank" class="button button-utility">View on ICON Tracker</a>
    </div>
  </div>
</div>

<!-- TOP UP PAYOUT TOKEN MODAL -->
<div class="modal modal-bond" [ngStyle]="displayAsBlockOrHide(isModalActive(ModalType.TOP_UP_PAYOUT))">
  <div class="sidebar-container">
    <div class="sidebar-heading">
      <div class="sidebar-heading-pair">
        <h2 class="text-white sidebar-heading-text">{{principalTokenName()}} {{principalIsLp() ? "LP Bond": "Bond"}} Treasury</h2>
      </div>
      <a data-w-id="d67296ce-56e5-75dc-36b8-e731b8206c68" href="javascript:void(0);" (click)="hideActiveModal()"
         class="sidebar-close w-inline-block"><img src="assets/images/close-arrow.svg" loading="lazy" alt=""></a>
    </div>
    <div class="sidebar-content-wrapper">
      <div class="sidebar-pair">
        <img src="assets/images/{{payoutTokenImg()}}" loading="lazy" height="32" alt="" class="sidebar-pair-image">
        <div class="sidebar-pair-name">{{payoutTokenName()}}</div>
      </div>
    </div>
    <div class="sidebar-content-cta" [ngStyle]="hideElement(!persistenceService.userIsLoggedIn())">
      <div class="form-block w-form">
        <form id="bond-form" name="bond-form" data-name="Bond form" class="form" (submit)="handleTopUpSubmit($event)">
          <div class="bond-input-wrap">
            <input type="text" class="text-field w-input" maxlength="256" #topUpInput (keyup)="onTopUpPayoutLostFocus($event)" name="name" data-name="Name" placeholder="Amount" id="name">
            <a href="javascript:void(0);" class="max-link" (click)="onMaxTopUpPayoutTokenClick()">Max</a>
          </div>
          <input type="submit" value="Top up" data-wait="Please wait..." class="button button-bond w-button">
        </form>
        <div class="w-form-done">
          <div>Thank you! Your submission has been received!</div>
        </div>
        <div class="w-form-fail">
          <div>Oops! Something went wrong while submitting the form.</div>
        </div>
      </div>
    </div>
    <div class="sidebar-content-cta" [ngStyle]="hideElement(persistenceService.userIsLoggedIn() || true )">
      <a href="javascript:void(0);" (click)="showSignInModal()" class="button-modal button button-gradient button-block">Connect Wallet</a>
    </div>
    <div class="sidebar-content-wrapper sidebar-table">
      <div class="sidebar-table-row">
        <div class="sidebar-table-column">
          <div class="sidebar-table-text-left roi">
            User payout token balance
            <img src="assets/images/tooltip-icon.svg" class="tooltip-icon">
            <span class="roi-tooltip">
              <div class="roi-tooltip-close" onclick="document.getElementByClassName('roi-tooltip').style.display = 'none';">&times;</div>
              Displays the current user payout token balance.
            </span>
          </div>
        </div>
        <div class="sidebar-table-column">
          <div class="sidebar-table-text-right">
            {{userPayoutBalance() | roundDown2Dec | usFormat | toZeroIfDash}} {{payoutTokenName()}}
          </div>
        </div>
      </div>
    </div>
    <div class="sidebar-content-wrapper sidebar-table">
      <div class="sidebar-table-row">
        <div class="sidebar-table-column">
          <div class="sidebar-table-text-left roi">
            Treasury payout token balance
            <img src="assets/images/tooltip-icon.svg" class="tooltip-icon">
            <span class="roi-tooltip">
              <div class="roi-tooltip-close" onclick="document.getElementByClassName('roi-tooltip').style.display = 'none';">&times;</div>
              Displays the current payout token balance of custom treasury.
            </span>
          </div>
        </div>
        <div class="sidebar-table-column">
          <div class="sidebar-table-text-right">
            {{treasuryPayoutBalance() | roundDown2Dec | usFormat | toZeroIfDash}} {{payoutTokenName()}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- MODAL TOP UP PAYOUT SUCCESS -->
<div class="modal modal-claim-rewards-success" [ngStyle]="displayAsBlockOrHide(isModalActive(ModalType.TOP_UP_PAYOUT_SUCCESS))">
  <div class="sidebar-container">
    <div class="sidebar-heading">
      <div class="sidebar-heading-pair">
        <h2 class="text-white sidebar-heading-text">{{payoutTokenName()}} transferred to {{principalTokenName()}}  custom treasury</h2>
      </div>
      <a data-w-id="9340a9f2-1312-5bb1-67ba-4160cc49646d" href="javascript:void(0);" (click)="hideActiveModal()"
         class="sidebar-close w-inline-block"><img src="assets/images/close-arrow.svg" loading="lazy" alt=""></a>
    </div>
    <div class="sidebar-content-wrapper sidebar-table">
      <div class="sidebar-table-row sidebar-table-row-success">
        <div class="sidebar-table-column">
          <div class="sidebar-table-text-left">Amount</div>
        </div>
        <div class="sidebar-table-column">
          <div class="sidebar-table-text-right">{{toppedAmount() | usFormat}}</div>
        </div>
      </div>
    </div>

    <div class="sidebar-content-utility-buttons bottom-20">
      <a href="{{topUpSuccessTrackerLink()}}" target="_blank" class="button button-utility">View on ICON Tracker</a>
    </div>
  </div>
</div>
