// @ts-ignore
import bridgeLogoSvg from "../../../assets/img/logo/stably-logo.png";
// @ts-ignore
import icxLogoSvg from "../../../assets/img/logo/icon-logo.svg";
// @ts-ignore
import iusdcLogoSvg from "../../../assets/img/logo/iusdc-logo.png";
// @ts-ignore
import sicxLogoSvg from "../../../assets/img/logo/sicx-logo.png";
// @ts-ignore
import ommLogoSvg from '../../../assets/img/logo/omm-logo.svg';
import {environment} from "../../../environment/environment";
import BigNumber from "bignumber.js";

export class Token {
    tag: SupportedTokens;
    name: string;
    logo: any;
    scoreAddress: string;
    balance: number = 0;
    decimals: number = 18;
    usdBalance: number | '-' = '-';

    constructor(tag: SupportedTokens, name: string, logo: any, scoreAddress: string, decimals?: number, balance?: number, usdBalance?: number | '-') {
        this.tag = tag;
        this.name = name;
        this.logo = logo;
        this.scoreAddress = scoreAddress;        
        if (balance)
            this.balance = balance;
        if (decimals)
            this.decimals = decimals;
        if (usdBalance)
            this.usdBalance = usdBalance;
    }
}

export enum SupportedTokens {
    USDS = "USDS",
    ICX = "ICX",
    sICX = "sICX",
    IUSDC = "IUSDC",
    OMM = "OMM",
}

export enum OracleTokenNames {
    USDS = 'USDS',
    IUSDC = 'USDC',
    ICX = 'ICX',
    sICX = 'sICX',
    OMM = 'OMM',
    USD = 'USD',
}

export class Tokens {
    public static supportedTokensMap: Map<string,Token> = new Map([
        [SupportedTokens.USDS, new Token(SupportedTokens.USDS, "Stably USD", bridgeLogoSvg, environment.BRIDGE_SCORE_ADDRESS, 18)],
        [SupportedTokens.ICX, new Token(SupportedTokens.ICX, "ICON", icxLogoSvg, "", 18)],
        [SupportedTokens.IUSDC, new Token(SupportedTokens.IUSDC, "ICON USD Coin", iusdcLogoSvg, environment.IUSDC_SCORE_ADDRESS, 6)],
        [SupportedTokens.sICX, new Token(SupportedTokens.sICX, "Staked ICX", sicxLogoSvg, environment.SICX_SCORE_ADDRESS, 18)],
        [SupportedTokens.OMM, new Token(SupportedTokens.OMM, 'OMM Token', ommLogoSvg, environment.OMM_SCORE_ADDRESS, 18)],
    ]);
}

export interface TokenRates {
    USDS: BigNumber | '-',
    IUSDC: BigNumber | '-',
    sICX: BigNumber | '-',
    OMM: BigNumber | '-',
    ICX: BigNumber | '-',
}