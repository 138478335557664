import BigNumber from "bignumber.js";

export class BondData {
  payout: BigNumber;
  vesting: BigNumber;
  lastBlock: BigNumber;
  truePricePaid: BigNumber;

  constructor(payout: BigNumber, vesting: BigNumber, lastBlock: BigNumber, truePricePaid: BigNumber) {
    this.payout = payout;
    this.vesting = vesting;
    this.lastBlock = lastBlock;
    this.truePricePaid = truePricePaid;
  }
}
