// @ts-ignore
import Axios, { AxiosInstance } from 'axios';
import { environment } from '../../environment/environment';

const axiosObj = Axios.create({
  baseURL: environment.LOGS_URL,
  headers: {
    common: {
      "Content-Type": "application/json",
      Accept: "application/json",
    }
  },
  timeout: 3000,
});

export class BackendLogger {

  axios: AxiosInstance;
  browser: string;

  constructor () {
    this.axios = axiosObj;
    this.browser = this.detectBrowser();
  }

  private detectBrowser() { 
    if((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1 ) {
        return 'Opera';
    } else if(navigator.userAgent.indexOf("Chrome") != -1 ) {
        return 'Chrome';
    } else if(navigator.userAgent.indexOf("Safari") != -1) {
        return 'Safari';
    } else if(navigator.userAgent.indexOf("Firefox") != -1 ){
        return 'Firefox';
    } else if((navigator.userAgent.indexOf("MSIE") != -1 ) || (!!document.documentMode == true )) {
        return 'IE';//crap
    } else {
        return 'Unknown';
    }
  } 

  async postError(title: string, errorCode: number = 0, errorMsg: string, 
    walletAddr: string = 'unknown') {

    // Don't send logs from debug env
    if(window.location.href.includes('localhost') || window.location.href.includes('0.0.0.0')) {
      console.error('Can\'t send log messages from localhost');
      return;
    }
    try {
      this.axios.post(
        '/', 
        {
          address: walletAddr,
          errorCode,
          errorMsg,
          source: title,
          browser: this.browser,
          link: window.location.href
        }
      )
    } catch (e) {
      console.group('Sending logs to errorLogger backend');
      console.error(e);
      console.groupEnd();
    }
  }
}

export default new BackendLogger();

declare global {
  interface Document {
    documentMode?: any;
  }
}