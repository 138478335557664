import "./widget/icon-bridge-widget";
import {BridgeService} from "./lib/BridgeService";

declare global {
  interface Window {
    BridgeService: any;
  }
}

window.BridgeService = BridgeService;

export default BridgeService;

