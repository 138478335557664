<div class="heading-section wf-section">
  <div class="container w-container">
    <h1 class="heading-2">Bond Marketplace <img class="refresh-button" src="assets/images/refresh.svg" alt="refresh" (click)="onRefreshClick()"></h1>
  </div>
</div>

<div class="pairs-section wf-section">
  <div class="container w-container">
    <div class="table table-header">
      <div class="table-header-item table-header-item-1">
        <div class="table-header-item-text">Bonds</div>
      </div>
      <div class="table-header-item table-header-item-2">
        <div class="table-header-item-text">PAY OUT ASSET</div>
      </div>
      <div class="table-header-item hide-mobile table-header-item-3" (click)="onDiscountClick()">
        <div class="table-header-item-text roi">
          <span class="cursor-pointer">DISCOUNT</span>
          <img src="assets/images/tooltip-icon.svg" class="tooltip-icon">
          <span class="roi-tooltip">
            A discount or a premium is the price a user will pay for the pay-out asset compared to
            the current market price. If the value is positive, the user pays for the asset at a market
            discount. If the value is negative, the user pays for the asset at a market premium.
            Do not bond when the pay-out token is at a premium.
            </span>
        </div>
      </div>
      <div class="table-header-item hide-mobile table-header-item-4" (click)="onTbvClick()">
        <div class="table-header-item-text roi">
          <span class="cursor-pointer">TBV</span>
          <img src="assets/images/tooltip-icon.svg" class="tooltip-icon">
          <span class="roi-tooltip">
            Total Bonded Value is the total amount of
            payout assets sold to the bonders. It also
            represents the liquidity accrued by the
            protocol. A high bonded value implies that the
            payout asset is actively sought after by the bonders.
          </span>
        </div>
      </div>
      <div class="table-header-item hide-mobile table-header-item-5"></div>
    </div>
      <ng-container *ngFor="let bond of bonds; last as isLast; let i = index">
        <app-bond [bond]="bond" [ngStyle]="{ display: 'contents' }"></app-bond>
      </ng-container>
  </div>
</div>
