// Berlin configuration
export const environment = {
  production: false,
  MAGIC_LOGIN_API_KEY: "pk_live_73729FB167F70BEC", 
  ICON_RPC_URL: "https://berlin.net.solidwallet.io/api/v3",
  ICON_RPC_DEBUG_URL: "https://berlin.net.solidwallet.io/api/v3d",
  BRIDGE_SCORE_ADDRESS: "cxb06e005e72d1a654fae6405c32bc51e88636126d",
  // BRIDGE_SCORE_ADDRESS: "cx1a52a17ee1a2a6bb52e31fc80bee692baa7455a0", old address
  SICX_SCORE_ADDRESS: "cx3eb01d055d6f3c33eab8bd6db1b6ff6a605a843e",
  IUSDC_SCORE_ADDRESS: "cx73ea9de607f61f26e356225cc049c6c39df93da6",
  OMM_SCORE_ADDRESS: "cx539af698a2c783df73d2a051cb7b6f2d06ad9a5b",
  OMM_PRICE_SCORE_ADDRESS: "cx5cca72c6031db6244bf37a776e8746755f623063",
  BAND_SCORE_ADDRESS: "cx2967524993849708516481982b8076a6d492db64",
  STAKING_SCORE_ADDRESS: "cx24c14f9171beac581fa1b55efe9bb8946a3d4b5b",
  BRIDGE_NID: 7,
  BASE_REST_API_URL: "https://dev-bridge.ibriz.ai/api/v1",
  ICON_REST_API_URL: "https://berlin.tracker.solidwallet.io/v3",
  LOGS_URL: 'https://xxx',
  STABLY_IFRAME_URL: 'https://ramp-beta.stably.io/'
};
