import { Component, OnInit } from '@angular/core';
import {ModalService} from "../../services/modal.service";
import {BaseClass} from "../../base-class";
import {PersistenceService} from "../../services/persistence.service";
import {StateChangeService} from "../../services/state-change.service";

@Component({
  selector: 'app-overlay',
  templateUrl: './overlay.component.html'
})
export class OverlayComponent extends BaseClass {

  constructor(private modalService: ModalService,
              public override persistenceService: PersistenceService,
              private stateChangeService: StateChangeService) {
    super(persistenceService);
  }

  showOverlay(): boolean {
    return this.modalService.activeModal !== undefined;
  }

  hideOverlayAndModal(): void {
    this.stateChangeService.overlayHiddenUpdate();
    this.modalService.hideActiveModal();
  }

}
