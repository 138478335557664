import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BaseClass} from '../../../base-class';
import {BondConfig} from '../../../models/classes/BondConfigInfo';
import {Utils} from '../../../common/utils';
import {Subscription} from 'rxjs';
import {ModalService} from '../../../services/modal.service';
import {PersistenceService} from '../../../services/persistence.service';
import {PriceOracleService} from '../../../services/price-oracle.service';
import {CalculationService} from '../../../services/calculation-service/calculation.service';
import {StateChangeService} from '../../../services/state-change.service';
import {defaultPrepLogoUrl} from '../../../common/constants';
import {ModalType} from '../../../models/enums/ModalType';
import {BondTogglePayload} from '../../../models/classes/BondTogglePayload';
import {WithdrawPayoutPayload} from '../../../models/classes/WithdrawPayoutPayload';
import {Irc2Token} from '../../../models/classes/Irc2Token';
import {WithdrawPrincipalPayload} from '../../../models/classes/WithdrawPrincipalPayload';
import {TopUpPayload} from '../../../models/classes/TopUpPayload';

@Component({
  selector: 'app-custom-treasury-row',
  templateUrl: './custom-treasury-row.component.html'
})
export class CustomTreasuryRowComponent extends BaseClass implements OnInit, OnDestroy {

  @Input() bond!: BondConfig;

  // Template variables
  treasuryPayoutBalance = Utils.ZERO;
  treasuryPayoutUsdBalance = Utils.ZERO;
  treasuryPrincipalBalance = Utils.ZERO;
  treasuryPrincipalUsdBalance = Utils.ZERO;
  bondIsEnabled = false;

  // subscriptions
  afterCoreDataReloadSub?: Subscription;
  bondStatusSub?: Subscription;

  constructor(private modalService: ModalService,
              public override persistenceService: PersistenceService,
              private priceOracleService: PriceOracleService,
              private calculationService: CalculationService,
              private stateChangeService: StateChangeService
  ) {
    super(persistenceService);
  }

  ngOnInit(): void {
    this.initCoreValues();
    this.registerSubscriptions();
  }

  ngOnDestroy(): void {
    this.afterCoreDataReloadSub?.unsubscribe();
    this.bondStatusSub?.unsubscribe();
  }

  registerSubscriptions(): void {
    this.subscribeToCoreDataChange();
    this.subscribeToBondStatusChange();
  }

  subscribeToCoreDataChange(): void {
    this.afterCoreDataReloadSub = this.stateChangeService.coreDataFinishedLoading$.subscribe(() => {
      this.initCoreValues();
    });
  }

  subscribeToBondStatusChange(): void {
    this.bondStatusSub = this.stateChangeService.bondStatusChange$.subscribe(res => {
      if (res.bondTag === this.bond.tag) {
        this.bondIsEnabled = res.status;
      }
    })
  }

  private initCoreValues(): void {
    this.treasuryPayoutBalance = this.persistenceService.getBondTreasuryPayoutBalance(this.bond.tag);
    this.treasuryPayoutUsdBalance = this.treasuryPayoutBalance.multipliedBy(this.priceOracleService.getIrc2TokenPrice(this.bond.payoutToken));
    this.treasuryPrincipalBalance = this.persistenceService.getBondTreasuryPrincipalBalance(this.bond.tag);
    this.treasuryPrincipalUsdBalance = this.treasuryPrincipalBalance.multipliedBy((
      this.bond.principalToken instanceof  Irc2Token) ? this.priceOracleService.getIrc2TokenPrice(this.bond.principalToken)
      : this.calculationService.calculateLpMarketUsdPrice(this.bond));
    this.bondIsEnabled = this.persistenceService.bondStatusMap.get(this.bond.tag) ?? false;
  }

  onWithdrawPrincipalClick(): void {
    this.modalService.setActiveModal(ModalType.WITHDRAW_PRINCIPAL, new WithdrawPrincipalPayload(this.bond,
      this.persistenceService.activeWallet?.address ?? ""));
  }

  onWithdrawPayoutClick(): void {
    this.modalService.setActiveModal(ModalType.WITHDRAW_PAYOUT, new WithdrawPayoutPayload(this.bond, this.bond.payoutToken.address,
      this.persistenceService.activeWallet?.address ?? ""));
  }

  onTopUpClick(): void {
    this.modalService.setActiveModal(ModalType.TOP_UP_PAYOUT, new TopUpPayload(this.bond));
  }

  principalTokenName(): string {
    return Utils.getBondPrincipalTokenName(this.bond)
  }

  principalImg(): string {
    return this.bond.principalToken.img
  }

  payoutImg(): string {
    return this.bond.payoutToken.img;
  }

  handlePrincipalImgError($event: any) {
    $event.target.src = defaultPrepLogoUrl;
  }

  onToggleBondClick(): void {
    this.modalService.setActiveModal(ModalType.TOGGLE_BOND, new BondTogglePayload(this.bond, this.bondIsEnabled));
  }
}
