import { Injectable } from '@angular/core';
import {ScoreAddressesI} from "../models/interfaces/ScoreAddressesI";
import {environment} from "../../environments/environment";
import {BalnLpTag, BondTag, Irc2TokenTag} from '../models/types/Types';
import {BondConfig, BondConfigInfo} from "../models/classes/BondConfigInfo";
import log from "loglevel";
import {BalnLpToken} from '../models/classes/BalnLpToken';
import {Irc2Token} from '../models/classes/Irc2Token';
import {Irc2TokenI} from '../models/interfaces/Irc2TokenI';
import {BalnLpTokenI} from '../models/interfaces/BalnLpTokenI';
import {BondI} from '../models/interfaces/BondI';
import {BalnDexOracleI} from '../models/interfaces/BalnDexOracleI';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  /**
   * Angular service responsible for loading application configuration (supported tokens, SCORE addresses, etc..)
   */

  public readonly TAG = "[AppConfigService]";

  public readonly scoreAddresses: ScoreAddressesI;
  public readonly bondConfigInfo: BondConfigInfo;
  public readonly pastBondConfigs: BondConfig[];
  public readonly bondTagToPrincipalTokenMap = new Map<BondTag, Irc2Token | BalnLpToken>();
  public readonly bondTagToPayoutAssetTagMap = new Map<BondTag, Irc2Token>();
  public readonly supportedIrc2TokenTagToIrc2TokenMap = new Map<Irc2TokenTag, Irc2Token>()
  public readonly balnLpTagToBalnLpToken = new Map<BalnLpTag, BalnLpToken>()
  public readonly irc2TokenTagToBalnDexOracle = new Map<Irc2TokenTag, BalnDexOracleI>();

  constructor() {
    this.scoreAddresses = environment.SCORE_ADDRESSES_JSON as ScoreAddressesI;

    // load map that maps IRC2 token tag to IRC2 token object
    this.loadIrc2TokenMap(this.scoreAddresses.supportedIrc2Tokens);

    // load map that maps Baln token tag to Baln token object
    this.loadBalnLpTokenMap(this.scoreAddresses.supportedBalnLpTokens);

    // load map that maps IRC2 token tag to Balanced DEX oracle config
    this.loadIrc2TokenTagToBalnDexOracleInfoMap(this.scoreAddresses.balancedDexOracle);

    // init bond config info
    this.bondConfigInfo = this.constructBondConfigInfo();

    // init past bond configs
    this.pastBondConfigs = [...this.scoreAddresses.pastBonds.map(bond => this.constructBondConfig(bond))]

    // trigger check if supported IRC2 tokens matches IRC2 tokens that occur in bonds
    this.checkIfSupportedTokensHasAllBondIrc2Tokens();

    log.debug("Bonds Configs:", this.bondConfigInfo.bondsConfig);

    log.debug(`Supported Irc2Tokens:`, this.getSupportedIrc2Tokens());
    log.debug(`Supported BalnLpTokens:`, this.getSupportedBalnLpTokensI());
  }

  private constructBondConfigInfo(): BondConfigInfo {
    return new BondConfigInfo(
      this.scoreAddresses.balancedDex,
      this.scoreAddresses.factory,
      this.scoreAddresses.factoryStorage,
      this.scoreAddresses.subsidyRouter,
      [...this.scoreAddresses.bonds.map(bond => this.constructBondConfig(bond))]
    )
  }

  private constructBondConfig(bond: BondI): BondConfig {
    const principalToken = bond.principalIsLpToken ? this.balnLpTagToBalnLpToken.get(bond.principalTokenName)
      : this.supportedIrc2TokenTagToIrc2TokenMap.get(bond.principalTokenName);

    if (!principalToken) {
      throw new Error(`${this.TAG} Failed to construct BondConfig for bond: ${bond.principalTokenName} -> ${bond.payoutTokenName}`);
    }

    return new BondConfig(
      bond,
      principalToken,
      this.supportedIrc2TokenTagToIrc2TokenMap.get(bond.payoutTokenName)!,
    )
  }

  private loadIrc2TokenMap(supportedIrc2Tokens: Irc2TokenI[]): void {
    supportedIrc2Tokens.forEach(token => this.supportedIrc2TokenTagToIrc2TokenMap.set(token.tag, new Irc2Token(
      token.tag,
      token.name,
      token.address,
      token.decimals,
      token.isStableCoin,
      token.img,
      token.oracle
    )));
  }

  private loadIrc2TokenTagToBalnDexOracleInfoMap(balancedDexOracle: BalnDexOracleI[]): void {
    balancedDexOracle.forEach(info => this.irc2TokenTagToBalnDexOracle.set(info.irc2TokenTag, info));
  }

  private loadBalnLpTokenMap(supportedBalnLpTokens: BalnLpTokenI[]): void {
    supportedBalnLpTokens.forEach(token => {
      const baseTokenTag = token.tag.split("/")[0];
      const quoteTokenTag = token.tag.split("/")[1];
      const baseToken = this.supportedIrc2TokenTagToIrc2TokenMap.get(baseTokenTag);
      const quoteToken = this.supportedIrc2TokenTagToIrc2TokenMap.get(quoteTokenTag);

      if (!baseToken || !quoteToken) { throw new Error(`Unable to obtain ${baseToken} ${quoteToken}`) }

      const balnLpToken = new BalnLpToken(token.tag, token.poolId, baseToken, quoteToken, token.img);

      this.balnLpTagToBalnLpToken.set(token.tag, balnLpToken)
    });
  }

  getSupportedBonds(): BondConfig[] {
    return this.bondConfigInfo.bondsConfig;
  }

  getSupportedAndPastBonds(): BondConfig[] {
    return this.getSupportedBonds().concat(this.pastBondConfigs);
  }

  getBalancedDexAddress(): string {
    return this.scoreAddresses.balancedDex;
  }

  getStableCoinIrc2Tokens(): Irc2TokenTag[] {
    return this.getSupportedIrc2Tokens().filter(token => token.isStableCoin).map(token => token.tag);
  }

  getSupportedIrc2Tokens(): Irc2Token[] {
    return this.scoreAddresses.supportedIrc2Tokens.map(token => new Irc2Token(
      token.tag,
      token.name,
      token.address,
      token.decimals,
      token.isStableCoin,
      token.img,
      token.oracle
    ));
  }

  getSupportedBalnLpTokensI(): BalnLpTokenI[] {
    return this.scoreAddresses.supportedBalnLpTokens;
  }

  getSupportedBalnLpTokens(): BalnLpToken[] {
    return Array.from(this.balnLpTagToBalnLpToken.values());
  }

  getOmmPriceOracleAddress(): string {
    return this.scoreAddresses.ommPriceOracle;
  }

  getBondsPrincipalToken(bondTag: BondTag): Irc2Token | BalnLpToken {
    const res = this.bondTagToPrincipalTokenMap.get(bondTag);
    if (res) { return res; }
    throw new Error(`Failed to obtain PrincipalToken for bondTag = ${bondTag}`);
  }

  getIrc2TokenBalnDexOracleInfo(token: Irc2Token): BalnDexOracleI {
    const res = this.irc2TokenTagToBalnDexOracle.get(token.tag);
    if (res) { return res; }
    log.error(this.irc2TokenTagToBalnDexOracle.entries());
    throw new Error(`Failed to obtain BalnDexOracleInfo for IRC2 token = ${token.tag}`);
  }

  getBondsPayoutToken(bondTag: BondTag): Irc2Token {
    const res = this.bondTagToPayoutAssetTagMap.get(bondTag);
    if (res) { return res; }
    throw new Error(`Failed to obtain PayoutToken for bondTag = ${bondTag}`);
  }

  // check if all IRC2 tokens that are in supported and past bonds are in supported tokens map
  checkIfSupportedTokensHasAllBondIrc2Tokens(): void {
    // iterate all supported and past bonds
    this.getSupportedBonds().concat(this.pastBondConfigs).forEach(bond => {
      // check if principal token is in supported IRC2 map, Baln LP token is split to base and quote
      if (bond.principalToken instanceof BalnLpToken) {
        if (!this.supportedIrc2TokenTagToIrc2TokenMap.get(bond.principalToken.baseToken.tag)) {
          throw new Error(`${bond.principalToken.baseToken.tag} token not found in supported IRC2 token map! Check config!`)
        }
        if (!this.supportedIrc2TokenTagToIrc2TokenMap.get(bond.principalToken.quoteToken.tag)) {
          throw new Error(`${bond.principalToken.baseToken.tag} token not found in supported IRC2 token map! Check config!`)
        }
      } else {
        if (!this.supportedIrc2TokenTagToIrc2TokenMap.get(bond.principalToken.tag)) {
          throw new Error(`${bond.principalToken.tag} token not found in supported IRC2 token map! Check config!`)
        }
      }

      // check if payout token is in supported IRC2 map
      if (!this.supportedIrc2TokenTagToIrc2TokenMap.get(bond.payoutToken.tag)) {
        throw new Error(`${bond.payoutToken.tag} token not found in supported IRC2 token map! Check config!`)
      }
    })

  }


}
