import {UserActionType} from "../enums/UserActionType";
import {BondActionPayload} from "./BondActionPayload";
import {ClaimBondSuccessPayload} from "./ClaimBondSuccessPayload";
import {ToggleBondSuccessPayload} from './ToggleBondSuccessPayload';
import {WithdrawPayoutSuccessPayload} from './WithdrawPayoutSuccessPayload';
import {WithdrawPrincipalSuccessPayload} from './WithdrawPrincipalSuccessPayload';
import {TopUpPayloadSuccess} from './TopUpPayloadSuccess';

export class UserAction {
  type: UserActionType;
  payload: BondActionPayload | ClaimBondSuccessPayload | ToggleBondSuccessPayload | WithdrawPayoutSuccessPayload
    | WithdrawPrincipalSuccessPayload | TopUpPayloadSuccess;

  constructor(type: UserActionType, payload: BondActionPayload | ClaimBondSuccessPayload | ToggleBondSuccessPayload | WithdrawPayoutSuccessPayload
    | WithdrawPrincipalSuccessPayload | TopUpPayloadSuccess) {
    this.type = type;
    this.payload = payload;
  }
}
