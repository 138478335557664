import {RequestsWrapper} from "../common/requests-wrapper";
import {RestApiUrls} from "../common/rest-api-urls";
import {User} from "../models/User/User";
import {AxiosResponse} from "axios";

export class UserApiService {

    private requestsWrapper: RequestsWrapper;

    constructor(requestsWrapper: RequestsWrapper) {
        this.requestsWrapper = requestsWrapper;
    }

    /**
     * @description Get currently logged in user data
     * @return {Promise<AxiosResponse<User>>} Promise with User model in response
     * @throws {BridgeError} - contains user friendly message from the API and external error
     */
    public getUser(): Promise<AxiosResponse<User>> {
        return this.requestsWrapper.axios.get(RestApiUrls.USER_REST_API, {
            responseType: 'json',
        })
    }

    /**
     * @description Create new User entity
     * @param {User} user - User model
     * @return {Promise<AxiosResponse<User>>} Promise with User model in response
     * @throws {BridgeError} - contains user friendly message from the API and external error
     */
    public postUser(user: User): Promise<AxiosResponse<User>> {
        return this.requestsWrapper.axios.post(RestApiUrls.USER_REST_API, user, {
            responseType: 'json',

        });
    }

    /**
     * @description Update user email
     * @param {string} email - New user email
     * @return {Promise<AxiosResponse<User>>} Promise with User model in response
     * @throws {BridgeError} - contains user friendly message from the API and external error
     */
    public updateUserEmail(email: string): Promise<AxiosResponse<User>> {
        return this.requestsWrapper.axios.put(RestApiUrls.USER_REST_API, {"email": email}, {
            responseType: 'json',
        });
    }
}
