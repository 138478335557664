
export class User {

    email: string;
    didEthrWalletAddress: string | null;
    iconWalletAddress: string | null;

    constructor(email: string, didEthrWalletAddress: string | null, iconWalletAddress: string | null) {
        this.email = email;
        this.didEthrWalletAddress = didEthrWalletAddress;
        this.iconWalletAddress = iconWalletAddress;
    }
}
