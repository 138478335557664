import {Component, OnDestroy, OnInit} from "@angular/core";
import {BondConfig} from "../../models/classes/BondConfigInfo";
import {ModalService} from "../../services/modal.service";
import {AppConfigService} from "../../services/app-config.service";
import {CalculationService} from "../../services/calculation-service/calculation.service";
import {PriceOracleService} from "../../services/price-oracle.service";
import {DataLoaderService} from "../../services/data-loader.service";
import {Subscription} from "rxjs";
import {StateChangeService} from "../../services/state-change.service";
import {PersistenceService} from "../../services/persistence.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html"
})
export class DashboardComponent implements OnInit, OnDestroy {

  bonds: BondConfig[] = [];

  coreDataIntervalTime = 10000; // 10 sec
  coreDataInterval?: NodeJS.Timeout;

  treasuryOwnerSub?: Subscription;
  loginSub?: Subscription;

  constructor(private modalService: ModalService,
              private appConfigService: AppConfigService,
              private calculationService: CalculationService,
              private priceOracleService: PriceOracleService,
              private dataLoaderService: DataLoaderService,
              private stateChangeService: StateChangeService,
              private persistenceService: PersistenceService) { }

  ngOnInit(): void {
    // load active bonds in bonds array to be rendered
    this.loadBonds();

    // init core data interval
    this.coreDataInterval = setInterval(() => this.triggerReloadOfCoreData(), this.coreDataIntervalTime);
    this.subscribeToTreasuryOwnersFinishedLoading();
    this.subscribeToLoginChange();
  }

  ngOnDestroy(): void {
    this.treasuryOwnerSub?.unsubscribe();
    this.loginSub?.unsubscribe();

    // clear core data reload interval when component is destroyed
    if (this.coreDataInterval) {
      clearInterval(this.coreDataInterval);
    }
  }

  triggerReloadOfCoreData(): void {
    // only trigger if no modal is active and browser tab is active
    if (!this.modalService.activeModal && !document.hidden) {
      this.dataLoaderService.loadCoreData();
    }
  }

  subscribeToTreasuryOwnersFinishedLoading(): void {
    this.treasuryOwnerSub = this.stateChangeService.treasuryOwnersFinishedLoading$.subscribe(() => {
      this.loadBonds();
    });
  }

  subscribeToLoginChange(): void {
    this.loginSub = this.stateChangeService.loginChange$.subscribe(() => {
      this.loadBonds();
    });
  }

  loadBonds(): void {
    const tmp: BondConfig[] = [];
    this.appConfigService.getSupportedAndPastBonds().forEach(bond => {
      const treasuryOwner = this.persistenceService.treasuryOwnerMap.get(bond.tag) ?? "-";
      const loggedInAddress = this.persistenceService.activeWallet?.address ?? "+";
      if (treasuryOwner === loggedInAddress) {
        tmp.push(bond);
      }
    });

    this.bonds = tmp;
  }

}
