import {Utils} from "./utils";
import {Terms} from "../models/classes/Terms";
import {TermsI} from "../models/interfaces/TermsI";
import {BondDataI} from "../models/interfaces/BondDataI";
import {BondData} from "../models/classes/BondData";
import {PoolStats, PoolStatsInterface} from "../models/classes/PoolStats";
import {BondInfoI} from "../models/interfaces/BondInfoI";
import {BondInfo} from "../models/classes/BondInfo";
import {BOND_PRICE_DECIMAL_PRECISION} from "./constants";
import {BondConfig} from "../models/classes/BondConfigInfo";

export class Mapper {

  public static mapBondInfo(bondInfo: BondInfoI, payoutDecimals: number): BondInfo {
    return new BondInfo(
      Utils.hexToNormalisedNumber(bondInfo.payout, payoutDecimals),
      Utils.hexToNumber(bondInfo.vesting),
      Utils.hexToNumber(bondInfo.lastBlock),
      Utils.hexToNormalisedNumber(bondInfo.truePricePaid, BOND_PRICE_DECIMAL_PRECISION),
    );
  }

  public static mapCustomBondTerms(terms: TermsI, bondConfig: BondConfig, payoutTokenDecimals: number): Terms {
    return new Terms(
      Utils.hexToNumber(terms.controlVariable),
      Utils.hexToNormalisedNumber(terms.maxDebt, payoutTokenDecimals),
      Utils.hexToNumber(terms.maxPayout),
      Utils.hexToNumber(terms.minimumPrice),
      Utils.hexToNumber(terms.vestingTerm)
    );
  }

  public static mapBond(bond: BondDataI): BondData {
    return new BondData(
      Utils.hexToNormalisedNumber(bond.payout),
      Utils.hexToNumber(bond.vesting),
      Utils.hexToNumber(bond.lastBlock),
      Utils.hexToNormalisedNumber(bond.truePricePaid)
    );
  }

  public static mapPoolStats(poolStats: PoolStatsInterface): PoolStats {
    const baseDecimals = Utils.hexToNumber(poolStats.base_decimals);
    const quoteDecimals = Utils.hexToNumber(poolStats.quote_decimals);

    return new PoolStats(
      Utils.hexToNormalisedNumber(poolStats.base, baseDecimals.toNumber()),
      Utils.hexToNormalisedNumber(poolStats.quote, quoteDecimals.toNumber()),
      poolStats.base_token,
      poolStats.quote_token,
      Utils.hexToNormalisedNumber(poolStats.total_supply, PoolStats.getPoolPrecision(baseDecimals, quoteDecimals).toNumber()),
      Utils.hexToNormalisedNumber(poolStats.price, quoteDecimals.toNumber()),
      poolStats.name,
      baseDecimals,
      quoteDecimals,
      Utils.hexToNumber(poolStats.min_quote)
    );
  }

}
