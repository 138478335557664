<h4 class="text-white wallet-balance">Tokens</h4>

<div class="tokens-wrapper">
  <ng-container *ngFor="let tokenBalance of tokenBalances;">
    <div class="sidebar-table-row">
      <div class="sidebar-table-column">
        <div class="sidebar-table-text-left">
          <img src="assets/images/{{tokenBalance.img}}" height="24" class="balances-icon" alt="">
          <span class="ticker">{{tokenBalance.name}}</span>
        </div>
      </div>
      <div class="sidebar-table-column">
        <div class="sidebar-table-text-right">{{tokenBalance.balance | roundDown2Dec | usFormat}}</div>
      </div>
    </div>
  </ng-container>
</div>
