import {environment} from "../../environment/environment";
import {MagicUserMetadata, RPCErrorCode, RPCError} from "magic-sdk";
import BigNumber from 'bignumber.js';
import {BridgeError} from "../models/errors/bridgeError";
import {log, parseHexToNumber} from "../common/Utils";
import {Irc2ParamsObj, TransactionObj} from '../../lib/models/Transaction/Irc2TokenTransaction';
import { OracleTokenNames } from "../models/Tokens/Tokens";

async function estimateStepsForTx(txObj: TransactionObj): Promise<number> {
    const reqObj = {
        jsonrpc: "2.0",
        method: "debug_estimateStep",
        id: 1234,
        params: txObj
    };
    try {
        const responsePromise = await fetch(environment.ICON_RPC_DEBUG_URL,
          {
            method: 'POST',
            body: JSON.stringify(reqObj),
            headers: {
              "Content-Type": "application/json"
            }
          }
        );
        const responseJSON = await responsePromise.json();
    
        return new BigNumber(responseJSON.result).plus(100000).toNumber();
    
      } catch (err) {
        console.error('Error while estimating step limit');
        throw err;
      }
}

function getBridgeScoreAddress() {
    return environment.BRIDGE_SCORE_ADDRESS;
}

function getNid() {
    return environment.BRIDGE_NID;
}

// returns txHash of transaction
export async function sendIrc2Token(magic: any, address: string, scoreAddress: string, amount: number, decimals: number, data: string | undefined, IconBuilder: any,
                                    IconConverter: any, magicUserMetadata: MagicUserMetadata | undefined): Promise<string> {
    if (!magicUserMetadata) {
        throw new BridgeError("An error occurred. Are you logged in?",
            Error("User not logged in Magic!"));
    }

    const paramsObj = new Irc2ParamsObj(
        address, 
        IconConverter.toHex(new BigNumber(amount).multipliedBy(Math.pow(10, decimals))), 
        data
    );

    const baseTxObj = new IconBuilder.CallTransactionBuilder()
        .from(magicUserMetadata.publicAddress)
        .to(scoreAddress)
        .nid(IconConverter.toHex(getNid()))
        .nonce(IconConverter.toHex(1))
        .version(IconConverter.toHex(3))
        .timestamp(IconConverter.toHex((new Date()).getTime() * 1000))
        .method('transfer')
        .params(paramsObj);
    
    const requiredSteps = await estimateStepsForTx(baseTxObj.build());

    const txObj = baseTxObj.stepLimit(IconConverter.toHex(requiredSteps)).build();
    console.log(txObj);
    
    try {
        return await magic.icon.sendTransaction(txObj);
    } catch (e) {
        log(e);
        if(e instanceof RPCError && e.code == RPCErrorCode.InternalError) {
            const displayMsg = e.message.includes('balance:') ?
                'Insufficient ICX balance' :
                'Magic RPC Error: Is the receiving address correct?';
            throw new BridgeError('Error while sending tokens! ' + 
                displayMsg, e);
        }
        throw new BridgeError('Error while sending tokens! ' +
            'Unknown error occurred.', e)
    }
}

// returns txHash of transaction
export async function sendIcxTokens(magic: any, to: string, amount: number, IconBuilder: any,
                                    IconAmount: any, IconConverter: any, magicUserMetadata: MagicUserMetadata | undefined): Promise<string> {
    if (!magicUserMetadata) {
        throw new BridgeError("An error occurred. Are you logged in?",
            Error("User not logged in Magic!"));
    }

    const baseTxObj = new IconBuilder.IcxTransactionBuilder()
        .nid(IconConverter.toHex(getNid()))
        .from(magicUserMetadata.publicAddress)
        .to(to)
        .value(IconConverter.toHex(IconAmount.of(amount, IconAmount.Unit.ICX).toLoop()))
        .version(IconConverter.toHex(3))
        .timestamp(IconConverter.toHex((new Date()).getTime() * 1000));
    
    const requiredSteps = await estimateStepsForTx(baseTxObj.build());

    const txObj = baseTxObj.stepLimit(IconConverter.toHex(requiredSteps)).build();
    console.log(txObj);

    try {
        return await magic.icon.sendTransaction(txObj);
    } catch (e) {
        log(e);
        if(e instanceof RPCError && e.code == RPCErrorCode.InternalError) {
            const displayMsg = e.message.includes('balance:') ?
                'Insufficient ICX balance' :
                'Magic RPC Error: Is the receiving address correct?';
            throw new BridgeError('Error while sending tokens! ' + 
                displayMsg, e);
        }
        throw new BridgeError('Error while sending ICX tokens! ' +
            'Please check your balance and try again later.', e)
    }
}

export async function getIcxBalance(address: string | null, IconSDK: any): Promise<number> {
    if (!address) {
        throw new BridgeError("Your Icon wallet address is not available.",
            Error("getIcxBalance -> address empty or null!"))
    }
    try {
        return await IconSDK.getBalance(address).execute();
    } catch (e) {
        log(e);
        throw new BridgeError(`Error while reading Icx account balance!`, e)
    }
}

export async function getIrc2TokenBalance(address: string, scoreAddress: string, IconBuilder: any, IconSDK: any): Promise<number> {
    const txObj = new IconBuilder.CallBuilder()
        .to(scoreAddress)
        .method('balanceOf')
        .params({
            _owner: address
        })
        .build();
    try {
        const res = await IconSDK.call(txObj).execute();
        return parseHexToNumber(res);
    } catch (e) {
        log(e);
        throw new BridgeError("Error while reading your Icon wallet balance. Please try again later. ", e)
    }
}

export async function getTokenRate(IconBuilder: any, IconSDK: any, baseToken:OracleTokenNames, quoteToken: OracleTokenNames ): Promise<BigNumber> {
    if(baseToken == OracleTokenNames.sICX) {
        const icxRate = await getTokenRate(IconBuilder, IconSDK, OracleTokenNames.ICX, OracleTokenNames.USD);
        const sicxIcxRatio = await getSicxIcxRatio(IconBuilder, IconSDK);
        const sicxRate = icxRate.multipliedBy(sicxIcxRatio);
        return sicxRate;
    }
    // omm has separate score address to fetch it's real-time price
    const priceScoreAddress = baseToken === OracleTokenNames.OMM ? 
        environment.OMM_PRICE_SCORE_ADDRESS : 
        environment.BAND_SCORE_ADDRESS;

    const txObj = new IconBuilder.CallBuilder()
        .to(priceScoreAddress)
        .method('get_reference_data')
        .params({
            '_base': baseToken,
            '_quote': quoteToken
        })
        .build();
    try {
        const res = await IconSDK.call(txObj).execute();
        const fetchedRate = baseToken === OracleTokenNames.OMM ?
            res : res.rate;
        return new BigNumber(fetchedRate).div(Math.pow(10, 18));
    } catch (e) {
        log(e);
        throw new BridgeError(`Error while fetching ${baseToken} rate. Please try again later`, e);
    }
}

export async function getSicxIcxRatio(IconBuilder: any, IconSDK: any): Promise<BigNumber> {
    const txObj = new IconBuilder.CallBuilder()
        .to(environment.STAKING_SCORE_ADDRESS)
        .method('getTodayRate')
        .params({})
        .build();
    try {
        const res = await IconSDK.call(txObj).execute();
        return new BigNumber(res).div(Math.pow(10, 18));
    } catch (e) {
        log(e);
        throw new BridgeError("Error while fetching current sICX rate. Please try again later", e);
    }
}

export function buildWithdrawalRequestTransaction(from: string, amount: number, IconBuilder: any,
                                                  IconAmount: any, IconConverter: any) {
    return new IconBuilder.CallTransactionBuilder()
        .from(from)
        .to(getBridgeScoreAddress())
        .stepLimit(IconConverter.toHex(2000000))
        .nid(IconConverter.toHex(getNid()))
        .nonce(IconConverter.toHex(1))
        .version(IconConverter.toHex(3))
        .value(IconConverter.toHex(0))
        .timestamp(IconConverter.toHex((new Date()).getTime() * 1000))
        .method("createWithdrawalRequest")
        .params({
            _value: IconConverter.toHex(IconAmount.of(amount, IconAmount.Unit.ICX).toLoop())
        })
        .build();
}
