import {Injectable} from "@angular/core";
import log from "loglevel";
import {IconApiService} from "./icon-api.service";
import {PersistenceService} from "./persistence.service";
import {TransactionResultService} from "./transaction-result.service";
import {DataLoaderService} from "./data-loader.service";
import {ScoreApiService} from "./score-api.service";
import {LoginService} from "./login.service";
import {Wallet, WalletType} from "../models/classes/Wallet";
import IconService from 'icon-sdk-js';
import {IconexId} from "../models/enums/IconexId";
import {NotificationService} from "./notification.service";
import {NotificationType} from "../models/enums/NotificationType";
import {WALLET_CANCEL, NO_WALLET} from "../common/constants";
import {ModalService} from './modal.service';

const { IconConverter } = IconService;

@Injectable({
  providedIn: "root"
})
export class IconexApiService {

  /*
  * https://www.icondev.io/docs/chrome-extension-connect
  */

  constructor(private iconApiService: IconApiService,
              private persistenceService: PersistenceService,
              private transactionResultService: TransactionResultService,
              private scoreService: ScoreApiService,
              private dataLoaderService: DataLoaderService,
              private loginService: LoginService,
              private notificationService: NotificationService,
              private modalService: ModalService) { }

  public iconexEventHandler( e: any): void {
    const {type, payload} = e.detail;
    log.debug("iconexEventHandler:");
    log.debug(type, " : ", payload);

    switch (type) {
      case "RESPONSE_HAS_ACCOUNT": {
        if (payload.hasAccount) { this.requestAddress(); }
        else {
          this.notificationService.showAlertNotification(NO_WALLET);
          log.debug("Wallet does not exist. Please log in to Iconex and try again.");
        }
        break;
      }
      case "RESPONSE_ADDRESS": {
        this.loginService.walletLogin(new Wallet(payload, WalletType.ICON));
        log.debug("Successfully connected your Icon wallet!");
        break;
      }
      case "RESPONSE_SIGNING": {
        log.debug("RESPONSE_SIGNING:");
        log.debug(payload); // e.g., 'q/dVc3qj4En0GN+...'
        break;
      }
      case "RESPONSE_JSON-RPC": {
        log.debug("RESPONSE_JSON-RPC", payload.result);

        this.transactionResultService.processIconexTransactionResult(payload);
        break;
      }
      case "CANCEL_JSON-RPC": {
        this.notificationService.hideActiveNotification();
        this.modalService.hideActiveModal();

        this.notificationService.showErrorNotification(WALLET_CANCEL);
        break;
      }
      default: {
        log.debug("Iconex default response handler:", payload, type);
        break;
      }
    }
  }

  /*
    REQUEST_HAS_ACCOUNT Requests for whether iconex has any icon wallet.
    Returns boolean-typed result in event.
   */
  public hasAccount(): void {
    this.dispatchIconexEvent("REQUEST_HAS_ACCOUNT", null);
  }

  /*
    REQUEST_ADDRESS Requests for the address to use for service.
   */
  public requestAddress(): void {
    this.dispatchIconexEvent("REQUEST_ADDRESS", null);
  }

  public dispatchIconexEvent(requestType: string, payload: any): void {
    window.dispatchEvent(new CustomEvent("ICONEX_RELAY_REQUEST", {
      detail: {
        type: requestType,
        payload
      }}));
  }

  public dispatchSendTransactionEvent(transaction: any, id: number = IconexId.DEFAULT): void {
    log.debug("dispatchSendTransactionEvent..");
    window.dispatchEvent(new CustomEvent("ICONEX_RELAY_REQUEST", {
      detail: {
        type: "REQUEST_JSON-RPC",
        payload: {
          jsonrpc: "2.0",
          method: "icx_sendTransaction",
          params: IconConverter.toRawTransaction(transaction),
          id
        }
      }
    }));
  }

  public dispatchSignTransactionEvent(transaction: any): void {
    const payload = {
      detail: {
        type: "REQUEST_SIGNING",
        payload: IconConverter.toRawTransaction(transaction)
      }
    };
    log.debug("dispatchSignTransactionEvent payload:");
    log.debug(payload);
    window.dispatchEvent(new CustomEvent("ICONEX_RELAY_REQUEST", {
      detail: {
        type: "REQUEST_SIGNING",
        payload: IconConverter.toRawTransaction(transaction)
      }
    }));
  }
}
