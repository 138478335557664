import BigNumber from "bignumber.js";

export const defaultPrepLogoUrl = "assets/images/icx.png";
export const MIN_BOND_INPUT = 0.0001;
export const BOND_INPUT_DECIMAL_PRECISION = 4;

export const MIN_TREASURY_USD_BALANCE = 10;

export const ZERO = new BigNumber(0);

export const INVALID_INPUT_ALERT = "Invalid input.";
export const TOO_LOW_BALANCE = "Insufficient balance.";
export const MIN_BOND_INPUT_ALERT = `Input amount must be greater than ${MIN_BOND_INPUT}.`;
export const INPUT_MAX_ALERT = "Input amount can not be greater than max.";
export const UNEXPECTED_ERROR = "Unexpected error occurred.";
export const WALLET_CANCEL = "Wallet transaction cancelled.";
export const NO_WALLET = "Wallet does not exist. Please connect wallet and try again.";
export const BOND_UNDEFINED = "Bond info undefined. Please try again in a moment.";

/**
 * Constants from Karma Bond
 */
export const PERCENT_VESTED_PRECISION = 10000; // 10000 == 100%
export const KARMA_FEE_PRECISION = 4;
export const BOND_PRICE_DECIMAL_PRECISION = 7;
export const PAYOUT_PRECISION = 6; // Used in calculations only!
export const PAYOUT_FOR_DECIMALS_PRECISION = 5;
export const TOTAL_PAYOUT_PRECISION = 11;
export const EXA = new BigNumber(10).pow(18); // 10**18
export const FULLY_VESTED = 10000;

/**
 * SCORE Error codes
 */

export const UNKNOWN_FAILURE = "UnknownFailure";

export class Times {
  /** Class containing times (seconds, minutes, ..) in seconds, milliseconds and methods to retrieve arbitrary time in it */
  public static readonly SECOND_IN_MILLISECONDS = 1000;
  public static readonly MINUTE_IN_MILLISECONDS = 60000;
  public static readonly HOUR_IN_MILLISECONDS = 3600000;
  public static readonly DAY_IN_MILLISECONDS = 86400000;
  public static readonly WEEK_IN_MILLISECONDS = new BigNumber("604800000");
  public static readonly MONTH_IN_MILLISECONDS = new BigNumber("2592000000");
  public static readonly YEAR_IN_MILLISECONDS = new BigNumber("31536000000");
  public static readonly TWO_YEARS_IN_MILLISECONDS = new BigNumber("63072000000");
  public static readonly FOUR_YEARS_IN_MILLISECONDS = new BigNumber("126144000000");

  public static readonly MINUTE_IN_SECONDS = 60;
  public static readonly HOUR_IN_SECONDS = 3600;
  public static readonly DAY_IN_SECONDS = 86400;

  public static secondsInMilliseconds(seconds: number): number {
    return seconds * Times.SECOND_IN_MILLISECONDS;
  }

  public static minutesInMilliseconds(minutes: number): number {
    return minutes * Times.MINUTE_IN_MILLISECONDS;
  }

  public static minutesInSeconds(minutes: number): number {
    return minutes * Times.MINUTE_IN_SECONDS;
  }

  public static daysInSeconds(days: number): number {
    return days * Times.DAY_IN_SECONDS;
  }
}
