import {ActionPayloadI} from '../interfaces/ActionPayloadI';
import {BondConfig} from './BondConfigInfo';

export class ToggleBondSuccessPayload implements ActionPayloadI {
  bondWasEnabled: boolean;
  bond: BondConfig;
  txHash: string;

  constructor(bondWasEnabled: boolean, bond: BondConfig, txHash: string) {
    this.bondWasEnabled = bondWasEnabled;
    this.bond = bond;
    this.txHash = txHash;
  }

  successMessage(): string {
    return `Successfully ${this.bondWasEnabled ? "disabled" : "enabled"} ${this.bond.principalToken.tag} bond.}`
  }

  errorMessage(): string {
    return "Failed to toggle bond. Please try again in a moment."
  }
}
