import {LitElement, html, customElement, property} from 'lit-element';

// css styles
// @ts-ignore
import mainCSS from '../../assets/css/main.css';
// @ts-ignore
import normalizeCSS from '../../assets/css/normalize.css';
// @ts-ignore
import paymentSuccessSvg from "../../assets/img/icon/payment-successful.svg";
// @ts-ignore
import paymentDeclinedSvg from "../../assets/img/icon/payment-declined.svg";
// @ts-ignore
import dollarGreenSvg from "../../assets/img/icon/dollar-out-green.svg";


import {BridgeService} from "../../lib/BridgeService";
import {User} from "../../lib/models/User/User";
import {log, numberWithCommas, parseHexToNumber} from "../../lib/common/Utils";
import {hideModalView} from "../logic/view-transitions";
import {validateSendBridgeTokens} from "../../lib/validation/send-validation";
import {BridgeError} from "../../lib/models/errors/bridgeError";
import {SupportedTokens, Token} from "../../lib/models/Tokens/Tokens";
import {hideSpanError, showSpanError} from "../logic/utils";
import {amountContainsMaxOneDotFollowedBy2decimals} from "../../lib/validation/validation";
import errorLogger from '../services/errorLogger';


@customElement('send-token')
class SendToken extends LitElement {

    @property() bridge: BridgeService | undefined;
    @property() user: User | undefined;
    @property() selectedToken: Token | undefined;
    @property() tokenBalance: number = 0;
    @property() userTokensMap: Map<string,Token> = new Map<SupportedTokens, Token>();

    // Input values
    @property() inputAmount: HTMLInputElement | null | undefined;
    @property() amountInputError: HTMLElement | undefined | null;
    @property() sendAddress: HTMLInputElement | null | undefined;
    @property() selectToken: HTMLInputElement | null | undefined;

    @property() sendAmountView : HTMLElement | null | undefined;
    @property() confirmationView: HTMLElement | null | undefined;
    @property() processingModalView: HTMLElement | null | undefined;
    @property() sendSuccessView: HTMLElement | null | undefined;
    @property() sendErrorView: HTMLElement | null | undefined;

    @property() activeView: HTMLElement | null | undefined;

    @property() amount: number = 0;

    @property() errors: string[] = [];

    protected firstUpdated(_changedProperties: any): void {
        super.firstUpdated(_changedProperties);

        this.inputAmount = <HTMLInputElement>this.shadowRoot!.getElementById("send-amount-input");
        this.amountInputError = this.shadowRoot!.getElementById("amount-error");
        this.sendAddress = <HTMLInputElement>this.shadowRoot!.getElementById("send-address");
        this.selectToken = <HTMLInputElement>this.shadowRoot!.getElementById("select-token");

        this.sendAmountView = this.shadowRoot!.getElementById("send-amount");
        this.confirmationView = this.shadowRoot!.getElementById("send-confirmation");
        this.processingModalView = this.shadowRoot!.getElementById("send-processing");
        this.sendSuccessView = this.shadowRoot!.getElementById("payment-successful");
        this.sendErrorView = this.shadowRoot!.getElementById("send-error-view");

        this.showModalView(this.sendAmountView);
    }

    constructor() {
        super();
    }

    static styles = [
        mainCSS,
        normalizeCSS
    ];

    private showConfirmationView(event: Event) {
        event.preventDefault();
        if (this.amount == 0) {
            return;
        }
        if (!amountContainsMaxOneDotFollowedBy2decimals(this.amount)) {
            showSpanError("Max 2 decimal places allowed.", this.amountInputError!, this.inputAmount!);
            return false;
        } else if (this.amount > this.selectedToken!.balance) {
            showSpanError("Must be equal or less than the balance.", this.amountInputError!, this.inputAmount!);
            return false;
        } else {
            hideSpanError(this.amountInputError!, this.inputAmount!);
        }

        let transferToAddress = this.sendAddress!.value.trim();
        const errors: string[] = [];
        if (!this.selectedToken) {
          errors.push("No token selected");
        } else {
          errors.concat(validateSendBridgeTokens(this.amount, transferToAddress, this.selectedToken!.balance, this.user!.iconWalletAddress!));
        }
        if (errors.length > 0) {
            this.errors = errors;
            this.showModalView(this.sendErrorView);
            return;
        }

        this.showModalView(this.confirmationView);
        return false;
    }

    private handleSessionExpired() {
        let event = new CustomEvent('handleSessionExpiry', {});
        this.dispatchEvent(event);
        this.showModalView(this.sendAmountView);
        this.clearInputFields();
    }

    private async transferTokens() {
        this.showModalView(this.processingModalView);
        if(!await this.bridge?.userIsLoggedIn()) {
          this.handleSessionExpired();
          return;
        }
        let transferToAddress = this.sendAddress!.value.trim();

        const errors: string[] = [];
        if (!this.selectedToken) {
          errors.push("No token selected");
        } else {
          errors.concat(validateSendBridgeTokens(this.amount, transferToAddress, this.selectedToken!.balance, this.user!.iconWalletAddress!));
        }

        if (errors.length > 0) {
            this.errors = errors;
            this.showModalView(this.sendErrorView);
        } else {
          let tokenTag = this.selectedToken!.tag;
            try {
                let txHash: string;
                if (this.selectedToken?.tag == SupportedTokens.ICX) {
                    txHash = await this.bridge!.sendIcxTokens(transferToAddress, this.amount);
                } else {
                    txHash = await this.bridge!.sendIrc2Tokens(transferToAddress, this.amount, this.selectedToken!.decimals, this.selectedToken!.scoreAddress);
                }
                setTimeout(this.checkTransferTxHashResult.bind(this,txHash, this.amount, transferToAddress, tokenTag), 3000);
            } catch (e) {
                // post error message to errorLogger
                errorLogger.postError('transferTokens method', undefined, typeof e == 'string' ? e : JSON.stringify(e), 
                  this.user?.iconWalletAddress ?? undefined);
                this.clearInputFields();
                this.handleError(e);
            }
        }
    }

    private amountChange(event: InputEvent) {
        // skip for arrow keys
        if (event.which >= 37 && event.which <= 40){
            return;
        }

        this.inputAmount!.value = this.inputAmount!.value.split(",").join("");

        this.amount = +this.inputAmount!.value;
        if (this.amount < 0) {
            this.clearInputFields();
            return;
        }
        if (this.amount > this.selectedToken!.balance) {
            this.inputAmount!.classList.add("border-red");
        } else {
            this.inputAmount!.classList.remove("border-red");
        }

        if (!amountContainsMaxOneDotFollowedBy2decimals(this.amount)) {
            showSpanError("Max 2 decimal places allowed.", this.amountInputError!, this.inputAmount!);
        } else if (this.amount > this.selectedToken!.balance) {
            showSpanError("Must be equal or less than the balance.", this.amountInputError!, this.inputAmount!);
        } else {
            hideSpanError(this.amountInputError!, this.inputAmount!);
        }

        this.inputAmount!.value = numberWithCommas(this.inputAmount!.value);
    }

    private selectedTokenChange() {
        this.selectedToken = this.userTokensMap.get(this.selectToken!.value);
        console.log("selectedTokenChange:", this.selectedToken);
    }

    private checkTransferTxHashResult(txHash: string, amount: number, to:string, tokenTag:string, retryAttempt:number=1) {

      if(retryAttempt > 5) {
        this.handleError(new BridgeError('Could not fetch transaction details. Check ICON tracker for more info.'));
        return;
      }

      log('Trying to fetch txhash attempt: ', retryAttempt);
        this.bridge!.getTxResult(txHash).then(result => {
            log("Transaction result :", result);
            if (parseHexToNumber(result.status) != 1) {
                this.handleError(new BridgeError("Transaction failed"))
            } else {
                const balanceNow = +(this.selectedToken!.balance - this.amount).toFixed(2);
                this.showModalView(this.sendSuccessView);
                this.tokenTransferEvent(amount,to);
                this.updateBalanceEvent(balanceNow);

                window.dispatchEvent(new CustomEvent('bri.sendToken', {
                  detail: {
                    token: tokenTag,
                    to,
                    amount,
                    txHash
                  }
                }))
            }
        }).catch((e) => {
            log("Error: ", e);
            if (e instanceof BridgeError) {
              setTimeout(() => this.checkTransferTxHashResult(txHash, amount, to, tokenTag, retryAttempt+1), 2000)
            } else {
              // post error message to errorLogger
              errorLogger.postError('checkTransferTxHashResult method', undefined, typeof e == 'string' ? e : JSON.stringify(e), 
                this.user?.iconWalletAddress ?? undefined);
              this.handleError(e);
            }
        });
    }

    private handleError(e: any) {
        if (e instanceof BridgeError) {
            this.errors = [e.userFriendlyMessage];
        } else {
            this.errors = [e.message];
        }
        this.showModalView(this.sendErrorView)
    }

    private clearInputFields() {
        this.amount = 0;
        this.inputAmount!.value = "";
        this.sendAddress!.value = "";
    }


    private showModalView(modalView: HTMLElement | null | undefined) {
        hideModalView(this.activeView);
        modalView!.style.display = "block";
        this.setActiveModalView(modalView);
    }

    private setActiveModalView(newActiveModal: HTMLElement | null | undefined) {
        this.activeView = newActiveModal;
        log("New send active modal:", newActiveModal);
    }

    tokenTransferEvent(amount: number, to: string) {
        let event = new CustomEvent('tokenTransfer', {
            detail: {
                amount: amount,
                to: to,
                tokenTag: this.selectedToken?.tag
            }
        });
        this.dispatchEvent(event);
    }

    private backToHomeViewEvent(){
        this.clearInputFields();

        let event = new CustomEvent('backToHomeView', {});
        this.dispatchEvent(event);
        this.showModalView(this.sendAmountView)
    }

    private updateBalanceEvent(balance: number){
        this.dispatchEvent(new CustomEvent('updateBalance', {
            detail: {
                balance: balance,
                tokenTag: this.selectedToken!.tag
            }
        }));
    }

    protected render(): unknown {
        return html`
            <div class="widget" id="send-amount" hidden>
              <!-- Navigation -->
              <div class="grid">
                <div class="grid-cell">
                  <a @click="${this.backToHomeViewEvent}" class="back">Back</a>
                </div>
                <div class="grid-cell">
                  <p class="label-upper text-center">Send</p>
                </div>
                <div class="grid-cell">
            
                </div>
              </div>
            
              <!-- Send amount form -->
              <div class="grid grid-center inherit-height">
                <div class="grid-cell">
                  <form class="margin-top-25" @submit=${(e:Event) => this.showConfirmationView(e)}>
                    <div class="grid grid-center">
                      <div class="grid-cell">
                        <label for="send-amount-input">Send amount</label><br>
                      </div>
                      <div class="grid-cell text-right">
                        <p><span class="text-small opacity-75">Balance: ${this.selectedToken?.balance} ${this.selectedToken?.tag}</span></p>
                      </div>
                    </div>
                    <select name="currency-selector" class="input-currency-selector" @change=${() => this.selectedTokenChange()} id="select-token">
                        ${Array.from(this.userTokensMap.values()).map((token: Token) => {
                            if (token.tag == this.selectedToken?.tag)
                                return html `<option value="${token.tag}" selected>${token.tag}</option>`
                            else
                                return html `<option value="${token.tag}">${token.tag}</option>`
                            }
                        )}
                    </select>
                    <input class="margin-top-10 margin-bottom-25 text-center" type="text" value="" @keyup=${(e:InputEvent) => this.amountChange(e)} 
                    required id="send-amount-input" name="send-amount-input" placeholder="0">
                    <span class="error-span"  id="amount-error" hidden></span>

   
                    <label for="send-address">To</label><br>
                    <input class="margin-top-10 margin-bottom-25 text-center" type="text" minlength="42" maxlength="42" 
                    required id="send-address" name="send-address" placeholder="ICON blockchain address (hx...)" autocomplete="on">
            
                    <table class="fee-list">
                      <tbody>
                        <tr class="opacity-75">
                          <td class="padding-bottom-5">Fee</td>
                          <td class="text-right padding-bottom-5">0.00 ${this.selectedToken?.tag}</td>
                        </tr>
                        <tr class="border-top">
                          <td>Total</td>
                          <td class="text-right">${this.amount.toFixed(2)} ${this.selectedToken?.tag}</td>
                        </tr>
                      </tbody>
                    </table>
            
                    <p class="text-center margin-top-25"><input type="submit" value="Send" class="button"></p>
                  </form>
                </div>
              </div>
            </div>
            
            <!-- Send confirmation -->
            <div class="widget" id="send-confirmation" hidden>
              <div class="grid grid-center inherit-height">
                <div class="grid-cell">
                  <img src=${dollarGreenSvg} class="feature-icon">
                  <h2>Send ${this.amount.toFixed(2)} ${this.selectedToken?.tag}?</h2>
                  <div class="grid margin-top-15 padding-bottom-15 border-bottom">
                    <div class="grid-cell">
                      <p class="text-center label-small">Receiving Address</p>
                      <p class="text-center text-bold">
                      <span class="blockchain-address">${this.sendAddress?.value.trim()}</span>
                      </p>
                    </div>
                  </div>
                  <div class="grid margin-top-25 margin-bottom-25">
                    <div class="grid-cell border-right">
                      <p class="text-center label-small">Before</p>
                      <p class="text-center text-bold">${this.selectedToken?.balance} ${this.selectedToken?.tag}</p>
                    </div>
                    <div class="grid-cell">
                      <p class="text-center label-small">After</p>
                      <p class="text-center text-bold">${((this.selectedToken?.balance ?? 0) - this.amount).toFixed(2)} ${this.selectedToken?.tag}</p>
                    </div>
                  </div>
                  <div class="grid grid-center">
                    <div class="grid-cell">
                      <p class="text-center margin-top-15 margin-bottom-15"><a @click="${() => this.showModalView(this.sendAmountView)}">Cancel</a></p>
                    </div>
                    <div class="grid-cell">
                      <p class="margin-top-15 margin-bottom-15"><a @click="${this.transferTokens}" class="button">Confirm</a></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <!-- Sending payment -->
            <div class="widget" id="send-processing" hidden>
              <div class="grid grid-center inherit-height">
                <div class="grid-cell">
                  <img src=${dollarGreenSvg} class="feature-icon">
                  <h2 class="margin-bottom-10">Sending your payment</h2>
                  <p class="text-center margin-top-15 margin-bottom-10">This may take a few moments.</p>
            
            
                  <!-- Loading animation -->
                  <div class="loading-animation">
                    <label>●</label>
                    <label>●</label>
                    <label>●</label>
                    <label>●</label>
                    <label>●</label>
                    <label>●</label>
                  </div>
                </div>
              </div>
            </div>
            
            <!-- Payment successful -->
            <div class="widget" id="payment-successful" hidden>
              <div class="grid grid-center inherit-height">
                <div class="grid-cell">
                  <img src=${paymentSuccessSvg} class="feature-icon">
                  <h2>Payment sent</h2>
                  <p class="text-center margin-top-5">${this.amount.toFixed(2)} ${this.selectedToken?.tag} has been removed from your account.</p>
                  <p class="text-center margin-top-15 margin-bottom-15"><a @click="${this.backToHomeViewEvent}" class="button">Close</a></p>
                </div>
              </div>
            </div>
                
            <!-- Payment declined -->
            <div class="widget" id="send-error-view" hidden>
              <div class="grid grid-center inherit-height">
                <div class="grid-cell">
                    <img src=${paymentDeclinedSvg} class="feature-icon">
                    <h2>Payment declined</h2>
                    ${this.errors.map((error: string) => html `
                    <p class="text-center margin-top-5">${error}</br></p>
                    `)}
                    <p class="text-center margin-top-15 margin-bottom-15">
                     <a @click="${() => this.showModalView(this.sendAmountView)}" class="button">Back</a>
                    </p>
                </div>
              </div>
            </div>
        `
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'send-token': SendToken;
    }
}
