import {BondConfig} from "./BondConfigInfo";
import BigNumber from 'bignumber.js';

export class BondModalPayload {
  /**
   * @description Payload class for the bonding modal
   */

  bond: BondConfig;
  trueBondPrice: BigNumber;

  constructor(bond: BondConfig, trueBondPrice: BigNumber) {
    this.bond = bond;
    this.trueBondPrice = trueBondPrice;
  }
}
