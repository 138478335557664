import {Component, OnInit} from '@angular/core';
import {NotificationService} from "../../services/notification.service";
import {NotificationType} from "../../models/enums/NotificationType";
import {NotificationI} from "../../models/interfaces/NotificationI";
import {BaseClass} from "../../base-class";
import {PersistenceService} from "../../services/persistence.service";

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html'
})
export class NotificationComponent extends BaseClass {

  constructor(private notificationService: NotificationService,
              public override persistenceService: PersistenceService) {
    super(persistenceService);
  }

  activeNotification(): NotificationI | undefined {
    return this.notificationService.getActiveNotification();
  }

  notificationType(): NotificationType | undefined {
    return this.notificationService.getActiveNotification()?.type;
  }

  onCloseClick() {
    this.notificationService.hideActiveNotification();
  }

  isSuccessNotification(): boolean {
    return this.notificationType() === NotificationType.SUCCESS;
  }

  isErrorNotification(): boolean {
    return this.notificationType() === NotificationType.ERROR;
  }

  isAlertNotification(): boolean {
    return this.notificationType() === NotificationType.ALERT;
  }

  isInfoNotification(): boolean {
    return this.notificationType() === NotificationType.INFO;
  }
}
