import Axios, {AxiosInstance, AxiosResponse} from "axios";
import {BridgeError} from "../models/errors/bridgeError";
import {log} from "../common/Utils";
import {environment} from "../../environment/environment";
import {RestApiUrls} from "../common/rest-api-urls";

export class IconApiService {

    private axios: AxiosInstance;

    constructor() {
        this.axios = Axios.create({
            withCredentials: false,
            baseURL: environment.ICON_REST_API_URL,
            headers: {
                common: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                },
            }
        });
    }

    /**
     * @description Get all token transactions of address
     * @param {string} address - ICON address to check
     * @param {number} page - number of the page for pagination
     * @param {number} count - number of the results to fetch per page
     * @return {Promise<AxiosResponse>} Promise with list of transactions from "https://bicon.tracker.solidwallet.io/v3"
     * @throws {BridgeError} - contains user friendly message from the API and external error
     */
    public async getTokenTransactions(address: string, page?:number, count?: number): Promise<AxiosResponse> {
        try {
            return await this.axios.get(RestApiUrls.TOKENS_TRANSACTIONS_API_URL, {
                params: {
                    address: address,
                    page: page ?? 1,
                    count: count ?? 10
                },
                withCredentials: false,
                responseType: 'json',
            })
        } catch (e) {
            log(e);
            throw new BridgeError(`Error while getting token transactions:\n${e.message}\n. 
            Please try again later.`, e)
        }
    }
    /**
     * @description Get ICX transactions of the address
     * @param {string} address - ICON address to check
     * @param {number} page - number of the page for pagination
     * @param {number} count - number of the results to fetch per page
     * @return {Promise<AxiosResponse>} Promise with list of transactions from "https://bicon.tracker.solidwallet.io/v3"
     * @throws {BridgeError} - contains user friendly message from the API and external error
     */
    public async getIcxTransactions(address: string, page?:number, count?: number): Promise<AxiosResponse> {
        try {
            return await this.axios.get(RestApiUrls.ICX_TRANSACTIONS_API_URL, {
                params: {
                    address: address,
                    page: page ?? 1,
                    count: count ?? 20
                },
                withCredentials: false,
                responseType: 'json',
            })
        } catch (e) {
            log(e);
            throw new BridgeError(`Error while getting ICX transactions:\n${e.message}\n. 
            Please try again later.`, e)
        }
    }

}
