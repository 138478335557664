import { Injectable } from '@angular/core';
import {IconApiService} from "./icon-api.service";
import {ScoreMethodNames} from "../common/score-method-names";
import IconService from 'icon-sdk-js';
const { IconConverter, IconAmount } = IconService;
import {IconTransactionType} from "../models/enums/IconTransactionType";
import {Terms} from "../models/classes/Terms";
import {AppConfigService} from "./app-config.service";
import {Mapper} from "../common/mapper";
import {Utils} from "../common/utils";
import BigNumber from "bignumber.js";
import {PersistenceService} from "./persistence.service";
import {BondData} from "../models/classes/BondData";
import {PoolStats, PoolStatsInterface} from "../models/classes/PoolStats";
import log from "loglevel";
import {BondConfig} from "../models/classes/BondConfigInfo";
import {BondInfo} from "../models/classes/BondInfo";
import {BOND_PRICE_DECIMAL_PRECISION} from "../common/constants";
import {Irc2Token} from '../models/classes/Irc2Token';
import {BalnLpToken} from '../models/classes/BalnLpToken';

@Injectable({
  providedIn: 'root'
})
export class ScoreApiService {

  constructor(private iconApiService: IconApiService,
              private appConfig: AppConfigService,
              private persistenceService: PersistenceService) { }

  /**
   * @description Calculate amount of payout token available for claim by user
   * @param bond - Bond information
   * @return BigNumber - Amount of payout token available for claim by user
   */
  public async bondInfo(bond: BondConfig): Promise<BondInfo | undefined> {
    const params = { depositor: this.persistenceService.activeWallet?.address };

    const tx = this.iconApiService.buildTransaction("",  bond.baseInfo.customBond,
      ScoreMethodNames.BOND_INFO, params, IconTransactionType.READ);

    const res = await this.iconApiService.iconService.call(tx).execute();
    log.debug("bondInfo res:", res);

    return res ? Mapper.mapBondInfo(res, bond.payoutToken.decimals) : undefined;
  }

  /**
   * @description Calculate amount of payout token available for claim by user
   * @param bond - Bond information
   * @return BigNumber - Amount of payout token available for claim by user
   */
  public async pendingPayoutFor(bond: BondConfig): Promise<BigNumber> {
    const params = { depositor: this.persistenceService.activeWallet?.address };

    const tx = this.iconApiService.buildTransaction("",  bond.baseInfo.customBond,
      ScoreMethodNames.PENDING_PAYOUT_FOR, params, IconTransactionType.READ);

    return Utils.hexToNormalisedNumber(await this.iconApiService.iconService.call(tx).execute(), bond.payoutToken.decimals);
  }

  /**
   * @description Calculate how far into vesting an user is
   * @param bond - Bond information
   * @return BigNumber - Percentage indicating how far into vesting an user is
   */
  public async percentVestedFor(bond: BondConfig): Promise<BigNumber> {
    const params = { depositor: this.persistenceService.activeWallet?.address };

    const tx = this.iconApiService.buildTransaction("",  bond.baseInfo.customBond,
      ScoreMethodNames.PERCENT_VESTED_FOR, params, IconTransactionType.READ);

    return Utils.hexToNumber(await this.iconApiService.iconService.call(tx).execute());
  }

  /**
   * @description Get terms of Bond
   * @param bond - Bond for which the terms are requested
   * @return Terms - Terms configuration of the bond
   */
  public async getCustomTreasuryTerms(bond: BondConfig): Promise<Terms> {
    const tx = this.iconApiService.buildTransaction("",  bond.baseInfo.customBond,
      ScoreMethodNames.TERMS, {}, IconTransactionType.READ);

    return Mapper.mapCustomBondTerms(await this.iconApiService.iconService.call(tx).execute(), bond, bond.payoutToken.decimals);
  }

  /**
   * @description Get bond treasury balance of payout token
   * @param bond - Bond for which balance is requested
   * @return BigNumber - Payout token balance
   */
  public async getCustomTreasuryPayoutTokenBalance(bond: BondConfig): Promise<BigNumber> {
    if (bond.payoutToken.tag === "ICX") {
      return this.iconApiService.getIcxBalance(bond.baseInfo.customTreasury)
    }

    const method = bond.payoutToken.tag === "BALN" ? ScoreMethodNames.AVAILABLE_BALANCE_OF : ScoreMethodNames.BALANCE_OF;

    const tx = this.iconApiService.buildTransaction("", bond.payoutToken.address,
      method, { _owner: bond.baseInfo.customTreasury }, IconTransactionType.READ);

    return Utils.hexToNormalisedNumber((await this.iconApiService.iconService.call(tx).execute()), bond.payoutToken.decimals);
  }

  public async getCustomTreasuryPrincipalTokenBalance(bond: BondConfig): Promise<BigNumber> {
    if (bond.principalToken instanceof BalnLpToken) {
      const poolId = bond.principalToken.poolId;
      const decimals = this.persistenceService.getBalancedPoolDecimals(poolId)

      if (!decimals) {
        throw new Error(`[getCustomTreasuryPrincipalTokenBalance] Undefined decimals for poolId = ${poolId}`);
      }

      const params = {
        _owner: bond.baseInfo.customTreasury,
        _id: IconConverter.toHex(poolId)
      }

      const tx = this.iconApiService.buildTransaction("", this.appConfig.getBalancedDexAddress(),
        ScoreMethodNames.BALANCE_OF, params , IconTransactionType.READ);

      const res = await this.iconApiService.iconService.call(tx).execute();
      const balance = Utils.hexToNormalisedNumber(res, decimals.toNumber());

      log.debug(`Custom treasury (${bond.principalToken.tag}) with poolId ${poolId.toString()} LP balance = ${balance}`);

      return balance;
    } else {
      const method = bond.principalToken.tag === "BALN" ? ScoreMethodNames.AVAILABLE_BALANCE_OF : ScoreMethodNames.BALANCE_OF;

      const tx = this.iconApiService.buildTransaction("", bond.principalToken.address,
        method, {
          _owner: bond.baseInfo.customTreasury
        }, IconTransactionType.READ);

      const res = await this.iconApiService.iconService.call(tx).execute();
      return Utils.hexToNormalisedNumber(res, bond.principalToken.decimals);
    }
  }

  /**
   * @description Get custom treasury owner wallet address
   * @param bond - Bond config for which treasury balance is requested
   * @return string - owner address
   */
  public async getCustomTreasuryOwner(bond: BondConfig): Promise<string> {
    const tx = this.iconApiService.buildTransaction("", bond.baseInfo.customTreasury,
      ScoreMethodNames.CUSTOM_TREASURY_OWNER, { }, IconTransactionType.READ);

    return this.iconApiService.iconService.call(tx).execute();
  }

  /**
   * @description Get bond status from custom treasury (check if it is enabled)
   * @param bond - Bond for which balance is requested
   * @return boolean
   */
  public async getCustomTreasuryBondStatus(bond: BondConfig): Promise<boolean> {
    const tx = this.iconApiService.buildTransaction("", bond.baseInfo.customTreasury,
      ScoreMethodNames.BOND_CONTRACT_STATUS, { address: bond.baseInfo.customBond }, IconTransactionType.READ);

    return Utils.hexToBoolean(await this.iconApiService.iconService.call(tx).execute());
  }

  /**
   * @description Get users Bond data
   * @param depositor - Address of the bond depositor (user)
   * @param bond - Bond for which data is requested
   * @return BondData - Data specifying the bond state
   */
  public async getUsersBondData(depositor: string, bond: BondConfig): Promise<BondData> {
    const tx = this.iconApiService.buildTransaction("",  bond.baseInfo.customBond,
      ScoreMethodNames.BOND_INFO, {depositor}, IconTransactionType.READ);

    return Mapper.mapBond(await this.iconApiService.iconService.call(tx).execute());
  }

  /**
   * @description Get total payout given
   * @param bond - Bond for which the max payout is requested
   * @return Terms - Terms configuration of the bond
   */
  public async getTotalPayoutGiven(bond: BondConfig): Promise<BigNumber> {
    const tx = this.iconApiService.buildTransaction("",  bond.baseInfo.customBond,
      ScoreMethodNames.TOTAL_PAYOUT_GIVEN, {}, IconTransactionType.READ);

    return Utils.hexToNormalisedNumber(await this.iconApiService.iconService.call(tx).execute(), bond.payoutToken.decimals);
  }

  /**
   * @description Get max payout of Bond
   * @param bond - Bond for which the max payout is requested
   * @return Terms - Terms configuration of the bond
   */
  public async getBondMaxPayout(bond: BondConfig): Promise<BigNumber> {
    const tx = this.iconApiService.buildTransaction("",  bond.baseInfo.customBond,
      ScoreMethodNames.MAX_PAYOUT, {}, IconTransactionType.READ);

    return Utils.hexToNormalisedNumber(await this.iconApiService.iconService.call(tx).execute());
  }

  /**
   * @description Get true bond price (the price user pays including the Karma fee)
   * @param bond - Bond for which the true bond price is requested
   * @return BigNumber - True bond price amount
   */
  public async getTrueBondPrice(bond: BondConfig): Promise<BigNumber> {
    const tx = this.iconApiService.buildTransaction("",  bond.baseInfo.customBond,
      ScoreMethodNames.TRUE_BOND_PRICE, {}, IconTransactionType.READ);

    return Utils.hexToNormalisedNumber(await this.iconApiService.iconService.call(tx).execute(), BOND_PRICE_DECIMAL_PRECISION);
  }

  /**
   * @description Get bond price
   * @param bond - Bond for which the bond price is requested
   * @return BigNumber - Bond price amount
   */
  public async getBondPriceNotNormalised(bond: BondConfig): Promise<BigNumber> {
    const tx = this.iconApiService.buildTransaction("",  bond.baseInfo.customBond,
      ScoreMethodNames.BOND_PRICE, {}, IconTransactionType.READ);

    return Utils.hexToNumber(await this.iconApiService.iconService.call(tx).execute());
  }

  /**
   * @description Get current Karma fee
   * @param bond - Bond for which the current Karma fee is requested
   * @return BigNumber - Karma current fee
   */
  public async getCurrentKarmaFee(bond: BondConfig): Promise<BigNumber> {
    const tx = this.iconApiService.buildTransaction("",  bond.baseInfo.customBond,
      ScoreMethodNames.CURRENT_KARMA_FEE, {}, IconTransactionType.READ);

    const fee = await this.iconApiService.iconService.call(tx).execute();
    log.debug(`Bond ${bond.tag} Karma fee before: ${fee}`);

    return Utils.hexToNumber(fee);
  }

  /**
   * @description Get the payout amount user is receiving based on the principal input amount
   * @param principalInputAmount - Input amount of principal token
   * @param bond - Bond configuration object
   * @return BigNumber - Payout amount of payout token
   */
  public async getPayoutFor(principalInputAmount: number, bond: BondConfig): Promise<BigNumber> {
    if (bond.principalToken instanceof BalnLpToken) {
      const params = {
        value: IconConverter.toHex(principalInputAmount)
      };

      const tx = this.iconApiService.buildTransaction("",  bond.baseInfo.customBond,
          ScoreMethodNames.PAYOUT_FOR, params, IconTransactionType.READ);

      return Utils.hexToNormalisedNumber(await this.iconApiService.iconService.call(tx).execute(), bond.payoutToken.decimals);
    } else {
      const params = {
        value: IconConverter.toHex(IconAmount.of(principalInputAmount, bond.principalToken.decimals).toLoop())
      };

      const tx = this.iconApiService.buildTransaction("",  bond.baseInfo.customBond,
          ScoreMethodNames.PAYOUT_FOR, params, IconTransactionType.READ);

      return Utils.hexToNormalisedNumber(await this.iconApiService.iconService.call(tx).execute(), bond.payoutToken.decimals);
    }
  }

  /**
   * @description Get total debt for bond
   * @param bond - Bond configuration object
   * @return BigNumber - Total debt
   */
  public async getBondTotalDebt(bond: BondConfig): Promise<BigNumber> {
    const tx = this.iconApiService.buildTransaction("",  bond.baseInfo.customBond,
      ScoreMethodNames.TOTAL_DEBT, {}, IconTransactionType.READ);

    return Utils.hexToNormalisedNumber(await this.iconApiService.iconService.call(tx).execute(), bond.payoutToken.decimals);
  }

  /**
   * @description Get total principal token bonded (inflow token)
   * @param bond - Bond for which the total principal bonded amount is requested
   * @return BigNumber - Total principal bonded
   */
  public async getBondsTotalPrincipalBonded(bond: BondConfig): Promise<BigNumber> {
    const tx = this.iconApiService.buildTransaction("",  bond.baseInfo.customBond,
      ScoreMethodNames.TOTAL_PRINCIPAL_BONDED, {}, IconTransactionType.READ);

    return Utils.hexToNormalisedNumber(await this.iconApiService.iconService.call(tx).execute());
  }

  /**
   * @description Claim payout tokens for bond
   * @param bond - Bond for which user is claiming payout tokens
   * @return IconTransactionType.WRITE - Claim payout tokens Icon transaction
   */
  public buildClaimTx(bond: BondConfig): any {
    const params = { depositor: this.persistenceService.activeWallet?.address ?? "" };

    return this.iconApiService.buildTransaction(this.persistenceService.activeWallet?.address ?? "",
      bond.baseInfo.customBond, ScoreMethodNames.REDEEM, params, IconTransactionType.WRITE);
  }

  /**
   * @description Toggle Bond
   * @param bond - Bond being toggled
   * @return IconTransactionType.WRITE - Toggle Bond Icon transaction
   */
  public buildToggleBondTx(bond: BondConfig): any {
    const params = { bondContract: bond.baseInfo.customBond };

    return this.iconApiService.buildTransaction(this.persistenceService.activeWallet?.address ?? "",
      bond.baseInfo.customTreasury, ScoreMethodNames.TOGGLE_BOND, params, IconTransactionType.WRITE);
  }

  /**
   * @description Build withdraw payout token from treasury tx
   * @param bond - Bond for which treasury is used
   * @param amount - Amount to withdraw
   * @param destination - Address to which the funds will be sent
   * @return IconTransactionType.WRITE - Build withdraw payout token Icon transaction
   */
  public buildWithdrawIrc2PayoutFromTreasuryTx(bond: BondConfig, amount: BigNumber, destination: string): any {
    const params = {
      token: bond.payoutToken.address,
      destination,
      amount: IconConverter.toHex(IconAmount.of(amount, bond.payoutToken.decimals).toLoop())
    };

    return this.iconApiService.buildTransaction(this.persistenceService.activeWallet?.address ?? "",
      bond.baseInfo.customTreasury, ScoreMethodNames.WITHDRAW, params, IconTransactionType.WRITE);
  }

  public buildTransferPayoutToTreasuryTx(bond: BondConfig, amount: BigNumber): any {
    let params = {
      _to: bond.baseInfo.customTreasury,
      _value: IconConverter.toHex(IconAmount.of(amount, bond.payoutToken.decimals).toLoop()),
      _data: IconConverter.fromUtf8('{ "method": "funding" }')
    };

    return this.iconApiService.buildTransaction(this.persistenceService.activeWallet?.address ?? "",
      bond.baseInfo.payoutToken, ScoreMethodNames.TRANSFER, params, IconTransactionType.WRITE);
  }

  /**
   * @description Build withdraw principal token from treasury tx
   * @param bond - Bond for which treasury is used
   * @param amount - Amount to withdraw
   * @param destination - Address to which the funds will be sent
   * @return IconTransactionType.WRITE - Build withdraw principal token Icon transaction
   */
  public buildWithdrawPrincipalFromTreasuryTx(bond: BondConfig, amount: BigNumber, destination: string): any {
    if (bond.principalToken instanceof BalnLpToken) {
      const poolId = bond.principalToken.poolId;
      const decimals = this.persistenceService.getBalancedPoolDecimals(poolId)

      const params = {
        token: this.appConfig.getBalancedDexAddress(),
        destination,
        amount: IconConverter.toHex(IconAmount.of(amount, decimals).toLoop())
      };

      return this.iconApiService.buildTransaction(this.persistenceService.activeWallet?.address ?? "",
        bond.baseInfo.customTreasury, ScoreMethodNames.WITHDRAW_LP, params, IconTransactionType.WRITE);
    } else {
      const params = {
        token: bond.principalToken.address,
        destination,
        amount: IconConverter.toHex(IconAmount.of(amount, bond.principalToken.decimals).toLoop())
      };

      return this.iconApiService.buildTransaction(this.persistenceService.activeWallet?.address ?? "",
        bond.baseInfo.customTreasury, ScoreMethodNames.WITHDRAW, params, IconTransactionType.WRITE);
    }
  }

  /**
   * @description Get Bond data
   * @param amount - Amount of principal inflow token received. This field is handled by tokenFallback
   * @param maxPrice - Max price for slippage protection. maxPrice value needs to be superior or equal to
   *                    the true bond price, otherwise the transaction will fail.
   * @param bond - Bond for which the deposit tx is being constructed for
   * @return  IconTransaction - Constructed Icon transaction
   */
  public buildDepositTx(amount: BigNumber, maxPrice: BigNumber, bond: BondConfig): any {
    let decimals: number | BigNumber | undefined;
    if (bond.principalToken instanceof Irc2Token) {
      decimals = bond.principalToken.decimals;
    } else {
      log.debug("Principal instanceOf BalnLpToken")
      decimals = this.persistenceService.getBalancedPoolDecimals(bond.principalToken.poolId);
    }


    if (!decimals) {
      throw new Error(`[buildDepositTx] Undefined decimals for bond = ${bond.tag}`);
    }

    decimals = new BigNumber(decimals.toString());
    const _maxPrice = IconConverter.toHex(IconAmount.of(maxPrice, BOND_PRICE_DECIMAL_PRECISION).toLoop());
    const address = this.persistenceService.activeWallet?.address;

    const dataPayload = `{ "method": "deposit", "params": { "maxPrice": "${_maxPrice}", "depositor": "${address}" }}`
    log.debug("[buildDepositTx] data payload", dataPayload);

    log.debug("dataPayload:", dataPayload);

    let params = {
      _to: bond.baseInfo.customBond,
      ...(bond.principalToken instanceof BalnLpToken && { _id:  IconConverter.toHex(bond.principalToken.poolId)}),
      _value: IconConverter.toHex(IconAmount.of(amount, decimals).toLoop()),
      _data: IconConverter.fromUtf8(dataPayload)
    };

    return this.iconApiService.buildTransaction(this.persistenceService.activeWallet?.address ?? "",
      bond.baseInfo.principalToken, ScoreMethodNames.TRANSFER, params, IconTransactionType.WRITE);
  }

  /**
   * @description Get stats for specific pool
   * @param poolId - Balanced DEX pool id
   * @return  PoolStats
   */
  public async getPoolStats(poolId: number): Promise<PoolStats> {
    const params = {
      _id: IconConverter.toHex(poolId)
    };

    const tx = this.iconApiService.buildTransaction(this.persistenceService.activeWallet?.address ?? "",  this.appConfig.getBalancedDexAddress(),
      ScoreMethodNames.GET_POOL_STATS, params, IconTransactionType.READ);

    const res: PoolStatsInterface = await this.iconApiService.iconService.call(tx).execute();

    return Mapper.mapPoolStats(res);
  }

  /**
   * @description Get USD price for IRC2 token from stable coin Balanced DEX pair
   * @param token - IRC2 token
   * @return BigNumber - base token price denominated in quote token which should be stable coin = 1 USD worth
   */
  public async getIrc2TokenBalnDexPrice(token: Irc2Token): Promise<BigNumber> {
    const balnDexOracleInfo = this.appConfig.getIrc2TokenBalnDexOracleInfo(token);

    const params = {
      _id: IconConverter.toHex(balnDexOracleInfo.poolInfo.poolId)
    };

    const tx = this.iconApiService.buildTransaction(this.persistenceService.activeWallet?.address ?? "",  this.appConfig.getBalancedDexAddress(),
      ScoreMethodNames.GET_POOL_STATS, params, IconTransactionType.READ);

    const res: PoolStatsInterface = await this.iconApiService.iconService.call(tx).execute();

    const poolStats = Mapper.mapPoolStats(res);

    return poolStats.price;
  }

  /**
   * @description Get users LP balance from Balanced DEX
   * @param poolId - Id of the pool
   * @return BigNumber - Normalised LP balance amount
   */
  public async getUserLpBalance(poolId: number): Promise<BigNumber> {
    const decimals = this.persistenceService.getBalancedPoolDecimals(poolId)

    if (!decimals) {
      throw new Error(`[getLpBalance] Undefined decimals for poolId = ${poolId}`);
    }

    const params = {
      _owner: this.persistenceService.activeWallet!.address,
      _id: IconConverter.toHex(poolId)
    }

    const tx = this.iconApiService.buildTransaction(this.persistenceService.activeWallet?.address ?? "", this.appConfig.getBalancedDexAddress(),
      ScoreMethodNames.BALANCE_OF, params , IconTransactionType.READ);

    const res = await this.iconApiService.iconService.call(tx).execute();
    const balance = Utils.hexToNormalisedNumber(res, decimals.toNumber());

    log.debug(`User (${this.persistenceService.activeWallet!.address}) ${poolId.toString()} LP balance = ${balance}`);

    return balance;
  }

  /**
   * @description Get ICX balanced of the logged in user
   */
  public async getIcxBalance(): Promise<BigNumber> {
    return this.iconApiService.getIcxBalance(this.persistenceService.activeWallet?.address ?? "");
  }

  private async getIRC2TokenBalance(token: Irc2Token): Promise<BigNumber> {
    const method = token.tag === "BALN" ? ScoreMethodNames.AVAILABLE_BALANCE_OF : ScoreMethodNames.BALANCE_OF;

    const tx = this.iconApiService.buildTransaction(this.persistenceService.activeWallet?.address ?? "", token.address,
      method, {
        _owner: this.persistenceService.activeWallet!.address
      }, IconTransactionType.READ);

    const res = await this.iconApiService.iconService.call(tx).execute();
    return Utils.hexToNormalisedNumber(res, token.decimals);
  }

  /**
   * @description Get users asset (IRC-2 token) balance
   * @param token - Token tag associated with the IRC-2 token
   * @return BigNumber - Normalised token balance amount
   */
  public async getUserIrc2TokenBalance(token: Irc2Token): Promise<BigNumber> {
    let balance: BigNumber;
    if ("ICX" === token.tag) {
      balance = await this.iconApiService.getIcxBalance(this.persistenceService.activeWallet!.address ?? "");
    } else {
      balance = await this.getIRC2TokenBalance(token);
    }

    return balance;
  }

  /**
   * @description Get price of base token in quote token
   * @param bondConfig - bond configuration
   * @return BigNumber - Normalised price in USD
   */
  public async getPoolsBaseAssetPriceInQuotedAsset(bondConfig: BondConfig): Promise<BigNumber> {
    const quoteIrc2Token = bondConfig.principalToken instanceof  BalnLpToken ? bondConfig.principalToken.quoteToken : undefined;
    const baseIrc2Token = bondConfig.principalToken instanceof  BalnLpToken ? bondConfig.principalToken.baseToken : undefined;

    if (!quoteIrc2Token || !baseIrc2Token) {
      throw Error(`[getAssetsBalancedPrice] quoteIrc2Token or baseIrc2Token undefined for bond tag ${bondConfig.tag}`);
    }

    const params = {
      _id: IconConverter.toHex(bondConfig.principalToken instanceof  BalnLpToken ? bondConfig.principalToken.poolId : -1)
    }

    const tx = this.iconApiService.buildTransaction(this.persistenceService.activeWallet?.address ?? "", this.appConfig.getBalancedDexAddress(),
      ScoreMethodNames.GET_PRICE, params , IconTransactionType.READ);

    const res = await this.iconApiService.iconService.call(tx).execute();
    const price = Utils.hexToNormalisedNumber(res, quoteIrc2Token.decimals);

    log.debug(`Asset (${baseIrc2Token}) price = ${price} ${quoteIrc2Token}`);

    return price;
  }

  /**
   * @description Get assets price from Omm Price Oracle
   * @param token - Asset for which we are fetching the price for
   * @return BigNumber - Normalised price in USD
   */
  public async getIrc2TokenUsdPriceFromOmmPriceOracle(token: Irc2Token): Promise<BigNumber> {
    // handle sICX
    let price;
    if ("sICX" === token.tag) {
      const icxPriceTx = this.iconApiService.buildTransaction("", this.appConfig.getOmmPriceOracleAddress(),
        ScoreMethodNames.GET_REFERENCE_DATA, { _base: "ICX", _quote: "USD"} , IconTransactionType.READ);
      const icxPrice = Utils.hexToNormalisedNumber(await this.iconApiService.iconService.call(icxPriceTx).execute(), 18);

      const sicxConvTx = this.iconApiService.buildTransaction("", token.address, ScoreMethodNames.PRICE_IN_LOOP, {} ,
        IconTransactionType.READ);
      const sicxToIcx = Utils.hexToNormalisedNumber(await this.iconApiService.iconService.call(sicxConvTx).execute(), 18);

       price =icxPrice.multipliedBy(sicxToIcx);
    } else {
      const tx = this.iconApiService.buildTransaction("", this.appConfig.getOmmPriceOracleAddress(),
        ScoreMethodNames.GET_REFERENCE_DATA, { _base: token.tag, _quote: "USD"} , IconTransactionType.READ);

      const res = await this.iconApiService.iconService.call(tx).execute();

      price = Utils.hexToNormalisedNumber(res, token.decimals);
    }

    log.debug(`Asset (${token.tag}) price = ${price} USD`);

    return price;
  }

  /**
   * @description Get USD price of token from Karma Oracle
   * @param token - IRC2 token
   */
  public async getKarmaOracleTokenUsdPrice(token: Irc2Token): Promise<BigNumber> {
    const params = {
      base: token.address
    };

    const tx = this.iconApiService.buildTransaction("",  this.appConfig.scoreAddresses.karmaOracle,
      ScoreMethodNames.GET_USD_PRICE, params, IconTransactionType.READ);

    return Utils.hexToNormalisedNumber(await this.iconApiService.iconService.call(tx).execute());
  }

}
