/* ### VALIDATION FOR SENDING BRIDGE TOKENS ### */

import {amountContainsMaxOneDotFollowedBy2decimals, isEoaAddress} from "./validation";

/**
 * @description Validate send Bridge tokens input fields
 * @param {string} transferAmount - Amount of the Bridge tokens to transfer
 * @param {string} transferToAddress - Icon wallet address of the recipient
 * @param {number} userBalance - Users current Bridge stable coin balance
 * @param {string} iconWalletAddress - Icon wallet address of the current user
 * @return {string[]} errors - List of error messages that occurred during the validation
 */
export function validateSendBridgeTokens(transferAmount: number, transferToAddress: string,
                                         userBalance: number | undefined, iconWalletAddress: string): string[] {
    let errors: string[] = [];

    if (iconWalletAddress == transferToAddress) {
        errors.push("To address must not equal sender address!")
    }

    if (!transferToAddress) errors.push("To address missing!");
    if (!transferAmount) errors.push("Amount to transfer missing!");

    if (transferToAddress) {
        if (!transferToAddress.startsWith("hx")) {
            errors.push("Invalid address! Must start with hx..");
        }
        else if (!isEoaAddress(transferToAddress)) {
            errors.push("Invalid address! Must start with hx.. and have 42 characters.");
        }
    }

    if (transferAmount) {
        if (!amountContainsMaxOneDotFollowedBy2decimals(transferAmount))
            errors.push("Invalid amount, use dot for decimals and max 2 decimal places (e.g. 1.05)");

        if (transferAmount <= 0) {
            errors.push("Invalid amount! Must be greater than zero. (e.g. 1)");
        }
        if (userBalance == undefined) {
            errors.push("User balance undefined.")
        }
        else if(userBalance < transferAmount) {
            errors.push("You have insufficient funds.")
        }
    }
    return errors;
}
