import { Injectable } from '@angular/core';
import BigNumber from "bignumber.js";
import {PersistenceService} from "./persistence.service";
import {ScoreApiService} from "./score-api.service";
import {AppConfigService} from "./app-config.service";
import {Irc2TokenTag} from '../models/types/Types';
import {Irc2Token} from '../models/classes/Irc2Token';

@Injectable({
  providedIn: 'root'
})
export class PriceOracleService {

  private assetToUsdPriceMap = new Map<Irc2TokenTag, BigNumber>();

  constructor(private persistenceService: PersistenceService,
              private scoreApiService: ScoreApiService,
              private appConfigService: AppConfigService) {
    // initialise map with stable coins price (1 USD)
    this.initStableCoinsPrices();
  }

  getIrc2TokenPrice(token: Irc2Token): BigNumber {
    if (token.isStableCoin) {
      return new BigNumber(1);
    }

    return this.assetToUsdPriceMap.get(token.tag) ?? new BigNumber(-1);
  }

  setAssetPrice(assetTag: Irc2TokenTag, price: BigNumber): void {
    this.assetToUsdPriceMap.set(assetTag, price);
  }

  initStableCoinsPrices(): void {
    this.appConfigService.getStableCoinIrc2Tokens().forEach(token => this.assetToUsdPriceMap.set(token, new BigNumber(1)));
  }

}
