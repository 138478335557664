<!-- BOND MODAL -->
<div class="modal modal-bond" [ngStyle]="displayAsBlockOrHide(displayModal())">
    <div class="sidebar-container">
        <div class="sidebar-heading">
            <div class="sidebar-heading-pair">
                <h2 class="text-white sidebar-heading-text">{{principalTokenName}} {{principalIsLp ? "LP Bond": "Bond"}}</h2>
            </div>
            <a data-w-id="d67296ce-56e5-75dc-36b8-e731b8206c68" href="javascript:void(0);" (click)="hideActiveModal()"
               class="sidebar-close w-inline-block"><img src="assets/images/close-arrow.svg" loading="lazy" alt=""></a>
        </div>
        <div class="sidebar-content-wrapper">
            <div class="sidebar-bond-price">
                <div class="sidebar-price-text">${{bondPriceUSD.toFixed(5) | usFormat | toZeroIfDash}}</div>
                <div class="sidebar-price-subtext">Discounted Price</div>
            </div>
            <div class="divider"></div>
            <div class="sidebar-bond-price">
                <div class="sidebar-price-text">${{marketPayoutTokenPrice.toFixed(5) | usFormat | toZeroIfDash}}</div>
                <div class="sidebar-price-subtext">Market price</div>
            </div>
        </div>
        <div class="sidebar-content-wrapper">
            <div class="sidebar-pair">
                <img src="assets/images/{{principalTokenImg}}" loading="lazy" height="32" alt="" class="sidebar-pair-image">
                <div class="sidebar-pair-name">{{principalTokenName}}</div>
                <div class="sidebar-pair-subtitle">You Give</div>
            </div>
            <div class="sidebar-pair"><img src="assets/images/vesting-time.svg" loading="lazy" height="32" alt="" class="sidebar-pair-image">
                <div class="sidebar-pair-name">{{secondsToHumanizedDateString(bondVestingTerm)}}</div>
                <div class="sidebar-pair-subtitle">Bonded</div>
            </div>
            <div class="sidebar-pair"><img src="assets/images/{{payoutTokenImg}}" loading="lazy" height="32" alt="" class="sidebar-pair-image">
                <div class="sidebar-pair-name {{bondRoi.isNegative() ? 'negative-discount': ''}}">{{bondRoi | toFixed2DecPercent: true}}</div>
                <div class="sidebar-pair-subtitle">You Get</div>
            </div>
        </div>
        <div class="sidebar-content-cta" [ngStyle]="hideElement(!persistenceService.userIsLoggedIn() || showGetLp)">
            <div class="form-block w-form">
                <form id="bond-form" name="bond-form" data-name="Bond form" class="form" (submit)="handleBondSubmit($event)">
                    <div class="bond-input-wrap">
                        <input type="text" class="text-field w-input" maxlength="256" #bondInput (keyup)="onBondInputLostFocus($event)"
                               (paste)="onBondInputLostFocus($event)" name="name" data-name="Name" placeholder="Amount" id="name">
                        <a href="javascript:void(0);" class="max-link" (click)="onMaxBondInputClick()">Max</a>
                    </div>
                    <input type="submit" value="Bond" data-wait="Please wait..." class="button button-bond w-button {{!bondCheckboxConfirmed ? 'disabled':''}}" [disabled]="!bondCheckboxConfirmed">
                </form>
                <div class="bond-checkbox">
                    <input #bondCheckbox type="checkbox" id="confirm-bond-amount" [checked]="bondCheckboxConfirmed" (change)="handleBondCheckbox()"/>
                    <label for="confirm-bond-amount">
                        <div class="custom-checkbox"></div>
                        Check the box to confirm the bonded amount.
                    </label>
                </div>
                <div class="w-form-done">
                    <div>Thank you! Your submission has been received!</div>
                </div>
                <div class="w-form-fail">
                    <div>Oops! Something went wrong while submitting the form.</div>
                </div>
            </div>
        </div>
        <div class="sidebar-content-cta" [ngStyle]="hideElement(persistenceService.userIsLoggedIn())">
            <a href="javascript:void(0);" (click)="showSignInModal()" class="button-modal button button-gradient button-block">Connect Wallet</a>
        </div>
        <div class="sidebar-content-cta" [ngStyle]="hideElement(!persistenceService.userIsLoggedIn() || !showGetLp)">
            <a href="{{balancedDexUrl()}}" target="_blank" class="button-modal button button-block button-get-lp">Get LP Token</a>
        </div>
        <div class="sidebar-content-wrapper sidebar-table">
            <div class="sidebar-table-row">
                <div class="sidebar-table-column">
                    <div class="sidebar-table-text-left roi">
                        Your balance
                        <img src="assets/images/tooltip-icon.svg" class="tooltip-icon">
                        <span class="roi-tooltip">
              <div class="roi-tooltip-close" onclick="document.getElementByClassName('roi-tooltip').style.display = 'none';">&times;</div>
              Displays the available{{principalIsLp ? " LP" : ""}} token balance in your connected wallet.
            </span>
                    </div>
                </div>
                <div class="sidebar-table-column">
                    <div class="sidebar-table-text-right">
                        {{userPrincipalTokenBalance | roundDown2Dec | usFormat | toZeroIfDash}} {{principalIsLp ? principalTokenName + " LP" : principalTokenName}}
                    </div>
                </div>
            </div>
            <div class="sidebar-table-row">
                <div class="sidebar-table-column">
                    <div class="sidebar-table-text-left roi">
                        You will get
                        <img src="assets/images/tooltip-icon.svg" class="tooltip-icon">
                        <span class="roi-tooltip">
              <div class="roi-tooltip-close" onclick="document.getElementByClassName('roi-tooltip').style.display = 'none';">&times;</div>
              The total amount of payout asset you will receive from this bond purchase.
            </span>
                    </div>
                </div>
                <div class="sidebar-table-column">
                    <div class="sidebar-table-text-right">{{payoutAmount | roundDown2Dec | usFormat | toZeroIfDash}} {{payoutTokenName}}</div>
                </div>
            </div>
            <div class="sidebar-table-row">
                <div class="sidebar-table-column">
                    <div class="sidebar-table-text-left roi">
                        Max
                        <img src="assets/images/tooltip-icon.svg" class="tooltip-icon">
                        <span class="roi-tooltip">
              <div class="roi-tooltip-close" onclick="document.getElementByClassName('roi-tooltip').style.display = 'none';">&times;</div>
              The maximum amount of pay-out tokens the protocol is able to currently offer per bond.
            </span>
                    </div>
                </div>
                <div class="sidebar-table-column">
                    <div class="sidebar-table-text-right">{{bondMaxPayout | roundDown2Dec | usFormat}} {{payoutTokenName}}</div>
                </div>
            </div>
            <div class="sidebar-table-row">
                <div class="sidebar-table-column">
                    <div class="sidebar-table-text-left roi">
                        DISCOUNT
                        <img src="assets/images/tooltip-icon.svg" class="tooltip-icon">
                        <span class="roi-tooltip">
              <div class="roi-tooltip-close" onclick="document.getElementByClassName('roi-tooltip').style.display = 'none';">&times;</div>
              A discount or a premium is the price a user will pay for the pay-out asset compared to
              the current market price. If the value is positive, the user pays for the asset at a market
              discount. If the value is negative, the user pays for the asset at a market premium.
              Do not bond when the pay-out token is at a premium.
            </span>
                    </div>
                </div>
                <div class="sidebar-table-column">
                    <div class="sidebar-table-text-right {{bondRoi.isNegative() ? 'negative-discount': ''}}">{{bondRoi | toFixed2DecPercent:true}}</div>
                </div>
            </div>
            <div class="sidebar-table-row">
                <div class="sidebar-table-column">
                    <div class="sidebar-table-text-left roi">
                        Vesting term
                        <img src="assets/images/tooltip-icon.svg" class="tooltip-icon">
                        <span class="roi-tooltip">
              <div class="roi-tooltip-close" onclick="document.getElementByClassName('roi-tooltip').style.display = 'none';">&times;</div>
              The duration of the bond whereby the bond
              can be claimed in its entirety. With linear
              vesting, you may decide to claim a portion of
              the payout before this time. However, claiming
              multiple times will result in more total gas fees.
            </span>
                    </div>
                </div>
                <div class="sidebar-table-column">
                    <div class="sidebar-table-text-right">{{secondsToHumanizedDateString(bondVestingTerm)}}</div>
                </div>
            </div>
            <div class="sidebar-table-row">
                <div class="sidebar-table-column">
                    <div class="sidebar-table-text-left roi">
                        bKARMA
                        <img src="assets/images/tooltip-icon.svg" class="tooltip-icon">
                        <span class="roi-tooltip">
              <div class="roi-tooltip-close" onclick="document.getElementByClassName('roi-tooltip').style.display = 'none';">&times;</div>
              The amount of bKARMA you will receive. For each 1 USD equivalent of fees collected by the Karma Bond protocol, you will be rewarded with 1 bKARMA.
            </span>
                    </div>
                </div>
                <div class="sidebar-table-column">
                    <div class="sidebar-table-text-right">{{bKarmaPayoutAmount.dp(0) | usFormat | toZeroIfDash}}</div>
                </div>
            </div>
            <div class="sidebar-table-row">
                <div class="sidebar-table-column">
                    <div class="sidebar-table-text-left">Bond contract</div>
                </div>
                <div class="sidebar-table-column">
                    <a href="{{customBondLink}}" target="_blank" class="sidebar-table-text-right">View</a>
                </div>
            </div>
        </div>
    </div>
</div>
