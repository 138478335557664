import {Injectable} from '@angular/core';
import {ScoreApiService} from './score-api.service';
import {PersistenceService} from './persistence.service';
import {AppConfigService} from './app-config.service';
import {StateChangeService} from './state-change.service';
import log from 'loglevel';
import BigNumber from 'bignumber.js';
import {WalletType} from '../models/classes/Wallet';
import {PriceOracleService} from './price-oracle.service';
import {BondTag, Irc2TokenTag, PoolId} from '../models/types/Types';
import {BondInfo} from '../models/classes/BondInfo';
import {OracleType} from '../models/enums/OracleType';
import {CoingeckoService} from './coingecko.service';
import {Irc2Token} from '../models/classes/Irc2Token';

@Injectable({
  providedIn: 'root'
})
export class DataLoaderService {

  constructor(private scoreApiService: ScoreApiService,
              private persistenceService: PersistenceService,
              private appConfigService: AppConfigService,
              private stateChangeService: StateChangeService,
              private priceOracleService: PriceOracleService,
              private coingeckoService: CoingeckoService) { }


  public async loadUserActiveBonds(): Promise<void> {
      // load bond infos for active and past bonds
      try {
        // reset user active bonds
        this.persistenceService.userBondTagToBondInfoMap = new Map<BondTag, BondInfo>();

        await Promise.all(this.appConfigService.getSupportedAndPastBonds().map( async (bond) => {
          const bondInfo = await this.scoreApiService.bondInfo(bond);
          this.stateChangeService.userBondInfoUpdate(bond, bondInfo);
          log.debug(`Bond ${bond.tag} user info:`, bondInfo);
        }));
      } catch (e) {
        log.error("Error in loading of bond infos:");
        log.error(e);
      }

    // load pending payout and percent vested for active and past bonds
      try {
        await Promise.all(this.appConfigService.getSupportedAndPastBonds().map( async (bond) => {

          try {
            const [pendingPayoutFor, percentVestedFor] = await Promise.all([
              await this.scoreApiService.pendingPayoutFor(bond),
              await this.scoreApiService.percentVestedFor(bond)
            ]);

            this.stateChangeService.userPendingPayoutForUpdate(bond, pendingPayoutFor);
            this.stateChangeService.userPercentVestedForUpdate(bond, percentVestedFor);

            log.debug(`Bond ${bond.tag} user pendingPayoutFor:`, pendingPayoutFor.toString());
            log.debug(`Bond ${bond.tag} user percentVestedFor:`, percentVestedFor.toString());
          } catch (e) {
            log.debug(`Unable to load users active bond ${bond.tag}. Probably user does not have that active bond.`);
          }
        }));
      } catch (e) {
        log.debug("Unable to load user pendingPayoutFor and percentVestedFor. Probably user has no active bonds.");
      }
  }

  public async loadAllUserIrc2TokenBalances(): Promise<void> {
    try {
      log.debug("****** User IRC2 tokens balances ******");

      const newIrc2TokenBalances = new Map<Irc2TokenTag, BigNumber>();

      await Promise.all(this.appConfigService.getSupportedIrc2Tokens().map( async (token) => {
        const balance = await this.scoreApiService.getUserIrc2TokenBalance(token);
        newIrc2TokenBalances.set(token.tag, balance);
        log.debug(`Users IRC2 token = ${token.tag} balance: ${balance}`);
      }));

      // commit the user IRC2 token balances change
      this.stateChangeService.updateUserAssetBalances(newIrc2TokenBalances);
    } catch (e) {
      log.error("Failed to load all users IRC2 token balances..");
      log.error(e);
    }
  }

  public async loadAllUserBalnLpTokenBalances(): Promise<void> {
    log.debug("****** User LP tokens balances ******");
    try {
      const newUserBalnLpTokenBalances = new Map<PoolId, BigNumber>();

      await Promise.all(this.appConfigService.getSupportedBalnLpTokensI().map( async (lpToken) => {
        const balance = await this.scoreApiService.getUserLpBalance(lpToken.poolId);
        newUserBalnLpTokenBalances.set(lpToken.poolId, balance);
        log.debug(`Users Balanced DEX poolId = ${lpToken.poolId} balance: ${balance}`);
      }));

      // commit Balanced LP tokens balances change
      this.stateChangeService.updateUserBalnLpBalances(newUserBalnLpTokenBalances);
    } catch (e) {
      log.error("Failed to load all users Balanced LP token balances..");
      log.error(e);
    }
  }

  public async loadAllBondsTerms(): Promise<void> {
    try {
      await Promise.all(this.appConfigService.getSupportedBonds().map( async (bond) => {
        const terms = await this.scoreApiService.getCustomTreasuryTerms(bond);
        this.stateChangeService.bondTermsUpdate(bond, terms);
        log.debug(`Bond ${bond.tag} terms:`, terms);
      }));
    } catch (e) {
      log.error("Failed to load all bonds terms..");
      log.error(e);
    }
  }

  public async loadAllBondTotalDebt(): Promise<void> {
    try {
      await Promise.all(this.appConfigService.getSupportedBonds().map( async (bond) => {
        const totalBondDebt = await this.scoreApiService.getBondTotalDebt(bond);
        this.stateChangeService.karmaBondTotalDebtUpdate(bond, totalBondDebt);
        log.debug(`Bond ${bond.tag} totalBondDebt:`, totalBondDebt.toString());
      }));
    } catch (e) {
      log.error("Failed to load all bonds total debt..");
      log.error(e);
    }
  }

  public async loadKarmaBondFee(): Promise<void> {
    try {
      await Promise.all(this.appConfigService.getSupportedBonds().map( async (bond) => {
        const karmaFee = await this.scoreApiService.getCurrentKarmaFee(bond);
        this.stateChangeService.karmaBondFeeUpdate(bond, karmaFee);
        log.debug(`Bond ${bond.tag} karmaFee:`, karmaFee.toString());
      }));
    } catch (e) {
      log.error("Failed to load all bonds fees..");
      log.error(e);
    }
  }

  public async loadAllBondsTreasuryPayoutBalances(): Promise<void> {
    try {
      await Promise.all(this.appConfigService.getSupportedAndPastBonds().map( async (bond) => {
        const balance = await this.scoreApiService.getCustomTreasuryPayoutTokenBalance(bond);
        this.stateChangeService.bondTreasuryPayoutBalanceUpdate(bond.tag, balance);

        log.debug(`Bond (${bond.tag}) treasury ${bond.payoutToken.tag} balance = ${balance.toString()}`);
      }));
    } catch (e) {
      log.error("Failed to load all bonds treasury payout balances..");
      log.error(e);
    }
  }

  public async loadAllBondsTreasuryPrincipalBalances(): Promise<void> {
    try {
      await Promise.all(this.appConfigService.getSupportedAndPastBonds().map( async (bond) => {
        const balance = await this.scoreApiService.getCustomTreasuryPrincipalTokenBalance(bond);
        this.stateChangeService.bondTreasuryPrincipalBalanceUpdate(bond.tag, balance);

        log.debug(`Bond (${bond.tag}) treasury ${bond.principalToken.tag} balance = ${balance.toString()}`);
      }));
    } catch (e) {
      log.error("Failed to load all bonds treasury principal balances..");
      log.error(e);
    }
  }

  public async loadAllBondsTreasuryStatuses(): Promise<void> {
    try {
      await Promise.all(this.appConfigService.getSupportedAndPastBonds().map( async (bond) => {
        const status = await this.scoreApiService.getCustomTreasuryBondStatus(bond);
        this.stateChangeService.bondStatusUpdate(bond.tag, status);

        log.debug(`Bond (${bond.tag}) status = ${status.toString()}`);
      }));
    } catch (e) {
      log.error("Failed to load all bonds statuses..");
      log.error(e);
    }
  }

  public async loadAllCustomTreasuryOwners(): Promise<void> {
    try {
      await Promise.all(this.appConfigService.getSupportedAndPastBonds().map( async (bond) => {
        const owner = await this.scoreApiService.getCustomTreasuryOwner(bond);
        this.persistenceService.treasuryOwnerMap.set(bond.tag, owner);
        log.debug(`Treasury (${bond.tag}) owner = ${owner}`);
      }));

      this.stateChangeService.treasuryOwnersFinishedLoadingUpdate();
    } catch (e) {
      log.error("Failed to load all treasury owners..");
      log.error(e);
    }
  }

  public async loadBalancedPoolStats(): Promise<void> {
    log.debug("loadBalancedPoolStats...");
    try {
      await Promise.all(this.appConfigService.getSupportedBalnLpTokensI().map( async (token) => {
        const poolStats = await this.scoreApiService.getPoolStats(token.poolId);
        this.stateChangeService.poolStatsUpdate(token.poolId, poolStats);
        log.debug(`Pool ${token.poolId} pool stats:`, poolStats);
      }));
    } catch (e) {
      log.error("Failed to load all pools stats..");
      log.error(e);
    }
  }

  public async loadBondsMaxPayouts(): Promise<void> {
    try {
      await Promise.all(this.appConfigService.getSupportedBonds().map( async (bond) => {
        const maxPayout = await this.scoreApiService.getBondMaxPayout(bond);
        this.stateChangeService.bondMaxPayoutUpdate(bond, maxPayout);
        log.debug(`Bond ${bond.tag} maxPayout:`, maxPayout.toString());
      }));
    } catch (e) {
      log.error("Failed to load all bonds max payouts..");
      log.error(e);
    }
  }

  public async loadTrueBondPrices(): Promise<void> {
    try {
      await Promise.all(this.appConfigService.getSupportedBonds().map( async (bond) => {
        const trueBondPrice = await this.scoreApiService.getTrueBondPrice(bond);
        this.stateChangeService.trueBondPriceUpdate(bond, trueBondPrice);
        log.debug(`Bond ${bond.tag} trueBondPrice:`, trueBondPrice.toString());
      }));
    } catch (e) {
      log.error("Failed to load all bonds true prices..");
      log.error(e);
    }
  }

  public async loadBondPrices(): Promise<void> {
    try {
      await Promise.all(this.appConfigService.getSupportedBonds().map( async (bond) => {
        const bondPrice = await this.scoreApiService.getBondPriceNotNormalised(bond);
        this.stateChangeService.bondPriceUpdate(bond, bondPrice);
        log.debug(`Bond ${bond.tag} bondPrice:`, bondPrice.toString());
      }));
    } catch (e) {
      log.error("Failed to load all bonds prices..");
      log.error(e);
    }
  }

  // public async loadBondsTotalPrincipalBonded(): Promise<void> {
  //   try {
  //     await Promise.all(this.appConfigService.getSupportedBonds().map( async (bond) => {
  //       const totalPrincipalBonded = await this.scoreApiService.getBondsTotalPrincipalBonded(bond);
  //       this.stateChangeService.bondsTotalPrincipalBondedUpdate(bond, totalPrincipalBonded);
  //       log.debug(`Bond ${bond.tag} totalPrincipalBonded:`, totalPrincipalBonded.toString());
  //     }));
  //   } catch (e) {
  //     log.error("Failed to load all bonds total principal bonded..");
  //     log.error(e);
  //   }
  // }

  public async loadTotalPayoutGiven(): Promise<void> {
    try {
      await Promise.all(this.appConfigService.getSupportedBonds().map( async (bond) => {
        const totalPayoutGiven = await this.scoreApiService.getTotalPayoutGiven(bond);
        this.stateChangeService.bondsTotalPayoutGivenUpdate(bond, totalPayoutGiven);
        log.debug(`Bond ${bond.tag} totalPayoutGiven:`, totalPayoutGiven.toString());
      }));
    } catch (e) {
      log.error("Failed to load all bonds total payout given..");
      log.error(e);
    }
  }

  async loadSupportedTokenPrices(): Promise<void> {
    try {
      await Promise.all(Array.from(this.appConfigService.supportedIrc2TokenTagToIrc2TokenMap.values()).map( async (token) => {
        // fetch price for the payout asset that is not yet initialized
        await this.initIrc2Price(token);
      }));
    } catch (e) {
      log.error(`Failed to load supported tokens prices`);
      log.error(e);
    }
  }

  async initIrc2Price(token: Irc2Token): Promise<void> {
    log.debug(`initIrc2Price for:`, token);

    // fetch only if price is not yet loaded
    const currentPrice = this.priceOracleService.getIrc2TokenPrice(token);
    log.debug(`currentPrice = ${currentPrice}`);

    let price
    switch (token.oracle) {
      case OracleType.BALN_DEX:
        price = await this.scoreApiService.getIrc2TokenBalnDexPrice(token);
        this.priceOracleService.setAssetPrice(token.tag, price);
        this.stateChangeService.updateAssetsPrice(token.tag, price);
        break;
      case OracleType.PRICE_ORACLE:
        price = await this.scoreApiService.getIrc2TokenUsdPriceFromOmmPriceOracle(token);
        this.priceOracleService.setAssetPrice(token.tag, price);
        this.stateChangeService.updateAssetsPrice(token.tag, price);
        break;
      case OracleType.COINGECKO:
        const config = this.appConfigService.scoreAddresses.coingeckoEndpoints.find(value => value.irc2TokenTag === token.tag)
        if (config) {
          this.coingeckoService.getIrc2TokenUsdPrice(config).subscribe(res => {
            price = new BigNumber(res[config.key]["usd"]);
            this.priceOracleService.setAssetPrice(token.tag, price);
            this.stateChangeService.updateAssetsPrice(token.tag, price);
          });
        } else {
          log.error(`Failed to find CoingeckoApiConfig for ${token.tag}!`);
        }
        break;
      case OracleType.KARMA_ORACLE:
        price = await this.scoreApiService.getKarmaOracleTokenUsdPrice(token);
        this.priceOracleService.setAssetPrice(token.tag, price);
        this.stateChangeService.updateAssetsPrice(token.tag, price);
        break;
      default:
        price = new BigNumber(0);
        this.priceOracleService.setAssetPrice(token.tag, price);
        this.stateChangeService.updateAssetsPrice(token.tag, price);
    }
  }

  public async loadCoreData(): Promise<void> {
    await Promise.all([
      this.loadAllBondsTreasuryStatuses(),
      this.loadAllBondsTreasuryPayoutBalances(),
      this.loadAllBondsTerms(),
      this.loadAllBondTotalDebt(),
      this.loadKarmaBondFee(),
      this.loadBondsMaxPayouts(),
      this.loadTrueBondPrices(),
      this.loadBondPrices(),
      this.loadTotalPayoutGiven(),
      this.loadBalancedPoolStats(),
      this.loadSupportedTokenPrices(),
    ]);

    this.asyncLoadCore();

    // commit core data finished loading event
    this.stateChangeService.coreDataFinishedLoadingUpdate();
  }

  asyncLoadCore(): void {
    this.loadAllCustomTreasuryOwners();
    this.loadAllBondsTreasuryPrincipalBalances();
  }

  public async loadUserData(): Promise<void> {
    await Promise.all([
      this.loadAllUserIrc2TokenBalances(),
      this.loadAllUserBalnLpTokenBalances(),
      this.loadUserActiveBonds()
    ]);

    this.initUserRelatedData();

    // commit user data finished loading event
    this.stateChangeService.userDataFinishedLoadingUpdate();
  }

  public async afterUserActionReload(): Promise<void> {
    if (this.persistenceService.activeWallet?.type === WalletType.BRIDGE) {
      // emit event to Bridge to refresh the balances
      this.refreshBridgeBalances();
    }

    // reset

    // reload all reserves and user asset-user reserve data
    await this.loadCoreData();
    await this.loadUserData();
  }

  initUserRelatedData(): void {
    this.initUserTreasuries();
  }

  initUserTreasuries(): void {
    this.appConfigService.getSupportedAndPastBonds().forEach(bond => {
      const treasuryOwner = this.persistenceService.treasuryOwnerMap.get(bond.tag) ?? "-";
      const loggedInAddress = this.persistenceService.activeWallet?.address ?? "+";
      if (treasuryOwner === loggedInAddress) {
        this.persistenceService.userTreasuries.set(bond.tag, bond);
      }
    });
  }

  private refreshBridgeBalances(): void {
    window.dispatchEvent(new CustomEvent("bri.widget", {
      detail: {
        action: 'refreshBalance'
      }
    }));
  }
}
