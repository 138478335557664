import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {BondConfig} from '../../models/classes/BondConfigInfo';
import {ModalService} from '../../services/modal.service';
import {AppConfigService} from '../../services/app-config.service';
import {CalculationService} from '../../services/calculation-service/calculation.service';
import {PriceOracleService} from '../../services/price-oracle.service';
import {DataLoaderService} from '../../services/data-loader.service';
import {StateChangeService} from '../../services/state-change.service';
import {PersistenceService} from '../../services/persistence.service';
import {Utils} from '../../common/utils';


@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html'
})
export class StatsComponent implements OnInit {

  @ViewChild('addressInput', { static: true }) addressInputEl!: ElementRef;

  bonds: BondConfig[] = [];

  coreDataIntervalTime = 10000; // 10 sec
  coreDataInterval?: NodeJS.Timeout;

  constructor(private modalService: ModalService,
              private appConfigService: AppConfigService,
              private calculationService: CalculationService,
              private priceOracleService: PriceOracleService,
              private dataLoaderService: DataLoaderService,
              private stateChangeService: StateChangeService,
              private persistenceService: PersistenceService) { }

  ngOnInit(): void {
    // load active bonds in bonds array to be rendered
    this.loadBonds();

    // init core data interval
    this.coreDataInterval = setInterval(() => this.triggerReloadOfCoreData(), this.coreDataIntervalTime);
  }

  ngOnDestroy(): void {
    // clear core data reload interval when component is destroyed
    if (this.coreDataInterval) {
      clearInterval(this.coreDataInterval);
    }
  }

  onKeyUp(): void {
    Utils.delay(() => {
      const address = this.addressInputEl.nativeElement.value;
      if (!address) {
        this.loadBonds()
      }
    }, 600);
  }

  onResetClick(): void {
    this.addressInputEl.nativeElement.value = "";
    this.loadBonds();
  }

  handleAddressInputSubmit(event: any): void {
    // prevent default submit behavior
    event.preventDefault();

    this.loadBonds(this.addressInputEl.nativeElement.value);
  }

  triggerReloadOfCoreData(): void {
    // only trigger if no modal is active and browser tab is active
    if (!this.modalService.activeModal && !document.hidden) {
      this.dataLoaderService.loadCoreData();
    }
  }

  loadBonds(address?: string): void {
    const tmp: BondConfig[] = [];
    this.appConfigService.getSupportedAndPastBonds().forEach(bond => {
      const treasuryOwner = this.persistenceService.treasuryOwnerMap.get(bond.tag) ?? "-";

      if (address) {
        if (treasuryOwner === address) {
          tmp.push(bond);
        }
      } else {
        tmp.push(bond);
      }

    });

    this.bonds = tmp;
  }

}
