<!--header component containing navigation menu -->
<app-header></app-header>

<router-outlet></router-outlet>

<!--modal component containing all of the modals-->
<app-modal></app-modal>

<!-- overlay component used in combination with modals to dim the background and handle away click -->
<app-overlay></app-overlay>

<app-notification></app-notification>

<!-- Bridge widget (hidden) -->
<icon-bridge-widget hideButton="true"></icon-bridge-widget>
