<div class="modal modal-ledger" [ngStyle]="displayAsBlockOrHide(isModalActive(ModalType.LEDGER))" #ledgerModal>
  <div class="sidebar-container">
    <div class="sidebar-heading">
      <div class="sidebar-heading-pair">
        <h2 class="text-white sidebar-heading-text">Choose a wallet</h2>
      </div>
      <a data-w-id="bc244b2b-7012-02d5-9396-7b638950f0ea" href="javascript:void(0);" (click)="hideActiveModal()"
         class="sidebar-close w-inline-block"><img src="assets/images/close-arrow.svg" loading="lazy" alt=""></a>
    </div>
    <div class="sidebar-content-wrapper sidebar-table">
      <ng-container *ngFor="let wallet of ledgerWallets; let i = index">
        <a href="javascript:void(0);" class="sidebar-table-row ledger-row" (click)="onSelectLedgerAddressClick(wallet)">
          <div class="sidebar-table-column">
            <div class="sidebar-table-text-left">{{wallet.address}}</div>
          </div>
          <div class="sidebar-table-column">
            <div class="sidebar-table-text-right">{{formatNumberToUSLocaleString(ledgerIcxBalance(wallet), true)}} ICX</div>
          </div>
        </a>
      </ng-container>
    </div>
    <div class="sidebar-content-utility-buttons utility-buttons-wallet">
      <div class="row w-row">
        <div class="column w-col w-col-3">
          <a href="javascript:void(0);" (click)="onLedgerPageBackClick()" class="button button-utility copy-address">Previous</a>
        </div>
        <div class="column w-col w-col-6 text-center pages-row">
          <ng-container *ngFor="let i of activeLedgerAddressPageList">
            <a href="javascript:void(0);" (click)="onLedgerAddressPageClick(i)" class="button button-utility button-page
              {{i === selectedLedgerAddressPage ? 'current' : ''}}">{{i + 1}}</a>
          </ng-container>
        </div>
        <div class="column w-col w-col-3">
          <a href="javascript:void(0);" (click)="onLedgerPageNextClick()" class="button button-utility">Next</a>
        </div>
      </div>
    </div>
  </div>
</div>
