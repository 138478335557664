import { Injectable } from '@angular/core';
import {PersistenceService} from "./persistence.service";
import {DataLoaderService} from "./data-loader.service";
import {StateChangeService} from "./state-change.service";
import log from "loglevel";
import {Wallet} from "../models/classes/Wallet";
import {LogoutService} from "./logout.service";
import {NotificationService} from './notification.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private persistenceService: PersistenceService,
    private dataLoaderService: DataLoaderService,
    private stateChangeService: StateChangeService,
    private logoutService: LogoutService,
    private notificationService: NotificationService
  ) { }

  public async walletLogin(wallet: Wallet, relogin: boolean = false, keepBridgeSession: boolean = false
  ): Promise<void> {
    // clear old login
    this.logoutService.signOutUser(keepBridgeSession);

    this.stateChangeService.loginUpdate(wallet);

    try {
      await this.dataLoaderService.loadUserData();
    } catch (e) {
      log.debug(e);
      this.persistenceService.activeWallet = undefined;
      this.notificationService.showErrorNotification("Error occurred! Try again in a moment.");
      log.error(e);
      throw new Error("Error occurred during the login! Try again in a moment.");
    }
  }
}
