import {Irc2TokenTransaction} from "../models/Transaction/Irc2TokenTransaction";
import {SupportedIrc2TokenTransaction} from '../models/Transaction/SupportedIrc2TokenTransaction';
import {BridgeTransaction, TransactionType} from "../models/Transaction/BridgeTransaction";
import {environment} from "../../environment/environment";
import {CodeLists} from "./code-lists";
import {IcxTransaction, IcxTransactions} from "../models/Transaction/IcxTransaction";
import {log} from "./Utils";

export function formatAndFilterIcxTransactions(icxTransactions: IcxTransactions, userIconWalletAddress: string): IcxTransaction[] {
    log("formatAndFilterIcxTransactions-> icxTransactions:", icxTransactions)
    if (icxTransactions && icxTransactions.data?.length > 0) {
        let transactions: IcxTransaction[] = [];
        let date: Date;
        icxTransactions.data.forEach((icxTransaction: IcxTransaction) => {
            if (icxTransaction.state == 1 && icxTransaction.dataType == "icx") {
                date = new Date(icxTransaction.createDate);
                transactions.push(new IcxTransaction(+icxTransaction.amount, icxTransaction.createDate, icxTransaction.dataType,
                    icxTransaction.fromAddr, icxTransaction.state, icxTransaction.toAddr,icxTransaction.txHash, icxTransaction.txType, date.toDateString(), date,
                    userIconWalletAddress == icxTransaction.fromAddr ? TransactionType.SENT : TransactionType.RECEIVED,));
            }
        })
        log("Returning transactions: ", transactions);
        return transactions.sort((a: IcxTransaction, b: IcxTransaction) => b.date.getTime() - a.date.getTime());
    } else {
        return [];
    }
}

// filter out non Bridge token transactions as well as failed ones , format date, set type of transaction and sort by date
export function formatAndFilterBridgeTransactions(iconTokenTransactions: Irc2TokenTransaction[] | undefined,
                                                  userIconWalletAddress: string): BridgeTransaction[] {

    let transactions: BridgeTransaction[] = [];
    let date: Date;

    if (iconTokenTransactions) {
        iconTokenTransactions.forEach((transaction:Irc2TokenTransaction) => {
            if ((transaction.contractSymbol != CodeLists.BRIDGE_SYMBOL) 
                || transaction.state != 1 || transaction.contractAddr != environment.BRIDGE_SCORE_ADDRESS) return;
            // create Transaction object and push it to accumulated transaction list
            else {
                date = new Date(transaction.createDate);
                transactions.push(new BridgeTransaction(date.toDateString(), date, +transaction.quantity, transaction,
                    userIconWalletAddress == transaction.fromAddr ? TransactionType.SENT : TransactionType.RECEIVED
                ));
            }
        });
    }

    return transactions.sort((a: BridgeTransaction, b: BridgeTransaction) => b.date.getTime() - a.date.getTime())
}

export function formatAndFilterIrc2Transactions(iconTokenTransactions: Irc2TokenTransaction[] | undefined): 
    SupportedIrc2TokenTransaction {

    let iusdcTransactions: Irc2TokenTransaction[] = [];
    let sicxTransactions: Irc2TokenTransaction[] = [];
    let ommTransactions: Irc2TokenTransaction[] = [];

    if (iconTokenTransactions) {
        iconTokenTransactions.forEach((transaction:Irc2TokenTransaction) => {
            if (transaction.contractSymbol == CodeLists.IUSDC_SYMBOL && transaction.state == 1 && transaction.contractAddr == environment.IUSDC_SCORE_ADDRESS) {
                iusdcTransactions.push(transaction);
            }
            else if (transaction.contractSymbol == CodeLists.SICX_SYMBOL && transaction.state == 1 && transaction.contractAddr == environment.SICX_SCORE_ADDRESS) {
                sicxTransactions.push(transaction);
            }
            else if (transaction.contractSymbol == CodeLists.OMM_SYMBOL && transaction.state == 1 && transaction.contractAddr == environment.OMM_SCORE_ADDRESS) {
                ommTransactions.push(transaction);
            }
        });
    }

    iusdcTransactions.sort((a: Irc2TokenTransaction, b: Irc2TokenTransaction) => new Date(b.createDate).getTime() - new Date(a.createDate).getTime())
    sicxTransactions.sort((a: Irc2TokenTransaction, b: Irc2TokenTransaction) => new Date(b.createDate).getTime() - new Date(a.createDate).getTime())
    ommTransactions.sort((a: Irc2TokenTransaction, b: Irc2TokenTransaction) => new Date(b.createDate).getTime() - new Date(a.createDate).getTime())

    return new SupportedIrc2TokenTransaction(iusdcTransactions, sicxTransactions, ommTransactions);
}


export function transactionTypeToClass(transaction: BridgeTransaction): string {
    switch (transaction.type) {
        case TransactionType.RECEIVED:
            return "deposited";
        case TransactionType.SENT:
            return "sent";
    }
}

export function tokenTransactionTypeToClass(type: TransactionType) {
    switch (type) {
        case TransactionType.RECEIVED:
            return "deposited";
        case TransactionType.SENT:
            return "sent";
        default:
            return "sent";
    }
}

export function getTransactionName(transaction: BridgeTransaction): string  {
    return transaction.type.valueOf();
}
