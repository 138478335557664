import BigNumber from "bignumber.js";
import {BondInfo} from "./BondInfo";
import {BondConfig} from "./BondConfigInfo";
import {FULLY_VESTED} from "../../common/constants";

export class BondClaimData {
  pendingPayoutFor: BigNumber;
  percentVestedFor: BigNumber;
  bondInfo: BondInfo;
  bond: BondConfig;


  constructor(pendingPayoutFor: BigNumber, percentVestedFor: BigNumber, bondInfo: BondInfo, bond: BondConfig) {
    this.pendingPayoutFor = pendingPayoutFor;
    this.percentVestedFor = percentVestedFor;
    this.bondInfo = bondInfo;
    this.bond = bond;
  }

  bondPrincipalIsLp(): boolean {
    return this.bond.isPrincipalLp();
  }

  vestingTermInSeconds(): BigNumber {
    return this.bondInfo.vesting.multipliedBy(2);
  }

  vestingLeftInSeconds(): BigNumber {
    if (this.percentVestedFor.isZero()) {
      return this.vestingTermInSeconds();
    } else if (this.percentVestedFor.isGreaterThan(FULLY_VESTED)) {
      return new BigNumber(0);
    }

    return this.vestingTermInSeconds().minus(this.vestingTermInSeconds().multipliedBy(this.percentVestedFor.dividedBy(FULLY_VESTED)));
  }
}
