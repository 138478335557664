import {BondConfig} from './BondConfigInfo';

export class BondTogglePayload {
  bond: BondConfig;
  bondIsEnabled: boolean;

  constructor(bond: BondConfig, bondIsEnabled: boolean) {
    this.bond = bond;
    this.bondIsEnabled = bondIsEnabled;
  }
}
