import {log} from "../common/Utils";

export function validateEmail(email: string | undefined): boolean {
    if (!email) return false;
    return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email);
}

export function validateFileExtension(filename: string | undefined): boolean {
    log("validating extension for name: ", filename);
    if (!filename || !filename.includes(".")) return false;
    return /^\.?[a-zA-Z]{2,5}$/.test(<string>filename.split(".").pop());
}

export function validateName(name: string | undefined): boolean {
    if (!name) return false;
    return name.length <= 80;
}

export function validateUsPostalCode(postalCode: string | undefined): boolean {
    if (!postalCode) return false;
    return /^[0-9]{5}(-[0-9]{4})?$/.test(postalCode);
}

export function validateStreet(street: string | undefined): boolean {
    if (!street) return false;
    return street.length < 100;
}

export function validateDateString(dateString: string | undefined): boolean {
    if (!dateString) return false;

    var regEx = /^\d{4}-\d{2}-\d{2}$/;
    if(!dateString.match(regEx)) return false;  // Invalid format
    var d = new Date(dateString);
    var dNum = d.getTime();
    if(!dNum && dNum !== 0) return false; // NaN value, Invalid date

    if(!(d.toISOString().slice(0,10) === dateString)) return false;

    return d <= new Date();
}

export function validateTaxIdNumber(taxIdNumber: string | undefined): boolean {
    if (!taxIdNumber) return false;
    return /^\d{9}$/.test(taxIdNumber);
}

export function validateUuidV4(uuidV4: string | undefined): boolean {
    if (!uuidV4) return false;
    return /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(uuidV4);
}
