import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { LottieModule } from 'ngx-lottie';
import log from "loglevel";
import BigNumber from "bignumber.js";
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { BondMarketplaceComponent } from './components/bond-marketplace/bond-marketplace.component';
import { ModalComponent } from './components/modal/modal.component';
import { OverlayComponent } from './components/overlay/overlay.component';
import { MyBondsComponent } from './components/my-bonds/my-bonds.component';
import {environment} from "../environments/environment";
import {HttpClientModule} from "@angular/common/http";
import { BondRowComponent } from './components/bond-marketplace/bond-row/bond-row.component';
import {UsFormatPipe} from "./pipes/us-format.pipe";
import {RoundOff2DecPipe} from "./pipes/round-off-2-dec.pipe";
import {RoundOff0DecPipe} from "./pipes/round-off-0-dec.pipe";
import {RoundDown2DecPipe} from "./pipes/round-down-2-dec.pipe";
import {ToZeroIfDashPipe} from "./pipes/to-zero-if-dash.pipe";
import { NotificationComponent } from './components/notification/notification.component';
import {DollarUsLocalePipe} from './pipes/dollar-us-locale.pipe';
import {ToFixed2DecPercentPipe} from './pipes/to-fixed-2-dec-percent.pipe';
import { LedgerModalComponent } from './components/ledger-modal/ledger-modal.component';
import { MyBondRowComponent } from './components/my-bonds/my-bond-row/my-bond-row.component';
import { TokenBalancesComponent } from './components/token-balances/token-balances.component';
import { AppRoutingModule } from './app-routing.module';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { CustomTreasuryRowComponent } from './components/dashboard/custom-treasury-row/custom-treasury-row.component';
import {RouteReuseStrategy} from '@angular/router';
import {CustomReuseStrategy} from './routing';
import { StatsComponent } from './components/stats/stats.component';
import { BondStatsRowComponent } from './components/stats/bond-stats-row/bond-stats-row.component';
import { BondModalComponent } from './components/modal/bond-modal/bond-modal.component';

export function playerFactory() {
  return import('lottie-web');
}

// Big Number configs
const fmt = {
  prefix: '',
  decimalSeparator: '.',
  groupSeparator: ',',
  groupSize: 3,
  secondaryGroupSize: 0,
  fractionGroupSeparator: ' ',
  fractionGroupSize: 0,
  suffix: '',
};

BigNumber.config({ FORMAT: fmt, ROUNDING_MODE: BigNumber.ROUND_DOWN, DECIMAL_PLACES: 128 });
BigNumber.set({ ROUNDING_MODE: BigNumber.ROUND_DOWN }); // equivalent

// set logging level
log.setLevel(environment.production ? "error" : "debug");

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    BondMarketplaceComponent,
    ModalComponent,
    OverlayComponent,
    MyBondsComponent,
    BondRowComponent,
    UsFormatPipe,
    RoundOff2DecPipe,
    RoundDown2DecPipe,
    RoundOff0DecPipe,
    ToZeroIfDashPipe,
    DollarUsLocalePipe,
    ToFixed2DecPercentPipe,
    NotificationComponent,
    LedgerModalComponent,
    MyBondRowComponent,
    TokenBalancesComponent,
    DashboardComponent,
    CustomTreasuryRowComponent,
    StatsComponent,
    BondStatsRowComponent,
    BondModalComponent,
  ],
  imports: [
    BrowserModule,
    LottieModule.forRoot({ player: playerFactory }),
    HttpClientModule,
    AppRoutingModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
