import {TransactionType} from "./BridgeTransaction";

export interface TransactionObj {
    version: string;
    from: string;
    to: string;
    data: object;
    dataType: string;
    timestamp: string;
    nid: string;
    nonce: string;
}

export class Irc2TokenTransaction {
    txHash: string;
    contractAddr: string;
    contractName: string;
    contractSymbol: string;
    unit: string;
    createDate: string;
    fromAddr: string;
    toAddr: string;
    quantity: string;
    ircVersion: string;
    state: number;
    // custom properties
    type: TransactionType | undefined;

    constructor(txHash: string, contractAddr: string, contractName: string, contractSymbol: string, unit: string,
                createDate: string, fromAddr: string, toAddr: string, quantity: string, ircVersion: string, state: number,
                type?: TransactionType) {
        this.txHash = txHash;
        this.contractAddr = contractAddr;
        this.contractName = contractName;
        this.contractSymbol = contractSymbol;
        this.unit = unit;
        this.createDate = createDate;
        this.fromAddr = fromAddr;
        this.toAddr = toAddr;
        this.quantity = quantity;
        this.ircVersion = ircVersion;
        this.state = state;
        if (type)
            this.type = type;
    }
}

export class Irc2ParamsObj {
    _to: string;
    _value: string;
    _data?: string | undefined;

    constructor(to: string, value: string, data?: string | undefined) {
        this._to = to;
        this._value = value;
        if(data)
            this._data = data;
    }
}