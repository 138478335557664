/**
 *  This class wraps up an external error and provides user friendly message
 * */
export class BridgeError extends Error {
    externalError?: any;
    userFriendlyMessage: string;

    constructor(userFriendlyMessage: string = "Unknown error occurred! Try again later.", externalError?: any) {
        super(userFriendlyMessage);
        this.name = 'BridgeError';
        this.userFriendlyMessage = userFriendlyMessage;
        this.externalError = externalError;

        Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
    }
}