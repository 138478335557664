export function hideModalView(modalView: HTMLElement | null | undefined) {
    if (!modalView) return;
    modalView!.style.display = "none";
}

export function showSpan(spanView: HTMLElement | null | undefined) {
    if (!spanView) return;
    spanView!.style.display = "inline";
}

export function showSpanAsBlock(spanView: HTMLElement | null | undefined) {
    if (!spanView) return;
    spanView!.style.display = "block";
}

export function hideSpan(spanView: HTMLElement | null | undefined) {
    if (!spanView) return;
    spanView!.style.display = "none";
}

