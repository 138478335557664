import {Magic, MagicUserMetadata, RPCError, RPCErrorCode, SDKError} from 'magic-sdk';
import {IconExtension} from '@magic-ext/icon';
import {BigNumber} from 'bignumber.js';
import {MagicLogin} from "./magic-login";
import {RequestsWrapper} from "./common/requests-wrapper";
import {UserApiService} from "./services/user-api-service";
import {
    getIcxBalance, getIrc2TokenBalance,
    sendIrc2Token, sendIcxTokens,
    getTokenRate
} from "./services/icon-rpc-api-service";
import {IconApiService} from "./services/icon-api-service";

import {environment} from "../environment/environment";
import {MagicLoginResponse} from "./models/Interfaces/MagicLogin";
import {OracleTokenNames, SupportedTokens} from './models/Tokens/Tokens';
import {BridgeError} from "./models/errors/bridgeError";
import {Consts} from './common/consts';
import {log} from "./common/Utils";

const IconService = require('icon-sdk-js');
const {IconBuilder, IconAmount, IconConverter, HttpProvider} = IconService;

export class BridgeService {
    private readonly magic: Magic;
    public magicUserMetadata: MagicUserMetadata | undefined; // logged in user metadata
    readonly iconSdk: typeof IconService;

    public userApiService: UserApiService;
    public iconApiService: IconApiService;

    constructor() {

        this.magic = new Magic(environment.MAGIC_LOGIN_API_KEY, {
            extensions: {
                icon: new IconExtension({
                    rpcUrl:  environment.ICON_RPC_URL
                })
            }
        });
        // instantiate request wrapper and pass it to api services to use it
        const requestsWrapper = new RequestsWrapper(this.magic);

        this.userApiService = new UserApiService(requestsWrapper);
        this.iconApiService = new IconApiService();

        this.iconSdk = new IconService(new HttpProvider(environment.ICON_RPC_URL));
    }

    /**
     * @description Login user in to Magic using email and dispatch login event
     * @param {string} email - The email of user.
     * @return {Promise<MagicLoginResponse>} Promise with MagicLoginResponse model containing user and magicUserMetadata.
     * @throws {BridgeError} - contains user friendly message and external error (if present)
     */
    async magicLogin(email: string): Promise<MagicLoginResponse> {
        const magicLoginResponse: MagicLoginResponse = await MagicLogin(this.magic, email, this.userApiService);
        this.magicUserMetadata = magicLoginResponse.magicUserMetadata;
        log("Login magicUserMetadata:", this.magicUserMetadata);
        // dispatch login event
        window.dispatchEvent(new CustomEvent('bri.login', {
            detail: {
                publicAddress: this.magicUserMetadata.publicAddress,
                email: email
            }
        }));
        return magicLoginResponse;
    }

    /**
     * @description Logout user from Magic and dispatch logout event
     * @return {void}
     * @throws {BridgeError} - contains user friendly message and external error (if present)
     */
    async magicLogout(): Promise<void> {
        try {
            this.checkMagicInitialized()
        } catch (e) {
            if (e instanceof BridgeError)
            window.dispatchEvent(new CustomEvent('bri.logout.res', {
                detail: {
                    error: e.userFriendlyMessage
                }
            }));
        }

        await this.magic.user.isLoggedIn().then(async (loggedIn: boolean) => {
            if (loggedIn) {
                try {
                    const userEmail = this.magicUserMetadata?.email;
                    const userPublicAddress = this.magicUserMetadata?.publicAddress;
                    await this.magic.user.logout();
                    window.dispatchEvent(new CustomEvent('bri.logout.res', {
                        detail: {
                            publicAddress: userPublicAddress,
                            email: userEmail
                        }
                    }));
                    this.magicUserMetadata = undefined;
                    log("Successfully logged out!")
                } catch (e) {
                    window.dispatchEvent(new CustomEvent('bri.logout.res', {
                        detail: {
                            error: e.message
                        }
                    }));
                    throw new BridgeError(undefined, e);
                }
            } else {
                log("User already logged out.");
                window.dispatchEvent(new CustomEvent('bri.logout.res', {
                    detail: {
                        error: "User already logged out."
                    }
                }));
            }
        }).catch((e) => {
            window.dispatchEvent(new CustomEvent('bri.logout.res', {
                detail: {
                    error: e.message
                }
            }));
            throw new BridgeError(undefined, e);
        });
    }

    /**
     * @description Check if user is logged in to Magic.
     * @return {Promise<boolean>} The boolean Promise, true if user is logged in.
     * @throws {BridgeError} - contains user friendly message and external error (if present)
     */
    async userIsLoggedIn(): Promise<boolean> {
        this.checkMagicInitialized();

        try {
            return this.magic.user.isLoggedIn();
        } catch (e) {
            throw new BridgeError(undefined, e)
        }
    }


    /**
     * @description Get logged in user Magic metadata
     * @return {Promise<MagicUserMetadata>} The MagicUserMetadata Promise
     * @throws {BridgeError} - contains user friendly message and external error (if present)
     */
    async getLoggedInUsersMagicMetadata(): Promise<MagicUserMetadata> {
        this.checkMagicInitialized();
        try {
            return this.magic.user.getMetadata();
        } catch (e) {
            throw new BridgeError(undefined, e)
        }
    }

    /**
     * @description Sign withdrawal request transaction using Magic extension
     * @param {any} txObj - Icon transaction, see here: https://github.com/icon-project/icon-sdk-js#iconserviceiconbuilder
     * @return {Promise<string>} Promise of signature (string)
     * @throws {BridgeError} - contains user friendly message and external error (if present)
     */
    async signTransaction(txObj: any):Promise<string> {
        log("signTransaction txObj:", txObj);
        this.checkMagicInitialized();

        try {
            // @ts-ignore
            const { signature, rawTransaction } = await this.magic.icon.signTransaction(txObj);
            log(`result:`, signature, rawTransaction);
            return signature;
        } catch (e) {
            throw new BridgeError(e.message ?? "Sign transaction failed.", e)
        }
    }

    /**
     * @description Send IRC2 tokens to another wallet.
     * @param {string} to - The EOA address.
     * @param {string} scoreAddress - The EOA address of the token SCORE.
     * @param {number} amount - The amount of tokens to send as a whole number.
     * @return {Promise<string>} The transaction hash promise
     * @throws {BridgeError} - contains user friendly message and external error (if present)
     */
    async sendIrc2Tokens(to: string, amount: number, decimals: number, scoreAddress: string, data?: string): Promise<string> {
        log("Send IRC2 token " + amount + " to " + to + ". IRC 2 token SCORE address = " + scoreAddress);
        this.checkMagicInitialized();

        if(!this.magicUserMetadata) {
            if(this.userIsLoggedIn()) {
                this.magicUserMetadata = await this.getLoggedInUsersMagicMetadata();
            }
        }

        return sendIrc2Token(this.magic, to, scoreAddress, amount, decimals, data ?? undefined, IconBuilder, IconConverter, this.magicUserMetadata)
    }

    /**
     * @description Send ICX tokens to another wallet.
     * @param {string} to - The EOA address.
     * @param {number} amount - The amount of ICX tokens to send as a whole number.
     * @return {Promise<string>} The transaction hash promise
     * @throws {BridgeError} - contains user friendly message and external error (if present)
     */
    async sendIcxTokens(to: string, amount: number): Promise<string> {
        log("Send ICX tokens " + amount + " to " + to + ".)");
        this.checkMagicInitialized();

        if(!this.magicUserMetadata) {
            if(this.userIsLoggedIn()) {
                this.magicUserMetadata = await this.getLoggedInUsersMagicMetadata();
            }
        }

        return sendIcxTokens(this.magic, to, amount, IconBuilder, IconAmount, IconConverter, this.magicUserMetadata)
    }

    addSendTransactionEventListener() : void {
        window.addEventListener(Consts.SEND_TRANSACTION_EVENT, (e) => this.handleSendTransactionEvent(e))
    }


    /**
     * @description Polls for txResult until not pending and dispatches the result as event
     * @param {string} txHash - The transaction hash.
     * @param {number} retryAttempt - The number of attempts to get the transaction result
     */
    private dispatchTxResultEvent(txHash: string, retryAttempt = 0) {
        log("Retry Attempt: " + retryAttempt)
        this.getTxResult(txHash).then(result => {
            log("Transaction result: ", result);
            // Actual transaction result
            window.dispatchEvent(new CustomEvent(Consts.BRIDGE_TRANSACTION_RESULT, {
                detail:{
                    status: result.status,
                    txHash: txHash,
                    error: result.failure
                }
            }))
        }).catch((e: Error) => {
            if(retryAttempt < Consts.RETRY_ATTEMPT_LIMIT)
                setTimeout(this.dispatchTxResultEvent.bind(this,txHash, ++retryAttempt), 2000)
            else {
                // Maximum retry limit reached
                window.dispatchEvent(new CustomEvent(Consts.BRIDGE_TRANSACTION_RESULT, {
                    detail:{
                        status: 0,
                        txHash: txHash,
                        error: "Maximum retry of get tx result attempt reached" ,
                    }
                }))
                throw new BridgeError(`Maximum retry attempt reached. 
                Could not get transaction result for transaction with hash ${txHash}`, e)
            }
        });
    }

    /**
     * @description Event handler for sending stable coins initiated by Bridge integrator.
     * @throws {BridgeError} - contains user friendly message and external error (if present)
     */
    async handleSendTransactionEvent(event: Event) {
        log("Handling bri.send.tx event: ", event);
        if (!await this.userIsLoggedIn()) {
            window.dispatchEvent(new CustomEvent(Consts.BRIDGE_TRANSACTION_RESULT, {
                detail:{
                    status: 0,
                    txHash: null,
                    error: "Can not send transaction. No user logged in.",
                }
            }));
            throw new BridgeError("Can not send transaction. No user logged in.");
        }

        const {payload} = (event as CustomEvent).detail;
        try {
            // @ts-ignore
            let txHash = await this.magic.icon.sendTransaction(payload);
            log("Successfully submitted transaction. TxHash=", txHash);
            await this.dispatchTxResultEvent(txHash)
        }
        catch (e) {
            let message: string
            if (e instanceof RPCError) {
                console.error(e)
                switch (e.code) {
                    case RPCErrorCode.InternalError:
                        if(e.rawMessage.includes("Out of balance"))
                            message = "User does not have sufficient ICX for transaction fees"
                        else
                            message = "Check your transaction object."
                        break;
                    default:
                        message = e.message
                        break;
                }
            } else if (e instanceof SDKError) {
                message = "System error occurred. Please contact site maintainer or try again later."
            } else {
                message = "Unknown error occurred. Please try again later."
            }

            // Transaction Error
            window.dispatchEvent(new CustomEvent(Consts.BRIDGE_TRANSACTION_RESULT, {
                detail:{
                    status: 0,
                    txHash: null,
                    error: message,
                }
            }))
            throw new BridgeError(message, e)
        }
    }

    /**
     * @description Get users Icon wallet ICX balance.
     * @return {Promise<number>} The icx balance as number in promise
     * @throws {BridgeError} - contains user friendly message and external error (if present)
     */
    async getIcxBalance(): Promise<number> {
        log("Get icx balance called");
        this.checkMagicInitialized()
        this.checkUserMetadataInitialized()

        if(!this.magicUserMetadata) {
            if(this.userIsLoggedIn()) {
                this.magicUserMetadata = await this.getLoggedInUsersMagicMetadata();
            }
        }

        return getIcxBalance(this.magicUserMetadata!.publicAddress, this.iconSdk);
    }

    /**
     * Get users IRC-2 token balance.
     * @param {string} scoreAddress - The EOA address of the token SCORE.
     * @return {Promise<number> } The Promise IRC2 token balance as number.
     * @throws {BridgeError} - contains user friendly message and external error (if present)
     */
    async getIrc2TokenBalance(scoreAddress: string): Promise<number> {
        log("Get stable coin balance called");
        this.checkMagicInitialized()
        this.checkUserMetadataInitialized()

        if(!this.magicUserMetadata) {
            if(this.userIsLoggedIn()) {
                this.magicUserMetadata = await this.getLoggedInUsersMagicMetadata();
            }
        }
        if (!this.magicUserMetadata!.publicAddress) {
            throw new BridgeError("Icon public address is missing. Are you logged in?",
                Error("BridgeService.magicUserMetadata.publicAddress is null or undefined."));
        }
        return getIrc2TokenBalance(this.magicUserMetadata!.publicAddress, scoreAddress, IconBuilder, this.iconSdk);
    }

    /**
     * @description Get current dollar rate of specified token
     * @param {SupportedTokens} tokenSymbol - Symbol of the token
     * @return {Promise<BigNumber>} - Current Dollar Rate in BigNumber
     * @throws {BridgeError} - contains user friendly message and external error (if present)
     */
    async getTokenPrice(tokenSymbol: SupportedTokens): Promise<BigNumber> {
        return await getTokenRate(IconBuilder, this.iconSdk, OracleTokenNames[tokenSymbol], OracleTokenNames.USD);
    }

    /**
     * @description Get transaction result object.
     * @param {string} txHash - The transaction hash.
     * @return {Promise<any>} The transaction result.
     * @throws {BridgeError} - contains user friendly message and external error (if present)
     */
    async getTxResult(txHash: string): Promise<any> {
        try {
            return await this.iconSdk.getTransactionResult(txHash).execute();
        } catch (e) {
            log(e);
            throw new BridgeError("Error while geting the transaction result." +
                " Please try again later.", e)
        }
    }

    /**
     * @description Change users Magic email
     * @param {string} email - The email of user.
     * @return {Promise<void>} Promise with empty response.
     * @throws {BridgeError} - contains user friendly message and external error (if present)
     */
    async updateEmail(email: string): Promise<void> {
        this.checkMagicInitialized()

        try {
            // Magic update email
            await this.magic.user.updateEmail({email: email});
        } catch (e) {
            log(e);
            let message: string
            if (e instanceof RPCError) {
                switch (e.code) {
                    case RPCErrorCode.UpdateEmailFailed:
                        message = "Update email failed."
                        break;
                    default:
                        message = e.message;
                        break;
                }
            } else {
                message = `Error while changing the email.\n Details: ${e.message}`;
            }
            throw new BridgeError(message, e);
        }

        this.userApiService.updateUserEmail(email)
            .then(user => {
                log(`User updated: ${user.data}`)
            })
            .catch(error => {
                // No need to rollback magic update of email because
                // it will get updated next time user logs in
                log(`User email update failed at backend: ${error} \n will be updated on next login..`);
            });
    }

    private checkMagicInitialized() {
        if (!this.magic) {
            throw new BridgeError("An error occurred. Are you logged in?",
                Error("BridgeService.magic is null or undefined."));
        }
    }

    private checkUserMetadataInitialized() {
        if (!this.magicUserMetadata) {
            throw new BridgeError("An error occurred. User not logged in?",
                Error("BridgeService.magicUserMetadata is null or undefined."));
        }
    }
}
