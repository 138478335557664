import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {BondConfig} from "../../../models/classes/BondConfigInfo";
import {Utils} from "../../../common/utils";
import {Subscription} from "rxjs";
import {ModalService} from "../../../services/modal.service";
import {PersistenceService} from "../../../services/persistence.service";
import {PriceOracleService} from "../../../services/price-oracle.service";
import {CalculationService} from "../../../services/calculation-service/calculation.service";
import {StateChangeService} from "../../../services/state-change.service";
import {Irc2Token} from "../../../models/classes/Irc2Token";
import {defaultPrepLogoUrl} from "../../../common/constants";
import {BaseClass} from "../../../base-class";

@Component({
  selector: "app-bond-stats-row",
  templateUrl: "./bond-stats-row.component.html"
})
export class BondStatsRowComponent extends BaseClass implements OnInit, OnDestroy {

  @Input() bond!: BondConfig;

  // Template variables
  treasuryPayoutBalance = Utils.ZERO;
  treasuryPayoutUsdBalance = Utils.ZERO;
  treasuryPrincipalBalance = Utils.ZERO;
  treasuryPrincipalUsdBalance = Utils.ZERO;
  bondIsEnabled = false;

  // subscriptions
  afterCoreDataReloadSub?: Subscription;
  bondStatusSub?: Subscription;

  constructor(private modalService: ModalService,
              public override persistenceService: PersistenceService,
              private priceOracleService: PriceOracleService,
              private calculationService: CalculationService,
              private stateChangeService: StateChangeService
  ) {
    super(persistenceService);
  }

  ngOnInit(): void {
    this.initCoreValues();
    this.registerSubscriptions();
  }

  ngOnDestroy(): void {
    this.afterCoreDataReloadSub?.unsubscribe();
    this.bondStatusSub?.unsubscribe();
  }

  registerSubscriptions(): void {
    this.subscribeToCoreDataChange();
    this.subscribeToBondStatusChange();
  }

  subscribeToCoreDataChange(): void {
    this.afterCoreDataReloadSub = this.stateChangeService.coreDataFinishedLoading$.subscribe(() => {
      this.initCoreValues();
    });
  }

  subscribeToBondStatusChange(): void {
    this.bondStatusSub = this.stateChangeService.bondStatusChange$.subscribe(res => {
      if (res.bondTag === this.bond.tag) {
        this.bondIsEnabled = res.status;
      }
    });
  }

  private initCoreValues(): void {
    this.treasuryPayoutBalance = this.persistenceService.getBondTreasuryPayoutBalance(this.bond.tag);
    this.treasuryPayoutUsdBalance = this.treasuryPayoutBalance.multipliedBy(this.priceOracleService.getIrc2TokenPrice(this.bond.payoutToken));
    this.treasuryPrincipalBalance = this.persistenceService.getBondTreasuryPrincipalBalance(this.bond.tag);
    this.treasuryPrincipalUsdBalance = this.treasuryPrincipalBalance.multipliedBy((
      this.bond.principalToken instanceof  Irc2Token) ? this.priceOracleService.getIrc2TokenPrice(this.bond.principalToken)
      : this.calculationService.calculateLpMarketUsdPrice(this.bond));
    this.bondIsEnabled = this.persistenceService.bondStatusMap.get(this.bond.tag) ?? false;
  }

  principalTokenName(): string {
    return Utils.getBondPrincipalTokenName(this.bond);
  }

  principalImg(): string {
    return this.bond.principalToken.img;
  }

  payoutImg(): string {
    return this.bond.payoutToken.img;
  }

  handlePrincipalImgError($event: any) {
    $event.target.src = defaultPrepLogoUrl;
  }
}
