<img src="assets/images/color-blur.png" loading="lazy" sizes="(max-width: 546px) 100vw, 546px"
     srcset="assets/images/color-blur-p-500.png 500w, assets/images/color-blur.png 546w" alt="" class="background-blur">
<div class="app-nav wf-section">
  <div class="container relative w-container">
    <div class="menu">
      <div class="menu-logo">
        <a data-w-id="5a3442b8-2e0a-32f6-a1f5-dbbae685c5a3" href="javascript:void(0);" class="open-menu w-inline-block" (click)="onHamMenuClick()">
          <img src="assets/images/menu.svg" loading="lazy" alt="">
        </a>
        <img class="cursor-pointer" src="assets/images/karmabond.png" loading="lazy" width="41.5" alt="" (click)="onLogoClick()">
      </div>
      <div class="hide-mobile menu-links">
        <a href="javascript:void(0);" (click)="onMarketplaceClick()" class="app-menu {{isMenuActive(NavItem.MARKETPLACE) ? 'app-menu-active':''}}">Marketplace</a>
        <a *ngIf="userOwnsTreasuries()" href="javascript:void(0);" (click)="onDashboardClick()" class="app-menu {{isMenuActive(NavItem.DASHBOARD) ? 'app-menu-active':''}}">Manage bonds</a>
        <a *ngIf="userHasActiveBond" href="javascript:void(0);"  (click)="onMyBondsClick()"
           class="app-menu {{isMenuActive(NavItem.MY_BONDS) ? 'app-menu-active':''}}" [ngStyle]="hideElement(!userIsLoggedIn())">My bonds</a>
        <a href="https://karma-finance.gitbook.io/karma-bond-documentation/overview/introduction" target="_blank" class="app-menu">Developers<img src="assets/images/cta-arrow.svg" loading="lazy" alt="" class="cta-arrow"></a>
        <a href="https://discord.gg/beQW4DHP2m" target="_blank" class="app-menu">Community<img src="assets/images/cta-arrow.svg" loading="lazy" alt="" class="cta-arrow"></a>

      </div>
      <div class="menu-cta">
        <a data-w-id="651d27fc-1073-90da-0591-fc8a79631926" href="javascript:void(0);" (click)="onConnectWalletClick()" class="button button-gradient w-inline-block">
          <div class="button-content">
            <div class="text-block">{{!userIsLoggedIn() ? "Connect ": ""}}Wallet</div><img src="assets/images/cta-arrow.svg" loading="lazy" alt="" class="cta-arrow">
          </div>
        </a>
      </div>
    </div>
    <div class="mobile-menu" [ngStyle]="displayAsBlockOrHide(hamMenuOpen())">
      <a href="javascript:void(0);" (click)="onMarketplaceClick()" class="app-menu {{isMenuActive(NavItem.MARKETPLACE) ? 'app-menu-active':''}}">Marketplace</a>
      <a *ngIf="userOwnsTreasuries()" href="javascript:void(0);" (click)="onDashboardClick()" class="app-menu {{isMenuActive(NavItem.DASHBOARD) ? 'app-menu-active':''}}">Manage bonds</a>
      <a href="javascript:void(0);"  (click)="onMyBondsClick()" class="app-menu {{isMenuActive(NavItem.MY_BONDS) ? 'app-menu-active':''}}"
         [ngStyle]="hideElement(!userIsLoggedIn())">My bonds</a>
      <a href="https://karma-finance.gitbook.io/karma-bond-documentation/overview/introduction" target="_blank" class="app-menu">Developers<img src="assets/images/cta-arrow.svg" loading="lazy" alt="" class="cta-arrow"></a>
      <a href="https://discord.gg/beQW4DHP2m" target="_blank" class="app-menu">Community<img src="assets/images/cta-arrow.svg" loading="lazy" alt="" class="cta-arrow"></a>
    </div>
  </div>
</div>
