import {TransactionType} from "./BridgeTransaction";

export class IcxTransactions {
    data: IcxTransaction[];

    constructor(data: IcxTransaction[]) {
        this.data = data;
    }
}

export class IcxTransaction {
    amount: number; // number in string (e.g. 265)
    createDate: string;
    dataType: string; // e.g. "icx"
    fromAddr: string;
    state: number;
    toAddr: string;
    txHash: string;
    txType: string;

    dateString: string = "";
    date: Date = new Date();
    type: TransactionType = TransactionType.SENT;


    constructor(amount: number, createDate: string, dataType: string, fromAddr: string, state: number, toAddr: string,
                txHash: string, txType: string, dateString?: string, date?: Date, type?: TransactionType) {
        this.amount = +amount;
        this.createDate = createDate;
        this.dataType = dataType;
        this.fromAddr = fromAddr;
        this.state = state;
        this.toAddr = toAddr;
        this.txHash = txHash;
        this.txType = txType;

        if (dateString)
            this.dateString = dateString
        if (date)
            this.date = date
        if(type)
            this.type = type;
    }

// EXAMPLE
    // amount: "265"
    // createDate: "2020-11-12T09:46:26.000+0000"
    // dataType: "icx"
    // errorMsg: null
    // fee: "0.00125"
    // fromAddr: "hx2730819d95ce5485d43f325c4a2056ff86721e05"
    // height: 26546474
    // id: null
    // state: 1
    // targetContractAddr: null
    // toAddr: "hx955bc742ab5890a9562d54d13c6e3120604b113e"
    // txHash: "0xbd15b7fc4ae527c64a111715ad7320b5e117eef62815e130a4ab66aef9b2489b"
    // txType: "0"
}
