import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import {BondModalPayload} from "../../../models/classes/BondModalPayload";
import {ModalPayload} from "../../../models/types/Types";
import {BaseClass} from "../../../base-class";
import {PersistenceService} from "../../../services/persistence.service";
import BigNumber from "bignumber.js";
import {CalculationService} from "../../../services/calculation-service/calculation.service";
import {
  BOND_INPUT_DECIMAL_PRECISION,
  BOND_UNDEFINED,
  INPUT_MAX_ALERT,
  INVALID_INPUT_ALERT, MIN_BOND_INPUT, MIN_BOND_INPUT_ALERT, TOO_LOW_BALANCE,
  UNEXPECTED_ERROR,
  ZERO
} from "../../../common/constants";
import {PriceOracleService} from "../../../services/price-oracle.service";
import {BalnLpToken} from "../../../models/classes/BalnLpToken";
import {environment} from "../../../../environments/environment";
import {UserAction} from "../../../models/classes/UserAction";
import {UserActionType} from "../../../models/enums/UserActionType";
import {BondActionPayload} from "../../../models/classes/BondActionPayload";
import log from "loglevel";
import {normalFormat} from "../../../common/formats";
import {NotificationService} from "../../../services/notification.service";
import {ScoreApiService} from "../../../services/score-api.service";
import {TransactionDispatcherService} from "../../../services/transaction-dispatcher.service";

@Component({
  selector: "app-bond-modal",
  templateUrl: "./bond-modal.component.html"
})
export class BondModalComponent extends BaseClass implements OnInit {

  @ViewChild("bondInput", { static: true }) bondInputEl?: ElementRef;
  @ViewChild("bondCheckbox", { static: true }) bondCheckboxEl?: ElementRef;

  private _payload?: BondModalPayload;

  @Input() set payload(payload: ModalPayload | undefined) {
    if (payload instanceof BondModalPayload) {
      this._payload = payload;
    }
  }

  @Output() hideModalEvent = new EventEmitter<void>();
  @Output() showSignInModalEvent = new EventEmitter<void>();
  @Output() showLoadingModalEvent = new EventEmitter<void>();

  // input related variables
  payoutAmount = ZERO; // estimated payout amount for bond input
  bKarmaPayoutAmount = ZERO;
  bondCheckboxConfirmed = false;
  bondInput = 0;

  // Template variables calculated when payload changes
  bondPriceUSD: BigNumber = ZERO;
  marketPayoutTokenPrice: BigNumber = ZERO;
  principalTokenName = "";
  payoutTokenName = "";
  principalTokenImg = "";
  payoutTokenImg = "";
  principalIsLp = false;
  bondVestingTerm = ZERO;
  bondRoi = ZERO;
  showGetLp = false;
  userPrincipalTokenBalance = ZERO;
  customBondLink = "";
  bondMaxPayout = ZERO;

  constructor(
      public override persistenceService: PersistenceService,
      private calculationService: CalculationService,
      private oracleService: PriceOracleService,
      private notificationService: NotificationService,
      private scoreApiService: ScoreApiService,
      private transactionDispatcher: TransactionDispatcherService
  ) {
    super(persistenceService);
  }

  ngOnInit(): void {
    this.resetBondInputFields();
    this.initCoreValues();
  }

  initCoreValues(): void {
    this.bondPriceUSD = this.calculateBondPriceUSD();
    this.marketPayoutTokenPrice = this.calculateMarketPayoutTokenPrice();
    this.principalTokenImg = this.payload?.bond.principalToken.img ?? "";
    this.principalTokenName = this.getPrincipalTokenName();
    this.payoutTokenImg = this.getPayoutTokenImg();
    this.payoutTokenName = this.getPayoutTokenName();
    this.bondVestingTerm = this.getBondVestingTerm();
    this.principalIsLp = this.isPrincipalIsLp();
    this.bondRoi = this.calculateBondRoi();
    this.showGetLp = this.getShowGetLp();
    this.userPrincipalTokenBalance = this.getUserPrincipalTokenBalance();
    this.customBondLink = this.getCustomBondLink();
    this.bondMaxPayout = this.calculateBondMaxPayout();
  }

  private resetBondInputFields(): void {
    this.resetCheckboxInputs();
    this.setBondInputElValue("");
    this.bondInput = 0;
    this.payoutAmount = new BigNumber(0);
    this.bKarmaPayoutAmount = new BigNumber(0);
  }

  private resetCheckboxInputs(): void {
    this.setBondCheckboxElValue(false);
    this.bondCheckboxConfirmed = false;
  }

  calculateBondMaxPayout(): BigNumber {
    // max payout is minimum of either maxPayout() or bond treasury balance
    if (this.payload?.bond) {
      const maxPayout = this.persistenceService.getBondsMaxPayout(this.payload.bond.tag);
      const bondTreasuryBalance = this.persistenceService.getBondTreasuryPayoutBalance(this.payload.bond.tag);
      return BigNumber.min(maxPayout, bondTreasuryBalance);
    } else {
      return ZERO;
    }
  }

  handleBondCheckbox(): void {
    if (this.bondInput > MIN_BOND_INPUT) {
      this.bondCheckboxConfirmed = !this.bondCheckboxConfirmed;
    } else {
      this.setBondCheckboxElValue(false);
      this.bondCheckboxConfirmed = false;
      this.notificationService.showAlertNotification(`Input amount must be greater than ${MIN_BOND_INPUT}.`);
    }
  }

  onMaxBondInputClick() {
    const bond = this.payload?.bond;

    if (bond) {
      let maxInput = this.calculationService.calculateInputFromPayoutFor(this.bondMaxPayout, bond.tag);

      // make sure maxInput is not greater than user principal token balance
      if (maxInput.gt(this.userPrincipalTokenBalance.dp(BOND_INPUT_DECIMAL_PRECISION))) {
        maxInput = this.userPrincipalTokenBalance.dp(BOND_INPUT_DECIMAL_PRECISION);
      }

      this.setBondInputElValue(normalFormat.to(maxInput.toNumber()));
      this.bondInput = maxInput.toNumber();
      this.payoutAmount = this.calculationService.payoutFor(maxInput, bond.tag);
      this.bKarmaPayoutAmount = this.calculationService.calculatebKarmaAmount(this.payoutAmount, bond);
      this.onBondInputLostFocus({ target: { value: this.bondInputEl?.nativeElement.value ?? "" } });
    }
  }

  handleBondSubmit(event: SubmitEvent): void {
    // prevent default submit behavior
    event.preventDefault();
    event.stopPropagation();

    // check if bond input amount or payout amount are zero
    if (this.bondInput === 0 || this.payoutAmount.isZero()) {
      return;
    } else if (this.bondMaxPayout.isLessThan(this.payoutAmount)) {
      // check if payout amount is less than bond max payout
      this.notificationService.showAlertNotification(INPUT_MAX_ALERT);
      return;
    }

    const bond = this.payload?.bond;
    const vestingTerm = this.bondVestingTerm;
    const payload =  this.payload;

    if (bond && payload) {
      // show loading modal
      this.showLoadingModal();

      const amount = new BigNumber(this.bondInput.toString());
      const maxPrice = payload.trueBondPrice;
      const tx = this.scoreApiService.buildDepositTx(amount, maxPrice, bond);

      try {
        // dispatch built transaction
        this.transactionDispatcher.dispatchTransaction(tx,
            new UserAction(UserActionType.BOND, new BondActionPayload(bond, amount, this.payoutAmount, vestingTerm,
                this.bKarmaPayoutAmount, payload.trueBondPrice))
        );
      } catch (e) {
        // Show unexpected error notification and hide active modal if error pops
        this.notificationService.showErrorNotification(UNEXPECTED_ERROR);
        this.notificationService.hideActiveNotification();
      }
    } else {
      this.notificationService.showErrorNotification(BOND_UNDEFINED);
    }
  }

  onBondInputLostFocus(e: any): void {
    // hide existing INVALID INPUT ALERT message
    this.hideInvalidInputNotification();

    // hide TOO LOW BALANCE message if it passes the check
    this.hideTooLowBalanceNotification();

    this.delay(() => {
      const value = normalFormat.from(e.target.value);
      const amount = new BigNumber(value);

      console.log("value", value);
      console.log("amount", amount);

      // make sure value and amount are valid
      if (value && amount.isFinite() && amount.isPositive() && amount.gt(0)) {

        // assign pretty formatted amount to bond input element if amount is greater than thousand
        if (amount.gt(1000)) {
          this.setBondInputElValue(normalFormat.to(amount.toNumber()));
        }

        const userPrincipalTokenBalance = this.userPrincipalTokenBalance.dp(BOND_INPUT_DECIMAL_PRECISION);

        // if input is greater than users balance, assign input to be max user balance
        if (amount.gt(userPrincipalTokenBalance)) {
          this.notificationService.showAlertNotification(TOO_LOW_BALANCE);
          this.setBondInputElValue(normalFormat.to(userPrincipalTokenBalance.toNumber()));
          this.bondInput = userPrincipalTokenBalance.toNumber();
          this.resetCheckboxInputs();
          return;
        }

        const bond = this.payload?.bond;

        if (bond) {
          // check if amount is > MIN_BOND_INPUT
          if (amount.lt(MIN_BOND_INPUT)) {
            this.notificationService.showAlertNotification(MIN_BOND_INPUT_ALERT);
            this.resetCheckboxInputs();
            return;
          }

          const maxInput = this.calculationService.calculateInputFromPayoutFor(this.bondMaxPayout, bond.tag);
          const inputValue = +value;

          if (maxInput.gte(inputValue)) {
            this.bondInput = inputValue;
          } else {
            this.notificationService.showAlertNotification(`Input amount can not be greater than ${maxInput} .`);
            this.setBondInputElValue(normalFormat.to(maxInput.toNumber()));
            this.bondInput = maxInput.toNumber();
            this.payoutAmount = this.calculationService.payoutFor(maxInput, bond.tag);
            this.bKarmaPayoutAmount = this.calculationService.calculatebKarmaAmount(this.payoutAmount, bond);
          }


          // fetch payout amount for given users input
          this.scoreApiService.getPayoutFor(this.bondInput, bond).then(payout => {
            log.debug(`Actual payout = ${payout.toString()}`);
            this.payoutAmount = payout;
            this.bKarmaPayoutAmount = this.calculationService.calculatebKarmaAmount(this.payoutAmount, bond);
          }).catch(err => log.error(err));
        }
      } else {
        if (amount.toString() !== "" && !amount.isZero()) {
          this.resetBondInputFields();
          this.notificationService.showErrorNotification(INVALID_INPUT_ALERT);
        }
      }
    }, 600);
  }

  private hideInvalidInputNotification(): void {
    if (this.notificationService.getActiveNotification()?.message === INVALID_INPUT_ALERT ||
        this.notificationService.getActiveNotification()?.message === MIN_BOND_INPUT_ALERT) {
      this.notificationService.hideActiveNotification();
    }
  }

  private hideTooLowBalanceNotification(): void {
    if (this.notificationService.getActiveNotification()?.message === TOO_LOW_BALANCE) {
      this.notificationService.hideActiveNotification();
    }
  }

  private getCustomBondLink(): string {
    return this.payload?.bond.getCustomBondTrackerUrl() ?? "";
  }

  private getPayoutTokenName(): string {
    return this.payload?.bond.baseInfo.payoutTokenName ?? "";
  }

  private getUserPrincipalTokenBalance(): BigNumber {
    if (this.payload?.bond) {
      return (this.payload.bond.principalToken instanceof BalnLpToken) ? this.persistenceService.getUserLpBalance(
          this.payload.bond.principalToken.poolId) : this.persistenceService.getUserIrc2TokenBalance(this.payload.bond.principalToken);
    }

    return ZERO;
  }

  private getShowGetLp(): boolean {
    return this.payload?.bond ?
        (this.payload.bond.principalToken instanceof BalnLpToken)
        && this.persistenceService.getUserLpBalance(this.payload.bond.principalToken.poolId).isZero() : false;
  }

  private getBondVestingTerm(): BigNumber {
    return this.payload?.bond ? this.persistenceService.getBondsVestingTermInSeconds(this.payload.bond.tag) : ZERO;
  }

  private calculateBondRoi(): BigNumber {
    return this.payload?.bond ? this.calculationService.calculateBondDiscountUSD(this.payload.bond) : ZERO;
  }

  private calculateBondPriceUSD(): BigNumber {
    return this.payload?.bond ? this.calculationService.calculateBondPriceUSD(this.payload?.bond) : ZERO;
  }

  private calculateMarketPayoutTokenPrice(): BigNumber {
    return this.payload?.bond ? this.oracleService.getIrc2TokenPrice(this.payload?.bond.payoutToken) : ZERO;
  }

  private isPrincipalIsLp(): boolean {
    return this.payload?.bond.baseInfo.principalIsLpToken ?? false;
  }

  private getPayoutTokenImg(): string {
    return this.payload?.bond.payoutToken.img ?? "";
  }

  private getPrincipalTokenName(): string {
    return this.payload?.bond.baseInfo.principalTokenName ?? "";
  }

  balancedDexUrl(): string {
    return environment.balancedDexUrl;
  }

  hideActiveModal(): void {
    this.hideModalEvent.emit();
  }

  showSignInModal(): void {
    this.showSignInModalEvent.emit();
  }

  showLoadingModal(): void {
    this.showLoadingModalEvent.emit();
  }

  displayModal(): boolean {
    return this._payload !== undefined;
  }

  setBondInputElValue(value: string): void {
    if (this.bondInputEl) {
      this.bondInputEl.nativeElement.value = value;
    }
  }

  setBondCheckboxElValue(value: boolean): void {
    if (this.bondCheckboxEl) {
      this.bondCheckboxEl.nativeElement.checked = value;
    }
  }

  get payload(): BondModalPayload | undefined {
    return this._payload;
  }



}
