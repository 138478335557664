import {Magic, RPCError, RPCErrorCode, SDKError} from "magic-sdk";
import {UserApiService} from "./services/user-api-service";
import {User} from "./models/User/User";
import {extractDidEthAddress, log} from "./common/Utils";
import {Consts} from "./common/consts";
import {AxiosResponse} from "axios";
import {MagicLoginResponse} from "./models/Interfaces/MagicLogin";
import {BridgeError} from "./models/errors/bridgeError";


export async function MagicLogin(magic: Magic, email: string, userApiService: UserApiService): Promise<MagicLoginResponse> {

    log(`Login attempt with email: ${email}`);

    // ERRORS docs: https://docs.magic.link/client-sdk/web#errors--warnings
    try {
        await magic.auth.loginWithMagicLink({
            email: email,
            showUI: false
        });
    } catch (e) {
        let message: string

        if (e instanceof RPCError) {
            switch (e.code) {
                case RPCErrorCode.MagicLinkFailedVerification:
                    message = "Verification of the magic link failed! Check the internet connection."
                    break;
                case RPCErrorCode.MagicLinkExpired:
                    message = "Magic link has expired! You exceed the time of 10 minutes to check your email!"
                    break;
                case RPCErrorCode.MagicLinkRateLimited:
                    message = "Server is overloaded. Please try again later."
                    break;
                case RPCErrorCode.UserAlreadyLoggedIn:
                    message = "User is already logged in! Please log out from another account first."
                    break;
                default:
                    message = "Unknown error occurred. Please try again later."
                    break;
            }

        } else if (e instanceof SDKError) {
            message = "System error occurred. Please contact site maintainer or try again later."
        } else {
            message = "Unknown error occurred. Please try again later."
        }

        throw new BridgeError(message, e)
    }

    try {
        // get DID token and store it in localstorage
        localStorage.setItem("bridgeToken", await magic.user.getIdToken({lifespan: Consts.DID_LIFESPAN}));
    } catch (e) {
        throw new BridgeError("Problem with generating DID token. Try again later.", e)
    }

    let ethAddress: string;
    let iconAddress: string | null;

    let magicUserMetadata;
    try {
        magicUserMetadata = await magic.user.getMetadata();
    } catch (e) {
        throw new BridgeError("Can't retrieve information for the authenticated user.", e)
    }
    ethAddress = extractDidEthAddress(magicUserMetadata.issuer);
    iconAddress = magicUserMetadata.publicAddress;

    // if icon address is null then throw error
    if (!iconAddress) {
        throw new BridgeError("Icon address from Magic service is unavailable! " +
            "Try again later or contact site maintainer.",
            Error("Icon address (magicUserMetadata.publicAddress) is null!"));
    }

    // store the current bridge user in browser storage
    localStorage.setItem("BRIDGE_USER_ICON_ADDRESS", iconAddress);
    localStorage.setItem("BRIDGE_USER_ICON_EMAIL", email);

    // get user data from backend REST API
    let user: User;

    try {
        let userRes: AxiosResponse<User> = await userApiService.getUser();
        user = userRes.data;

        if (user.email != email) {
            log("user.email != email , " + user.email + " != " + email);

            // update email and change user property if success
            await userApiService.updateUserEmail(email)
            user.email = email;
        }
    } catch (e) {
        if (e instanceof BridgeError) {
            if (e.externalError?.response?.status == 404) {
                // create user if it does not exist
                let userRes: AxiosResponse<User> = await userApiService.postUser(new User(email, ethAddress, iconAddress))
                user = userRes.data
            } else {
                throw e
            }
        } else {
            throw new BridgeError(undefined, e)
        }
    }

    return {
        user: user,
        magicUserMetadata: magicUserMetadata,
    };
}
