import {BigNumber} from "bignumber.js";
import {Utils} from "./common/utils";
import {environment} from "../environments/environment";
import {PersistenceService} from "./services/persistence.service";
import {WalletType} from "./models/classes/Wallet";
import {ModalType} from "./models/enums/ModalType";
import {NavItem} from "./models/enums/NavItem";


/*
* Base class to be used as extension in component in order to inherit useful methods
*/
export class BaseClass {

  public WalletType: typeof WalletType = WalletType;
  public ModalType: typeof ModalType = ModalType;
  public NavItem: typeof NavItem = NavItem;

  constructor(public persistenceService: PersistenceService) {
  }

  public delay = (() => {
    let timer: any;
    return (callback: any, ms: any) => {
      clearTimeout (timer);
      timer = setTimeout(callback, ms);
    };
  })();

  userIsLoggedIn(): boolean {
    return this.persistenceService.activeWallet !== undefined;
  }

  public roundDownTo2Decimals(value: BigNumber | string | undefined): string {
    if (!value || !(new BigNumber(value).isFinite())) {
      return "0";
    }
    return Utils.roundDownTo2Decimals(value);
  }

  public roundUpTo2Decimals(value: BigNumber | string | undefined): BigNumber {
    if (!value || !(new BigNumber(value).isFinite())) {
      return new BigNumber("0");
    }
    return Utils.roundUpTo2Decimals(value);
  }

  public roundDownToZeroDecimals(value: BigNumber | string | undefined): string {
    if (!value || !(new BigNumber(value).isFinite())) {
      return "0";
    }
    return Utils.roundDownToZeroDecimals(value);
  }

  public formatNumberToZeroOrPosUSLocaleString(num?: BigNumber | string, defaultZero = false): string {
    if (!num || !(new BigNumber(num).isFinite()) || (+num) <= 0) { return defaultZero ? "0" : "-"; }
    if (typeof num === 'string') {
      return Utils.formatNumberToUSLocaleString(new BigNumber(num));
    } else {
      return Utils.formatNumberToUSLocaleString(num);
    }
  }

  public formatNumberToUSLocaleString(num?: BigNumber | string, defaultZero = false): string {
    if (!num || !(new BigNumber(num).isFinite())) { return defaultZero ? "0" : "-"; }
    if (typeof num === 'string') {
      return Utils.formatNumberToUSLocaleString(new BigNumber(num));
    } else {
      return Utils.formatNumberToUSLocaleString(num);
    }
  }

  public toDollarUSLocaleString(num?: BigNumber | string, defaultZero = false): string {
    if (!num || !(new BigNumber(num).isFinite()) || (+num) <= 0) { return defaultZero ? "0" : "-"; }
    return `$${this.formatNumberToZeroOrPosUSLocaleString(num)}`;
  }

  public to2DecZeroOrPositiveRoundOffPercString(num?: BigNumber | string, defaultZero = false): string {
    if (!num || !(new BigNumber(num).isFinite()) || (+num) <= 0) { return defaultZero ? "0%" : "-"; }

    // convert in to percentage
    num = new BigNumber(num).multipliedBy(new BigNumber("100"));

    // handle values smaller than 0.01%
    if (num < new BigNumber("0.01")) {
      return Utils.handleSmallDecimal(num);
    }

    return `${(this.formatNumberToZeroOrPosUSLocaleString(Utils.roundOffTo2Decimals(num)))}%`;
  }

  public to0DecimalRoundedDownPercentString(num?: BigNumber | string, defaultZero = false): string {
    if (!num || !(new BigNumber(num).isFinite()) || (+num) <= 0) { return defaultZero ? "0%" : "-"; }

    // convert in to percentage
    num = new BigNumber(num).multipliedBy(new BigNumber("100"));

    if (num.isLessThan(1)) {
      return defaultZero ? "0%" : "-";
    }

    return `${(this.formatNumberToZeroOrPosUSLocaleString(Utils.roundDownToZeroDecimals(num)))}%`;
  }

  // hide element if flag is true
  public hideElement(hide: boolean): any {
    return {display: hide ? 'none' : null};
  }

  public hideElementOrDisplayContents(hide: boolean): any {
    return {display: hide ? 'none' : 'contents'};
  }

  public displayAsTableRowOrHide(show: boolean): any {
    return {display: show ? 'table-row' : 'none'};
  }

  public displayAsBlockOrHide(show: boolean): any {
    return {display: show ? 'block' : 'none'};
  }

  public listIsNotNullOrEmpty(list?: any[]): boolean {
    return (list != null && list.length > 0);
  }

  formatIconAddressToShort(address?: string): string {
    return address ? Utils.formatIconAddressToShort(address) : "";
  }

  addClass(htmlElement: any, className: string): void {
    htmlElement.classList.add(className);
  }

  removeClass(htmlElement: any, className: string): void {
    htmlElement.classList.remove(className);
  }

  setText(htmlElement: any, text: string): void {
    htmlElement.textContent = text;
  }

  getText(htmlElement: any): string {
    return htmlElement.textContent ?? "";
  }

  makeAbsolute(value: BigNumber): BigNumber {
    return value.abs();
  }

  isNegative(value: BigNumber): boolean {
    return value.isNegative();
  }

  isProduction(): boolean {
    return environment.production;
  }

  secondsToHumanizedDateString(seconds: number | BigNumber | undefined): string {
    if (!seconds) {
      return "-"
    }

    return Utils.secondsToHumanizedDateString(seconds);
  }

}
