import { Injectable } from '@angular/core';
import {StateChangeService} from "./state-change.service";

@Injectable({
  providedIn: 'root'
})
export class UiStateService {

  /**
   * @description Angular Service used to keep UI state (open/close, modal show/hide, etc..)
   */

  hamMenuOpen = false;

  constructor(private stateChangeService: StateChangeService) {
    this.subscribeToOverlayHideChange();
  }

  subscribeToOverlayHideChange(): void {
    this.stateChangeService.overlayClickedEvent$.subscribe(() => this.hamMenuOpen = false);
  }

  toggleHamMenu(): void {
    this.hamMenuOpen = !this.hamMenuOpen;
  }
}
