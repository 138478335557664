import BigNumber from "bignumber.js";

export class Terms {
  controlVariable: BigNumber;
  maxDebt: BigNumber;
  maxPayout: BigNumber;
  minimumPrice: BigNumber;
  vestingTerm: BigNumber; // in seconds


  constructor(controlVariable: BigNumber, maxDebt: BigNumber, maxPayout: BigNumber, minimumPrice: BigNumber,
              vestingTerm: BigNumber) {
    this.controlVariable = controlVariable;
    this.maxDebt = maxDebt;
    this.maxPayout = maxPayout;
    this.minimumPrice = minimumPrice;
    this.vestingTerm = vestingTerm;
  }
}

// EXAMPLE:
// {
//   "controlVariable": "0x61a80",
//   "maxDebt": "0x10f0cf064dd59200000",
//   "maxPayout": "0x1f4",
//   "minimumPrice": "0x151b",
//   "vestingTerm": "0x49d40"
// }
