<div class="table table-content">
  <div class="table-item table-2-item-1">
    <img src="assets/images/{{principalImg}}" (error)="handlePrincipalImgError($event)" loading="lazy"
         width="{{principalImgWidth}}" alt="" class="table-item-pair-image">
    <div class="table-item-pair-content">
      <div class="table-item-pair-name">{{principalTag}} {{bond?.bondPrincipalIsLp() ? "LP " : ""}}bond</div>
      <div *ngIf="bond?.bondPrincipalIsLp()" class="table-item-pair-subtext">Balanced</div>
    </div>
  </div>
  <div class="table-item mobile-right table-2-item-2">
    <div class="table-item-pair-content table-item-pair-content-my-bonds full-width-col">
      <div class="table-item-pair-name table-item-pair-name-my-bonds full-width-col">
        {{pendingPayoutFor | roundDown2Dec | usFormat | toZeroIfDash}} {{payoutTag}}
      </div>
    </div>
  </div>
  <div class="table-item hide-mobile table-2-item-3">
    <div class="table-item-pair-name">{{payoutAmount | roundDown2Dec | usFormat | toZeroIfDash}} {{payoutTag}}</div>
  </div>
  <div class="table-item hide-mobile table-2-item-4">
    <div class="table-item-pair-name">{{vestingLeftInSeconds?.isZero() ? "Fully vested" : secondsToHumanizedDateString(bond?.vestingLeftInSeconds())}}</div>
  </div>
  <div class="table-item table-2-item-5">
    <a data-w-id="a98553fb-eec2-9bc3-ca82-3493bf4569c6" href="javascript:void(0);" (click)="onClaimClick(bond)" class="button-small button-bond w-inline-block">
      <div>Claim</div>
    </a>
  </div>
</div>
