import { Injectable } from '@angular/core';
import BigNumber from "bignumber.js";
import {PoolStats} from "../../models/classes/PoolStats";
import {PersistenceService} from "../persistence.service";
import {PriceOracleService} from "../price-oracle.service";
import {BondConfig} from "../../models/classes/BondConfigInfo";
import {BondClaimData} from "../../models/classes/BondClaimData";
import {BOND_INPUT_DECIMAL_PRECISION, EXA, PAYOUT_FOR_DECIMALS_PRECISION, PAYOUT_PRECISION} from '../../common/constants';
import {BondTag} from "../../models/types/Types";
import {Utils} from '../../common/utils';
import {BalnLpToken} from '../../models/classes/BalnLpToken';

@Injectable({
  providedIn: 'root'
})
export class CalculationService {

  constructor(private persistenceService: PersistenceService,
              private priceOracleService: PriceOracleService) { }

  /**
   * Calculate price of the token in pool
   * @param poolTokenReserveAmount - Amount of token in pool
   * @param poolTotalSupply - Total pool supply amount
   * @param tokenMarketPriceUSD - Market price of token in USD
   * @return BigNumber - Price of pool token reserve amount in USD
   */
  poolTokenReservePrice(poolTokenReserveAmount: BigNumber, poolTotalSupply: BigNumber, tokenMarketPriceUSD: BigNumber
  ): BigNumber {
    return (poolTokenReserveAmount.dividedBy(poolTotalSupply)).multipliedBy(tokenMarketPriceUSD);
  }

  /**
   * Calculate the bond price of LP token
   * @param poolStats - Amount of token in pool
   * @param baseTokenMarketPrice - USD market price of base token
   * @param quoteTokenMarketPrice - USD market price of quote token
   * @param trueBondPrice - Bond price with Karma fee subtracted // should be normalised by 10**7
   * @return BigNumber - Bond price in USD
   */
  calculateBondPriceForLpToken(poolStats: PoolStats, baseTokenMarketPrice: BigNumber, quoteTokenMarketPrice: BigNumber,
                               trueBondPrice: BigNumber): BigNumber {
    const baseTokenReserveAmount = poolStats.base;
    const quoteTokenReserveAmount = poolStats.quote;
    const poolTotalSupply = poolStats.totalSupply;

    if (baseTokenMarketPrice.eq(-1) || quoteTokenMarketPrice.eq(-1)) {
      return new BigNumber(-1);
    }

    const poolBaseTokenPriceUSD = this.poolTokenReservePrice(baseTokenReserveAmount, poolTotalSupply, baseTokenMarketPrice);
    const poolQuoteTokenPriceUSD = this.poolTokenReservePrice(quoteTokenReserveAmount, poolTotalSupply, quoteTokenMarketPrice);

    // USD price per LP token
    const lpMarketPrice = poolBaseTokenPriceUSD.plus(poolQuoteTokenPriceUSD);
    // log.debug(`${poolStats.name} poolBaseTokenPriceUSD = ${poolBaseTokenPriceUSD}`);
    // log.debug(`${poolStats.name} poolQuoteTokenPriceUSD = ${poolQuoteTokenPriceUSD}`);
    // log.debug(`${poolStats.name} lpMarketPrice = ${lpMarketPrice}`);

    return trueBondPrice.multipliedBy(lpMarketPrice);
  }

  calculateLpMarketUsdPrice(bond: BondConfig): BigNumber {
    if (bond.principalToken instanceof BalnLpToken) {
      const poolStats = this.persistenceService.balancedPoolStatsMap.get(bond.principalToken.poolId);

      const baseTokenMarketPrice = this.priceOracleService.getIrc2TokenPrice(bond.principalToken.baseToken);
      const quoteTokenMarketPrice = this.priceOracleService.getIrc2TokenPrice(bond.principalToken.quoteToken);
      const baseTokenReserveAmount = poolStats?.base;
      const quoteTokenReserveAmount = poolStats?.quote;
      const poolTotalSupply = poolStats?.totalSupply;

      if (baseTokenMarketPrice.eq(-1) || quoteTokenMarketPrice.eq(-1) || !baseTokenReserveAmount || !quoteTokenReserveAmount
        || !poolTotalSupply) {
        return new BigNumber(-1);
      }

      const poolBaseTokenPriceUSD = this.poolTokenReservePrice(baseTokenReserveAmount, poolTotalSupply, baseTokenMarketPrice);
      const poolQuoteTokenPriceUSD = this.poolTokenReservePrice(quoteTokenReserveAmount, poolTotalSupply, quoteTokenMarketPrice);

      // USD price per LP token
      return poolBaseTokenPriceUSD.plus(poolQuoteTokenPriceUSD);
    } else {
     return Utils.ZERO;
    }
  }

  /**
   * Calculate the bond price of LP token
   * @param principalTokenMarketPriceUSD - Market price of principal token in USD
   * @param trueBondPrice - Bond price with Karma fee subtracted // should be normalised by 10**7
   * @return BigNumber - Bond price in USD
   */
  calculateBondPriceForIrc2Token(principalTokenMarketPriceUSD: BigNumber, trueBondPrice: BigNumber): BigNumber {
    return trueBondPrice.multipliedBy(principalTokenMarketPriceUSD);
  }

  /**
   * Calculate bond discount using following equation: bondDiscount = (marketPrice - bondPrice) / marketPrice
   * @param bondPriceUSD - Bond price converted in USD
   * @param payoutTokenMarketPriceUSD - Market price of payout token in USD
   * @return BigNumber - Discount in percentage
   */
  calculateBondDiscount(bondPriceUSD: BigNumber, payoutTokenMarketPriceUSD: BigNumber): BigNumber {
    if (payoutTokenMarketPriceUSD.isEqualTo(bondPriceUSD)) {
      return new BigNumber(0);
    }

    return (payoutTokenMarketPriceUSD.minus(bondPriceUSD)).dividedBy(payoutTokenMarketPriceUSD);
  }

  // /**
  //  * Calculate roi using bond discount and following equation: 1 / (1 - bondDiscount)
  //  * @param bond - Bond for which we are calculating the roi for
  //  * @return BigNumber - Discount in percentage
  //  */
  // calculateBondRoi(bond: BondConfig): BigNumber {
  //   const bondDiscount = this.calculateBondDiscountUSD(bond);
  //   // log.debug("calculateBondRoi:")
  //   // log.debug("bondDiscount=" + bondDiscount.toString());
  //   return (new BigNumber(1).dividedBy(new BigNumber(1).minus(bondDiscount)))
  // }

  calculateBondPriceUSD(bond: BondConfig): BigNumber {
    const trueBondPrice = this.persistenceService.getBondsTruePrice(bond.tag);

    if (bond.principalToken instanceof BalnLpToken) {
      const poolStats = this.persistenceService.balancedPoolStatsMap.get(bond.principalToken.poolId);

      if (!poolStats) { return new BigNumber(-1); }

      const quoteAsset = bond.principalToken.quoteToken;
      const baseAsset = bond.principalToken.baseToken;
      const baseTokenMarketPrice = this.priceOracleService.getIrc2TokenPrice(baseAsset);
      const quoteTokenMarketPrice = this.priceOracleService.getIrc2TokenPrice(quoteAsset);

      return this.calculateBondPriceForLpToken(poolStats, baseTokenMarketPrice, quoteTokenMarketPrice, trueBondPrice);
    } else {
      console.log("calculateBondPriceUSD..");
      const principalTokenMarketPrice = this.priceOracleService.getIrc2TokenPrice(bond.principalToken);
      console.log(`principalTokenMarketPrice: ${principalTokenMarketPrice}`);

      if (principalTokenMarketPrice.eq(-1) || trueBondPrice.isZero()) { return new BigNumber(-1); }

      return this.calculateBondPriceForIrc2Token(principalTokenMarketPrice, trueBondPrice)
    }
  }

  /**
   * @description payoutTokenTotalSupply * terms.maxPayout / 10**DECIMALS_PRECISION
   */
  calculateMaxPayout(payoutTokenTotalSupply: BigNumber, termsMaxPayout: BigNumber): BigNumber {
    return (payoutTokenTotalSupply.multipliedBy(termsMaxPayout)).dividedBy(10**5)
  }

  calculateBondDiscountUSD(bond: BondConfig): BigNumber {
    const bondPriceUSD = this.calculateBondPriceUSD(bond);

    if (bondPriceUSD.eq(-1)) {
      return new BigNumber(-1);
    }

    const payoutTokenMarketPriceUSD = this.priceOracleService.getIrc2TokenPrice(bond.payoutToken);

    return this.calculateBondDiscount(bondPriceUSD, payoutTokenMarketPriceUSD);
  }

  calculateClaimBondDiscountUSD(bond: BondClaimData): { bondDiscount: BigNumber, bondPriceUSD: BigNumber } {
    const trueBondPrice = bond.bondInfo.truePricePaid
    const payoutTokenMarketPriceUSD = this.priceOracleService.getIrc2TokenPrice(bond.bond.payoutToken);

    if (bond.bond.principalToken instanceof BalnLpToken) {
      const poolStats = this.persistenceService.balancedPoolStatsMap.get(bond.bond.principalToken.poolId);

      if (!poolStats) {
        return { bondDiscount: new BigNumber(0), bondPriceUSD: new BigNumber(0) };
      }

      const quoteAsset = bond.bond.principalToken.quoteToken;
      const baseAsset = bond.bond.principalToken.baseToken;
      const baseTokenMarketPrice = this.priceOracleService.getIrc2TokenPrice(baseAsset);
      const quoteTokenMarketPrice = this.priceOracleService.getIrc2TokenPrice(quoteAsset);

      const bondPriceUSD = this.calculateBondPriceForLpToken(poolStats, baseTokenMarketPrice, quoteTokenMarketPrice, trueBondPrice);
      const bondDiscount = this.calculateBondDiscount(bondPriceUSD, payoutTokenMarketPriceUSD);
      return { bondDiscount, bondPriceUSD }
    } else {
      const principalTokenMarketPrice = this.priceOracleService.getIrc2TokenPrice(bond.bond.principalToken);
      const bondPriceUSD = this.calculateBondPriceForIrc2Token(principalTokenMarketPrice, trueBondPrice);
      const bondDiscount = this.calculateBondDiscount(bondPriceUSD, payoutTokenMarketPriceUSD);
      return { bondDiscount, bondPriceUSD }
    }
  }

  /**
   * Calculate how much bKarma will user receive based off quoted payout token amount and Karma fee taken)
   * payoutFor = total - (total * currentKarmaFee() / 10**PAYOUT_PRECISION)
   */
  public calculatebKarmaAmount(quotedPayout: BigNumber, bond: BondConfig): BigNumber {
    const tokenMarketPrice = this.priceOracleService.getIrc2TokenPrice(bond.payoutToken);
    const karmaFeeForBond = this.persistenceService.getCurrentKarmaBondFee(bond.tag);

    return quotedPayout.multipliedBy(tokenMarketPrice).multipliedBy(karmaFeeForBond.dividedBy(1000000)).dp(0);
  }


  /**
   * Calculate user's interest due for new bond, accounting for Karma Fee
   * payoutFor = total - (total * currentKarmaFee() / 10**PAYOUT_PRECISION)
   */
  public payoutFor(value: BigNumber, bondTag: BondTag): BigNumber {
    const bondPrice = this.persistenceService.getBondsPrice(bondTag)
    const karmaFee = this.persistenceService.getCurrentKarmaBondFee(bondTag);

    // ZERO check
    if (bondPrice.isZero() || karmaFee.isZero()) return new BigNumber(0);

    const total = this._payoutFor(value, bondPrice);

    return (total.minus((total.multipliedBy(karmaFee)).dividedBy(new BigNumber(10).pow(PAYOUT_PRECISION))))
      .dividedBy(new BigNumber(10).pow(PAYOUT_FOR_DECIMALS_PRECISION)).dp(0);

  }

  // Add Karma fee of bond to the payoutFor amount
  public addKarmaFeeToPayoutFor(payoutFor: BigNumber, bondTag: BondTag): BigNumber {
    const karmaFee = this.persistenceService.getCurrentKarmaBondFee(bondTag);

    return payoutFor.plus(payoutFor.multipliedBy(Utils.karmaFeeToPercent(karmaFee)));
  }

  /**
   * Calculate total interest due for new bond
   *  result = ((value * 10**18) / bondPrice()) / 10**TOTAL_PAYOUT_PRECISION
   */
  private _payoutFor(value: BigNumber, bondPrice: BigNumber): BigNumber {
    return ((value.multipliedBy(EXA)).dividedBy(bondPrice)).dividedBy(new BigNumber(10).pow(PAYOUT_PRECISION))
  }

  public calculateInputFromPayoutFor(value: BigNumber, bondTag: BondTag): BigNumber {
    const bondPrice = this.persistenceService.getBondsPrice(bondTag)
    const karmaFee = this.persistenceService.getCurrentKarmaBondFee(bondTag);

    // ZERO check
    if (bondPrice.isZero() || karmaFee.isZero()) return new BigNumber(0);

    value = value.multipliedBy(new BigNumber(10).pow(PAYOUT_FOR_DECIMALS_PRECISION));
    const inputForPayout = this._inputForPayout(value, bondPrice);

    return inputForPayout.plus(inputForPayout.dividedBy(new BigNumber(10).pow(PAYOUT_PRECISION))).dp(BOND_INPUT_DECIMAL_PRECISION);

  }

  /**
   * Calculate input for provided payout for value
   *  value = payoutFor * 10**TOTAL_PAYOUT_PRECISION * bondPrice() / 10**18
   *  payoutFor = ((input * 10**18) / bondPrice()) / 10**TOTAL_PAYOUT_PRECISION
   */
  private _inputForPayout(payoutFor: BigNumber, bondPrice: BigNumber) {
    return payoutFor.multipliedBy(new BigNumber(10).pow(PAYOUT_PRECISION)).multipliedBy(bondPrice).dividedBy(EXA);
  }
}
