export class Consts {

    public static RETRY_ATTEMPT_LIMIT = 10;

    // DID token lifespan = 900s =15min
    public static DID_LIFESPAN = 900;

    // TITLES
    public static spinnerLoggingInTitle = "Logging you in safely..";
    public static spinnerTransferingTitle = "Transferring stable coins...";
    public static success = "Success!";
    public static bridgeButtonSignInTitle = "Sign in with Bridge";
    public static bridgeButtonTitle = "Bridge";

    // EVENTS
    public static SEND_TRANSACTION_EVENT = "bri.send.tx";
    public static BRIDGE_TRANSACTION_RESULT = "bri.tx.result";
    public static BRIDGE_WIDGET_ACTION = "bri.widget";
    public static BRIDGE_WIDGET_ACTION_RESPONSE = "bri.widget.res";
}
