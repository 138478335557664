import {BondConfig} from "./BondConfigInfo";
import BigNumber from "bignumber.js";
import {ActionPayloadI} from "../interfaces/ActionPayloadI";

export class BondActionPayload implements ActionPayloadI {
  bond: BondConfig;
  inputAmount: BigNumber;
  payoutAmount: BigNumber;
  bKarmaAmount: BigNumber
  vestingTerm: BigNumber;
  trueBondPrice: BigNumber;

  constructor(bond: BondConfig, inputAmount: BigNumber, payoutAmount: BigNumber, vestingTerm: BigNumber, bKarmaAmount: BigNumber,
              trueBondPrice: BigNumber) {
    this.bond = new BondConfig(bond.baseInfo, bond.principalToken, bond.payoutToken);
    this.inputAmount = new BigNumber(inputAmount);
    this.payoutAmount = new BigNumber(payoutAmount);
    this.vestingTerm = new BigNumber(vestingTerm);
    this.bKarmaAmount = new BigNumber(bKarmaAmount);
    this.trueBondPrice = new BigNumber(trueBondPrice);
  }

  successMessage(): string {
    return `Successfully bonded ${this.inputAmount.toString()} ${this.bond.principalToken} ${this.bond.isPrincipalLp() ? "LP":""} tokens.`
  }

  errorMessage(): string {
    return "Failed to bond. Please try again in a moment."
  }
}
