<div class="heading-section wf-section">
  <div class="container w-container">
    <h1 class="heading-2">Custom Treasury stats</h1>
  </div>
</div>

<div class="container w-container">
  <div class="form-block w-form" style="padding-bottom: 25px; max-width: 600px">
    <form id="bond-form" name="bond-form" data-name="Bond form" class="form" (submit)="handleAddressInputSubmit($event)">
      <div class="bond-input-wrap">
        <input type="text" class="text-field w-input" maxlength="42" #addressInput (keyup)="onKeyUp()" name="name" data-name="Name" placeholder="Address" id="name" autocomplete="true">
      </div>
      <input type="submit" value="Search" data-wait="Please wait..." class="button button-bond w-button">

      <a data-w-id="651d27fc-1073-90da-0591-fc8a79631926" href="javascript:void(0);" (click)="onResetClick()" class="button button-gradient w-button" style="margin-left: 15px">
        <div class="button-content">
          <div class="text-block">Reset</div>
        </div>
      </a>
    </form>
    <div class="w-form-done">
      <div>Thank you! Your submission has been received!</div>
    </div>
    <div class="w-form-fail">
      <div>Oops! Something went wrong while submitting the form.</div>
    </div>
  </div>
</div>


<div class="pairs-section wf-section">
  <div class="container w-container">
    <div class="table table-header">
      <div class="table-header-item table-header-item-col-4 ">
        <div class="table-header-item-text">Bonds</div>
      </div>
      <div class="table-header-item table-header-item-col-4 ">
        <div class="table-header-item-text roi">
          <span class="cursor-pointer">PAY OUT TOKEN</span>
          <img src="assets/images/tooltip-icon.svg" class="tooltip-icon">
          <span class="roi-tooltip">
            Payout out token is the token being vested to the user when principal token is bonded.
            (e.g. user bonds OMM/IUSDC LP token - principal in return for OMM token - payout which gets vested to the user)
          </span>
        </div>
      </div>
      <div class="table-header-item table-header-item-col-4 ">
        <div class="table-header-item-text roi">
          <span class="cursor-pointer">PRINCIPAL TOKEN</span>
          <img src="assets/images/tooltip-icon.svg" class="tooltip-icon">
          <span class="roi-tooltip">
            Principal token is the token user exchanges/bonds in return for payout token
            (e.g. user bonds OMM/IUSDC LP token - principal in return for OMM token - payout.)
            </span>
        </div>
      </div>
    </div>
    <ng-container *ngFor="let bond of bonds; last as isLast; let i = index">
      <app-bond-stats-row [bond]="bond" [ngStyle]="{ display: 'contents' }"></app-bond-stats-row>
    </ng-container>
  </div>
</div>
