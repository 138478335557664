import {Component, OnDestroy, OnInit} from '@angular/core';
import {ModalService} from "../../services/modal.service";
import {BaseClass} from "../../base-class";
import {PersistenceService} from "../../services/persistence.service";
import {StateChangeService} from "../../services/state-change.service";
import {AppConfigService} from "../../services/app-config.service";
import {BondClaimData} from "../../models/classes/BondClaimData";
import log from "loglevel";
import {Subscription} from 'rxjs';
import {DataLoaderService} from '../../services/data-loader.service';
import {NotificationService} from '../../services/notification.service';


@Component({
  selector: 'app-my-bonds',
  templateUrl: './my-bonds.component.html'
})
export class MyBondsComponent extends BaseClass implements OnInit, OnDestroy {

  public userBondClaimData: BondClaimData[] = [];

  userDataSub?: Subscription;

  userDataIntervalTime = 10000; // 10 sec
  userDataInterval?: NodeJS.Timeout;

  constructor(private modalService: ModalService,
              public override persistenceService: PersistenceService,
              private stateChangeService: StateChangeService,
              private appConfigService: AppConfigService,
              private dataLoaderService: DataLoaderService,
              private notificationService: NotificationService) {
    super(persistenceService);
  }

  ngOnInit(): void {
    this.loadUsersBonds();
    this.subscribeToUserDataReloadUpdate();

    // init core data interval
    this.userDataInterval = setInterval(() => this.triggerReloadOfUserData(), this.userDataIntervalTime);
  }

  ngOnDestroy(): void {
    // un-subscribe from user data reload subscription
    this.userDataSub?.unsubscribe();

    // clear user data reload interval when component is destroyed
    if (this.userDataInterval) {
      clearInterval(this.userDataInterval);
    }
  }

  triggerReloadOfUserData(): void {
    // only trigger if no modal is active and browser tab is active
    if (!this.modalService.activeModal && !document.hidden) {
      this.dataLoaderService.loadUserData();
    }
  }

  subscribeToUserDataReloadUpdate(): void {
    // load claimable bonds after user data has finished loading
    this.stateChangeService.userDataFinishedLoading$.subscribe(() => {
      if (!this.modalService.activeModal && !document.hidden) {
        this.loadUsersBonds();
      }
    })
  }

  loadUsersBonds(): void {
    log.debug("loadUsersBonds...");

    // clear old bond data
    const tmp: BondClaimData[] = [];

    this.appConfigService.getSupportedBonds().concat(this.appConfigService.pastBondConfigs).forEach(bond => {
      log.debug("bond:" + bond.tag);
      const pendingPayoutFor = this.persistenceService.getPendingPayoutFor(bond.tag);
      const percentVestedFor = this.persistenceService.getPercentVestedFor(bond.tag);
      const bondInfo = this.persistenceService.userBondTagToBondInfoMap.get(bond.tag);
      log.debug("pendingPayoutFor:" + pendingPayoutFor?.toString());
      log.debug("percentVestedFor:" + percentVestedFor?.toString());
      log.debug("bond vesting term:", bondInfo?.vesting.toString());
      log.debug("bondInfo:", bondInfo);

      if (bondInfo)
        tmp.push(new BondClaimData(pendingPayoutFor, percentVestedFor, bondInfo, bond));
    });

    this.userBondClaimData = tmp;
  }

  onRefreshClick(): void {
    this.dataLoaderService.loadUserData().then(() => {
      this.notificationService.showSuccessNotification("Successfully reloaded user data.");
    });
  }

}
