import {BridgeTransaction} from "./BridgeTransaction";
import {IcxTransaction} from "./IcxTransaction";
import {Irc2TokenTransaction} from './Irc2TokenTransaction';

export class Transactions {
    bridgeTransactions: BridgeTransaction[];
    icxTransactions: IcxTransaction[];
    iusdcTransactions: Irc2TokenTransaction[];
    sicxTransactions: Irc2TokenTransaction[];
    ommTransactions: Irc2TokenTransaction[];

    constructor(bridgeTransactions: BridgeTransaction[], icxTransactions: IcxTransaction[],
        iusdcTransactions: Irc2TokenTransaction[], sicxTransactions: Irc2TokenTransaction[],
        ommTransactions: Irc2TokenTransaction[]) {
        this.bridgeTransactions = bridgeTransactions;
        this.icxTransactions = icxTransactions;
        this.iusdcTransactions = iusdcTransactions;
        this.sicxTransactions = sicxTransactions;
        this.ommTransactions = ommTransactions;
    }
}
