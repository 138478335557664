import {BalnLpTag} from '../types/Types';
import {Irc2Token} from './Irc2Token';

export class BalnLpToken {
  tag: BalnLpTag; // e.g. USDb
  poolId: number;
  baseToken: Irc2Token;
  quoteToken: Irc2Token;
  img: string;

  constructor(tag: BalnLpTag, poolId: number, baseToken: Irc2Token, quoteToken: Irc2Token, img: string) {
    this.tag = tag;
    this.poolId = poolId;
    this.baseToken = baseToken;
    this.quoteToken = quoteToken;
    this.img = img;
  }
}

export enum LpName {
  BALN_bnUSD = "BALN/bnUSD",
  BALN_sICX = "BALN/sICX",
  OMM_IUSDC = "OMM/IUSDC",
  OMM_sICX = "OMM/sICX",
  OMM_USDS = "OMM/USDS",
  CFT_sICX = "CFT/sICX",
  GBET_bnUSD = "GBET/bnUSD"
}

// export class LpTag {
//   static BALN_bnUSD = "BALN/bnUSD";
//   static BALN_sICX = "BALN/sICX";
//   static OMM_IUSDC = "OMM/IUSDC";
//   static OMM_sICX = "OMM/sICX";
//   static OMM_USDS = "OMM/USDS";
//   static CFT_sICX = "CFT/sICX";
//   static BET_bnUSD = "GBET/bnUSD";
//
//   static fromString(value: string): LpTag {
//     // string should be in format "token1/token2"
//     const key = value.replace("/", "_");
//     return LpTag[key as keyof typeof LpTag];
//   }
//
//   static getBaseToken(poolName: string): TokenTag {
//     return poolName.split("/")[0];
//   }
//
//   static getQuoteToken(poolName: string): TokenTag {
//     return poolName.split("/")[1];
//   }
//
// }




