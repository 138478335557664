export enum ModalType {
  SIGN_IN,
  BOND,
  BOND_SUCCESS,
  WALLET,
  CLAIM,
  CLAIM_SUCCESS,
  LOADING,
  LEDGER,
  TOGGLE_BOND,
  TOGGLE_BOND_SUCCESS,
  WITHDRAW_PAYOUT,
  WITHDRAW_PAYOUT_SUCCESS,
  WITHDRAW_PRINCIPAL,
  WITHDRAW_PRINCIPAL_SUCCESS,
  TOP_UP_PAYOUT,
  TOP_UP_PAYOUT_SUCCESS
}

