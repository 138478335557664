import {customElement, html, LitElement, property} from 'lit-element';
import {BigNumber} from 'bignumber.js';
// css styles
// @ts-ignore
import mainCSS from '../assets/css/main.css';
// @ts-ignore
import normalizeCSS from '../assets/css/normalize.css';

import {hideModalView, hideSpan, showSpan} from "./logic/view-transitions";
import {formatTxAmount} from './logic/utils';
import {BridgeService} from "../lib/BridgeService";
import {User} from "../lib/models/User/User";
import {validateEmail} from "../lib/validation/account-validation";
import {icxValueToNormalValue, log} from "../lib/common/Utils";
import {Irc2TokenTransaction} from "../lib/models/Transaction/Irc2TokenTransaction";
import {
  formatAndFilterBridgeTransactions,
  formatAndFilterIcxTransactions,
  getTransactionName,
  tokenTransactionTypeToClass,
  transactionTypeToClass,
  formatAndFilterIrc2Transactions
} from "../lib/common/Mapping";
import {environment} from "../environment/environment";
import {BridgeTransaction, TransactionType} from "../lib/models/Transaction/BridgeTransaction";

import {Consts} from "../lib/common/consts";
import {CodeLists} from "../lib/common/code-lists";
import {MagicLoginResponse} from "../lib/models/Interfaces/MagicLogin";
import {MagicUserMetadata, RPCError, RPCErrorCode} from "magic-sdk";
import {BridgeError} from "../lib/models/errors/bridgeError";
import {NotificationType} from "../lib/models/Enums/NotificationType";

// @ts-ignore
import bridgeLogo from "../assets/img/logo/logo.png";
// @ts-ignore
import checkEmailSvg from '../assets/img/icon/check-email.svg';
// @ts-ignore
import signInSuccessSvg from '../assets/img/icon/sign-in-successful.svg';
// @ts-ignore
import signingInSvg from "../assets/img/icon/browser-lock.svg"
// @ts-ignore
import signInExpiredSvg from "../assets/img/icon/error.svg"
// @ts-ignore
import cardSvg from "../assets/img/icon/card.svg"
// @ts-ignore
import bankSvg from "../assets/img/icon/bank.svg";
// @ts-ignore
import kycUserWaitSvg from "../assets/img/icon/kyc-user-wait.svg";


// child custom elements
import "./child-elements/send-token";
import "./child-elements/deposit";
import "./child-elements/withdraw";
import {SupportedTokens, Token, Tokens, TokenRates} from "../lib/models/Tokens/Tokens";
import {Transactions} from "../lib/models/Transaction/Transactions";
import {IcxTransaction, IcxTransactions} from "../lib/models/Transaction/IcxTransaction";
import {AxiosResponse} from "axios";
import {WidgetAction} from "../lib/models/Enums/WidgetAction";
import errorLogger from './services/errorLogger';

const QRCode = require('qrcode');

// intervals for reloading data
let baseInterval: any;
// intervals for checking valid user session
let sessionCheckInterval: any;


@customElement('icon-bridge-widget')
export class IconBridgeWidget extends LitElement {
  @property() private hideButton: boolean = false;
  @property() private sessionExpired: boolean = false;

  //Elements
  @property() private mainModalWrapper: HTMLElement | null | undefined;
  @property() private overlay: HTMLElement | null | undefined;
  @property() private menuTooltipMain: HTMLElement | null | undefined;
  @property() private menuTooltipEmail: HTMLElement | null | undefined;

  @property() private animationUnderline: HTMLElement | null | undefined;
  @property() private inputEmail : HTMLInputElement | undefined;
  @property() private changeEmail : HTMLInputElement | undefined;

  // modal views
  @property() private signInModalView : HTMLElement | null | undefined;
  @property() private homeModalView : HTMLElement | null | undefined;
  @property() private checkEmailModalView : HTMLElement | null | undefined;
  @property() private processingView: HTMLElement | null | undefined;
  @property() private signingInView: HTMLElement | null | undefined;
  @property() private errorView: HTMLElement | null | undefined;
  @property() private transactionDetailView: HTMLElement | null | undefined;

  // custom child element views
  @property() private sendTokenElemView: HTMLElement | null | undefined;
  @property() private depositElemView: HTMLElement | null | undefined;
  @property() private withdrawElemView: HTMLElement | null | undefined;

  // global variables
  @property() private activeModalView: HTMLElement | null | undefined;
  @property() private notification: HTMLElement | null | undefined;


  // let emailInput: HTMLElement | null;
  @property() private emailError : HTMLElement | null | undefined;

  @property() private bridge:BridgeService;

  @property({}) private emailInput: string;

  @property({}) private emailErrMsg: string;

  @property() private transactions: Transactions = new Transactions([], [], [], [], []);
  @property() private selectedTransaction: BridgeTransaction | IcxTransaction | Irc2TokenTransaction | undefined;

  // logged in User
  @property() private user: User | undefined;
  @property() private userMagicMetadata: MagicUserMetadata | undefined;

  @property() private userTokensMap: Map<string,Token> = new Map<SupportedTokens, Token>();
  @property() private selectedToken: Token | undefined;
  @property() private tokenRates: TokenRates;

  @property() private processingTitle: string;
  @property() private processingSvg: any;

  @property() private error: string = "";
  @property() private notificationMessage: string = "";
  @property() private previousView: HTMLElement | undefined | null;
  @property() private viewAddressView: HTMLElement | undefined | null;
  @property() private qrCodeContainer: HTMLElement | undefined | null;

  constructor() {
    super();
    this.bridge = new BridgeService();

    this.tokenRates = { USDS: '-', IUSDC: '-', sICX: '-', ICX: '-', OMM: '-' }
    this.emailInput = "";
    this.emailErrMsg = "";
    this.processingTitle = Consts.spinnerLoggingInTitle;
    this.processingSvg = cardSvg;
    const fontLinkTag = document.createElement('link');
    fontLinkTag.rel = 'stylesheet';
    fontLinkTag.type = 'text/css';
    fontLinkTag.href = 'https://bridgefont.s3.us-east-2.amazonaws.com/fonts.css';
    document.body.appendChild(fontLinkTag);
  }

  private resetProperties() {
    this.bridge = new BridgeService();
    this.emailErrMsg = "";
    this.user = undefined;
    this.processingTitle = Consts.spinnerLoggingInTitle;
    this.processingSvg = cardSvg;
    this.userTokensMap = new Map<string, Token>();
  }

  static styles = [
    mainCSS,
    normalizeCSS
  ];


  protected firstUpdated(_changedProperties:any): void {
    super.firstUpdated(_changedProperties);
    this.mainModalWrapper = this.shadowRoot!.getElementById("bridge-modal");
    this.overlay = this.shadowRoot!.getElementById("overlay");
    this.processingView = this.shadowRoot!.getElementById("processing-view");
    this.signInModalView = this.shadowRoot!.getElementById("sign-in");
    this.signingInView = this.shadowRoot!.getElementById("signing-you-in")
    this.errorView = this.shadowRoot!.getElementById("error");
    this.checkEmailModalView = this.shadowRoot!.getElementById("check-email");
    this.homeModalView = this.shadowRoot!.getElementById("home");
    this.transactionDetailView = this.shadowRoot!.getElementById("transaction-detail");
    this.notification = this.shadowRoot!.getElementById("notification");

    this.qrCodeContainer = this.shadowRoot!.getElementById("qr-code-container");
    this.viewAddressView = this.shadowRoot!.getElementById("view-address");

    this.emailError = this.shadowRoot!.getElementById("email-error");
    this.inputEmail = <HTMLInputElement>this.shadowRoot!.getElementById("input-email");
    this.changeEmail = <HTMLInputElement>this.shadowRoot!.getElementById("change-email");

    this.menuTooltipMain = this.shadowRoot!.getElementById("menu-tooltip");
    this.menuTooltipEmail = this.shadowRoot!.getElementById("menu-tooltip-email");

    this.animationUnderline = this.shadowRoot!.getElementById("animation-underline");

    // custom elemens view
    this.sendTokenElemView = this.shadowRoot!.getElementById("send-token-elem");
    this.depositElemView = this.shadowRoot!.getElementById('deposit-elem');
    this.withdrawElemView = this.shadowRoot!.getElementById('withdraw-elem');


    document.addEventListener('bri.notify', (e: any) => {
      log(e.detail.message);
      log("Event bri.notify FIRED!");
      this.showNotification(e.detail.message, e.detail.type);
    });
  }

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener(Consts.BRIDGE_WIDGET_ACTION, (e: any) => {
      log("Event bri.widget FIRED!");
      log("Event:", e.detail.action);
      this.handleBriWidgetEvent(e.detail.action);
    });
  }


  private async handleBriWidgetEvent(action: WidgetAction) {
    switch (action) {
      case WidgetAction.OPEN:
        this.onBridgeButtonClick();
        window.dispatchEvent(new CustomEvent(Consts.BRIDGE_WIDGET_ACTION_RESPONSE, {
          detail:{
            success: WidgetAction.OPEN ,
          }
        }));
        break;
      case WidgetAction.CLOSE:
        this.onOverlayClick();
        window.dispatchEvent(new CustomEvent(Consts.BRIDGE_WIDGET_ACTION_RESPONSE, {
          detail:{
            success: WidgetAction.CLOSE ,
          }
        }));
        break;
      case WidgetAction.LOGOUT:
        this.signOut()
        break;
      case WidgetAction.REFRESH_BALANCE:
        let detail = {};
        if(this.user) {
          await this.refreshBaseData();
          detail = { success: WidgetAction.REFRESH_BALANCE }
        } else {
          detail = { error: WidgetAction.REFRESH_BALANCE }
        }
        window.dispatchEvent(new CustomEvent(
          Consts.BRIDGE_WIDGET_ACTION_RESPONSE, 
          {
            detail
          }
        ));
        break;
      default:
        log("bri.widget wrong action: ", action);
        window.dispatchEvent(new CustomEvent(Consts.BRIDGE_WIDGET_ACTION_RESPONSE, {
          detail:{
            error: "Incorrect widget action, must be on of ['open', 'close']" ,
          }
        }));
    }
  }

  private showModalView(modalView: HTMLElement | null | undefined) {
    // do not set processing views as previous view to go back to after error
    if (this.activeModalView != this.processingView && this.activeModalView != this.signingInView && this.activeModalView != this.checkEmailModalView
        && this.activeModalView != this.errorView) {
      this.previousView = this.activeModalView;
    }
    hideModalView(this.activeModalView);
    modalView!.style.display = "block";

    // clear reload interval if switching from home view to another
    if (modalView != this.homeModalView) {
      this.clearIntervals();
    }
    this.setActiveModalView(modalView);
  }

  private setActiveModalView(newActiveModal: HTMLElement | null | undefined) {
    this.activeModalView = newActiveModal;
    // turn on reload interval if switched to home modal view
    if (newActiveModal == this.homeModalView) {
      this.establishIntervals();
    }
    this.activeModalView?.scrollTo({ top: 0});
  }

  private handleSessionExpiry() {
    this.sessionExpired = true;
    this.selectedToken = undefined;
    window.dispatchEvent(new CustomEvent('bri.session', {detail: 'Session expired'}));
    this.resetProperties();
    this.showModalView(this.signInModalView);
  }

  private async checkUserSession(): Promise<boolean> {
    if(await this.bridge.userIsLoggedIn()) {
      if(!this.userMagicMetadata) {
        try {
          this.userMagicMetadata = await this.bridge.getLoggedInUsersMagicMetadata();
        } catch (e) {
          console.error(e);
          this.handleSessionExpiry();
          return false;
        }
      }
      return true;  // session persists
    } else {
      this.handleSessionExpiry();
      return false;
    }
  }

  private async refreshBaseData() {
    await this.loadTokenPrice();
    await this.loadAllUserTokens();
    await this.loadIcxTransactions();
    await this.loadIrc2Transactions();

    // Refresh token balance on token view modal as well
    if(this.selectedToken) {
      this.selectedToken = this.userTokensMap.get(this.selectedToken.tag);
    }
  }

  private establishIntervals() {
    baseInterval = setInterval(() => this.refreshBaseData(), 10000); // 10 sec
    // primeTrustRelatedInterval = setInterval(() => this.refreshPrimeTrustData(), 60000); // 60 sec
  }

  private clearIntervals() {
    clearInterval(baseInterval);
    // clearInterval(primeTrustRelatedInterval);
  }

  private addSessionCheckIntervals() {
    sessionCheckInterval = setInterval(() => this.checkUserSession(), 300000); // 5 mins
  }

  private clearSessionCheckIntervals() {
    clearInterval(sessionCheckInterval);
  }

  private async loadUserUsdsBalance() {
    const res = await this.bridge.getIrc2TokenBalance(environment.BRIDGE_SCORE_ADDRESS);
    this.userTokensMap!.get(SupportedTokens.USDS)!.balance = icxValueToNormalValue(res, Tokens.supportedTokensMap.get(SupportedTokens.USDS)!['decimals']);
  }

  private async loadUserToken(tokenTag: SupportedTokens) : Promise<Token> {
    const token = Tokens.supportedTokensMap.get(tokenTag)!;
    let newToken = new Token(token.tag, token.name, token.logo, token.scoreAddress, token.decimals);
      if (token.tag == SupportedTokens.ICX) {
        newToken.balance = icxValueToNormalValue(await this.bridge.getIcxBalance(), token.decimals);
        if(this.tokenRates.ICX instanceof BigNumber) {
          newToken.usdBalance = this.tokenRates.ICX.multipliedBy(newToken.balance).toNumber();
        }
      } else {
        const res = await this.bridge.getIrc2TokenBalance(token.scoreAddress);
        newToken.balance = icxValueToNormalValue(res, token.decimals);  
        if(newToken.tag == SupportedTokens.sICX && this.tokenRates.sICX instanceof BigNumber) {
          newToken.usdBalance = this.tokenRates.sICX.multipliedBy(newToken.balance).toNumber()
        }
        if(newToken.tag == SupportedTokens.IUSDC && this.tokenRates.IUSDC instanceof BigNumber) {
          newToken.usdBalance = this.tokenRates.IUSDC.multipliedBy(newToken.balance).toNumber()
        }
        if(newToken.tag == SupportedTokens.OMM && this.tokenRates.OMM instanceof BigNumber) {
          newToken.usdBalance = this.tokenRates.OMM.multipliedBy(newToken.balance).toNumber()
        }
        if(newToken.tag == SupportedTokens.USDS) {
          newToken.usdBalance = newToken.balance;
        }
      }
      return newToken;
  }

  private async loadAllUserTokens() {
    let tokensMap: Map<string,Token> = new Map<SupportedTokens, Token>();
    let newToken;
    for (const token of Tokens.supportedTokensMap.values()) {
        newToken = await this.loadUserToken(token.tag);
        tokensMap.set(token.tag, newToken);
    }
    this.userTokensMap = tokensMap;
  }

  private async loadTokenPrice() {
    const icxRate = await this.bridge.getTokenPrice(SupportedTokens.ICX);
    const sicxRate = await this.bridge.getTokenPrice(SupportedTokens.sICX);
    const iusdcRate = await this.bridge.getTokenPrice(SupportedTokens.IUSDC);
    const ommRate = await this.bridge.getTokenPrice(SupportedTokens.OMM);
    this.tokenRates.ICX = icxRate;
    this.tokenRates.sICX = sicxRate;
    this.tokenRates.IUSDC = iusdcRate;
    this.tokenRates.OMM = ommRate;
  }

  private signOut() {
    // logout user
    this.bridge.magicLogout();
    // reset properties
    this.resetProperties();
    // show sign in modal
    this.showModalView(this.signInModalView);
    // hide menu
    this.onMenuTriggerClick();
    // clear session check intervals
    this.clearSessionCheckIntervals();
  }

  private submitLoginForm(event: Event) {
    this.sessionExpired = false;
    event.preventDefault();
    this.magicLogin();
    return false;
  }

  private handleEmailInput(): void {
    // validate email
    if (!validateEmail(this.emailInput)) {
      this.emailErrMsg = "Enter a valid email address";
      this.emailError!.style.display = "inline";
      return;
    }
    // clear email Error if it passes checks before
    this.emailError!.style.display = "none";
  }

  // handle on resend email click in login flow
  private loginResendEmail() {
    // trigger magicLogin flow again
    this.magicLogin();
  }

  private async magicLogin(resend?: boolean): Promise<void> {
    try {
      this.clearSessionCheckIntervals();
      this.handleEmailInput();

      // show signing in view
      this.showModalView(this.signingInView);

      // if resend email was clicked then logout user before triggering login flow
      if (resend) {
        await this.bridge.magicLogout();
      }

      // if there is a valid session in browser get metadata and compare with logged in email
      // if session email does not match with email trying to log in then logout/clean session
      let validMagicSessionInBrowser = await this.bridge.userIsLoggedIn();
      if (validMagicSessionInBrowser) {
          this.userMagicMetadata = await this.bridge.getLoggedInUsersMagicMetadata();
          if (this.emailInput != this.userMagicMetadata.email) {
            await this.bridge.magicLogout();
            validMagicSessionInBrowser = false;
          }
      }

      // show check email view when magic link is sent to user email
      if (!validMagicSessionInBrowser) {
        this.showModalView(this.checkEmailModalView);
      }

      const magicLoginResponse: MagicLoginResponse = await this.bridge.magicLogin(this.emailInput);
      if (this.activeModalView != this.signingInView) {
        this.showModalView(this.signingInView);
      }
      // load user data
      this.user = magicLoginResponse.user;

      // add bri.send.tx event handler after successful login
      this.bridge.addSendTransactionEventListener();
      // async load transactions
      this.loadIcxTransactions();
      this.loadIrc2Transactions();

      // Load dollar rates of supported tokens
      await this.loadTokenPrice();

      // get all token balances
      await this.loadAllUserTokens();
      await this.loadUserUsdsBalance();

      // if user has only USDS show token view
      if (this.userTokensMap.size == 1 && this.userTokensMap.get(SupportedTokens.USDS)) {
        this.onAssetClick(this.userTokensMap.get(SupportedTokens.USDS)!)
      }
      this.showModalView(this.homeModalView);
      log("Logged with:", this.emailInput);
      this.userMagicMetadata = await this.bridge.getLoggedInUsersMagicMetadata();

      this.addSessionCheckIntervals();

    } catch (e) {
      if (e instanceof BridgeError) {
        if (e.externalError instanceof RPCError) {

          // post error message to errorLogger
          errorLogger.postError(e.externalError.name, e.externalError.code, e.externalError.rawMessage, 
            this.user?.iconWalletAddress ?? undefined);

          // if magic link expires revert back to the main sign-in screen
          if (e.externalError.code == RPCErrorCode.MagicLinkExpired) {
            this.sessionExpired = true;
            this.showModalView(this.signInModalView);
            return;
          }
        } else {
          // post error message to errorLogger
          errorLogger.postError('magicLogin method', undefined, typeof e == 'string' ? e : JSON.stringify(e), 
            this.user?.iconWalletAddress ?? undefined);
        }
      }
      this.handleError(e);
    }
  }

  private async loadBridgeTransactions(transactionsRes: any) {
    this.transactions.bridgeTransactions = formatAndFilterBridgeTransactions(transactionsRes?.data.data, this.user?.iconWalletAddress!);
  }

  private async loadIcxTransactions() {
    try {
      const transactionsRes: AxiosResponse<IcxTransactions> = await this.bridge.iconApiService.getIcxTransactions(this.user?.iconWalletAddress!);
      this.transactions.icxTransactions = formatAndFilterIcxTransactions(transactionsRes.data, this.user?.iconWalletAddress!);
    } catch (e) {
      console.error(e);
      // post error message to errorLogger
      errorLogger.postError('loadIcxTransactions method', undefined, typeof e == 'string' ? e : JSON.stringify(e), 
        this.user?.iconWalletAddress ?? undefined);
      log(e.userFriendlyMessage);
    }
  }

  private async loadIrc2Transactions(page?: number, count?: number) {
    let transactionsRes;
    try {
      transactionsRes = await this.bridge.iconApiService.getTokenTransactions(this.user?.iconWalletAddress!, page, count);
      this.loadBridgeTransactions(transactionsRes);
    } catch (e) {
      // post error message to errorLogger
      errorLogger.postError('loadIrc2Transactions method', undefined, typeof e == 'string' ? e : JSON.stringify(e), 
        this.user?.iconWalletAddress ?? undefined);
      log(e.userFriendlyMessage);
    }
    const supportedTokensTransactions = formatAndFilterIrc2Transactions(transactionsRes?.data.data);
    this.transactions.iusdcTransactions = supportedTokensTransactions.iusdcTransactions;
    this.transactions.sicxTransactions = supportedTokensTransactions.sicxTransactions;
    this.transactions.ommTransactions = supportedTokensTransactions.ommTransactions;
  }

  private handleError(e: any) {
    if (e instanceof BridgeError) {
      this.error = e.userFriendlyMessage;
    } else {
      this.error = e.message;
    }
    this.showModalView(this.errorView)
  }

  private showChangeEmail() {
    this.onMenuTriggerClick();
    hideSpan(this.menuTooltipMain);
    showSpan(this.menuTooltipEmail);
  }

  private hideEmailChangeShowMain() {
    hideSpan(this.menuTooltipEmail);
    showSpan(this.menuTooltipMain);
  }

  public backToHomeView() {
    this.shadowRoot!.getElementById('bridge-modal')!.style.top = '50%';
    this.shadowRoot!.getElementById('bridge-modal')!.style.left = '50%';
    if(this.activeModalView) {
      this.showModalView(this.homeModalView);
    } else {
      log("No active modal view!");
    }
  }

  public depositToAddressViewTransition() {
    this.showModalView(this.depositElemView);

    this.handleOnViewBlockchainAddressClick(true);
  }

  private async onBridgeButtonClick() {
    this.showWidget();
    this.overlay!.style.display = "block";
    if (!this.activeModalView) {
      this.showModalView(this.signInModalView);
    }
    if(this.activeModalView == this.homeModalView) {
      this.establishIntervals();
    }
  }

  private showWidget() {
    this.mainModalWrapper!.classList.add("modal-show");
  }

  private hideWidget() {
    this.mainModalWrapper!.classList.remove("modal-show");
  }

  private onMenuTriggerClick() {
    this.menuTooltipMain!.classList.toggle("active");
    this.animationUnderline!.classList.toggle("active");
  }

  private onOverlayClick() {
    this.clearIntervals();
    this.hideWidget();
    this.overlay!.style.display = "none";
  }

  private async updateEmail() {
    const newEmail = this.changeEmail!.value;
    // validate new email
    if (!validateEmail(newEmail)) {
      this.handleError(new BridgeError("Invalid new email"));
    }
    // update email
    try {
      const oldEmail = this.user!.email;
      const res = await this.bridge.updateEmail(newEmail);
      this.emailInput = newEmail;
      this.user!.email = newEmail;
      log("updateEmail result:", res);

      window.dispatchEvent(new CustomEvent('bri.emailChanged', {
        detail: {
          oldEmail: oldEmail,
          newEmail: newEmail,
          publicAddress: this.userMagicMetadata!.publicAddress
        }
    }))

    } catch (e) {
      // post error message to errorLogger
      errorLogger.postError('updateEmail method', undefined, typeof e == 'string' ? e : JSON.stringify(e), 
        this.user?.iconWalletAddress ?? undefined);
      
      if (e instanceof BridgeError && e.userFriendlyMessage.includes("email is already in use")) {
        return;
      } else {
        this.handleError(e);
      }
    }
    this.hideEmailChangeShowMain();
  }

  private handleTokenTransfer(amount: number, to: string, tokenTag: string) {
    const currentDate = new Date();
    if (tokenTag == SupportedTokens.USDS) {
      const newBridgeTx = new Irc2TokenTransaction("","","Bridge",CodeLists.BRIDGE_SYMBOL,"",
          currentDate.toDateString(), this.user?.iconWalletAddress!, to, amount.toString(),"",1,TransactionType.SENT);
      this.transactions.bridgeTransactions = [new BridgeTransaction(newBridgeTx.createDate, currentDate, +amount, newBridgeTx,
          TransactionType.SENT), ...this.transactions.bridgeTransactions];
    } else if (tokenTag == SupportedTokens.ICX) {
      const newIcxTx = new IcxTransaction(amount, currentDate.toDateString(), "icx", this.user?.iconWalletAddress!,
          1, to, "", "", currentDate.toDateString(), currentDate, TransactionType.SENT)
      this.transactions.icxTransactions = [newIcxTx, ...this.transactions.icxTransactions]
    } else if (tokenTag == SupportedTokens.IUSDC) {
      const newIusdcTx = new Irc2TokenTransaction("", "", "IUSDC", SupportedTokens.IUSDC, "", 
          currentDate.toISOString(), this.user?.iconWalletAddress!, to, amount.toString(), "", 1,TransactionType.SENT);
      this.transactions.iusdcTransactions = [newIusdcTx, ...this.transactions.iusdcTransactions]
    } else if (tokenTag == SupportedTokens.sICX) {
      const newSicxTx = new Irc2TokenTransaction("", "", "sICX", SupportedTokens.sICX, "", 
          currentDate.toISOString(), this.user?.iconWalletAddress!, to, amount.toString(), "", 1,TransactionType.SENT);
      this.transactions.sicxTransactions = [newSicxTx, ...this.transactions.sicxTransactions]
    } else if (tokenTag == SupportedTokens.OMM) {
      const newOmmTx = new Irc2TokenTransaction("", "", "OMM", SupportedTokens.OMM, "", 
          currentDate.toISOString(), this.user?.iconWalletAddress!, to, amount.toString(), "", 1,TransactionType.SENT);
      this.transactions.ommTransactions = [newOmmTx, ...this.transactions.ommTransactions]
    }
  }

  private emailChange() {
    this.emailInput = this.inputEmail!.value;
  }

  private async handleOnWithdrawFundsClick() {
    this.showModalView(this.withdrawElemView);
  }

  private handleOnViewBlockchainAddressClick(fromDeposit?: boolean) {
    if (!fromDeposit) {
      this.onMenuTriggerClick();
    }
    var opts = {
      errorCorrectionLevel: 'H',
      type: 'image/jpeg',
      quality: 0.3,
      margin: 1,
      color: {
        dark:"#2e2e2e",
        light:"#f1f4f9"
      }
    }

    QRCode.toDataURL(this.user?.iconWalletAddress, opts, (err: any, url: any) => {
      if (err) {
        this.handleError(err);
      } else {
        // @ts-ignore
        this.qrCodeContainer!.src  = url;
        this.showModalView(this.viewAddressView);
      }
    })
  }

  private onDepositClick() {
    if(this.selectedToken?.tag == SupportedTokens.USDS || !this.selectedToken) {
      this.showModalView(this.depositElemView);
      console.log(this.depositElemView);
    } else {
      this.handleOnViewBlockchainAddressClick(true);
    }
  }

  private onAssetClick(token: Token) {
    this.selectedToken = token;
    this.activeModalView?.scrollTo({ top: 0});
  }

  private onCopyAddressClick() {
    let textArea = document.createElement("textarea");

    // Place in top-left corner of screen regardless of scroll position.
    textArea.style.position = 'fixed';
    textArea.style.top = "0";
    textArea.style.left = "0";

    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textArea.style.width = '2em';
    textArea.style.height = '2em';

    // We don't need padding, reducing the size if it does flash render.
    textArea.style.padding = "0";

    // Clean up any borders.
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';

    // Avoid flash of white box if rendered for any reason.
    textArea.style.background = 'transparent';
    textArea.value = this.user?.iconWalletAddress!;

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
      log('Copying text command was ' + msg);
      // show notification
      this.showNotification("Address copied!", NotificationType.success)
    } catch (err) {
      log('Oops, unable to copy');
    }

    document.body.removeChild(textArea);
  }

  private showNotification(message: string, notificationType: NotificationType) {
    log("Showing notification message: ", message);
    this.notificationMessage = message;
    if (this.notification && !this.notification.classList.contains('opened')) {
      switch (notificationType) {
        case NotificationType.normal:
          if (this.notification.classList.contains('error')) this.notification.classList.remove('error');
          if (this.notification.classList.contains('success')) this.notification.classList.remove('success');
          break;
        case NotificationType.success:
          if (this.notification.classList.contains('error')) this.notification.classList.remove('error');
          if (!this.notification.classList.contains('success')) this.notification.classList.add('success');
          break;
        case NotificationType.error:
          if (!this.notification.classList.contains('error')) this.notification.classList.remove('error');
          if (this.notification.classList.contains('success')) this.notification.classList.add('success');
          break;
      }
      this.notification.classList.remove('closed');
      this.notification.classList.remove('visually-hidden');
      this.notification.classList.add('opened');
      // auto close notification after 5 seconds
      // setTimeout(() => this.closeNotification(), 5000);
    }
  }

  private closeNotification() {
    if (this.notification) {
      if (this.notification.classList.contains('opened')) {
        this.notification.classList.remove('opened');
        this.notification.classList.add('closed');
        this.notification.classList.add('visually-hidden');
      }
    }
  }

  private getTitleBalance(): any {
    if (this.selectedToken) {
      return html `${this.selectedToken.balance}<span class="currency label-small">${this.selectedToken?.tag}</span>`;
    } else {
      const totalBalance = [...this.userTokensMap.values()].reduce(
        (acc, token) => acc + (typeof token.usdBalance == 'number' ? token.usdBalance : 0),
        0
      );
      return html `$${totalBalance.toFixed(2)}`;
    }
  }

  private onTransactionClick(transaction: BridgeTransaction | IcxTransaction | Irc2TokenTransaction) {
    this.selectedTransaction = transaction;
    this.showModalView(this.transactionDetailView);
  }

  private getTransactionsHtml() {
    if (this.selectedToken) {
      if ((this.selectedToken.tag == SupportedTokens.USDS && this.transactions.bridgeTransactions.length == 0) ||
          (this.selectedToken.tag == SupportedTokens.ICX && this.transactions.icxTransactions.length == 0)) {
        return html `     <!-- If no transactions -->
          <p class="text-center label-small margin-top-35">No transactions yet.</p>`
      } else {
        if (this.selectedToken.tag == SupportedTokens.USDS) {
          return html `
          <!-- If transactions -->
          <table class="transaction-list">
            <tbody>
            ${this.transactions.bridgeTransactions.map((transaction: BridgeTransaction, index) => {
            if (index != 0 && transaction.dateString == this.transactions.bridgeTransactions[index -1].dateString) {
              return html `
                <tr @click="${() => this.onTransactionClick(transaction)}"
                class="${transactionTypeToClass(transaction)}">
                  <td>${getTransactionName(transaction)}</td>
                  <td class="text-bold">${transaction.type == TransactionType.SENT ? "-":"+"} ${formatTxAmount(transaction.amount)} ${this.selectedToken?.tag}</td>
                </tr>
                `
            } else {
              return html `
                 <tr>
                  <td colspan="2" class="date padding-top-15">${transaction.dateString.split(" ").slice(0, -1).join(" ")}</td>
                </tr>
                <tr @click="${() => this.onTransactionClick(transaction)}"
                class="${transactionTypeToClass(transaction)}">
                  <td>${getTransactionName(transaction)}</td>
                  <td class="text-bold">${transaction.type == TransactionType.SENT ? "-":"+"} ${formatTxAmount(transaction.amount)} ${this.selectedToken?.tag}</td>
                </tr>
                `
            }
          })}
            </tbody>
            </table>
          `
        }
        else if (this.selectedToken.tag == SupportedTokens.ICX) {
          return html `
          <!-- If transactions -->
          <table class="transaction-list">
            <tbody>
            ${this.transactions.icxTransactions.map((transaction: IcxTransaction, index) => {
            if (index != 0 && transaction.dateString == this.transactions.icxTransactions[index -1].dateString) {
              return html `
                <tr @click="${() => this.onTransactionClick(transaction)}"
                class="${tokenTransactionTypeToClass(transaction.type)}">
                  <td>${transaction.type.valueOf()}</td>
                  <td class="text-bold">${transaction.type == TransactionType.SENT? "-":"+"} ${formatTxAmount(transaction.amount)} ${this.selectedToken?.tag}</td>
                </tr>
                `
            } else {
              return html `
                 <tr>
                  <td colspan="2" class="date padding-top-15">${transaction.dateString.split(" ").slice(0, -1).join(" ")}</td>
                </tr>
                <tr @click="${() => this.onTransactionClick(transaction)}"
                class="${tokenTransactionTypeToClass(transaction.type)}">
                  <td>${transaction.type.valueOf()}</td>
                  <td class="text-bold">${transaction.type == TransactionType.SENT ? "-":"+"} ${formatTxAmount(transaction.amount)} ${this.selectedToken?.tag}</td>
                </tr>
                `
            }
          })}
            </tbody>
            </table>
          `
        }
        else if (this.selectedToken.tag == SupportedTokens.IUSDC || 
          this.selectedToken.tag == SupportedTokens.sICX || this.selectedToken.tag == SupportedTokens.OMM) {
          const transactionsList = this.selectedToken.tag == SupportedTokens.IUSDC ? 
            this.transactions.iusdcTransactions : 
            this.selectedToken.tag == SupportedTokens.sICX ?
              this.transactions.sicxTransactions :
              this.transactions.ommTransactions;
          return html `
          <!-- If transactions -->
          <table class="transaction-list">
            <tbody>
            ${transactionsList.map((transaction: Irc2TokenTransaction, index) => {
              const transactionType = transaction.toAddr == this.user?.iconWalletAddress ? 
                TransactionType.RECEIVED : TransactionType.SENT;
              if (index != 0 && 
                new Date(transaction.createDate).getDate() ==
                new Date(transactionsList[index -1].createDate).getDate()) {
              return html `
                <tr @click="${() => this.onTransactionClick(transaction)}"
                class="${tokenTransactionTypeToClass(transactionType)}">
                  <td>${transactionType}</td>
                  <td class="text-bold">${transactionType == TransactionType.RECEIVED ? "+":"-"} ${formatTxAmount(transaction.quantity)} ${this.selectedToken?.tag}</td>
                </tr>
                `
            } else {
              return html `
                 <tr>
                  <td colspan="2" class="date padding-top-15">${new Date(transaction.createDate).toDateString()}</td>
                </tr>
                <tr @click="${() => this.onTransactionClick(transaction)}"
                class="${tokenTransactionTypeToClass(transactionType)}">
                  <td>${transactionType}</td>
                  <td class="text-bold">${transactionType == TransactionType.SENT ? "-":"+"} ${formatTxAmount(transaction.quantity)} ${this.selectedToken?.tag}</td>
                </tr>
                `
            }
          })}
            </tbody>
            </table>
          `
        }
      }
    }
  }

  private hasSomeTokenTransactions(tag: SupportedTokens): boolean {
    if((tag == SupportedTokens.ICX && this.transactions.icxTransactions.length == 0) ||
      (tag == SupportedTokens.USDS && this.transactions.bridgeTransactions.length == 0) ||
      (tag == SupportedTokens.IUSDC && this.transactions.iusdcTransactions.length == 0) ||
      (tag == SupportedTokens.sICX && this.transactions.sicxTransactions.length == 0) || 
      (tag == SupportedTokens.OMM && this.transactions.ommTransactions.length == 0)
    ) {
      return false;
    }
    return true;
  }

  private getOneAvailableToken(): Token | undefined {
    for(let [_,token] of this.userTokensMap) {
      if(token.balance > 0) return token;
    }
    return this.userTokensMap.get(SupportedTokens.USDS);
  }

  private async updateTokenBalance(tokenTag: SupportedTokens) {
    // Update token and it's usd balance
    const newToken = await this.loadUserToken(tokenTag);
    this.userTokensMap.set(tokenTag, newToken);
    if (this.selectedToken?.tag === tokenTag) {
      this.selectedToken.balance = newToken.balance;
    }
  }

  private getAvailableTokens(): Map<string, Token> {
    const availableTokensMap = new Map(this.userTokensMap);
    for(let [key, token] of availableTokensMap) {
      if(token?.balance == 0 && token.tag !== SupportedTokens.USDS) {
        availableTokensMap.delete(key);
      }
    }
    return availableTokensMap;
  }

  private getTransactionDetailHtml() {
    let title = html``;

    const transactionType = this.selectedTransaction?.type ?? 
      (this.selectedTransaction?.toAddr == this.user?.iconWalletAddress ? 
        TransactionType.RECEIVED : TransactionType.SENT);
    
    //@ts-ignore
    const transactionAmount = this.selectedTransaction?.amount?.toFixed(4) ?? this.selectedTransaction?.quantity;

    //@ts-ignore
    const transactionDate = this.selectedTransaction?.date ?? new Date(this.selectedTransaction?.createDate);

    if (transactionType == TransactionType.SENT || transactionType == TransactionType.RECEIVED) {
      let blockchainAddress: string | undefined = "";
      if (this.selectedTransaction instanceof BridgeTransaction) {
        blockchainAddress = this.selectedTransaction?.iconTransaction ?
          transactionType == TransactionType.RECEIVED ?
            this.selectedTransaction.iconTransaction?.fromAddr 
            : 
            this.selectedTransaction.iconTransaction?.toAddr
          : 
          "Nan";
      } else {
        blockchainAddress = transactionType == TransactionType.RECEIVED ?
          this.selectedTransaction?.fromAddr 
          : 
          this.selectedTransaction?.toAddr
      }
      title = html`<div class="grid margin-top-15 padding-bottom-15 border-bottom">
                    <div class="grid-cell">
                      <p class="text-center label-small">${transactionType == TransactionType.SENT ? "Sent to": "Received from"}</p>
                      <p class="text-center text-bold">
                      <span class="blockchain-address">${blockchainAddress}</span>
                      </p>
                    </div>
                  </div>`
    }
    return html`
       <div class="grid-cell">
        <h2>${transactionType == TransactionType.SENT ? "-":"+"} ${this.selectedTransaction? formatTxAmount(transactionAmount) : "NaN"}<span class="currency label-small">${this.selectedToken?.tag}</span></h2>
        ${title}
        <p class="text-center label-small">${this.selectedTransaction ?  transactionDate.toDateString() + " " + transactionDate.toLocaleTimeString() : "Nan"}</p>
      </div>
    `
  }

  render() {
    // @ts-ignore
    return html`
<div id="icon-bridge-widget-wrapper" style="margin:0; padding: 0">
      <p class="text-center" style="display: ${this.hideButton ? 'none' : 'block'}">
        <a class="button modal-trigger" data-modal="modal-1" style="margin: auto; padding-left: 60px;" @click=${this.onBridgeButtonClick}>
            <span class="bridge-logo"></span>${this.user ? Consts.bridgeButtonTitle : Consts.bridgeButtonSignInTitle}</a>
      </p>
      
    <!-- The Modal container -->
  <div class="modal" id="bridge-modal">
    <div class="modal-content">
                    <div class="notification slider closed visually-hidden" id="notification">
                        <div class="notification-content">
                          <span class="icon"></span><p>${this.notificationMessage}</p>
                        </div>
                        <span class="notification-close" @click=${this.closeNotification}></span>
                    </div>
    
                    <!-- Custom child elements -->
                    <send-token id="send-token-elem" hidden
                      @backToHomeView="${this.backToHomeView}"
                      @updateBalance="${(e:any) => { this.updateTokenBalance(e.detail.tokenTag) }}"
                      @tokenTransfer="${(e:any) => this.handleTokenTransfer(e.detail.amount, e.detail.to, e.detail.tokenTag)}"
                      @handleSessionExpiry="${this.handleSessionExpiry}"
                      .bridge="${this.bridge}"
                      .user="${this.user}"
                      .selectedToken="${this.selectedToken ?? this.getOneAvailableToken()}"
                      .userTokensMap="${this.getAvailableTokens()}"
                      .tokenBalance="${this.selectedToken?.balance ?? this.userTokensMap.get(SupportedTokens.USDS)?.balance}"
                      >
                    </send-token>
                   
                    <deposit-elem id="deposit-elem" hidden
                      .user=${this.user}
                      @backToHomeView="${this.backToHomeView}"
                      @redirectToAddressView="${this.depositToAddressViewTransition}"
                    >
                    </deposit-elem>

                    <withdraw-elem id="withdraw-elem" hidden
                      @backToHomeView="${this.backToHomeView}"
                      @onCopyAddressClick="${this.onCopyAddressClick}"
                      .user=${this.user}
                      @onMenuTriggerClick="${this.onMenuTriggerClick}"
                    >
                    </withdraw-elem>
                    <!-- END Custom child elements -->
                    
                    <!-- Sign in -->
                        <div class="widget" id="sign-in">
                          <div class="grid-cell text-right">
                            <p class="verify-status not-verified"><span class="label-outline test-wallet">Test wallet</span></p>
                          </div>
                          <div class="grid grid-center inherit-height">
                            <div class="grid-cell">
                              <img src=${bridgeLogo} class="logo margin-bottom-15">
                              <h2>Sign in with Bridge</h2>
                              <p class="text-center margin-bottom-15">A payment service for blockchain apps.</p>
                              <form @submit=${(e:Event) => this.submitLoginForm(e)}>
                                <!-- Accessibility label -->
                                <label for="input-email" class="visually-hidden">Enter your email</label>
                                 <span class="red-error-text" id="email-error" hidden>* ${this.emailErrMsg}</span>
                                <input @change=${this.emailChange}  required autocomplete="on"
                                class="margin-bottom-15" id="input-email" type="email" name="" placeholder="hello@example.com" autofocus>
                                <p class="text-center margin-bottom-25"><input type="submit" value="Sign in" class="button"></p>
                              </form>
                              <p class="label-small text-center">If you don’t have a Bridge account,<br>you’ll create one when you sign in.</p>
                              <p class="label-small text-center margin-top-15 margin-bottom-15"><a href="https://bridgepay.money">What's Bridge?</a></p>
                              <p ?hidden=${!this.sessionExpired} class="label-small text-center" style="color:#9d4df1;">Your session expired. Please sign in again.</p>
                            </div>
                          </div>
                        </div>

                      <!-- Processing -->
                      <div class="widget" id="processing-view" hidden>
                        <div class="grid grid-center inherit-height">
                          <div class="grid-cell">
                            <img src="${this.processingSvg}" class="feature-icon">
                            <h2>${this.processingTitle}</h2>
                            <!-- Loading animation -->
                            <div class="loading-animation">
                              <label>●</label>
                              <label>●</label>
                              <label>●</label>
                              <label>●</label>
                              <label>●</label>
                              <label>●</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                       <!-- Login processing -->
                      <div class="widget" id="signing-you-in" hidden>
                        <div class="grid grid-center inherit-height">
                          <div class="grid-cell">
                            <img src=${signingInSvg} class="feature-icon">
                            <h2 class="margin-bottom-10">Signing you in</h2>
                      
                            <!-- Loading animation -->
                            <div class="loading-animation">
                              <label>●</label>
                              <label>●</label>
                              <label>●</label>
                              <label>●</label>
                              <label>●</label>
                              <label>●</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                      
                      <!-- Check your email -->
                      <div class="widget" id="check-email" hidden>
                        <div class="grid grid-center inherit-height">
                          <div class="grid-cell">
                            <img src=${checkEmailSvg} class="feature-icon">
                            <h2 class="margin-bottom-10">Check your email</h2>
                            <p class="text-center margin-bottom-15">Click the link in the email sent to<br><span class="text-bold">${this.emailInput}</span>.</p>
                            <p class="label-small text-center margin-bottom-15">You'll sign in here, even if you<br>click the link on another device.</p>
                            <p class="label-small text-center">Don't see it? <a @click="${() => this.loginResendEmail()}">Resend email.</a></p>
                          </div>
                        </div>
                      </div>
                     
                    
                      <!-- Sign in successful -->
                      <div class="widget" id="sign-in-successfull" hidden>
                        <div class="grid grid-center inherit-height">
                          <div class="grid-cell">
                            <img src=${signInSuccessSvg} class="feature-icon">
                            <h2>Sign in successful</h2>
                            <p class="text-center margin-bottom-15">Close this page and go back to<br><span class="text-bold">TwentyFour</span></p>
                          </div>
                        </div>
                      </div>
                      
                      <!-- Error view -->
                      <div class="widget" id="error" hidden>
                        <div class="grid">
                          <div class="grid-cell">
                              <a @click="${() => this.showModalView(this.previousView!)}" class="back">Back</a>
                              </div>
                              <div class="grid-cell">
                              </div>
                              <div class="grid-cell">
                          </div>
                      </div>
                        <div class="grid grid-center inherit-height">
                          <div class="grid-cell">
                            <img src=${signInExpiredSvg} class="feature-icon">
                            
                            <h2 ?hidden="${!this.error}" class="margin-bottom-10">Something went wrong</h2>
                            <p ?hidden="${!this.error}" class="text-center margin-bottom-15">${this.error}</p>
                          </div>
                        </div>
                      </div>
                    
                      <!-- Home -->
                      <div class="widget home" id="home" hidden>
                        <div class="floating-header">
                        <span ?hidden="${this.selectedToken && this.userTokensMap.size > 1}">
                          <span class="tooltip">
                            <span class="tooltip-item">
                      
                              <div class="account">
                                <p class="text-center"><a id="menu-trigger" @click="${this.onMenuTriggerClick}"><span class="animation-underline" id="animation-underline">${this.emailInput}</span></a></p>
                              </div>
                      
                              <span id="menu-tooltip" class="tooltip-content clearfix">
                                <div class="grid grid-center border-bottom padding-bottom-15">
                                  <div class="grid-cell">
                                    <p class="label-upper">Menu</p>
                                  </div>

                                </div>
                                <div class="grid">
                                  <div class="grid-cell">
                                    <ul class="menu-list">
                                      <li class="email" @click="${this.showChangeEmail}"><a>Change email address</a></li>
                                      <li class="blockchain" @click="${() => this.handleOnViewBlockchainAddressClick(false)}"><a>View blockchain address</a></li>
                                      <li class="withdraw" @click="${this.handleOnWithdrawFundsClick}"><a style="pointer-events:none;">Withdraw funds</a></li>
                                    </ul>
                                  </div>
                                </div>
                                <div class="grid">
                                  <div class="grid-cell">
                                    <p class="text-center margin-bottom-15"><a class="button" id="sign-out-button" @click="${this.signOut}">Sign out</a></p>
                                    <p class="text-center label-small padding-bottom-15">Learn more at <a href="https://bridgepay.money" target="_blank">bridgepay.money</a>.</p>
                                  </div>
                                </div>
                              </span>

                              <!-- Change email -->
                              <span id="menu-tooltip-email" class="tooltip-content clearfix active" hidden>
                                <div class="grid">
                                  <div class="grid-cell">
                                    <a @click="${this.hideEmailChangeShowMain}" class="back">Back</a>
                                  </div>
                                  <div class="grid-cell">
                                    <p class="label-upper text-center">Email</p>
                                  </div>
                                  <div class="grid-cell">
                              
                                  </div>
                                </div>
                                <div class="grid">
                                  <div class="grid-cell margin-top-25">
                                    <label for="change-email">Change your email address</label><br>
                                    <input class="margin-top-10 margin-bottom-25 text-center"
                                     type="email" id="change-email" name="change-email" placeholder="hello@example.com" autocomplete="on">
                                  </div>
                                </div>
                                <div class="grid">
                                  <div class="grid-cell">
                                    <p class="text-center"><a @click="${this.updateEmail}" class="button">Change email address</a></p>
                                  </div>
                                </div>
                              </span>
                            </span>
                          </span>
                        </span>
                          
                          <div ?hidden="${!this.selectedToken || this.userTokensMap.size <= 1}">
                             <div class="grid margin-bottom-10">
                              <div class="grid-cell">
                                <a @click="${() => this.selectedToken = undefined}" class="back">Back</a>
                              </div>
                              <div class="grid-cell">
                                <p class="label-upper text-center">${this.selectedToken?.tag ?? "??"}</p>
                              </div>
                              <div class="grid-cell">
                            
                              </div>
                            </div>
                          </div>
                          
                          <h2 class="margin-bottom-15">${this.getTitleBalance()}</h2>
                          <div class="grid grid-center margin-bottom-25">
                            <div class="grid-cell text-right margin-right-25">
                              <a class="button" @click="${this.onDepositClick}">Deposit</a>
                            </div>
                            <div class="grid-cell">
                              <a class="button outline" @click="${() => {this.showModalView(this.sendTokenElemView);}}">Send</a>
                            </div>
                          </div>
                          <p class="text-center label-outline test-wallet">Test wallet</p>
                        </div>
                        
                        
                        <div class="grid">
                          <div ?hidden="${!this.selectedToken}" class="grid-cell">
                            ${this.getTransactionsHtml()}
                          </div>
                        </div>

                        <table ?hidden="${this.selectedToken}" class="assets-list">
                          <tbody>
                            <tr>
                              <td colspan="3" class="title padding-top-15">Assets</td>
                            </tr>
                            ${Array.from(this.userTokensMap.values()).map((token: Token) => {
                              const hasTokenTransactions = this.hasSomeTokenTransactions(token.tag);
                              if(hasTokenTransactions || token.balance > 0) {
                                return html `
                                  <tr class="asset" @click="${() => this.onAssetClick(token)}">
                                    <td><img src=${token.logo} class="${token.tag == "USDS" ? 'bridge':''}"></td>
                                    <td>${token.name}<br><span class="label-small">${token.tag}</span></td>
                                    <td>${token.balance}${' '}${token.tag}
                                      <br>
                                      <span class="label-small">
                                        ${typeof token.usdBalance == 'number' ? `$${token.usdBalance.toFixed(2)}` : token.usdBalance}
                                      </span>
                                    </td>
                                  </tr>`
                              } else {
                                return html``;
                              }  
                            })}
                          </tbody>
                        </table>
                      </div>
                      <!-- END Home -->
                      
                      <!-- View blockchain address (from menu) -->
                      <div class="widget" id="view-address" hidden>
                        <!-- Navigation -->
                        <div class="grid">
                          <div class="grid-cell">
                            <a @click="${() => this.showModalView(this.previousView!)}" class="back">Back</a>
                          </div>
                          <div class="grid-cell">
                            <p class="label-upper text-center">Address</p>
                          </div>
                          <div class="grid-cell">
                      
                          </div>
                        </div>
                        <div class="grid grid-center margin-top-25">
                          <div class="grid-cell">
                            <p class="text-center margin-bottom-15 label-small">Send assets to this<br>ICON blockchain address:</p>
                            <img class="margin-bottom-15" id="qr-code-container">
                            <div class="text-center">
                                <span class="label-small">${this.user?.iconWalletAddress?.slice(0, this.user?.iconWalletAddress?.length / 2)}</span><br>
                                <span class="label-small">${this.user?.iconWalletAddress?.slice(this.user?.iconWalletAddress?.length / 2, this.user?.iconWalletAddress?.length)}</span>
                            </div>
                          </div>
                        </div>
                        <div class="grid">
                          <div class="grid-cell text-center">
                            <p class="margin-top-15 margin-bottom-15"><a @click="${this.onCopyAddressClick}" class="button" id="copy-addr-btn">Copy address</a></p>
                          </div>
                        </div>
                        <p class="label-outline test-wallet">This is a test wallet. Do not send any real assets to this address.</p>
                      </div>
                      
                        <!-- Transaction detail view -->
                        <div class="widget transaction" id="transaction-detail" hidden>
                          ${this.getTransactionNavHtml()}       
                          <!-- Details -->
                          <div class="grid grid-center inherit-height">
                            ${this.getTransactionDetailHtml()}
                          </div>
                        </div>
                        <!-- Transaction detail view END -->                                
        </div>
    </div>
    
    <div id="overlay" class="modal-overlay" @click=${this.onOverlayClick}></div>   
</div>
  <!-- ### End of modal code ### -->
    `;
  }

  private getTransactionNavHtml() {
    return html ` <!-- Navigation -->
                  <div class="grid">
                    <div class="grid-cell">
                      <a @click="${this.backToHomeView}" class="back">Back</a>
                    </div>
                    <div class="grid-cell">
                      <p class="label-upper text-center">Transaction</p>
                    </div>
                    <div class="grid-cell">
                
                    </div>
                  </div>`
  }


}

declare global {
  interface HTMLElementTagNameMap {
    'icon-bridge-widget': IconBridgeWidget;
  }
}
