import { Injectable } from '@angular/core';
import {Wallet} from "../models/classes/Wallet";
import {Observable, Subject} from "rxjs";
import {PersistenceService} from "./persistence.service";
import {Terms} from "../models/classes/Terms";
import BigNumber from "bignumber.js";
import {PoolStats} from "../models/classes/PoolStats";
import {BondTag, PoolId, Irc2TokenTag} from '../models/types/Types';
import log from "loglevel";
import {BondConfig} from "../models/classes/BondConfigInfo";
import {ModalType} from "../models/enums/ModalType";
import {BondInfo} from "../models/classes/BondInfo";
import {BalnLpToken} from '../models/classes/BalnLpToken';


@Injectable({
  providedIn: 'root'
})
export class StateChangeService {

  /**
   * Subscribable subjects to which components can subscribe in order to listen and react to changes in state
   */
  private loginChange: Subject<Wallet | undefined> = new Subject<Wallet | undefined>();
  loginChange$ = this.loginChange.asObservable();

  private logoutChange = new Subject<void>();
  logoutChange$= this.logoutChange.asObservable();

  private bondTermsChange = new Subject<{bond: BondConfig, terms: Terms}>();
  bondTermsChange$ = this.bondTermsChange.asObservable();

  private bondKarmaFeeChange = new Subject<BigNumber>();
  bondKarmaFeeChange$ = this.bondKarmaFeeChange.asObservable();

  private bondMaxPayoutChange = new Subject<{bond: BondConfig, maxPayout: BigNumber}>();
  bondMaxPayoutChange$ = this.bondMaxPayoutChange.asObservable();

  private trueBondPriceChange = new Subject<{bond: BondConfig, trueBondPrice: BigNumber}>();
  trueBondPriceChange$ = this.trueBondPriceChange.asObservable();

  private bondPriceChange = new Subject<{bond: BondConfig, bondPrice: BigNumber}>();
  bondPriceChange$ = this.bondPriceChange.asObservable();

  private bondsTotalPayoutGivenChange = new Subject<{bond: BondConfig, totalPayoutGiven: BigNumber}>();
  bondsTotalPayoutGivenChange$ = this.bondsTotalPayoutGivenChange.asObservable();

  private poolStatsChange = new Subject<{poolId: PoolId, poolStats: PoolStats}>();
  poolStatsChange$= this.poolStatsChange.asObservable();

  private userIrc2TokenBalancesChange= new Subject<Map<Irc2TokenTag, BigNumber>> ();
  userIrc2TokenBalancesChange$ = this.userIrc2TokenBalancesChange.asObservable();

  private userBalnLpBalancesChange= new Subject<Map<PoolId, BigNumber>> ();
  userBalnLpBalancesChange$ = this.userBalnLpBalancesChange.asObservable();

  // indicate that core data was loaded
  private coreDataFinishedLoading= new Subject<void> ();
  coreDataFinishedLoading$ = this.coreDataFinishedLoading.asObservable();

  // indicate that user data was loaded
  private userDataFinishedLoading= new Subject<void> ();
  userDataFinishedLoading$ = this.userDataFinishedLoading.asObservable();

  private assetPriceChange= new Subject<{assetTag: Irc2TokenTag, price: BigNumber}> ();
  assetPriceChange$ = this.assetPriceChange.asObservable();

  private modalChange: Subject<ModalType | undefined> = new Subject<ModalType | undefined>();
  modalChange$: Observable<ModalType | undefined> = this.modalChange.asObservable();

  private overlayClickedEvent = new Subject<void>();
  overlayClickedEvent$: Observable<void> = this.overlayClickedEvent.asObservable();

  private userBondInfoChange = new Subject<{bond: BondConfig, bondInfo: BondInfo}>();
  userBondInfoChange$ = this.userBondInfoChange.asObservable()

  private userPendingPayoutForChange = new Subject<{bond: BondConfig, pendingPayoutFor: BigNumber}>();
  userPendingPayoutForChange$ = this.userPendingPayoutForChange.asObservable()

  private userPercentVestedForChange = new Subject<{bond: BondConfig, percentVestedFor: BigNumber}>();
  userPercentVestedForChange$ = this.userPercentVestedForChange.asObservable()

  private bondTreasuryPayoutBalanceChange = new Subject<BigNumber>();
  bondTreasuryPayoutBalanceChange$: Observable<BigNumber> = this.bondTreasuryPayoutBalanceChange.asObservable();

  private bondTreasuryPrincipalBalanceChange = new Subject<BigNumber>();
  bondTreasuryPrincipalBalanceChange$: Observable<BigNumber> = this.bondTreasuryPrincipalBalanceChange.asObservable();

  private bondStatusChange = new Subject<{bondTag: BondTag, status: boolean}>();
  bondStatusChange$ = this.bondStatusChange.asObservable();

  private treasuryOwnersFinishedLoading = new Subject<void>();
  treasuryOwnersFinishedLoading$ = this.treasuryOwnersFinishedLoading.asObservable();

  private bondTotalDebtChange = new Subject<BigNumber>();
  bondTotalDebtChange$: Observable<BigNumber> = this.bondTotalDebtChange.asObservable();

  constructor(private persistenceService: PersistenceService) { }

  public bondTreasuryPayoutBalanceUpdate(bondTag: BondTag, balance: BigNumber): void {
    this.persistenceService.bondTreasuryPayoutBalanceMap.set(bondTag, balance)
    this.bondTreasuryPayoutBalanceChange.next(balance);
  }

  public bondTreasuryPrincipalBalanceUpdate(bondTag: BondTag, balance: BigNumber): void {
    this.persistenceService.bondTreasuryPrincipalBalanceMap.set(bondTag, balance)
    this.bondTreasuryPrincipalBalanceChange.next(balance);
  }

  public bondStatusUpdate(bondTag: BondTag, status: boolean): void {
    this.persistenceService.bondStatusMap.set(bondTag, status)
    this.bondStatusChange.next({ bondTag, status});
  }

  public treasuryOwnersFinishedLoadingUpdate(): void {
    this.treasuryOwnersFinishedLoading.next();
  }

  public userBondInfoUpdate(bond: BondConfig, bondInfo?: BondInfo): void {
    if (bondInfo) {
      this.persistenceService.userBondTagToBondInfoMap.set(bond.tag, bondInfo);
      this.userBondInfoChange.next({ bond, bondInfo })
    }
  }

  public userPendingPayoutForUpdate(bond: BondConfig, pendingPayoutFor: BigNumber): void {
      this.persistenceService.userBondTagToPendingPayoutForMap.set(bond.tag, pendingPayoutFor);
      this.userPendingPayoutForChange.next({ bond, pendingPayoutFor });
  }

  public userPercentVestedForUpdate(bond: BondConfig, percentVestedFor: BigNumber): void {
    this.persistenceService.userBondTagToPercentVestedForMap.set(bond.tag, percentVestedFor);
    this.userPercentVestedForChange.next({ bond, percentVestedFor });
  }

  public loginUpdate(wallet: Wallet): void {
    this.persistenceService.loginUser(wallet)
    this.loginChange.next(wallet);
    log.info("Login with wallet: ", wallet);
  }

  public logoutUpdate(): void {
    // clear active wallet from persistence service
    this.persistenceService.logoutUser();
    this.logoutChange.next();
  }

  public bondTermsUpdate(bond: BondConfig, terms: Terms): void {
    this.persistenceService.bondTagToTermsMap.set(bond.tag, terms);
    this.bondTermsChange.next({ bond, terms });
  }

  public karmaBondTotalDebtUpdate(bond: BondConfig, totalDebt: BigNumber): void {
    this.persistenceService.bondTagToTotalDebtMap.set(bond.tag, totalDebt);
    this.bondTotalDebtChange.next(totalDebt);
  }

  public karmaBondFeeUpdate(bond: BondConfig, fee: BigNumber): void {
    this.persistenceService.bondTagToKarmaFeeMap.set(bond.tag, fee);
    this.bondKarmaFeeChange.next(fee);
  }

  public bondMaxPayoutUpdate(bond: BondConfig, maxPayout: BigNumber): void {
    this.persistenceService.bondTagToMaxPayoutMap.set(bond.tag, maxPayout);
    this.bondMaxPayoutChange.next({ bond, maxPayout });
  }

  public trueBondPriceUpdate(bond: BondConfig, trueBondPrice: BigNumber): void {
    this.persistenceService.bondTagToTrueBondPriceMap.set(bond.tag, trueBondPrice);
    this.trueBondPriceChange.next({ bond, trueBondPrice });
  }

  public bondPriceUpdate(bond: BondConfig, bondPrice: BigNumber): void {
    this.persistenceService.bondTagToBondPriceMap.set(bond.tag, bondPrice);
    this.bondPriceChange.next({ bond, bondPrice });
  }

  public bondsTotalPayoutGivenUpdate(bond: BondConfig, totalPayoutGiven: BigNumber): void {
    this.persistenceService.bondTagToTotalPayoutGivenMap.set(bond.tag, totalPayoutGiven);
    this.bondsTotalPayoutGivenChange.next({ bond, totalPayoutGiven });
  }

  public poolStatsUpdate(poolId: PoolId, poolStats: PoolStats): void {
    this.persistenceService.balancedPoolStatsMap.set(poolId, poolStats);
    this.poolStatsChange.next({ poolId, poolStats });
  }

  public updateUserAssetBalances(balances: Map<Irc2TokenTag, BigNumber>): void {
    this.persistenceService.setUserIrc2TokenBalances(balances);
    this.userIrc2TokenBalancesChange.next(balances);
  }

  public updateUserBalnLpBalances(balances: Map<PoolId, BigNumber>): void {
    this.persistenceService.setUserBalnLpTokenBalances(balances);
    this.userBalnLpBalancesChange.next(balances);
  }

  public updateAssetsPrice(assetTag: Irc2TokenTag, price: BigNumber): void {
    this.assetPriceChange.next({assetTag, price});
  }

  public coreDataFinishedLoadingUpdate(): void {
    this.coreDataFinishedLoading.next();
  }

  public userDataFinishedLoadingUpdate(): void {
    this.userDataFinishedLoading.next();
  }

  public modalUpdate(type?: ModalType): void {
    log.debug("Modal update type:", type);
    this.modalChange.next(type);
  }

  public overlayHiddenUpdate(): void {
    this.overlayClickedEvent.next();
  }

}
