<div class="table table-content">
  <div class="table-item table-item-1">
    <img src="assets/images/{{principalImg()}}" (error)="handlePrincipalImgError($event)" loading="lazy"
         width="{{bond.baseInfo.principalIsLpToken ? '58' : '32'}}" alt="" class="table-item-pair-image">
    <div class="table-item-pair-content">
      <div class="table-item-pair-name">{{principalTokenName()}}</div>
      <div *ngIf="bond.baseInfo.principalIsLpToken" class="table-item-pair-subtext cursor-pointer" (click)="onGetLpClick()">Get LP tokens</div>
    </div>
  </div>
  <div class="table-item table-item-2">
    <img src="assets/images/{{payoutImg()}}" (error)="handlePrincipalImgError($event)"  loading="lazy" width="32" alt="" class="table-item-pair-image">
    <div class="table-item-pair-content">
      <div class="table-item-pair-name {{getSkeletonLoadingClass(bondPriceUSD)}}">${{bondPriceUSD.toFixed(5) | usFormat | toZeroIfDash}}</div>
      <div class="table-item-pair-subtext {{getSkeletonLoadingClass(marketPayoutTokenPrice())}}">${{marketPayoutTokenPrice().toFixed(5) | usFormat}} Market</div>
    </div>
  </div>
  <div class="table-item hide-mobile table-item-3">
    <div class="table-item-pair-name {{getDiscountSkeletonLoadingClass()}} {{isNegative(discount) && !discount.eq(-1) ? 'negative-discount' : ''}}"
    >{{discount | toFixed2DecPercent: true}}</div>
  </div>
  <div class="table-item hide-mobile table-item-4">
    <div>
      <div class="table-item-pair-name {{getSkeletonLoadingClass(tbv)}}">${{tbv.toFixed(0) | usFormat | toZeroIfDash}}</div>
      <div class="table-item-pair-subtext {{getSkeletonLoadingClass(payoutAvailableUSD)}}">${{payoutAvailableUSD.toFixed(0) | usFormat | toZeroIfDash}} Remaining</div>
    </div>
  </div>
  <div class="table-item table-item-5">
    <a data-w-id="a98553fb-eec2-9bc3-ca82-3493bf4569c6" href="javascript:void(0);"
       (click)="onBondClick()" class="button-small button-bond w-inline-block {{isSoldOut ? 'disabled':''}}">
      <div>{{isSoldOut ? "Sold out" : "Bond"}}</div>
    </a>
  </div>
</div>
