import {BigNumber} from "bignumber.js";
import {BridgeWidgetAction} from "../models/enums/BridgeWidgetAction";
import log from "loglevel";
import {BondConfig} from "../models/classes/BondConfigInfo";
import moment from "moment";

export class Utils {

  public static ZERO = new BigNumber("0");

  public static delay = (() => {
    let timer: any;
    return (callback: any, ms: any) => {
      clearTimeout (timer);
      timer = setTimeout(callback, ms);
    };
  })();

  public static hashCode(str: string): string {
    let hash = 0;
    for (let i = 0, len = str.length; i < len; i++) {
      const chr = str.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash.toString();
  }

  public static handleSmallDecimal(num: BigNumber): string {
    if (num.isGreaterThanOrEqualTo(new BigNumber("0.005"))) {
      //  Round 0.005% and above up to 0.01%
      return "<0.01%";
    } else {
      // Round value below 0.005% to 0
      return "0%";
    }
  }

  // Returns number divided by the 10^decimals
  public static hexToNormalisedNumber(value: BigNumber | string, decimals: number = 18): BigNumber {
    if (!value || !(new BigNumber(value).isFinite())) {
      return new BigNumber("0");
    } else if (typeof value === "string") {
      return new BigNumber(value, 16).dividedBy(new BigNumber("10").pow(decimals));
    } else {
      return value.dividedBy(new BigNumber("10").pow(decimals));
    }
  }

  public static karmaFeeToPercent(karmaFee: BigNumber): BigNumber {
    return karmaFee.dividedBy(1000000);
  }

  public static hexToNumber(value: string | BigNumber): BigNumber {
    if (!value || !(new BigNumber(value).isFinite())) {
      return new BigNumber("0");
    } else if (typeof value === "string") {
      return new BigNumber(value, 16);
    } else {
      return new BigNumber(value);
    }
  }

  public static hexToBoolean(value: any): boolean {
    if (typeof value === "string") {
      return value !== "0x0";
    } else if (value instanceof BigNumber) {
      return value.isEqualTo(1);
    } else {
      return value;
    }
  }

  public static formatNumberToUSLocaleString(num: BigNumber): string {
    return num.toNumber().toLocaleString("en-US", {maximumFractionDigits: 5});
  }

  public static normalisedAmountToBaseAmountString(amount: BigNumber, decimals: BigNumber = new BigNumber("18")): string {
    return amount.multipliedBy(new BigNumber("10").pow(decimals.toNumber())).toFixed();
  }

  public static tooUSLocaleString(num?: BigNumber | string, defaultZero = false): string {
    if (!num || !(new BigNumber(num).isFinite())) { return defaultZero ? "0" : "-"; }
    if (typeof num === "string") {
      return Utils.formatNumberToUSLocaleString(new BigNumber(num));
    } else {
      return Utils.formatNumberToUSLocaleString(num);
    }
  }

  public static toDollarUSLocaleString(num?: BigNumber | string, defaultZero = false): string {
    if (!num || !(new BigNumber(num).isFinite()) || (+num) <= 0) { return defaultZero ? "0" : "-"; }
    return `$${this.tooUSLocaleString(num)}`;
  }

  public static roundOffTo2Decimals(value: BigNumber | string): string {
    if (value instanceof BigNumber) {
      return value.toFixed(2, BigNumber.ROUND_HALF_CEIL);
    } else {
      return (new BigNumber(value).toFixed(2, BigNumber.ROUND_HALF_CEIL));
    }
  }

  public static roundOffTo0Decimals(value: BigNumber | string): string {
    if (value instanceof BigNumber) {
      return value.toFixed(0, BigNumber.ROUND_HALF_CEIL);
    } else {
      return (new BigNumber(value).toFixed(0, BigNumber.ROUND_HALF_CEIL));
    }
  }

  public static roundDownTo2Decimals(value: BigNumber | string | undefined): string {
    if (!value || !(new BigNumber(value).isFinite())) {
      return "0";
    } else if (value instanceof BigNumber) {
      return value.toFixed(2, BigNumber.ROUND_DOWN);
    } else {
      return new BigNumber(value).toFixed(2, BigNumber.ROUND_DOWN);
    }
  }

  public static roundUpTo2Decimals(value: BigNumber | string): BigNumber {
    if (value instanceof BigNumber) {
      return new BigNumber(value.toFixed(2, BigNumber.ROUND_UP));
    } else {
      return new BigNumber(new BigNumber(value).toFixed(2, BigNumber.ROUND_UP));
    }
  }

  // public static to2DecimalRndOffPercString(num?: BigNumber | string, defaultZero = false): string {
  //   if (!num || !(new BigNumber(num).isFinite()) || (+num) <= 0) { return defaultZero ? "0%" : "-"; }
  //
  //   // convert in to percentage
  //   num = new BigNumber(num).multipliedBy(new BigNumber("100"));
  //
  //   return `${(this.tooUSLocaleString(Utils.roundOffTo2Decimals(num)))}%`;
  // }

  public static to2DecimalFixedPercString(num?: BigNumber | string, defaultZero = false): string {
    if (!num || !(new BigNumber(num).isFinite())) { return defaultZero ? "0%" : "-"; }

    // convert in to percentage
    const percentNumber = (new BigNumber(num).multipliedBy(new BigNumber("100"))).toNumber();

    return `${(this.tooUSLocaleString(percentNumber.toFixed(2)))}%`;
  }

  public static roundDownToZeroDecimals(value: BigNumber | string): string {
    if (value instanceof BigNumber) {
      return value.toFixed(0, BigNumber.ROUND_DOWN);
    } else {
      return new BigNumber(value).toFixed(0, BigNumber.ROUND_DOWN);
    }
  }

  public static convertICXTosICX(value: BigNumber, todayRate: BigNumber): BigNumber {
    return value.dividedBy(todayRate);
  }

  public static convertICXToSICXPrice(icxPrice: BigNumber, sICXRate: BigNumber = new BigNumber("0")): BigNumber {
    return icxPrice.multipliedBy(sICXRate);
  }

  public static convertSICXToICX(sICXvalue: BigNumber, sIcxToIcxRate: BigNumber): BigNumber {
    return sICXvalue.multipliedBy(sIcxToIcxRate);
  }


  public static subtract(val1: BigNumber, val2: BigNumber): BigNumber {
    return val1.minus(val2);
  }

  public static add(val1: BigNumber, val2: BigNumber): BigNumber {
    return val1.plus(val2);
  }

  public static divide(val1: BigNumber, val2: BigNumber): BigNumber {
    return val1.dividedBy(val2);
  }

  public static multiply(val1: BigNumber, val2: BigNumber): BigNumber {
    return val1.multipliedBy(val2);
  }

  public static formatIconAddressToShort(address: string): string {
    const length = address.length;
    return address.substring(0, 7) + "..." + address.substring(length - 7, length);
  }

  public static getNumberOfDaysInCurrentMonth(): number {
    const tmp = new Date();
    const d = new Date(tmp.getFullYear(), tmp.getMonth() + 1, 0);
    return d.getDate();
  }

  public static extractTxFailureMessage(tx: any): string {
    return tx?.failure?.message ?? "";
  }

  public static dispatchBridgeWidgetAction(action: BridgeWidgetAction): void {
    const event = new CustomEvent("bri.widget", {
      detail: {
        action: action.valueOf()
      }
    });
    log.debug("Dispatched Bridge event: ", event);
    window.dispatchEvent(event);
  }

  public static makeNegativeNumber(value: BigNumber | string): BigNumber {
    const bigNum = new BigNumber(value);
    if (bigNum.isZero() || !bigNum.isFinite()) {
      return new BigNumber("0");
    }

    if (typeof value === "string") {
      return (new BigNumber(value)).abs().negated();
    } else {
      return value.abs().negated();
    }
  }

  public static isUndefinedOrZero(value?: number | BigNumber): boolean {
    if (!value) {
      return false;
    } else if (value instanceof BigNumber) {
      return value.isZero();
    } else {
      return value === 0;
    }
  }

  public static countDecimals(value: number): number {
    if (!value) {
      return 0;
    }

    if (Math.floor(value) === value) {
      return 0;
    }

    const split = value.toString().split(".");
    if (!split || !split[1]) {
      return 0;
    }

    return split[1].length || 0;
  }

  public static extractTokenNamesFromPoolName(poolName: string): { baseTokenName: string, quoteTokenName: string } {
    const splitString = poolName.replace(" ", "").split("/");
    return {baseTokenName: splitString[0], quoteTokenName: splitString[1]};
  }

  public static timestampNowMilliseconds(): BigNumber {
    return new BigNumber(Date.now());
  }

  public static timestampNowMicroseconds(): BigNumber {
    return new BigNumber(Date.now()).multipliedBy(new BigNumber("1000"));
  }

  public static addDaysToTimestamp(timestamp: BigNumber, days: number): BigNumber {
    const dayInMilliSeconds = new BigNumber("86400000000");
    return timestamp.plus(dayInMilliSeconds.multipliedBy(days));
  }

  public static addSecondsToTimestamp(timestamp: BigNumber, seconds: number): BigNumber {
    const microSecond = new BigNumber("1000000");
    return timestamp.plus(microSecond.multipliedBy(seconds));
  }

  public static getBondPrincipalTokenName(bond: BondConfig): string {
    return bond.tag.split("|")[0];
  }

  public static getBondPayoutTokenName(bond: BondConfig): string {
    return bond.tag.split("|")[1];
  }

  public static secondsToHumanizedDateString(seconds: number | BigNumber): string {
    return moment.duration(+seconds * 1000).humanize();
  }
}
