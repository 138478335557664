import {Component, OnDestroy, OnInit} from '@angular/core';
import {ModalService} from "../../services/modal.service";
import {ModalType} from "../../models/enums/ModalType";
import {PersistenceService} from "../../services/persistence.service";
import {BaseClass} from "../../base-class";
import {NavItem} from "../../models/enums/NavItem";
import {StateChangeService} from "../../services/state-change.service";
import {UiStateService} from "../../services/ui-state.service";
import {Subscription} from 'rxjs';
import {HeaderService} from '../../services/header.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent extends BaseClass implements OnInit, OnDestroy {

  // subscriptions
  logoutChangeSub?: Subscription;
  userDataReloadSub?: Subscription;

  userHasActiveBond: boolean = false;

  constructor(private modalService: ModalService,
              public override persistenceService: PersistenceService,
              private stateChangeService: StateChangeService,
              private globalStateService: UiStateService,
              private headerService: HeaderService,
              private router: Router) {
    super(persistenceService);
  }

  ngOnInit(): void {
    this.initTemplateData();

    this.subscribeToStateChanges();
  }

  initTemplateData(): void {
    if (this.userIsLoggedIn()) {
      this.userHasActiveBond = this.persistenceService.userHasActiveBond();
    }
  }

  ngOnDestroy(): void {
    // clear subscriptions
    this.logoutChangeSub?.unsubscribe();
    this.userDataReloadSub?.unsubscribe();
  }

  isMenuActive(menu: NavItem): boolean {
    return this.headerService.isMenuActive(menu);
  }

  onConnectWalletClick(): void {
    if (this.userIsLoggedIn()) {
      this.modalService.setActiveModal(ModalType.WALLET);
    } else {
      this.modalService.setActiveModal(ModalType.SIGN_IN);
    }
  }

  userOwnsTreasuries(): boolean {
    return this.persistenceService.getUserTreasuriesNumber() > 0;
  }

  onDashboardClick(): void {
    this.globalStateService.hamMenuOpen = false;
    this.router.navigate(["/dashboard"]);
  }

  onMarketplaceClick(): void {
    this.globalStateService.hamMenuOpen = false;
    this.router.navigate(["/"]);
  }

  onMyBondsClick(): void {
    this.globalStateService.hamMenuOpen = false;
    this.router.navigate(["/my-bonds"]);
  }

  hamMenuOpen(): boolean {
    return this.globalStateService.hamMenuOpen;
  }

  /**
   * State change subscriptions
   */
  subscribeToStateChanges(): void {
    this.subscribeToUserDataReloadUpdate();
    this.subscribeToLogoutChange();
  }

  subscribeToUserDataReloadUpdate(): void {
    this.stateChangeService.userDataFinishedLoading$.subscribe(() => {
      this.initTemplateData();
    })
  }

  subscribeToLogoutChange(): void {
    // on user logout default to marketplace menu
    this.stateChangeService.logoutChange$.subscribe(() => this.router.navigate([""]));
  }

  onHamMenuClick() {
    this.globalStateService.toggleHamMenu();
  }

  onLogoClick() {
    this.router.navigate(["/"]);
  }
}
