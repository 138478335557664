import {Injectable, OnDestroy} from '@angular/core';
import {StateChangeService} from './state-change.service';
import {Times} from '../common/constants';
import {PersistenceService} from './persistence.service';
import {DataLoaderService} from './data-loader.service';

@Injectable({
  providedIn: 'root'
})
export class ReloaderService implements OnDestroy {

  balancesInterval?: NodeJS.Timeout

  constructor(private stateChangeService: StateChangeService,
              private persistenceService: PersistenceService,
              private dataLoaderService: DataLoaderService) {
    this.stateChangeService.loginChange$.subscribe(wallet => {
      if (wallet) {
        // register intervals if user logged in
        this.registerIntervals();
      } else {
        // register intervals if user logged out
        this.clearIntervals();
      }
    })
  }

  registerIntervals(): void {
    if (this.persistenceService.userIsLoggedIn()) {
      this.balancesInterval = setInterval(() => this.loadAllUserIrc2TokenBalances(),
        Times.secondsInMilliseconds(10));
    }
  }

  private loadAllUserIrc2TokenBalances(): void {
    // only load token balances if tab is active
    if (!document.hidden) {
      this.dataLoaderService.loadAllUserIrc2TokenBalances();
    }
  }

  clearIntervals(): void {
    if (this.balancesInterval) {
      clearInterval(this.balancesInterval);
    }
  }

  ngOnDestroy(): void {
    this.clearIntervals();
  }
}
