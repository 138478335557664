import BigNumber from "bignumber.js";

export class BondSuccessPayload {
  payoutAmount: BigNumber;
  bKarmaAmount: BigNumber;
  vestingTerm: BigNumber;
  txHash: string;

  constructor(payoutAmount: BigNumber, vestingTerm: BigNumber, txHash: string, bKarmaAmount: BigNumber) {
    this.payoutAmount = payoutAmount;
    this.vestingTerm = vestingTerm;
    this.txHash = txHash;
    this.bKarmaAmount =  bKarmaAmount;
  }
}
