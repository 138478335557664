import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppConfigService} from '../../services/app-config.service';
import {TokenBalanceI} from '../../models/interfaces/TokenBalanceI';
import {PersistenceService} from '../../services/persistence.service';
import {StateChangeService} from '../../services/state-change.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-token-balances',
  templateUrl: './token-balances.component.html'
})
export class TokenBalancesComponent implements OnInit, OnDestroy {

  tokenBalances: TokenBalanceI[] = [];

  // subscriptions
  userDataFinishedLoadingSub?: Subscription;
  userIrc2TokenBalancesSub?: Subscription;

  constructor(private appConfigService: AppConfigService,
              private persistenceService: PersistenceService,
              private stateChangeService: StateChangeService) { }

  ngOnInit(): void {
    this.registerSubscriptions();

    this.loadAllTokenBalances();
  }

  ngOnDestroy(): void {
    // remove subscriptions
    this.userDataFinishedLoadingSub?.unsubscribe();
    this.userIrc2TokenBalancesSub?.unsubscribe();
  }

  registerSubscriptions(): void {
    this.subscribeToUserDataReload();
    this.subscribeToUserIrc2TokenBalancesUpdate();
  }

  subscribeToUserIrc2TokenBalancesUpdate(): void {
    this.userIrc2TokenBalancesSub = this.stateChangeService.userIrc2TokenBalancesChange$.subscribe(() => {
      this.loadAllTokenBalances();
    });
  }

  subscribeToUserDataReload(): void {
    this.userDataFinishedLoadingSub = this.stateChangeService.userDataFinishedLoading$.subscribe(() => {
      this.loadAllTokenBalances();
    });
  }

  private loadAllTokenBalances(): void {
    if (this.persistenceService.userIsLoggedIn()) {
      const res: TokenBalanceI[] = [];

      // load IRC2 balances
      this.appConfigService.getSupportedIrc2Tokens().forEach(token => {
        const balance = this.persistenceService.getUserIrc2TokenBalance(token);

        // push only tokens with balance > 0
        if (balance.gt(0)) {
          res.push({
            img: token.img,
            name: token.name,
            balance
          });
        }
      });

      // load Baln LP balances
      this.appConfigService.getSupportedBalnLpTokensI().forEach(lpToken => {
        const balance = this.persistenceService.getUserBalnLpTokenIBalance(lpToken);

        // push only tokens with balance > 0
        if (balance.gt(0)) {
          res.push({
            img: lpToken.img,
            name: lpToken.tag,
            balance
          });
        }
      });

      // sort by descending balance
      res.sort((a: TokenBalanceI, b: TokenBalanceI) => b.balance.minus(a.balance).toNumber());
      this.tokenBalances = res;
    }
  }


}
