<div class="table table-content">
  <div class="table-item table-item-1">
    <div style="position: relative">
      <img src="assets/images/{{principalImg()}}" (error)="handlePrincipalImgError($event)" loading="lazy"
           width="{{bond.baseInfo.principalIsLpToken ? '58' : '32'}}" alt="" class="table-item-pair-image">
      <span class="dot-{{bondIsEnabled ? 'green' : 'red'}}"></span>
    </div>
    <div class="table-item-pair-content">
      <div class="table-item-pair-name">{{principalTokenName()}}</div>
    </div>
  </div>
  <div class="table-item table-item-3" style="flex-direction: column">
    <div class="table-item table-item-3">
      <img src="assets/images/{{payoutImg()}}" (error)="handlePrincipalImgError($event)"  loading="lazy" width="32" alt="" class="table-item-pair-image">
      <div class="table-item-pair-content">
        <div class="table-item-pair-name">{{treasuryPayoutBalance.toFixed(2) | usFormat | toZeroIfDash}}</div>
        <div class="table-item-pair-subtext">${{treasuryPayoutUsdBalance.toFixed(2) | usFormat | toZeroIfDash}}</div>
      </div>
    </div>
    <a data-w-id="a98553fb-eec2-9bc3-ca82-3493bf4569c6" href="javascript:void(0);" (click)="onWithdrawPayoutClick()"
       class="button-small button-bond w-inline-block">
      <div>Withdraw</div>
    </a>
  </div>

  <div class="table-item table-item-3" style="flex-direction: column">
    <div class="table-item table-item-3">
      <img src="assets/images/{{principalImg()}}" (error)="handlePrincipalImgError($event)"  loading="lazy" width="{{bond.baseInfo.principalIsLpToken ? '58' : '32'}}" alt="" class="table-item-pair-image">
      <div class="table-item-pair-content">
        <div class="table-item-pair-name">{{treasuryPrincipalBalance.toFixed(2) | usFormat | toZeroIfDash}}</div>
        <div class="table-item-pair-subtext">${{treasuryPrincipalUsdBalance.toFixed(2) | usFormat | toZeroIfDash}}</div>
      </div>
    </div>
    <a data-w-id="a98553fb-eec2-9bc3-ca82-3493bf4569c6" href="javascript:void(0);" (click)="onWithdrawPrincipalClick()"
       class="button-small button-bond w-inline-block">
      <div>Withdraw</div>
    </a>
  </div>

  <div class="table-item table-item-4">
    <a data-w-id="a98553fb-eec2-9bc3-ca82-3493bf4569c6" href="javascript:void(0);" (click)="onTopUpClick()"
       class="button-small button-bond w-inline-block">
      <div>Top up</div>
    </a>
  </div>
  <div class="table-item table-item-5">
    <a data-w-id="a98553fb-eec2-9bc3-ca82-3493bf4569c6" href="javascript:void(0);" (click)="onToggleBondClick()"
       class="button-small button-bond w-inline-block">
      <div>{{bondIsEnabled ? 'Disable' : 'Enable'}} bond</div>
    </a>
  </div>
</div>
