<div class="heading-section wf-section">
  <div class="container w-container">
    <h1 class="heading-2">Custom Treasury Management</h1>
  </div>
</div>

<div class="pairs-section wf-section">
  <div class="container w-container">
    <div class="table table-header">
      <div class="table-header-item table-header-item-1">
        <div class="table-header-item-text">Bonds</div>
      </div>
      <div class="table-header-item hide-mobile table-header-item-3">
        <div class="table-header-item-text roi">
          <span class="cursor-pointer">PAY OUT TOKEN</span>
          <img src="assets/images/tooltip-icon.svg" class="tooltip-icon">
          <span class="roi-tooltip">
            Payout out token is the token being vested to the user when principal token is bonded.
            (e.g. user bonds OMM/IUSDC LP token - principal in return for OMM token - payout which gets vested to the user)
          </span>
        </div>
      </div>
      <div class="table-header-item hide-mobile table-header-item-3">
        <div class="table-header-item-text roi">
          <span class="cursor-pointer">PRINCIPAL TOKEN</span>
          <img src="assets/images/tooltip-icon.svg" class="tooltip-icon">
          <span class="roi-tooltip">
            Principal token is the token user exchanges/bonds in return for payout token
            (e.g. user bonds OMM/IUSDC LP token - principal in return for OMM token - payout.)
            </span>
        </div>
      </div>
      <div class="table-header-item hide-mobile table-header-item-3">
        <div class="table-header-item-text roi">
          <span class="cursor-pointer">TOP UP TREASURY PAY OUT TOKEN</span>
          <img src="assets/images/tooltip-icon.svg" class="tooltip-icon">
          <span class="roi-tooltip">
            Payout token balance in custom treasury must be sufficient in order for users to be able to bond.
            Use the below button to transfer the payout asset to the custom treasury.
          </span>
        </div>
      </div>
      <div class="table-header-item hide-mobile table-header-item-3">
        <div class="table-header-item-text roi">
          <span class="cursor-pointer">TOGGLE BOND</span>
          <img src="assets/images/tooltip-icon.svg" class="tooltip-icon">
          <span class="roi-tooltip">
            Enable or disable bond.
          </span>
        </div>
      </div>
    </div>
    <ng-container *ngFor="let bond of bonds; last as isLast; let i = index">
      <app-custom-treasury-row [bond]="bond" [ngStyle]="{ display: 'contents' }"></app-custom-treasury-row>
    </ng-container>
  </div>
</div>
