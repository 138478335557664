import {Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import {ModalService} from "../../services/modal.service";
import {BaseClass} from "../../base-class";
import {ModalType} from "../../models/enums/ModalType";
import {AnimationOptions} from "ngx-lottie";
import {AnimationItem} from "lottie-web";
import {PersistenceService} from "../../services/persistence.service";
import {StateChangeService} from "../../services/state-change.service";
import {WalletType} from "../../models/classes/Wallet";
import {IconexApiService} from "../../services/iconex-api.service";
import {BridgeWidgetService} from "../../services/bridge-widget.service";
import {LogoutService} from "../../services/logout.service";
import {ActiveModal} from "../../models/interfaces/ActiveModal";
import BigNumber from "bignumber.js";
import {CalculationService} from "../../services/calculation-service/calculation.service";
import {ScoreApiService} from "../../services/score-api.service";
import {BondConfig} from "../../models/classes/BondConfigInfo";
import log from "loglevel";
import {TransactionDispatcherService} from "../../services/transaction-dispatcher.service";
import {NotificationService} from "../../services/notification.service";
import { BOND_UNDEFINED, UNEXPECTED_ERROR, ZERO } from "../../common/constants";
import {BondModalPayload} from "../../models/classes/BondModalPayload";
import {ModalPayload} from "../../models/types/Types";
import {UserAction} from "../../models/classes/UserAction";
import {UserActionType} from "../../models/enums/UserActionType";
import {BondSuccessPayload} from "../../models/classes/BondSuccessPayload";
import {BondClaimModalPayload} from "../../models/classes/BondClaimModalPayload";
import {ClaimBondSuccessPayload} from "../../models/classes/ClaimBondSuccessPayload";
import {environment} from "../../../environments/environment";
import {LedgerModalComponent} from "../ledger-modal/ledger-modal.component";
import {BalnLpToken} from "../../models/classes/BalnLpToken";
import {BondTogglePayload} from "../../models/classes/BondTogglePayload";
import {ToggleBondSuccessPayload} from "../../models/classes/ToggleBondSuccessPayload";
import {WithdrawPayoutPayload} from "../../models/classes/WithdrawPayoutPayload";
import {Utils} from "../../common/utils";
import {WithdrawPayoutSuccessPayload} from "../../models/classes/WithdrawPayoutSuccessPayload";
import {WithdrawPrincipalSuccessPayload} from "../../models/classes/WithdrawPrincipalSuccessPayload";
import {WithdrawPrincipalPayload} from "../../models/classes/WithdrawPrincipalPayload";
import {TopUpPayload} from "../../models/classes/TopUpPayload";
import {TopUpPayloadSuccess} from "../../models/classes/TopUpPayloadSuccess";


@Component({
  selector: "app-modal",
  templateUrl: "./modal.component.html"
})
export class ModalComponent extends BaseClass implements OnInit {

  @ViewChild("withdrawIrc2Input", { static: true }) withdrawIrc2InputEl!: ElementRef;
  @ViewChild("withdrawPrincInput", { static: true }) withdrawPrincipalInputEl!: ElementRef;
  @ViewChild("topUpInput", { static: true }) topUpInputEl!: ElementRef;
  @ViewChild(LedgerModalComponent) ledgerModalComponent!: LedgerModalComponent;

  copyString = "Copy address";
  withdrawIrc2Input = 0;
  withdrawPrincipalInput = 0;
  topUpInput = 0;

  /* Confetti styling and options */
  showConfetti = false;

  options: AnimationOptions = {
    path: "assets/documents/confetti.json",
    autoplay: true,
    loop: 1,
  };

  styles: Partial<CSSStyleDeclaration> = {
    background: "transparent"
  };

  constructor(private modalService: ModalService,
              public override persistenceService: PersistenceService,
              private stateChangeService: StateChangeService,
              private iconexApiService: IconexApiService,
              private bridgeWidgetService: BridgeWidgetService,
              private logoutService: LogoutService,
              private calculationService: CalculationService,
              private scoreApiService: ScoreApiService,
              private transactionDispatcherService: TransactionDispatcherService,
              private notificationService: NotificationService) {
    super(persistenceService);
  }

  ngOnInit(): void {
    this.subscribeToModalChange();
  }

  subscribeToModalChange(): void {
    this.stateChangeService.modalChange$.subscribe(modalType => {
      // hide confetti after 5 seconds
      if (modalType === ModalType.BOND_SUCCESS || modalType === ModalType.CLAIM_SUCCESS) {
        this.showConfetti = true;
        setTimeout(() => this.showConfetti = false, 5000);
      } else {
        this.showConfetti = false;
      }
    });
  }

  onLoginClick(walletType: WalletType): void {
    switch (walletType) {
      case WalletType.ICON:
        this.onSignInIconexClick();
        break;
      case WalletType.BRIDGE:
        this.onSignInBridgeClick();
        break;
      case WalletType.LEDGER:
        this.onSignInLedgerClick();
        break;
    }
  }

  animationCreated(animationItem: AnimationItem): void {
    animationItem.setSpeed(1);
  }

  isModalActive(type: ModalType): boolean {
    return this.modalService.activeModal?.type === type;
  }

  activeModal(): ActiveModal | undefined {
    return this.modalService.activeModal;
  }

  hideActiveModal(): void {
    this.modalService.hideActiveModal();
  }

  showSignInModal(): void {
    this.modalService.setActiveModal(ModalType.SIGN_IN);
  }

  isBondModalActive(): boolean {
    return this.activeModalPayload() instanceof BondModalPayload && this.activeModal()?.type === ModalType.BOND;
  }

  onClaimClick(): void {
    log.debug("[onClaimClick]");
    const bond = this.claimBond();
    log.debug("[onClaimClick] bond:", bond);
    const claimedAmount = this.claimedAmount();
    log.debug("[onClaimClick] claimedSuccessAmount:", claimedAmount);

    if (bond) {
      // show loading modal
      this.showLoadingModal();

      try {
        const tx = this.scoreApiService.buildClaimTx(bond);
        // dispatch built transaction
        this.transactionDispatcherService.dispatchTransaction(tx,
          new UserAction(UserActionType.CLAIM, new ClaimBondSuccessPayload(claimedAmount, bond, ""))
        );
      } catch (e) {
        // Show unexpected error notification and hide active modal if error pops
        this.notificationService.showErrorNotification(UNEXPECTED_ERROR);
        this.notificationService.hideActiveNotification();
      }
    } else {
      this.notificationService.hideActiveNotification();
      this.notificationService.showAlertNotification(BOND_UNDEFINED);
    }
  }

  onBondToggleClick(): void {
    const payload = this.activeModalPayload();

    if (payload && payload instanceof BondTogglePayload) {
      const bond = payload.bond;

      if (bond) {
        // show loading modal
        this.showLoadingModal();

        try {
          const tx = this.scoreApiService.buildToggleBondTx(bond);
          // dispatch built transaction
          this.transactionDispatcherService.dispatchTransaction(tx,
            new UserAction(UserActionType.TOGGLE_BOND, new ToggleBondSuccessPayload(payload.bondIsEnabled, bond, ""))
          );
        } catch (e) {
          // Show unexpected error notification and hide active modal if error pops
          this.notificationService.showErrorNotification(UNEXPECTED_ERROR);
          this.notificationService.hideActiveNotification();
        }
      } else {
        this.notificationService.hideActiveNotification();
        this.notificationService.showAlertNotification(BOND_UNDEFINED);
      }
    }
  }

  userPayoutBalance(): BigNumber {
    const payload = this.activeModalPayload();
    if (payload instanceof TopUpPayload) {
      return this.persistenceService.getUserTokenBalance(payload.bond.payoutToken);
    } else {
      return Utils.ZERO;
    }
  }

  treasuryPayoutBalance(): BigNumber {
    const payload = this.activeModalPayload();
    if (payload && (payload instanceof WithdrawPayoutPayload || payload instanceof TopUpPayload)) {
      return this.persistenceService.getBondTreasuryPayoutBalance(payload.bond.tag);
    } else {
      return Utils.ZERO;
    }
  }

  treasuryPrincipalBalance(): BigNumber {
    const payload = this.activeModalPayload();
    if (payload && payload instanceof WithdrawPrincipalPayload) {
      return this.persistenceService.getBondTreasuryPrincipalBalance(payload.bond.tag);
    } else {
      return Utils.ZERO;
    }
  }

  withdrawDestinationAddress(): string {
    const payload = this.activeModalPayload();
    return payload && (payload instanceof WithdrawPayoutPayload || payload instanceof WithdrawPayoutSuccessPayload
    || payload instanceof WithdrawPrincipalPayload || payload instanceof WithdrawPrincipalSuccessPayload) ? payload.destination : "";
  }

  claimBond(): BondConfig | undefined {
    const payload = this.activeModalPayload();
    return payload && payload instanceof BondClaimModalPayload ? payload.bond : undefined;
  }

  claimedPayoutTokenName(): string {
    const payload = this.activeModalPayload();
    return payload && payload instanceof ClaimBondSuccessPayload ? payload.bond.baseInfo.payoutTokenName : "";
  }

  claimedAmount(): BigNumber {
    const payload = this.activeModalPayload();
    return payload && payload instanceof BondClaimModalPayload ? payload.claimable : new BigNumber(0);
  }

  claimedSuccessAmount(): BigNumber {
    const payload = this.activeModalPayload();
    return payload && payload instanceof ClaimBondSuccessPayload ? payload.claimed : new BigNumber(0);
  }

  claimedSuccessTrackerLink(): string {
    const payload = this.activeModalPayload();
    return payload && payload instanceof ClaimBondSuccessPayload ? this.constructTrackerLink(payload.txHash) : "";
  }

  toggleBondSuccessTrackerLink(): string {
    const payload = this.activeModalPayload();
    return payload && payload instanceof ToggleBondSuccessPayload ? this.constructTrackerLink(payload.txHash) : "";
  }

  withdrawSuccessTrackerLink(): string {
    const payload = this.activeModalPayload();
    return payload && (payload instanceof WithdrawPayoutSuccessPayload || payload instanceof WithdrawPrincipalSuccessPayload)
        ? this.constructTrackerLink(payload.txHash) : "";
  }

  topUpSuccessTrackerLink(): string {
    const payload = this.activeModalPayload();
    return payload && payload instanceof TopUpPayloadSuccess ? this.constructTrackerLink(payload.txHash) : "";
  }

  bondToggledSuccess(): ToggleBondSuccessPayload | undefined {
    const payload = this.activeModalPayload();
    return payload && payload instanceof ToggleBondSuccessPayload ? payload : undefined;
  }

  bondSuccessTrackerLink(): string {
    const payload = this.activeModalPayload();
    return payload && payload instanceof BondSuccessPayload ? this.constructTrackerLink(payload.txHash) : "";
  }

  constructTrackerLink(txHash: string): string {
    return environment.trackerUrl + "/transaction/" + txHash;
  }

  customBondLink(): string {
    const payload = this.activeModalPayload();
    return payload && payload instanceof BondModalPayload ? payload.bond.getCustomBondTrackerUrl() : "";
  }

  principalTokenName(): string {
    const payload = this.activeModalPayload();
    if (payload && (payload instanceof BondModalPayload || payload instanceof BondTogglePayload || payload instanceof WithdrawPayoutPayload
      || payload instanceof WithdrawPayoutSuccessPayload || payload instanceof WithdrawPrincipalPayload
      || payload instanceof WithdrawPrincipalSuccessPayload
      || payload instanceof  TopUpPayloadSuccess)) {
      return payload.bond.baseInfo.principalTokenName;
    } else {
      return "";
    }
  }

  bondIsEnabled(): boolean {
    const payload = this.activeModalPayload();
    return payload && payload instanceof BondTogglePayload ? payload.bondIsEnabled : false;
  }

  claimPrincipalTokenName(): string {
    const payload = this.activeModalPayload();
    return payload && payload instanceof BondClaimModalPayload ? payload.bond.baseInfo.principalTokenName : "";
  }

  principalIsLp(): boolean {
    const payload = this.activeModalPayload();
    return payload && (payload instanceof BondModalPayload || payload instanceof WithdrawPayoutPayload)
        ? payload.bond.baseInfo.principalIsLpToken : false;
  }

  claimPrincipalIsLp(): boolean {
    const payload = this.activeModalPayload();
    return payload && payload instanceof BondClaimModalPayload ? payload.bond.baseInfo.principalIsLpToken : false;
  }

  principalTokenImg(): string {
    const payload = this.activeModalPayload();
    if (payload && (payload instanceof BondModalPayload || payload instanceof BondTogglePayload || payload instanceof WithdrawPayoutPayload
      || payload instanceof WithdrawPrincipalPayload)) {
      return payload.bond.principalToken.img;
    } else {
      return "";
    }
  }

  payoutTokenName(): string {
    const payload = this.activeModalPayload();
    if (payload && (payload instanceof BondModalPayload || payload instanceof BondTogglePayload || payload instanceof WithdrawPayoutPayload
    || payload instanceof  WithdrawPayoutSuccessPayload || payload instanceof  TopUpPayload || payload instanceof  TopUpPayloadSuccess)) {
      return payload.bond.baseInfo.payoutTokenName;
    } else {
      return "";
    }
  }

  claimPayoutTokenName(): string {
    const payload = this.activeModalPayload();
    return payload && payload instanceof BondClaimModalPayload ? payload.bond.baseInfo.payoutTokenName : "";
  }

  payoutTokenImg(): string {
    const payload = this.activeModalPayload();
    return payload && (payload instanceof BondModalPayload || payload instanceof WithdrawPayoutPayload || payload instanceof TopUpPayload)
      ? payload.bond.payoutToken.img : "";
  }

  claimBondRoi(): BigNumber {
    const payload = this.activeModalPayload();
    return payload && payload instanceof BondClaimModalPayload ? payload.roi : new BigNumber(0);
  }

  claimBondPriceUSD(): BigNumber {
    const payload = this.activeModalPayload();
    return payload && payload instanceof BondClaimModalPayload ? payload.bondPrice : new BigNumber(0);
  }

  claimBondMarketPriceUSD(): BigNumber {
    const payload = this.activeModalPayload();
    return payload && payload instanceof BondClaimModalPayload ? payload.marketPrice : new BigNumber(0);
  }

  claimBondClaimable(): BigNumber {
    const payload = this.activeModalPayload();
    return payload && payload instanceof BondClaimModalPayload ? payload.claimable : new BigNumber(0);
  }

  claimBondPending(): BigNumber {
    const payload = this.activeModalPayload();
    return payload && payload instanceof BondClaimModalPayload ? payload.pending : new BigNumber(0);
  }


  userPrincipalTokenBalance(): BigNumber {
    const payload = this.activeModalPayload();

    if (payload && payload instanceof BondModalPayload) {
      return (payload.bond.principalToken instanceof BalnLpToken) ? this.persistenceService.getUserLpBalance(
          payload.bond.principalToken.poolId) : this.persistenceService.getUserIrc2TokenBalance(payload.bond.principalToken);
    } else {
      return ZERO;
    }
  }

  handleWithdrawIrc2Submit(event: any): void {
    // prevent default submit behavior
    event.preventDefault();

    const bond = this.activeBond();

    if (this.withdrawIrc2Input <= 0) {
      return;
    } else if (this.persistenceService.getBondTreasuryPayoutBalance(bond?.tag).lt(this.withdrawIrc2Input)) {
      this.notificationService.showErrorNotification("Amount can not be greater than treasury payout token balance.");
      return;
    }

    const payload = this.activeModalPayload();

    if (bond && payload instanceof WithdrawPayoutPayload) {
      // show loading modal
      this.showLoadingModal();

      const amount = new BigNumber(this.withdrawIrc2Input.toString());
      const destination = this.persistenceService.activeWallet?.address ?? "";
      const token = bond.payoutToken.address;
      const tx = this.scoreApiService.buildWithdrawIrc2PayoutFromTreasuryTx(bond, amount, destination);

      log.debug("TX:");
      log.debug(tx);

      try {
        // dispatch built transaction
        this.transactionDispatcherService.dispatchTransaction(tx,
          new UserAction(UserActionType.WITHDRAW_PAYOUT_TOKEN, new WithdrawPayoutSuccessPayload(bond, amount, token, destination, "")));

        // Clear input
        this.withdrawIrc2InputEl.nativeElement.value = "0";
        this.withdrawIrc2Input = 0;
      } catch (e) {
        // Show unexpected error notification and hide active modal if error pops
        this.notificationService.showErrorNotification(UNEXPECTED_ERROR);
        this.notificationService.hideActiveNotification();
      }
    } else {
      this.notificationService.showErrorNotification("Bond or payload object undefined. Close modal and try again.");
    }
  }

  handleWithdrawPrincipalSubmit(event: any): void {
    // prevent default submit behavior
    event.preventDefault();

    const bond = this.activeBond();
    const payload = this.activeModalPayload();

    if (bond && payload instanceof WithdrawPrincipalPayload) {
      if (this.withdrawPrincipalInput <= 0) {
        return;
      } else if (this.persistenceService.getBondTreasuryPrincipalBalance(bond?.tag).lt(this.withdrawPrincipalInput)) {
        this.notificationService.showErrorNotification("Amount can not be greater than treasury principal token balance.");
        return;
      }

      // show loading modal
      this.showLoadingModal();

      const amount = new BigNumber(this.withdrawPrincipalInput.toString());
      const destination = this.persistenceService.activeWallet?.address ?? "";
      const tx = this.scoreApiService.buildWithdrawPrincipalFromTreasuryTx(bond, amount, destination);

      log.debug("TX:");
      log.debug(tx);

      try {
        // dispatch built transaction
        this.transactionDispatcherService.dispatchTransaction(tx,
          new UserAction(UserActionType.WITHDRAW_PRINCIPAL_TOKEN, new WithdrawPrincipalSuccessPayload(bond, amount, destination, "")));

        // Clear input
        this.withdrawPrincipalInputEl.nativeElement.value = "0";
        this.withdrawPrincipalInput = 0;
      } catch (e) {
        // Show unexpected error notification and hide active modal if error pops
        this.notificationService.showErrorNotification(UNEXPECTED_ERROR);
        this.notificationService.hideActiveNotification();
      }
    } else {
      this.notificationService.showErrorNotification("Bond or payload object undefined. Close modal and try again.");
    }
  }

  handleTopUpSubmit(event: any): void {
    // prevent default submit behavior
    event.preventDefault();

    const bond = this.activeBond();
    const payload = this.activeModalPayload();

    if (bond && payload instanceof TopUpPayload) {
      if (this.topUpInput <= 0) {
        return;
      } else if (this.persistenceService.getUserTokenBalance(bond.payoutToken).lt(this.topUpInput)) {
        this.notificationService.showErrorNotification("Amount can not be greater than user payout token balance.");
        return;
      }

      // show loading modal
      this.showLoadingModal();

      const amount = new BigNumber(this.topUpInput.toString());
      const tx = this.scoreApiService.buildTransferPayoutToTreasuryTx(bond, amount);

      log.debug("TX:");
      log.debug(tx);

      try {
        // dispatch built transaction
        this.transactionDispatcherService.dispatchTransaction(tx,
          new UserAction(UserActionType.TOP_UP_PAYOUT_TOKEN, new TopUpPayloadSuccess(amount, bond, "")));

        // Clear input
        this.topUpInputEl.nativeElement.value = "0";
        this.topUpInput = 0;
      } catch (e) {
        // Show unexpected error notification and hide active modal if error pops
        this.notificationService.showErrorNotification(UNEXPECTED_ERROR);
        this.notificationService.hideActiveNotification();
      }
    } else {
      this.notificationService.showErrorNotification("Bond or payload object undefined. Close modal and try again.");
    }
  }



  onWithdrawIrc2LostFocus(e: any): void {
    const bond = this.activeBond();
    const value = e.target.value;
    const amount = new BigNumber(value);

    if (value && bond) {
      if (amount.lte(this.persistenceService.getBondTreasuryPayoutBalance(bond.tag))) {
        this.withdrawIrc2InputEl.nativeElement.value = value;
        this.withdrawIrc2Input = +value;
      } else {
        this.notificationService.showErrorNotification("Amount can not be greater than treasury payout token balance.");
      }
    } else {
      this.notificationService.showErrorNotification("Invalid amount.");
    }
  }

  onWithdrawPrincipalLostFocus(e: any): void {
    const bond = this.activeBond();
    const value = e.target.value;
    const amount = new BigNumber(value);

    if (!bond) {
      this.notificationService.showErrorNotification("Bond object undefined.");
      return;
    }

    if (value) {
      if (amount.lte(this.persistenceService.getBondTreasuryPrincipalBalance(bond.tag))) {
        this.withdrawPrincipalInputEl.nativeElement.value = value;
        this.withdrawPrincipalInput = +value;
      } else {
        this.notificationService.showErrorNotification("Amount can not be greater than treasury principal token balance.");
      }
    } else {
      this.notificationService.showErrorNotification("Invalid amount.");
    }
  }

  onTopUpPayoutLostFocus(e: any): void {
    const bond = this.activeBond();
    const value = e.target.value;
    const amount = new BigNumber(value);

    if (!bond) {
      this.notificationService.showErrorNotification("Bond object undefined.");
      return;
    }

    if (value) {
      if (amount.lte(this.persistenceService.getUserTokenBalance(bond.payoutToken))) {
        this.topUpInputEl.nativeElement.value = value;
        this.topUpInput = +value;
      } else {
        this.notificationService.showErrorNotification("Amount can not be greater than user payout token balance.");
      }
    } else {
      this.notificationService.showErrorNotification("Invalid amount.");
    }
  }

  onMaxWithdrawIrc2Click(): void {
    const bond = this.activeBond();

    if (bond) {
      this.withdrawIrc2InputEl.nativeElement.value = this.persistenceService.getBondTreasuryPayoutBalance(bond.tag).dp(2);
      this.withdrawIrc2Input = this.persistenceService.getBondTreasuryPayoutBalance(bond.tag).dp(2).toNumber();
      this.onWithdrawIrc2LostFocus({ target: { value: this.withdrawIrc2InputEl.nativeElement.value } });
    }
  }

  onMaxWithdrawPrincipalClick(): void {
    const bond = this.activeBond();

    if (bond) {
      this.withdrawPrincipalInputEl.nativeElement.value = this.persistenceService.getBondTreasuryPrincipalBalance(bond.tag).dp(2);
      this.withdrawPrincipalInput = this.persistenceService.getBondTreasuryPrincipalBalance(bond.tag).dp(2).toNumber();
      this.onWithdrawPrincipalLostFocus({ target: { value: this.withdrawPrincipalInputEl.nativeElement.value } });
    }
  }

  onMaxTopUpPayoutTokenClick(): void {
    const bond = this.activeBond();

    if (bond) {
      this.topUpInputEl.nativeElement.value = this.persistenceService.getUserTokenBalance(bond.payoutToken).dp(2);
      this.topUpInput = this.persistenceService.getUserTokenBalance(bond.payoutToken).dp(2).toNumber();
      this.onTopUpPayoutLostFocus({ target: { value: this.topUpInputEl.nativeElement.value } });
    }
  }

  activeModalPayload(): ModalPayload | undefined {
    return this.activeModal()?.payload;
  }

  activeBond(): BondConfig | undefined {
    const payload = this.activeModalPayload();
    return payload && (payload instanceof BondModalPayload || payload instanceof WithdrawPayoutPayload
      || payload instanceof WithdrawPrincipalPayload || payload instanceof WithdrawPrincipalSuccessPayload
      || payload instanceof WithdrawPayoutSuccessPayload || payload instanceof TopUpPayload
      || payload instanceof TopUpPayloadSuccess) ? payload.bond : undefined;
  }

  withdrawnAmount(): BigNumber {
    const payload = this.activeModalPayload();
    return payload && (payload instanceof WithdrawPayoutSuccessPayload || payload instanceof WithdrawPrincipalSuccessPayload)
        ? payload.amount : ZERO;
  }

  toppedAmount(): BigNumber {
    const payload = this.activeModalPayload();
    return payload && (payload instanceof TopUpPayloadSuccess) ? payload.amount
      : new BigNumber(0);
  }

  claimBondVestingTerm(): BigNumber {
    const payload = this.activeModalPayload();
    return payload && payload instanceof BondClaimModalPayload ? payload.vestingTerm : new BigNumber(0);
  }

  successBondPayoutAmount(): BigNumber {
    const payload = this.activeModalPayload();
    return payload && payload instanceof BondSuccessPayload ? payload.payoutAmount : new BigNumber(0);
  }

  successBondbKarmaAmount(): BigNumber {
    const payload = this.activeModalPayload();
    return payload && payload instanceof BondSuccessPayload ? payload.bKarmaAmount : new BigNumber(0);
  }

  successBondVestingTerm(): BigNumber {
    const payload = this.activeModalPayload();
    return payload && payload instanceof BondSuccessPayload ? payload.vestingTerm : new BigNumber(0);
  }


  /**
   * SIGN IN modal
   */

  onSignOutClick() {
    this.logoutService.signOutUser();
    this.modalService.hideActiveModal();
  }

  onSignInIconexClick(): void {
    this.modalService.hideActiveModal();
    this.iconexApiService.hasAccount();
  }

  onSignInBridgeClick(): void {
    this.modalService.hideActiveModal();
    this.bridgeWidgetService.openBridgeWidget();
  }

  onSignInLedgerClick(): void {
    this.ledgerModalComponent.onSignInLedgerClick();
  }

  onCopyIconAddressClick(): void {
    const textArea = document.createElement("textarea");

    // Place in top-left corner of screen regardless of scroll position.
    textArea.style.position = "fixed";
    textArea.style.top = "0";
    textArea.style.left = "0";

    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textArea.style.width = "2em";
    textArea.style.height = "2em";

    // We don't need padding, reducing the size if it does flash render.
    textArea.style.padding = "0";

    // Clean up any borders.
    textArea.style.border = "none";
    textArea.style.outline = "none";
    textArea.style.boxShadow = "none";

    // Avoid flash of white box if rendered for any reason.
    textArea.style.background = "transparent";
    textArea.value = this.persistenceService.activeWallet?.address ?? "";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand("copy");
      const msg = successful ? "successful" : "unsuccessful";

      if (msg !== "successful" || !textArea.value) {
        this.copyString = "Oops, unable to copy";
      } else {
        // show notification
        this.copyString = "Copied!";
      }
    } catch (err) {
      this.copyString = "Oops, unable to copy";
    }

    setTimeout(() => this.copyString = "Copy address", 3000);

    document.body.removeChild(textArea);
  }

  showLoadingModal(): void {
    this.modalService.setActiveModal(ModalType.LOADING);
  }


}
