import {BondConfig} from './BondConfigInfo';

export class WithdrawPayoutPayload {
  tokenAddress: string;
  destination: string;
  bond: BondConfig;

  constructor(bond: BondConfig, tokenAddress: string, destination: string) {
    this.tokenAddress = tokenAddress;
    this.destination = destination;
    this.bond = bond;
  }
}
