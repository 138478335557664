import { Irc2TokenTransaction } from './Irc2TokenTransaction';

export class SupportedIrc2TokenTransaction {
  iusdcTransactions: Irc2TokenTransaction[];
  sicxTransactions: Irc2TokenTransaction[];
  ommTransactions: Irc2TokenTransaction[];

  constructor(iusdcTransactions: Irc2TokenTransaction[], sicxTransactions: Irc2TokenTransaction[], ommTransactions: Irc2TokenTransaction[]) {
    this.iusdcTransactions = iusdcTransactions;
    this.sicxTransactions = sicxTransactions;
    this.ommTransactions = ommTransactions;
  }
}