import {BondConfig} from './BondConfigInfo';

export class WithdrawPrincipalPayload {
  destination: string;
  bond: BondConfig;

  constructor(bond: BondConfig, destination: string) {
    this.destination = destination;
    this.bond = bond;
  }
}
