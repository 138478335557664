export class ScoreMethodNames {

  /**
   * CustomBond SCORE
   */
  public static BOND_PRICE = "bondPrice";
  public static TRUE_BOND_PRICE = "trueBondPrice";
  public static MAX_PAYOUT = "maxPayout";
  public static PAYOUT_FOR = "payoutFor";
  public static DEBT_RATIO = "debtRatio";
  public static CURRENT_DEBT = "currentDebt";
  public static DEBT_DECAY = "debtDecay";
  public static PERCENT_VESTED_FOR = "percentVestedFor";
  public static PENDING_PAYOUT_FOR = "pendingPayoutFor";
  public static CURRENT_KARMA_FEE = "currentKarmaFee";
  public static NAME = "name";
  public static KARMA_TREASURY = "karmaTreasury";
  public static TOTAL_PRINCIPAL_BONDED = "totalPrincipalBonded";
  public static TOTAL_PAYOUT_GIVEN = "totalPayoutGiven";
  public static TOTAL_DEBT = "totalDebt";
  public static PAYOUT_SINCE_LAST_SUBSIDY = "payoutSinceLastSubsidy";
  public static LAST_DECAY = "lastDecay";
  public static TERMS = "terms";
  public static ADJUSTMENT = "adjustment";
  public static FEE_TIERS = "feeTiers";
  public static BOND_INFO = "bondInfo";
  public static REDEEM = "redeem";

  /**
   * Custom treasury methods
   */

  public static BOND_CONTRACT_STATUS = "bondContract";
  public static CUSTOM_TREASURY_OWNER = "policy";
  public static TOGGLE_BOND = "toggleBondContract";
  public static WITHDRAW = "withdraw";
  public static WITHDRAW_LP = "withdrawLp";

  /**
   * Balanced DEX SCORE
   */
  public static GET_PRICE_BY_NAME = "getPriceByName";
  public static GET_POOL_STATS = "getPoolStats";
  public static GET_POOL_TOTAL = "getPoolTotal";
  public static GET_PRICE = "getPrice";

  /**
   * OMM Price Oracle SCORE
   */
  public static GET_REFERENCE_DATA = "get_reference_data";

  /**
   * sICX SCORE
   */
  public static PRICE_IN_LOOP = "priceInLoop";

  /**
   * IRC2 interface methods
   */
  public static TRANSFER = "transfer";
  public static BALANCE_OF = "balanceOf";
  public static AVAILABLE_BALANCE_OF = "availableBalanceOf";

  /**
   * KarmaOracle SCORE
   */
  public static GET_USD_PRICE = "getUsdPrice";

}
