import {Injectable} from '@angular/core';
import {PersistenceService} from './persistence.service';
import IconService from 'icon-sdk-js';
import {IconexApiService} from './iconex-api.service';
import {BridgeWidgetService} from './bridge-widget.service';
import {IconApiService} from './icon-api.service';
import {TransactionResultService} from './transaction-result.service';
import BigNumber from 'bignumber.js';
import {IconexId} from '../models/enums/IconexId';
import log from 'loglevel';
import {WalletType} from '../models/classes/Wallet';
import {NotificationService} from './notification.service';
import {UserAction} from '../models/classes/UserAction';
import {LedgerService} from './ledger.service';
import {BondActionPayload} from '../models/classes/BondActionPayload';
import {ScoreApiService} from './score-api.service';
import {ModalService} from './modal.service';
import {DataLoaderService} from './data-loader.service';

const { IconConverter } = IconService;

@Injectable({
  providedIn: 'root'
})
export class TransactionDispatcherService {

  constructor(
    private persistenceService: PersistenceService,
    private iconexApiService: IconexApiService,
    private bridgeWidgetService: BridgeWidgetService,
    private notificationService: NotificationService,
    private iconApiService: IconApiService,
    private transactionResultService: TransactionResultService,
    private ledgerService: LedgerService,
    private scoreService: ScoreApiService,
    private modalService: ModalService,
    private dataLoaderService: DataLoaderService
  ) { }

  /**
   * Method that dispatches the built tx to Icon network (through Iconex, Bridge or directly) and triggers the proper notification
   */
  async dispatchTransaction(tx: any, userAction: UserAction): Promise<void> {
    log.debug("dispatchTransaction...");
    try {
      // save action to the persistence service
      this.persistenceService.setLastUserAction(userAction);
      log.debug("setLastUserAction = ", userAction);

      const estimateTx = IconConverter.toRawTransaction(tx);
      delete estimateTx.stepLimit;
      const estimatedStepCost = await this.iconApiService.estimateStepCost(estimateTx);
      log.debug("estimatedStepCost = ", estimatedStepCost);

      if (estimatedStepCost) {
        const estimateCostBuffered = estimatedStepCost.multipliedBy(new BigNumber("1.2")).dp(0);
        tx.stepLimit = this.iconApiService.convertNumberToHex(estimateCostBuffered);
      } else {
        if (userAction.payload instanceof  BondActionPayload) {
          const trueBondPrice = await this.scoreService.getTrueBondPrice(userAction.payload.bond);

          if (trueBondPrice.gt(this.persistenceService.getBondsTruePrice(userAction.payload.bond.tag))) {
            this.modalService.hideActiveModal();
            this.notificationService.showErrorNotification("Bond price has changed unfavorably. Refreshing prices..");
            this.dataLoaderService.loadCoreData().then(() => {
              this.notificationService.showSuccessNotification("Market prices successfully updated after unfavorable price change.");
            })
            return;
          }
        }
      }

      log.debug("Active wallet type = ", this.persistenceService.activeWallet?.type);
      if (this.persistenceService.activeWallet?.type === WalletType.ICON) {
        this.iconexApiService.dispatchSendTransactionEvent(tx, IconexId.SHOW_MESSAGE_HIDE_MODAL);
      }
      else if (this.persistenceService.activeWallet?.type === WalletType.BRIDGE) {
        this.bridgeWidgetService.sendTransaction(tx);
      }
      else if (this.persistenceService.activeWallet?.type === WalletType.LEDGER) {
        const signedRawTx = await this.ledgerService.signTransaction(IconConverter.toRawTransaction(tx));

        const txHash = await this.iconApiService.sendTransaction({
          getProperties: () => signedRawTx,
          getSignature: () => signedRawTx.signature,
        });

        this.transactionResultService.processIconTransactionResult(txHash);
      }
    } catch (e) {
      log.error(e);
      // show error
      this.notificationService.showErrorNotification(userAction.payload.errorMessage());
    }
  }

}
