import {environment} from "../../environment/environment";

export class RestApiUrls {
    public static BASE_REST_API_URL = environment.BASE_REST_API_URL;

    public static USER_REST_API = "/users";

    // ICON REST API
    public static TOKENS_TRANSACTIONS_API_URL = "/address/tokenTxList";
    public static ICX_TRANSACTIONS_API_URL = "/address/txList";
}
