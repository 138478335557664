import { Injectable } from '@angular/core';
import {PersistenceService} from "./persistence.service";
import {StateChangeService} from "./state-change.service";
import {Utils} from "../common/utils";
import {BridgeWidgetAction} from "../models/enums/BridgeWidgetAction";
import {WalletType} from "../models/classes/Wallet";

@Injectable({
  providedIn: 'root'
})
export class LogoutService {

  constructor(
    private persistenceService: PersistenceService,
    private stateChangeService: StateChangeService
  ) { }

  signOutUser(keepBridgeSession: boolean = false): void {
    // if Bridge wallet commit request to Bridge to sign out
    if (!keepBridgeSession && this.persistenceService.activeWallet?.type === WalletType.BRIDGE) {
      this.signOutBridgeUser();
    }

    // commit change to the state change service
    this.stateChangeService.logoutUpdate();
  }

  private signOutBridgeUser(): void {
    Utils.dispatchBridgeWidgetAction(BridgeWidgetAction.LOGOUT);
  }
}
