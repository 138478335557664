import {Component, OnDestroy, OnInit} from '@angular/core';
import {ModalService} from "../../services/modal.service";
import {BaseClass} from "../../base-class";
import {PersistenceService} from "../../services/persistence.service";
import {AppConfigService} from "../../services/app-config.service";
import {BondConfig} from "../../models/classes/BondConfigInfo";
import {CalculationService} from "../../services/calculation-service/calculation.service";
import BigNumber from "bignumber.js";
import {PriceOracleService} from "../../services/price-oracle.service";
import {DataLoaderService} from '../../services/data-loader.service';
import {NotificationService} from '../../services/notification.service';

@Component({
  selector: 'app-bond-marketplace',
  templateUrl: './bond-marketplace.component.html'
})
export class BondMarketplaceComponent extends BaseClass implements OnInit, OnDestroy {

  bonds: BondConfig[] = [];

  roiSortedAscending?: boolean;
  tbvSortedAscending?: boolean;

  coreDataIntervalTime = 10000; // 10 sec
  coreDataInterval?: NodeJS.Timeout;

  constructor(private modalService: ModalService,
              public override persistenceService: PersistenceService,
              private appConfigService: AppConfigService,
              private calculationService: CalculationService,
              private priceOracleService: PriceOracleService,
              private dataLoaderService: DataLoaderService,
              private notificationService: NotificationService) {
    super(persistenceService);
  }

  ngOnInit(): void {
    // load active bonds in bonds array to be rendered
    this.loadBonds();

    // init core data interval
    this.coreDataInterval = setInterval(() => this.triggerReloadOfCoreData(), this.coreDataIntervalTime);
  }

  ngOnDestroy(): void {
    // clear core data reload interval when component is destroyed
    if (this.coreDataInterval) {
      clearInterval(this.coreDataInterval);
    }
  }

  triggerReloadOfCoreData(): void {
    // only trigger if no modal is active and browser tab is active
    if (!this.modalService.activeModal && !document.hidden) {
      this.dataLoaderService.loadCoreData();
    }
  }

  onDiscountClick(): void {
    if (this.roiSortedAscending !== undefined) {
      if (this.roiSortedAscending) {
        // sort descending
        this.bonds.sort((b1, b2) => this.discount(b2) - this.discount(b1));
        this.roiSortedAscending = false;
      } else {
        // sort ascending
        this.bonds.sort((b1, b2) => this.discount(b1) - this.discount(b2));
        this.roiSortedAscending = true;
      }
    } else {
      // sort descending
      this.bonds.sort((b1, b2) => this.discount(b2) - this.discount(b1));
      this.roiSortedAscending = false;
    }
  }

  discount(bond: BondConfig): number {
    return this.calculationService.calculateBondDiscountUSD(bond).toNumber();
  }

  onTbvClick(): void {
    if (this.tbvSortedAscending !== undefined) {
      if (this.tbvSortedAscending) {
        // sort descending
        this.bonds.sort((b1, b2) => this.tbv(b2) - this.tbv(b1));
        this.tbvSortedAscending = false;
      } else {
        // sort ascending
        this.bonds.sort((b1, b2) => this.tbv(b1) - this.tbv(b2));
        this.tbvSortedAscending = true;
      }
    } else {
      // sort descending
      this.bonds.sort((b1, b2) => this.tbv(b2) - this.tbv(b1));
      this.tbvSortedAscending = false;
    }
  }

  tbv(bond: BondConfig): number {
    const payoutTokenPriceUSD = this.priceOracleService.getIrc2TokenPrice(bond.payoutToken);
    const totalPayoutGiven = this.persistenceService.getTotalPayoutGiven(bond.tag) ?? new BigNumber(0)
    return totalPayoutGiven.multipliedBy(payoutTokenPriceUSD).toNumber();
  }

  loadBonds(): void {
    const tmp: BondConfig[] = [];
    this.appConfigService.getSupportedBonds().forEach(bond => {
      if (bond.baseInfo.active) {
        tmp.push(bond);
      }
    });

    this.bonds = tmp;
  }

  onRefreshClick(): void {
    this.dataLoaderService.loadCoreData().then(() => {
      this.notificationService.showSuccessNotification("Successfully reloaded data.");
    });
  }
}
