/*
 * Utility functions for bridge web component
 */
import {environment} from "../../environment/environment";

export function icxValueToNormalValue(icxValue: number, decimals: number): number {
    const normalValue = +(icxValue / Math.pow(10, decimals)).toFixed(2);
    return normalValue;
}

export function parseHexToNumber(value: string | number): number {
    if (typeof value === 'string')
        return parseInt(value, 16)
    else
        return value;
}

export function extractDidEthAddress(issuer: string | null): string {
    if (!issuer) return "";
    return issuer.split(":")[2].trim();
}

export function extractFileExtension(file: string | File): string {
    if (file instanceof File)
        return String(file.name.split(".").pop());
    else
        return file.split(".").pop()!
}

export function stringToDateString(date: string): string {
    return new Date(date).toDateString();
}

export function roundUpToTwoDecimals(number: number): number {
    return Math.ceil(number * Math.pow(10, 2)) / Math.pow(10, 2);
}

export function extractMessageFromError(error: any): string {
    if (error && error.response && error.response.data) return String(error.response.data.message);
    else return String(error);
}

export function extractDetailMessageFromError(error: any): string {
    if (error?.response?.data?.errors?.length > 0) {
        let detail = error.response.data.errors[0].detail;
        if (error.response.data.errors[0].source?.pointer) {
            const splitPointer: string[] = error.response.data.errors[0].source.pointer.split("/");
            const pointer = splitPointer.length > 0 ? splitPointer[splitPointer.length - 1] : ""
            detail = "reason: " + pointer + " , detail: " + detail;
        }
        return detail;
    }
    else
        return "";
}


export function log(message: any, ...args: any) {
    // TODO consider using dedicated logging lib
    if (!environment.production) console.log(message);
    args.forEach((arg: any) => console.log(arg));
}

// format number so that thousands are separated by commas
export function numberWithCommas(amount: string) {
    return parseFloat(amount).toString() === amount ?
        Number(amount).toLocaleString('en') :
        amount;
}

// format date in format yyyy-mm-dd
export function dateWithDashes(date: string) {
    let v = date.replace(/\D/g,'').slice(0, 8);
    if (v.length >= 6) {
        return `${v.slice(0,4)}-${v.slice(4,6)}-${v.slice(6)}`;
    }
    else if (v.length >= 4) {
        return `${v.slice(0,4)}-${v.slice(4)}`;
    }
    return v
}
