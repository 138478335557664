import { Pipe, PipeTransform } from '@angular/core';
import {BigNumber} from "bignumber.js";
import {Utils} from "../common/utils";

@Pipe({
  name: 'toFixed2DecPercent'
})
export class ToFixed2DecPercentPipe implements PipeTransform {

  transform(num?: BigNumber | string, defaultZero = false): string {
    return Utils.to2DecimalFixedPercString(num, defaultZero);
  }

}
