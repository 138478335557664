import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {MyBondsComponent} from './components/my-bonds/my-bonds.component';
import {BondMarketplaceComponent} from './components/bond-marketplace/bond-marketplace.component';
import {StatsComponent} from './components/stats/stats.component';

const routes: Routes = [
  { path: '', component: BondMarketplaceComponent },
  { path: 'my-bonds', component: MyBondsComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'stats', component: StatsComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
