import { Injectable } from '@angular/core';
import {ModalType} from "../models/enums/ModalType";
import {ModalPayload} from "../models/types/Types";
import {ActiveModal} from "../models/interfaces/ActiveModal";
import {StateChangeService} from "./state-change.service";
import log from "loglevel";

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  activeModal?: ActiveModal;
  previousModal?: ActiveModal;

  constructor(private stateChangeService: StateChangeService) { }

  setActiveModal(modalType: ModalType, payload?: ModalPayload): void {
    this.previousModal = this.activeModal;
    this.activeModal = { type: modalType, payload };
    this.stateChangeService.modalUpdate(modalType);
  }

  hideActiveModal(): void {
    this.previousModal = this.activeModal;
    this.activeModal = undefined;
    this.stateChangeService.modalUpdate();
  }

}
