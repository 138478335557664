import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ModalType} from "../../../models/enums/ModalType";
import {ModalService} from "../../../services/modal.service";
import {PersistenceService} from "../../../services/persistence.service";
import {BaseClass} from "../../../base-class";
import {Utils} from "../../../common/utils";
import BigNumber from "bignumber.js";
import {PriceOracleService} from "../../../services/price-oracle.service";
import {defaultPrepLogoUrl, MIN_TREASURY_USD_BALANCE} from '../../../common/constants';
import {CalculationService} from "../../../services/calculation-service/calculation.service";
import {BondConfig} from "../../../models/classes/BondConfigInfo";
import {BondModalPayload} from "../../../models/classes/BondModalPayload";
import {Subscription} from 'rxjs';
import {StateChangeService} from '../../../services/state-change.service';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-bond',
  templateUrl: './bond-row.component.html'
})
export class BondRowComponent extends BaseClass implements OnInit, OnDestroy {

  @Input() bond!: BondConfig;

  // Template variables
  discount = Utils.ZERO;
  isSoldOut = true;
  tbv = Utils.ZERO;
  bondPriceUSD = Utils.ZERO;
  bondIsEnabled = true;
  payoutAvailableUSD = new BigNumber(-1);

  // subscriptions
  afterCoreDataReloadSub?: Subscription;
  bondStatusSub?: Subscription;

  constructor(private modalService: ModalService,
              public override persistenceService: PersistenceService,
              private priceOracleService: PriceOracleService,
              private calculationService: CalculationService,
              private stateChangeService: StateChangeService
              ) {
    super(persistenceService);
  }

  ngOnInit(): void {
    this.initCoreValues();
    this.registerSubscriptions();
  }

  ngOnDestroy(): void {
    this.afterCoreDataReloadSub?.unsubscribe();
    this.bondStatusSub?.unsubscribe();
  }

  registerSubscriptions(): void {
    this.subscribeToCoreDataChange();
    this.subscribeToBondStatusChange();
  }

  subscribeToCoreDataChange(): void {
    this.afterCoreDataReloadSub = this.stateChangeService.coreDataFinishedLoading$.subscribe(() => {
      this.initCoreValues();
    });
  }

  subscribeToBondStatusChange(): void {
    this.bondStatusSub = this.stateChangeService.bondStatusChange$.subscribe(res => {
      if (res.bondTag === this.bond.tag) {
        this.bondIsEnabled = res.status;
      }
    })
  }

  private initCoreValues(): void {
    this.discount = this.calculationService.calculateBondDiscountUSD(this.bond);
    this.tbv = this.getTbv();
    this.bondPriceUSD = this.getBondPriceUSD();
    this.bondIsEnabled = this.persistenceService.bondStatusMap.get(this.bond.tag) ?? false;
    this.payoutAvailableUSD = this.treasuryPayoutUsdValue();
    this.isSoldOut = this.discount.eq(-1) || this.discountIsInvalid() || this.bondTreasuryBalanceInvalid() || this.bondDebtIsInvalid()
      || !this.bond.baseInfo.active || !this.bondIsEnabled || this.payoutAvailableUSD.lt(1);
  }

  bondDebtIsInvalid(): boolean {
    // debt is invalid if max debt - total debt < 10
    return this.persistenceService.getMaxDebt(this.bond.tag).minus(this.persistenceService.getTotalDebt(this.bond.tag))
      .lt(10);
  }

  treasuryPayoutUsdValue(): BigNumber {
    const payoutTreasuryBalance = this.persistenceService.getBondTreasuryPayoutBalance(this.bond.tag);
    const payoutPrice = this.priceOracleService.getIrc2TokenPrice(this.bond.payoutToken);
    const treasuryPayoutUsdValue = payoutTreasuryBalance.multipliedBy(payoutPrice);

    return treasuryPayoutUsdValue.eq(0) || !treasuryPayoutUsdValue ? new BigNumber(-1) : treasuryPayoutUsdValue;
  }

  bondTreasuryBalanceInvalid(): boolean {
    // bond treasury balance is invalid if it has less than 100 $ worth of payout token
    const treasuryPayoutUsdValue = this.treasuryPayoutUsdValue();

    // USD value of payout token held in treasury must exceed USD value of min bond input
    return treasuryPayoutUsdValue.lt(MIN_TREASURY_USD_BALANCE)
  }

  discountIsInvalid(): boolean {
    // discount is invalid if it is more than 90%
    return this.discount.gt(0.9);
  }

  onBondClick() {
    if (!this.isSoldOut) {
      const trueBondPrice = this.persistenceService.getBondsTruePrice(this.bond.tag);
      this.modalService.setActiveModal(ModalType.BOND, new BondModalPayload(this.bond, new BigNumber(trueBondPrice)));
    }
  }

  principalTokenName(): string {
    return Utils.getBondPrincipalTokenName(this.bond)
  }

  getBondPriceUSD(): BigNumber {
    const bondPriceUSD = this.calculationService.calculateBondPriceUSD(this.bond);
    return bondPriceUSD.lte(0) ? new BigNumber(-1) : bondPriceUSD;
  }

  marketPayoutTokenPrice(): BigNumber {
    return this.priceOracleService.getIrc2TokenPrice(this.bond.payoutToken);
  }

  getTbv(): BigNumber {
    const payoutTokenPriceUSD = this.priceOracleService.getIrc2TokenPrice(this.bond.payoutToken);
    const totalPayoutGiven = this.persistenceService.getTotalPayoutGiven(this.bond.tag);

    if (!totalPayoutGiven) {
      return new BigNumber(-1);
    } else {
      return totalPayoutGiven.multipliedBy(payoutTokenPriceUSD);
    }
  }

  principalImg(): string {
    return this.bond.principalToken.img
  }

  payoutImg(): string {
    return this.bond.payoutToken.img;
  }

  handlePrincipalImgError($event: any) {
    $event.target.src = defaultPrepLogoUrl;
  }

  getSkeletonLoadingClass(value?: BigNumber): string {
    return value === undefined || value === null || value.isNaN() || value.eq(-1) ? "skeleton-loading" : ""
  }

  getDiscountSkeletonLoadingClass(): string {
    return this.discount === undefined || this.discount === null || this.discount.isNaN() || this.discount.eq(-1)
    || this.discountIsInvalid() ? "skeleton-loading" : ""
  }

  onGetLpClick(): void {
    window.open(environment.balancedDexUrl, "_blank");
  }
}
