import {BondI} from "../interfaces/BondI";
import {BalnLpToken} from './BalnLpToken';
import {BondTag} from '../types/Types';
import {environment} from "../../../environments/environment";
import {Irc2Token} from './Irc2Token';

export class BondConfigInfo {
  balancedDexAddress: string;
  factoryAddress: string;
  factoryStorageAddress: string;
  subsidyRouterAddress: string;

  bondsConfig: BondConfig[];


  constructor(balancedDexAddress: string, factoryAddress: string, factoryStorageAddress: string,
              subsidyRouterAddress: string, bondsConfig: BondConfig[]) {
    this.balancedDexAddress = balancedDexAddress;
    this.factoryAddress = factoryAddress;
    this.factoryStorageAddress = factoryStorageAddress;
    this.subsidyRouterAddress = subsidyRouterAddress;
    this.bondsConfig = bondsConfig;
  }
}

export class BondConfig {
  baseInfo: BondI;
  principalToken: Irc2Token | BalnLpToken;
  payoutToken: Irc2Token;
  tag: BondTag;

  constructor(baseInfo: BondI, principalToken: Irc2Token | BalnLpToken, payoutToken: Irc2Token) {
    this.baseInfo = baseInfo;
    this.principalToken = principalToken;
    this.payoutToken = payoutToken;
    // hash few base infos to create unique ID
    this.tag = `${baseInfo.principalTokenName}|${baseInfo.payoutTokenName}|${baseInfo.customBond}`;
  }

  isPrincipalLp(): boolean {
    return this.principalToken instanceof BalnLpToken;
  }

  getBaseToken(): Irc2Token {
    if (this.principalToken instanceof BalnLpToken) {
      return this.principalToken.baseToken;
    }
    throw new Error("[getBaseToken()] principalToken is not instanceof BalnLpToken!");
  }

  getQuoteToken(): Irc2Token {
    if (this.principalToken instanceof BalnLpToken) {
      return this.principalToken.quoteToken;
    }
    throw new Error("[getBaseToken()] principalToken is not instanceof BalnLpToken!");
  }

  getCustomBondTrackerUrl(): string {
    return environment.trackerUrl + "/contract/" + this.baseInfo.customBond;
  }
}
