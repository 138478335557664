<div class="table table-content">
  <div class="table-item table-item-col-4">
    <div style="position: relative">
      <img src="assets/images/{{principalImg()}}" (error)="handlePrincipalImgError($event)" loading="lazy"
           width="{{bond.baseInfo.principalIsLpToken ? '58' : '32'}}" alt="" class="table-item-pair-image">
      <span class="dot-{{bondIsEnabled ? 'green' : 'red'}}"></span>
    </div>
    <div class="table-item-pair-content">
      <div class="table-item-pair-name">{{principalTokenName()}}</div>
    </div>
  </div>
  <div class="table-item table-item-col-4">
    <img src="assets/images/{{payoutImg()}}" (error)="handlePrincipalImgError($event)"  loading="lazy" width="32" alt="" class="table-item-pair-image">
    <div class="table-item-pair-content">
      <div class="table-item-pair-name">{{treasuryPayoutBalance.toFixed(2) | usFormat | toZeroIfDash}}</div>
      <div class="table-item-pair-subtext">${{treasuryPayoutUsdBalance.toFixed(2) | usFormat | toZeroIfDash}}</div>
    </div>
  </div>

  <div class="table-item table-item-col-4">
    <img src="assets/images/{{principalImg()}}" (error)="handlePrincipalImgError($event)"  loading="lazy" width="{{bond.baseInfo.principalIsLpToken ? '58' : '32'}}" alt="" class="table-item-pair-image">
    <div class="table-item-pair-content">
      <div class="table-item-pair-name">{{treasuryPrincipalBalance.toFixed(2) | usFormat | toZeroIfDash}}</div>
      <div class="table-item-pair-subtext">${{treasuryPrincipalUsdBalance.toFixed(2) | usFormat | toZeroIfDash}}</div>
    </div>
  </div>
</div>
