import {ActionPayloadI} from '../interfaces/ActionPayloadI';
import {BondConfig} from './BondConfigInfo';
import BigNumber from 'bignumber.js';
import {Utils} from '../../common/utils';

export class WithdrawPayoutSuccessPayload implements ActionPayloadI {
  amount: BigNumber;
  tokenAddress: string;
  destination: string;
  bond: BondConfig;
  txHash: string;

  constructor(bond: BondConfig, amount: BigNumber, tokenAddress: string, destination: string, txHash: string) {
    this.amount = amount;
    this.tokenAddress = tokenAddress;
    this.destination = destination;
    this.bond = bond;
    this.txHash = txHash;
  }

  successMessage(): string {
    return `Successfully withdrew ${Utils.formatNumberToUSLocaleString(this.amount.dp(2))} ${this.bond.payoutToken.tag}.}`
  }

  errorMessage(): string {
    return `Failed to withdraw ${this.bond.payoutToken.tag}. Please try again in a moment.`
  }
}
