/* ==========================================================================
    Formats
========================================================================== */

// declare wNumb

import {BOND_INPUT_DECIMAL_PRECISION} from "./constants";

declare var wNumb: any;

// %
export const normalFormat = wNumb({
  decimals: BOND_INPUT_DECIMAL_PRECISION,
  thousand: ",",
});

// 000,000.00
export const usLocale = wNumb({
  decimals: 2,
  thousand: ",",
});

// %
export const percentageFormat = wNumb({
  decimals: 0,
  suffix: "%"
});

// + .00
export const prefixPlusFormat = wNumb({
  decimals: 2,
  thousand: ",",
  prefix: " + "
});

export function assetFormat(tokenTag: string): any {
    return wNumb({
      decimals: 2,
      thousand: ",",
      suffix: ` ${tokenTag}`
    });
}

// + ICX .00
export function assetPrefixPlusFormat(tokenTag: string): any {
  return wNumb({
    decimals: 2,
    thousand: ",",
    prefix: " + ",
    suffix: ` ${tokenTag}`
  });
}

// - ICX .00
export function assetPrefixMinusFormat(tokenTag: string): any {
  return wNumb({
    decimals: 2,
    thousand: ",",
    prefix: " - ",
    suffix: ` ${tokenTag}`
  });
}

// + OMM .00
export const ommPrefixPlusFormat = wNumb({
  decimals: 2,
  thousand: ",",
  prefix: " + ",
  suffix: " OMM"
});

// ICD
export const icdFormat = wNumb({
  decimals: 0,
  thousand: ",",
  suffix: " ICD"
});

// $
export const usdFormat = wNumb({
  decimals: 0,
  thousand: ",",
  prefix: "$"
});

// $ .00
export const usdTwoDecimalFormat = wNumb({
  decimals: 2,
  thousand: ",",
  prefix: "$"
});

// +$ .00
export const usdTwoDecimalPlusFormat = wNumb({
  decimals: 2,
  thousand: ",",
  prefix: "+ $"
});

// -$ .00
export const usdTwoDecimalMinusFormat = wNumb({
  decimals: 2,
  thousand: ",",
  prefix: "- $"
});

