export class CodeLists {

    // Bridge SCORE symbol
    public static BRIDGE_SYMBOL = "USDS";

    // SICX SCORE symbol
    public static SICX_SYMBOL = "sICX";

    // IUSDC SCORE symbol
    public static IUSDC_SYMBOL = "IUSDC";

    // OMM SCORE symbol
    public static OMM_SYMBOL = "OMM";
}