import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CoingeckoTokenApiI} from '../models/interfaces/CoingeckoTokenApiI';
import log from 'loglevel';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CoingeckoService {

  constructor(private http: HttpClient) { }

  getIrc2TokenUsdPrice(tokenApi: CoingeckoTokenApiI): Observable<any> {
    log.debug(`[Coingecko] Retrieving token price for ${tokenApi.irc2TokenTag}`);
    return this.http.get<any>(tokenApi.endpoint);
  }
}
