import {Injectable} from '@angular/core';
import log from 'loglevel';
import {DataLoaderService} from './data-loader.service';
import {PersistenceService} from './persistence.service';
import {IconApiService} from './icon-api.service';
import {StateChangeService} from './state-change.service';
import {ScoreApiService} from './score-api.service';
import {NotificationService} from './notification.service';
import {UserActionType} from '../models/enums/UserActionType';
import {ModalService} from './modal.service';
import {ModalType} from '../models/enums/ModalType';
import {BondSuccessPayload} from '../models/classes/BondSuccessPayload';
import {BondActionPayload} from '../models/classes/BondActionPayload';
import {ClaimBondSuccessPayload} from '../models/classes/ClaimBondSuccessPayload';
import {ToggleBondSuccessPayload} from '../models/classes/ToggleBondSuccessPayload';
import {WithdrawPayoutSuccessPayload} from '../models/classes/WithdrawPayoutSuccessPayload';
import {WithdrawPrincipalSuccessPayload} from '../models/classes/WithdrawPrincipalSuccessPayload';
import {TopUpPayloadSuccess} from '../models/classes/TopUpPayloadSuccess';
import {UNKNOWN_FAILURE} from '../common/constants';

@Injectable({
  providedIn: 'root'
})
export class TransactionResultService {

  constructor(private iconApiService: IconApiService,
              private scoreService: ScoreApiService,
              private persistenceService: PersistenceService,
              private dataLoaderService: DataLoaderService,
              private stateChangeService: StateChangeService,
              private notificationService: NotificationService,
              private modalService: ModalService) {
    window.addEventListener("bri.tx.result", (e) => this.processBridgeTransactionResult(e));
  }

  public async processIconexTransactionResult(payload: any, maxRetry: number = 5): Promise<void> {
    if (payload?.result) {
      try {
        const txResult = await this.iconApiService.getTxResult(payload.result);
        // reload all reserves and user specific data (reserve, account data, ..)
        this.dataLoaderService.afterUserActionReload();

        // success
        if (txResult.status === 1) {
          // show success notification
          this.showSuccessActionNotification(payload.result);
        } else {
          log.error(txResult);
          // show failed notification
          this.showFailedActionNotification(txResult);
        }
      } catch (e) {
        if (maxRetry > 0) {
          setTimeout( () => this.processIconexTransactionResult(payload, maxRetry - 1), 2200);
        } else {
          log.debug("Error in isTxConfirmed:", e);

          // show failed confirmation message
          this.notificationService.showErrorNotification("Failed to confirm the transaction.");

          // reload all reserves and user specific data (reserve, account data, ..)
          this.dataLoaderService.afterUserActionReload();
        }
      }
    } else  {
      // reload all reserves and user specific data (reserve, account data, ..)
      this.dataLoaderService.afterUserActionReload();

      log.error(`ICON RPC ERROR details:`);
      log.error(payload);

      // hide active modal
      this.modalService.hideActiveModal();
      // show failed confirmation message
      this.notificationService.showErrorNotification(payload?.message ?? "ICON RPC ERROR.");
    }
  }

  async processIconTransactionResult(txHash: string, maxRetry: number = 5): Promise<void> {
    try {
      const txResult = await this.iconApiService.getTxResult(txHash);
      // reload all reserves and user specific data (reserve, account data, ..)
      this.dataLoaderService.afterUserActionReload();

      // success
      if (txResult.status === 1) {
        // show proper success notification
        log.debug("[processIconTransactionResult] SUCCESS!");
        this.showSuccessActionNotification(txHash);
      } else {
        // show proper failed notification
        this.showFailedActionNotification(txResult);
        log.debug("Transaction failed! Details: ", txResult);
      }
    } catch (e) {
      if (maxRetry > 0) {
        setTimeout(() => this.processIconTransactionResult(txHash, maxRetry - 1), 2200);
      } else {
        // reload all reserves and user specific data (reserve, account data, ..)
        this.dataLoaderService.afterUserActionReload();

        log.debug("Error in isTxConfirmed:", e);
        this.showFailedActionNotification();
      }
    }
  }

  public processBridgeTransactionResult(event: any): void {
    const {txHash, error, status} = event.detail;

    // reload all reserves and user specific data (reserve, account data, ..)
    this.dataLoaderService.afterUserActionReload();

    // success
    if (status === 1) {
      // show success notification
      this.showSuccessActionNotification(txHash);
    }
    else {
      log.debug("Bridge: transaction failed, details:", error);
      // show failed notification
      this.showFailedActionNotification();
    }
  }

  public showSuccessActionNotification(txHash: string): void {
    const userAction = this.persistenceService.getLastUserAction();

    if (!userAction) return;

    switch (userAction.type) {
      case UserActionType.BOND:
        if (userAction.payload instanceof BondActionPayload) {
          this.modalService.setActiveModal(ModalType.BOND_SUCCESS,
            new BondSuccessPayload(userAction.payload.payoutAmount, userAction.payload.vestingTerm, txHash,
              userAction.payload.bKarmaAmount));
        } else {
          log.error("[TransactionResult] User action BOND does not contain BondActionPayload instance!");
        }
        break;
      case UserActionType.CLAIM:
        if (userAction.payload instanceof ClaimBondSuccessPayload) {
          userAction.payload.txHash = txHash;
          this.modalService.setActiveModal(ModalType.CLAIM_SUCCESS, userAction.payload);
        } else {
          log.error("[TransactionResult] User action CLAIM does not contain ClaimBondSuccessPayload instance!");
        }
        break;
      case UserActionType.TOGGLE_BOND:
        if (userAction.payload instanceof ToggleBondSuccessPayload) {
          userAction.payload.txHash = txHash;
          this.modalService.setActiveModal(ModalType.TOGGLE_BOND_SUCCESS, userAction.payload);
        } else {
          log.error("[TransactionResult] User action TOGGLE BOND does not contain ToggleBondSuccessPayload instance!");
        }
        break;
      case UserActionType.WITHDRAW_PAYOUT_TOKEN:
        if (userAction.payload instanceof WithdrawPayoutSuccessPayload) {
          userAction.payload.txHash = txHash;
          this.modalService.setActiveModal(ModalType.WITHDRAW_PAYOUT_SUCCESS, userAction.payload);
        } else {
          log.error("[TransactionResult] User action WITHDRAW IRC2 does not contain WithdrawIrc2SuccessPayload instance!");
        }
        break;
      case UserActionType.WITHDRAW_PRINCIPAL_TOKEN:
        if (userAction.payload instanceof WithdrawPrincipalSuccessPayload) {
          userAction.payload.txHash = txHash;
          this.modalService.setActiveModal(ModalType.WITHDRAW_PRINCIPAL_SUCCESS, userAction.payload);
        } else {
          log.error("[TransactionResult] User action WITHDRAW principal does not contain WithdrawPrincipalSuccessPayload instance!");
        }
        break;
      case UserActionType.TOP_UP_PAYOUT_TOKEN:
        if (userAction.payload instanceof TopUpPayloadSuccess) {
          userAction.payload.txHash = txHash;
          this.modalService.setActiveModal(ModalType.TOP_UP_PAYOUT_SUCCESS, userAction.payload);
        } else {
          log.error("[TransactionResult] User action TOP UP payout does not contain TopUpPayloadSuccess instance!");
        }
        break;
    }
  }

  public showFailedActionNotification(txResult?: any): void {
    // hide active modal
    this.modalService.hideActiveModal();

    const userAction = this.persistenceService.getLastUserAction();

    if (!userAction) return;

    if (txResult?.failure?.message !== UNKNOWN_FAILURE) {
      this.notificationService.showErrorNotification(userAction.payload.errorMessage() + txResult?.failure?.message?.toString().substring(0, 30)
        + "...");
    } else {
      this.notificationService.showErrorNotification(userAction.payload.errorMessage());
    }
  }
}
