import { Injectable } from '@angular/core';
import {NotificationI} from "../models/interfaces/NotificationI";
import {NotificationType} from "../models/enums/NotificationType";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private activeNotification?: NotificationI;

  constructor() { }

  getActiveNotification(): NotificationI | undefined {
    return this.activeNotification;
  }

  hideActiveNotification(): void {
    this.activeNotification = undefined;
  }

  showAlertNotification(message: string): void {
    this.activeNotification = {
      type: NotificationType.ALERT,
      message
    };
  }

  showInfoNotification(message: string): void {
    this.activeNotification = {
      type: NotificationType.INFO,
      message
    };
  }

  showErrorNotification(message: string): void {
    this.activeNotification = {
      type: NotificationType.ERROR,
      message
    };
  }

  showSuccessNotification(message: string): void {
    this.activeNotification = {
      type: NotificationType.SUCCESS,
      message
    };
  }
}
