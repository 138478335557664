import BigNumber from "bignumber.js";
import {BondConfig} from "./BondConfigInfo";
import {ActionPayloadI} from "../interfaces/ActionPayloadI";

export class ClaimBondSuccessPayload implements ActionPayloadI {
  claimed: BigNumber;
  bond: BondConfig;
  txHash: string;

  constructor(claimed: BigNumber, bond: BondConfig, txHash: string) {
    this.claimed = claimed;
    this.bond = bond;
    this.txHash = txHash;
  }

  successMessage(): string {
    return `Successfully claimed ${this.claimed.toString()} ${this.bond.payoutToken} tokens.`
  }

  errorMessage(): string {
    return "Failed to claim. Please try again in a moment."
  }
}
