<div *ngIf="isSuccessNotification()" class="message message-success" [ngStyle]="displayAsBlockOrHide(isSuccessNotification())">
  <div class="message-content"><img src="assets/images/message-success-icon.svg" loading="lazy" alt="" class="message-icon">
    <div class="message-text">{{activeNotification()?.message}}</div>
    <a href="javascript:void(0);" class="message-close w-inline-block" (click)="onCloseClick()">
      <img src="assets/images/message-close.svg" loading="lazy" alt="" class="message-close-icon">
    </a>
  </div>
</div>

<div *ngIf="isAlertNotification()" class="message message-alert" [ngStyle]="displayAsBlockOrHide(isAlertNotification())">
  <div class="message-content"><img src="assets/images/message-alert-icon.svg" loading="lazy" alt="" class="message-icon">
    <div class="message-text">{{activeNotification()?.message}}</div>
    <a href="javascript:void(0);" class="message-close w-inline-block" (click)="onCloseClick()">
      <img src="assets/images/message-close.svg" loading="lazy" alt="" class="message-close-icon">
    </a>
  </div>
</div>

<div *ngIf="isErrorNotification()" class="message message-error" [ngStyle]="displayAsBlockOrHide(isErrorNotification())">
  <div class="message-content"><img src="assets/images/message-error-icon.svg" loading="lazy" alt="" class="message-icon">
    <div class="message-text">{{activeNotification()?.message}}</div>
    <a href="javascript:void(0);" class="message-close w-inline-block" (click)="onCloseClick()">
      <img src="assets/images/message-close.svg" loading="lazy" alt="" class="message-close-icon">
    </a>
  </div>
</div>

<div *ngIf="isInfoNotification()" class="message message-info" [ngStyle]="displayAsBlockOrHide(isInfoNotification())">
  <div class="message-content"><img src="assets/images/icon-message-info.svg" loading="lazy" alt="" class="message-icon">
    <div class="message-text">{{activeNotification()?.message}}</div>
    <a href="javascript:void(0);" class="message-close w-inline-block" (click)="onCloseClick()">
      <img src="assets/images/message-close.svg" loading="lazy" alt="" class="message-close-icon">
    </a>
  </div>
</div>
