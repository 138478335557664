import { Injectable } from '@angular/core';
import {Wallet} from "../models/classes/Wallet";
import {Terms} from "../models/classes/Terms";
import {BondTag, Irc2TokenTag, PoolId} from '../models/types/Types';
import BigNumber from "bignumber.js";
import {PoolStats} from "../models/classes/PoolStats";
import {UserAction} from "../models/classes/UserAction";
import {BondInfo} from "../models/classes/BondInfo";
import {Irc2Token} from '../models/classes/Irc2Token';
import {BalnLpToken} from '../models/classes/BalnLpToken';
import {BalnLpTokenI} from '../models/interfaces/BalnLpTokenI';
import {BondConfig} from '../models/classes/BondConfigInfo';

@Injectable({
  providedIn: 'root'
})
export class PersistenceService {

  activeWallet?: Wallet;

  /**
   * Bond related state data
   */
  bondTagToTermsMap = new Map<BondTag, Terms>();
  bondTagToKarmaFeeMap = new Map<BondTag, BigNumber>();
  bondTagToTotalDebtMap = new Map<BondTag, BigNumber>();
  bondTagToMaxPayoutMap = new Map<BondTag, BigNumber>();
  bondTagToTrueBondPriceMap = new Map<BondTag, BigNumber>();
  bondTagToBondPriceMap = new Map<BondTag, BigNumber>(); // NOTE: bond price is not normalised
  bondTagToTotalPayoutGivenMap = new Map<BondTag, BigNumber>();
  balancedPoolStatsMap = new Map<PoolId, PoolStats>();
  bondTreasuryPayoutBalanceMap = new Map<BondTag, BigNumber>();
  bondTreasuryPrincipalBalanceMap = new Map<BondTag, BigNumber>();
  bondStatusMap = new Map<BondTag, boolean>();
  treasuryOwnerMap = new Map<BondTag, string>(); // bond to address map

  /**
   * User related state data
   */
  private userIrc2TokenBalances = new Map<Irc2TokenTag, BigNumber>();
  private userBalnLpTokenBalances = new Map<PoolId, BigNumber>();
  userBondTagToBondInfoMap = new Map<BondTag, BondInfo>();
  userBondTagToPendingPayoutForMap = new Map<BondTag, BigNumber>();
  userBondTagToPercentVestedForMap = new Map<BondTag, BigNumber>();
  userTreasuries = new Map<BondTag, BondConfig>();

  private lastUserAction?: UserAction;

  constructor() {
  }

  userHasActiveBond(): boolean {
    for (let [key, value] of this.userBondTagToBondInfoMap) {
      if (!value.payout.isZero()) {
        return true;
      }
    }
    return false;
  }

  loginUser(wallet: Wallet): void {
    this.resetUserData();
    this.activeWallet = wallet
  }

  logoutUser(): void {
    this.activeWallet = undefined;
    this.resetUserData();
  }

  userIsLoggedIn(): boolean {
    return this.activeWallet !== undefined;
  }

  resetUserData(): void {
    this.userIrc2TokenBalances = new Map<Irc2TokenTag, BigNumber>();
    this.userBalnLpTokenBalances = new Map<PoolId, BigNumber>();
    this.userBondTagToBondInfoMap = new Map<BondTag, BondInfo>();
    this.userBondTagToPendingPayoutForMap = new Map<BondTag, BigNumber>();
    this.userBondTagToPercentVestedForMap = new Map<BondTag, BigNumber>();
    this.userTreasuries = new Map<BondTag, BondConfig>();
    this.lastUserAction = undefined;
  }

  getUserTreasuriesNumber(): number {
    return this.userTreasuries.size;
  }

  getBondTreasuryPayoutBalance(bondTag?: BondTag): BigNumber {
    if (!bondTag) {
      return new BigNumber(0)
    }

    return this.bondTreasuryPayoutBalanceMap.get(bondTag) ?? new BigNumber(0);
  }

  getBondTreasuryPrincipalBalance(bondTag: BondTag): BigNumber {
    return this.bondTreasuryPrincipalBalanceMap.get(bondTag) ?? new BigNumber(0);
  }

  getCurrentKarmaBondFee(bondTag: BondTag): BigNumber {
    return this.bondTagToKarmaFeeMap.get(bondTag) ?? new BigNumber(0);
  }

  getMaxDebt(bondTag: BondTag): BigNumber {
    return this.bondTagToTermsMap.get(bondTag)?.maxDebt ?? new BigNumber(0);
  }

  getTotalDebt(bondTag: BondTag): BigNumber {
    return this.bondTagToTotalDebtMap.get(bondTag) ?? new BigNumber(0);
  }

  getPendingPayoutFor(bondTag: BondTag): BigNumber {
    return this.userBondTagToPendingPayoutForMap.get(bondTag) ?? new BigNumber(0);
  }

  getPercentVestedFor(bondTag: BondTag): BigNumber {
    return this.userBondTagToPercentVestedForMap.get(bondTag) ?? new BigNumber(0);
  }

  getLastUserAction(): UserAction | undefined {
    return this.lastUserAction;
  }

  getTotalPayoutGiven(bondTag: BondTag): BigNumber | undefined {
    return this.bondTagToTotalPayoutGivenMap.get(bondTag);
  }

  getUserLpBalance(poolId: PoolId): BigNumber {
    return this.userBalnLpTokenBalances.get(poolId) ?? new BigNumber(0);
  }

  getBalancedPoolDecimals(poolId: number): BigNumber | undefined {
    return this.balancedPoolStatsMap.get(poolId)?.getPrecision();
  }

  getBondsTruePrice(bondTag: BondTag): BigNumber {
    return this.bondTagToTrueBondPriceMap.get(bondTag) ?? new BigNumber(0);
  }

  getBondsPrice(bondTag: BondTag): BigNumber {
    return this.bondTagToBondPriceMap.get(bondTag) ?? new BigNumber(0);
  }

  getBondsMaxPayout(bondTag: BondTag): BigNumber {
    return this.bondTagToMaxPayoutMap.get(bondTag) ?? new BigNumber(0);
  }

  getBondsVestingTermInSeconds(bondTag: BondTag): BigNumber {
    // multiply by 2 second block time
    return this.bondTagToTermsMap.get(bondTag)?.vestingTerm.multipliedBy(2) ?? new BigNumber(0);
  }

  setLastUserAction(action?: UserAction): void {
    this.lastUserAction = action;
  }

  getUserTokenBalance(token: Irc2Token | BalnLpToken): BigNumber {
    if (token instanceof Irc2Token) {
      return this.userIrc2TokenBalances.get(token.tag) ?? new BigNumber(0);
    } else {
      return this.userBalnLpTokenBalances.get(token.poolId) ?? new BigNumber(0);
    }
  }

  getUserIrc2TokenBalance(token: Irc2Token): BigNumber {
    return this.userIrc2TokenBalances.get(token.tag) ?? new BigNumber(0);
  }

  getUserBalnLpTokenBalance(token: BalnLpToken): BigNumber {
    return this.userBalnLpTokenBalances.get(token.poolId) ?? new BigNumber(0);
  }

  getUserBalnLpTokenIBalance(token: BalnLpTokenI): BigNumber {
    return this.userBalnLpTokenBalances.get(token.poolId) ?? new BigNumber(0);
  }

  setUserIrc2TokenBalance(token: Irc2Token, balance: BigNumber): void {
    this.userIrc2TokenBalances.set(token.tag, balance);
  }

  setUserBalnLpTokenBalance(token: BalnLpToken, balance: BigNumber): void {
    this.userBalnLpTokenBalances.set(token.poolId, balance);
  }

  setUserIrc2TokenBalances(balances: Map<Irc2TokenTag, BigNumber>): void {
    this.userIrc2TokenBalances = balances;
  }

  setUserBalnLpTokenBalances(balances: Map<PoolId, BigNumber>): void {
    this.userBalnLpTokenBalances = balances;
  }

}
