import BigNumber from "bignumber.js";
import {BondConfig} from "./BondConfigInfo";

export class BondClaimModalPayload {
  /**
   * @description Payload class for the claim bonding modal
   */

  claimable: BigNumber;
  pending: BigNumber;
  roi: BigNumber;
  vestingTerm: BigNumber;
  bondPrice: BigNumber;
  marketPrice: BigNumber;
  bond: BondConfig;

  constructor(claimable: BigNumber, pending: BigNumber, roi: BigNumber, vestingTerm: BigNumber, bondPrice: BigNumber,
              marketPrice: BigNumber, bond: BondConfig) {
    this.claimable = claimable;
    this.pending = pending;
    this.roi = roi;
    this.vestingTerm = vestingTerm;
    this.bondPrice = bondPrice;
    this.marketPrice = marketPrice;
    this.bond = bond;
  }
}
